var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',{attrs:{"id":_vm.id,"centered":"","hide-header":"","hide-footer":"","no-close-on-backdrop":"","body-class":"p-0","content-class":"border-gary width-400","modal-class":"border-radius-2"}},[_c('div',{staticClass:"rs-04-03-c-4-1"},[_c('div',{staticClass:"flex-row"},[_c('div',{staticClass:"overlap-group"}),_c('img',{staticClass:"frame-1041-3",attrs:{"src":require("@/assets/img/common/frame-1041.svg"),"alt":"Frame 1041"},on:{"click":function($event){return _vm.clickEvt()}}})]),_c('div',{staticClass:"content-css"},[(_vm.contentFrontImgUrl)?_c('img',{staticClass:"img-size",attrs:{"src":require(`@/${_vm.contentFrontImgUrl}`)}}):_vm._e(),_c('p',{staticClass:"text-3 pretendard-regular-normal-daintree-18px text-center",domProps:{"innerHTML":_vm._s(_vm.content)}})]),(_vm.desc)?_c('p',{staticClass:"text-5",domProps:{"innerHTML":_vm._s(_vm.desc)}}):_vm._e(),_c('div',{staticClass:"button-container"},[_c('div',{staticClass:"d-flex justify-content-center"},_vm._l((_vm.btn),function(b,idx){return _c('div',{key:`modal_btn_${_vm.id}_${idx}`,class:[
            `${
              _vm.btn.length === 1
                ? 'button-5'
                : b.color === 'white'
                ? 'button-3'
                : 'button-4'
            }`,
            `${idx !== _vm.btn.length - 1 ? 'mr-3' : ''}`,
          ],staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clickEvt(b.clickMethod)}}},[_c('div',{class:[
              'label-1',
              'text-center',
              'label-4',
              b.color === 'white'
                ? 'pretendard-regular-normal-daintree-18px'
                : 'pretendard-regular-normal-white-18px',
            ]},[_vm._v(" "+_vm._s(b.text)+" ")])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }