
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class PolicyDetail extends Vue {
  private pageMode: string = 'service';

  mounted() {
    if (this.$route.path.includes('service')) {
      this.pageMode = 'service';
    } else {
      this.pageMode = 'privacy';
    }
  }

  movePolicy(policy: string) {
    return (this.pageMode = policy);
  }
}
