import { config, CognitoIdentityCredentials, S3 } from 'aws-sdk';
import { FileInfoProp } from '../../interfaces/notice.interface';
import { s3FileUpload } from '@/interface/project';
import {S3_SET} from "@/enum/common";


export class s3Upload {
  private fileS3: FileInfoProp[];
  private addFiles: FileInfoProp[] = [];
  private files: any[] = [];
  private pathName: string = '';
  private s3UploadFile: s3FileUpload = {
    Bucket: '',
    Etag: '',
    Key: '',
    Location: ''
  };
  constructor(file: any[] = [], fileInfo: any[] = [], path: string = '') {
    this.files = file;
    this.fileS3 = fileInfo;
    this.pathName = path;
  }
  /*{
      BFile_Nm: '',
      BFile_Type: 'application/octet-stream',
      BFile_URL: '',
      BFile_Size: 0,
    }*/
  async upload(fileCheck: boolean = false) {
    //메서드 3개,
    if (fileCheck) {
      if (this.files.length > 0 && this.fileS3.length > 0) {
        await this.deleteS3();
      }
    }
    return await this.uploadS3();
  }
  isUpload(count: number, size: string) {
    //true: 통과 못함
    //false: 패스
    let message = '';
    const isCount = this.fileCount(count);
    if (!message && isCount)
      message = `파일첨부 개수는 ${count}개까지만 가능합니다.`;
    const isSize = this.getFileSize(size);
    if (!message && isSize)
      message = `파일첨부 전체 용량은 ${size}까지만 가능합니다.`;
    const isExtension = this.fileExtension();
    if (!message && isExtension)
      message = `파일첨부된 파일 확장자(.doc, .docx, .hwp, .pdf, .ppt, .psd, .txt, .xls, .xlsx)만 가능합니다.`;
    return { bool: isCount || isSize || isExtension, message };
  }
  async delete() {
    await this.deleteS3();
  }
  get getFiles() {
    return this.fileS3;
  }
  get getAddFiles() {
    return this.addFiles;
  }
  get getOriginalFile() {
    return this.s3UploadFile;
  }
  private fileCount(count: number) {
    const fileS3Length = this.fileS3.length;
    const filesLength = this.files.length;
    const fileAllCount = fileS3Length + filesLength;
    return fileAllCount > count;
  }
  private getFileSize(sizeByte: string) {
    const sizeNum = Number(sizeByte.replace('MB', ''));
    const files = this.files;
    let filesSize = 0;
    files.forEach(file => {
      filesSize += file.size;
    });
    let isSize = false;
    //MB 단위
    if (filesSize < 1024 * 1024 * 1024) {
      const culSize = filesSize / (1024 * 1024);
      if (sizeNum < culSize) isSize = true;
    }
    return isSize;
  }
  private fileExtension() {
    const extensions = [
      '.doc',
      '.docx',
      '.hwp',
      '.pdf',
      '.ppt',
      '.psd',
      '.txt',
      '.xls',
      '.xlsx',
    ];

    const fileResult = this.files.filter(file => {
      const findExt = extensions.filter(item => {
        const { name } = file;
        return name.indexOf(item) > -1 ? name : '';
      });
      return findExt.length > 0 ? file : '';
    });
    return !(this.files.length === fileResult.length);
  }
  private async uploadS3() {
    this.setConfigUpdate();
    const s3 = this.setS3();
    const uploadFiles = this.setUploadFile();
    if (uploadFiles) {
      for (const param of uploadFiles) {
        const {_params: uploadResult, data} = await this.putObject(s3, param);
        const newFile = {
          BFile_URL: `${S3_SET.URL}${uploadResult.Key}`,
          BFile_Nm: uploadResult.Body.name,
          BFile_Type: uploadResult.ContentType,
          BFile_Size: uploadResult.Body.size,
        };
        this.addFiles.push(newFile);
        this.fileS3.push(newFile);
        this.s3UploadFile = data;
      }
      return true;
    }
    return false;
  }
  private setS3() {
    return new S3({
      apiVersion: S3_SET.APIVERSION,
      params: { Bucket: S3_SET.BUCKET },
    });
  }
  private setConfigUpdate() {
    config.update({
      region: S3_SET.REGION,
      credentials: new CognitoIdentityCredentials({
        IdentityPoolId: S3_SET.IDENTITYPOOLID,
      }),
    });
  }
  private setUploadFile() {
    if (this.files.length > 0) {
      const array: {
        Bucket: string;
        Key: string;
        ContentType: string;
        Body: any;
        ACL: string;
      }[] = [];
      this.files.forEach(file => {
        const reName = file.name.replace(/[\\+\\%\\!\\@\\#\\$\\^\\&\\*]/g, '_');
        array.push({
          Bucket: S3_SET.BUCKET,
          Key: `${S3_SET.KEY}${
            this.pathName
          }/${new Date().getTime()}_${reName}`,
          ContentType: 'applcation/octet-stream',
          Body: file,
          ACL: 'public-read',
        });
      });
      return array;
    }
  }
  async putObject(
    s3: any,
    _params: {
      Bucket: string;
      Key: string;
      ContentType: string;
      Body: any;
      ACL: string;
    },
  ): Promise<{_params: {
    Bucket: string;
    Key: string;
    ContentType: string;
    Body: File;
    ACL: string;
  }, data: {
    Bucket: string,
      Etag: string,
      Key: string,
      Location: string
  }}> {
    return new Promise((resolve, reject) => {
      s3.upload(_params, (err: any, data: any) => {
        if (err) return reject(err);
        return resolve({_params, data});
      }).on('httpUploadProgress', (progress: any) => {
        const { loaded, total } = progress;
        progress = (loaded / total) * 100;
      });
    });
  }

  private async deleteS3() {
    this.setConfigUpdate();
    const s3 = this.setS3();
    this.fileS3.forEach(item => {
      const params = {
        Bucket: S3_SET.BUCKET,
        Key: `${item.BFile_URL.replace(
          `${S3_SET.URL}`,
          '',
        )}`,
      };
      new Promise((resolve, reject) => {
        s3.deleteObject(params, (err, data) => {
          if (err) return reject(err);
          return resolve(params);
        }).on('complete', () => {
          console.log('삭제 완료');
        });
      });
    });
  }
}
