
import { Component, Prop, Vue } from 'vue-property-decorator';
import { visualizeTypeMatchingTableByKr } from '@/common/visualize';

@Component({
  computed: {
    visualizeTypeMatchingTableByKr() {
      return visualizeTypeMatchingTableByKr;
    },
  },
})

export default class History extends Vue {
  @Prop() history!: { type: string }[];

  imgSrc(type: string) {
    return require(`@/assets/img/visualization/${type}.svg`);
  }
}
