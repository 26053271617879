var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:`${
    _vm.modelValue.includes(_vm.profileOption.value)
      ? 'overlap-group-7'
      : 'overlap-group2'
  }`,style:({ cursor: _vm.disabled ? 'auto' : 'pointer' }),on:{"click":function($event){return _vm.profileClick(_vm.profileOption.value)}}},[_c('div',{class:`profile-text valign-text-middle pretendard-regular-normal-${
      _vm.modelValue.includes(_vm.profileOption.value) ? 'white' : 'gravel'
    }-16px`},[_vm._v(" "+_vm._s(_vm.profileOption.text)+" ")]),_c('img',{staticClass:"check_ring_light",attrs:{"src":require(`@/assets/img/data-set/check-ring-light${
        _vm.modelValue.includes(_vm.profileOption.value) ? '' : '-2'
      }.svg`)}})])
}
var staticRenderFns = []

export { render, staticRenderFns }