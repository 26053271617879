<template>
  <Transition>
    <div
      :class="`guide-container ${search ? 'search-height' : 'default-height'}`"
    >
      <div class="guide-inner-wrapper">
        <div class="d-flex justify-content-end mt-4">
          <img
            class="frame-1041-3"
            src="@/assets/img/common/frame-1041.svg"
            @click="$emit('guideClose')"
            alt="Frame 1041"
          />
        </div>
        <div class="d-flex justify-content-start first-row text-left">
          <div>
            <img
              class="vector-10"
              src="@/assets/img/data-set/guide/vector-10.svg"
              alt="Vector 10"
            />
          </div>
          <div class="layout-1">
            <p class="text-1 text-left">
              찾고 싶은 데이터의
              <span class="text-2">키워드, 프로파일 변수, 기간</span>을
              입력하세요!
            </p>
          </div>
        </div>
        <img
          class="x01_01"
          src="@/assets/img/data-set/guide/01-01.png"
          alt="01_01"
        />

        <div class="d-flex justify-content-start second-row">
          <div>
            <img
              class="card_01"
              src="@/assets/img/data-set/guide/card-01@2x1.png"
              alt="card_01"
            />
          </div>
          <div class="d-flex align-items-end flex-column mt-3">
            <div class="d-flex justify-content-start mb-5">
              <div>
                <img
                  class="ml-2"
                  src="@/assets/img/data-set/guide/vector-11.svg"
                  alt="Vector 11"
                  style="margin-top: 11px"
                />
              </div>
              <div class="layout-2 ml-2">
                <p class="text-1">
                  북마크를 클릭하시면
                  <span class="text-2">[데이터 저장소 > 마이 데이터 랩]</span>에
                  저장됩니다!
                </p>
              </div>
            </div>
            <div
              class="d-flex justify-content-start mb-5"
              style="margin-top: 96px"
            >
              <div>
                <img
                  class="ml-2"
                  src="@/assets/img/data-set/guide/vector-11.svg"
                  alt="Vector 11"
                  style="margin-top: 11px"
                />
              </div>
              <div class="layout-2 ml-2" style="margin-right: 122px">
                <p class="text-1">
                  데이터 결합이 가능한
                  <span class="text-2">프로파일 변수</span>를 확인 할 수
                  있습니다!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'guide',
  props: {
    search: Boolean,
  },
};
</script>

<style scoped>
.guide-container {
  min-width: 1920px;
  position: absolute;
  top: calc(var(--main-header-height));
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: rgba(40, 40, 40, 0.75);
}

.default-height {
  height: 100vh;
}

.search-height {
  height: 100%;
}

.guide-inner-wrapper {
  width: 1500px;
  margin: 0 auto;
  position: relative;
}

.frame-1041-3 {
  cursor: pointer;
}

.first-row {
  margin-top: 16px;
  margin-left: 420px;
  z-index: 10;
  height: 58px;
}

.vector-10 {
  padding-top: 20px;
  margin-right: 10px;
  width: 23px;
}

.layout-1 {
  height: 28px;
  z-index: 10;
}

.text-1 {
  color: #fff;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.48px;
  z-index: 992;
}

.text-2 {
  color: #00a880;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.48px;
}

.x01_01 {
  margin-left: 170px;
  width: 1160px;
}

.second-row {
  margin-top: 165px;
  margin-left: 386px;
}

.card_01 {
  height: 315px;
  width: 302px;
}

.vector-12 {
  height: 18px;
  width: 22px;
}

/*.rectangle-4071 {
  background-color: #282828;
  height: 100vh;
  left: 0;
  opacity: 0.75;
  position: absolute;
  width: 100%;
  z-index: 11;
}*/
/*


.x01_01 {
  height: 295px;
  left: 380px;
  position: absolute;
  top: 206px;
  width: 1152px;
  z-index: 992;
}


.layout-1 {
  position: absolute;
  top: 137px;
  left: 675px;
  height: 28px;
  z-index: 992;
}

.card_01 {
  height: 315px;
  left: 590px;
  position: absolute;
  top: 680px;
  width: 302px;
  z-index: 992;
}

.layout-2 {
  position: absolute;
  top: 689px;
  left: 935px;
  width: 774px;
  height: 39px;
  z-index: 992;
}

.vector-11 {
  height: 18px;
  left: 896px;
  position: absolute;
  top: 702px;
  width: 22px;
  z-index: 992;
}

.vector-12 {
  height: 18px;
  left: 896px;
  position: absolute;
  top: 908px;
  width: 22px;
  z-index: 992;
}

.layout-3 {
  position: absolute;
  top: 895px;
  left: 935px;
  width: 774px;
  height: 39px;
  z-index: 992;
}

.frame-1041-3 {
  position: absolute;
  top: 115px;
  left: 1835px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  z-index: 992;
}*/
</style>
