var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:`${_vm.subIndex === 0 ? 'overlap-group-2-sub' : 'overlap-group-2'}`,style:(`${_vm.subIdx !== 2 ? 'margin-right: 34px;' : ''}`)},[_c('div',{staticClass:"title"},[(_vm.imgUrl)?_c('img',{staticClass:"m",attrs:{"src":require(`@/assets/img/data-set/${_vm.imgUrl}.svg`)}}):_vm._e(),_c('div',{staticClass:"text-2 valign-text-middle pretendard-normal-daintree-14px-xxs"},[_vm._v(" "+_vm._s(_vm.data.type)+" 데이터 ")]),_c('img',{staticClass:"bookmark",attrs:{"src":require(`@/assets/img/data-set/${
          _vm.data.bookmark ? 'bookmark-2' : 'bookmark'
        }.svg`)},on:{"click":_vm.save}})]),_c('img',{staticClass:"line-5",attrs:{"src":require("@/assets/img/data-set/line-5.svg")}}),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.blockClick}},[_c('p',{staticClass:"text-3 pretendard-semi-bold-daintree-18px-s mb-1"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c('p',{staticClass:"text-4 pretendard-light-gravel-16px-xs mb-1"},[_vm._v(" "+_vm._s(_vm.data.content)+" ")]),_c('div',{staticClass:"btn_01"},_vm._l((_vm.profileDivision),function(profiles,idx){return _c('div',{class:`view-container ${idx > 0 ? 'gab-6' : ''}`},_vm._l((profiles),function(i){return _c('div',{class:`${
            _vm.data.profile.includes(i.value) ? 'view-select' : 'view-not-select'
          } mr-1`},[_c('div',{class:`${
              _vm.data.profile.includes(i.value) ? 'text-5' : 'text-7'
            } material-themebodysmall-12`},[_vm._v(" "+_vm._s(i.text)+" ")])])}),0)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }