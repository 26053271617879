
import { Vue, Component } from 'vue-property-decorator';
import { IProject } from '@/interface/project';
import noticeModal from '@/components/common/notice-popup.vue';
import { PROJECT_TYPE } from '@/enum/common';

@Component({ components: { noticeModal } })
export default class Bookmark extends Vue {
  private bookmarkList: IProject[] = [];
  private selectedBookmarks: (string | null)[] = [];
  private pending = false;
  private allChecked = false;

  created() {
    this.getBookmarks();
  }

  toggleAll() {
    if (this.bookmarkList.length > 0) {
      if (this.allChecked) {
        this.selectedBookmarks = this.bookmarkList.map(
          bookmark => bookmark._id,
        );
      } else {
        this.selectedBookmarks = [];
      }
    }
  }

  async getBookmarks() {
    try {
      this.pending = true;
      const { data } = await this.axios({
        method: 'GET',
        url: `/data-set/bookmarks/${this.$store.getters.getUserId}`,
      });
      const { result, bookmarkList } = data;
      if (result) {
        console.log(bookmarkList);
        this.bookmarkList = bookmarkList;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.pending = false;
      this.allChecked = false;
    }
  }

  private isCheck(item: string) {
    return this.selectedBookmarks.includes(item);
  }

  selectSomeRow(items: string) {
    if (this.selectedBookmarks.includes(items)) {
      this.selectedBookmarks.splice(this.selectedBookmarks.indexOf(items), 1);
    } else {
      this.selectedBookmarks.push(items);
    }
  }
  async showAlert() {
    await this.$bvModal.show('noticePopup-write');
  }

  async deleteBookmarks() {
    try {
      const { data } = await this.axios({
        method: 'DELETE',
        url: `/data-set/bookmarks/${this.selectedBookmarks}`,
      });
      const { result } = data;

      if (result) {
        this.selectedBookmarks = [];
        await this.getBookmarks();
      }
    } catch (e) {
      console.error(e);
    }
  }

  imgUrl(type: string) {
    if (type) {
      if (type === PROJECT_TYPE.CRAWLING) return 'crawling';
      if (type === PROJECT_TYPE.TRANSACTION) return 'transaction';
      if (type === PROJECT_TYPE.PUBLIC_SEOUL) return 'public_seoul';
      if (type === PROJECT_TYPE.MOBILE) return 'mobile';
      if (type === PROJECT_TYPE.SURVEY) return 'survey';
      return null;
    }
  }

  private typeTxt(type: string) {
    if (type === PROJECT_TYPE.CRAWLING) return '뉴스/SNS';
    if (type === PROJECT_TYPE.TRANSACTION) return '결제';
    if (type === PROJECT_TYPE.PUBLIC_SEOUL) return '공공';
    if (type === PROJECT_TYPE.MOBILE) return '모바일';
    if (type === PROJECT_TYPE.SURVEY) return '설문';
    if (type === PROJECT_TYPE.USER_UPLOAD) return '업로딩';
  }
}
