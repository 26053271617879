
import { Vue, Component } from 'vue-property-decorator';
@Component({})
export default class FindIndex extends Vue{
    private currentPath: string = '';
    private infos: { _id: string; userId: string; userName: string;} = {
        _id: '',
        userId: '',
        userName: ''
    };
    isPage(path: string) {
        this.currentPath = path.replace('/help/', '');
    }
    getInfos(info: { _id: string; userId: string; userName: string;}) {
        this.infos = info;
    }
}
