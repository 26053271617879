
import { Prop, Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';

@Component
export default class noticeModal extends Vue {
  @Prop({ default: '' }) titleModal?: string;
  @Prop({ default: '' }) modalContent?: string;
  @Prop({ default: 'BACK' }) clickType?: string;
  @Prop({ default: '' }) strGoOut?: string;
  @Prop({ default: '' }) modalId!: string;
  @Prop({ default: false }) backdropOption?: boolean;
  @Prop({ default: null }) data?: object | null;
  @Prop({ default: null }) messageFrontImg!: string | null;
  @Prop({ default: '' }) value?: string; // save-input 시 활용
  @Prop({ default: '' }) placeholder?: string; // save-input 시 활용
  @Prop({ default: '' }) btnText?: string;
  @Prop({ default: true }) isCancelBtn?: boolean;
  @Prop({ default: false }) pending?: boolean;
  @Prop({ default: '' }) pendingText?: string;
  @Prop({ default: '취소' }) customCancelBtnText?: string;
  @Prop({ default: '확인' }) customOkBtnText?: string;
  @Prop({ default: 100 }) maxlength!: number;
  @Prop({ default: '' }) dashboardTitle?: string;

  private args = '';

  /*  get modelValue() {
    return this.value;
  }*/

  get modelValue() {
    return (this.value = this.dashboardTitle);
  }

  set modelValue(value) {
    this.$emit('input', value);
  }

  setArgs(url: string) {
    this.args = url;
  }

  resetModal() {
    this.$emit('cancelModal');
    this.$nextTick(() => {
      this.$bvModal.hide(this.modalId);
    });
  }

  formatText(text: string) {
    let tmp = text;
    tmp = tmp.replace(/<br\s*\/?>/gi, '\n');
    return tmp;
  }

  async handleOk() {
    switch (this.clickType) {
      case 'GO':
        //부모 컴포넌트
        this.$bvModal.hide(this.modalId);
        this.$emit('isOk');
        break;
      case 'WITHDRAW':
        this.$bvModal.hide(this.modalId);
        this.$emit('logout');
        break;
      case 'ACCESS':
        this.$bvModal.hide(this.modalId);
        this.$emit('logout');
        break;
      case 'GoOut':
        this.$emit('goOut', this.strGoOut);
        break;
      case 'BACK':
        this.$emit('isCancel');
        break;
      case 'pwInquiry':
        this.$emit('goOut', '/login');
        break;
      case 'customDataOk':
        this.$emit('goOut', this.args);
        break;
      case 'MoveTo':
        this.$emit('moveTo', this.args);
        break;
      case 'DELETE':
        this.$emit('postDelete');
        this.$bvModal.hide(this.modalId);
        break;
      case 'WARN':
        this.$emit('moveChk');
        this.$bvModal.hide(this.modalId);
        break;
      case 'specificPage': {
        this.$emit('specificPage', this.data);
        break;
      }
      case 'CONFIRM': {
        this.$emit('confirm', true);
        this.$bvModal.hide(this.modalId);
        break;
      }
      // 마이 데이터 랩 카드 수정, 데이터 가공 및 시각화 저장 등등에 사용
      case 'SAVE_INPUT': {
        this.$emit('saveInput');
        break;
      }
      //  생성할 데이터의 타이틀 설정용
      case 'CREATE_DASHBOARD': {
        this.$emit('createDashBoard');
        break;
      }
      // 마이 데이터 랩 카드 삭제에 사용(save input 과 비슷하지만 input이 없음)
      case 'WRITE': {
        console.log('dd');
        this.$emit('write', true);
        this.$bvModal.hide(this.modalId);
        break;
      }
      default:
        this.$emit('confirm');
        this.$bvModal.hide(this.modalId);
        break;
    }
  }

  handleSubmit() {
    throw new Error('Method not implemented.');
  }

  limitLength() {
    if (this.modelValue && this.modelValue.length >= this.maxlength) {
      this.modelValue.slice(0, this.maxlength);
    }
  }
}
