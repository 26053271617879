var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    `overlap-group-${_vm.modelValue === _vm.periodOption.value ? '9' : '1'}`,
    `${_vm.disabled ? 'disabled-block' : ''}`,
    `${_vm.idx === 0 ? 'ml-2' : ''}`,
  ],on:{"click":function($event){return _vm.periodClick(_vm.periodOption.value)}}},[_c('div',{class:`address valign-text-middle pretendard-regular-normal-${
      _vm.modelValue === _vm.periodOption.value ? 'white' : 'gravel'
    }-16px`},[_vm._v(" "+_vm._s(_vm.periodOption.text)+" ")]),_c('img',{staticClass:"check_ring_light",attrs:{"src":require(`@/assets/img/data-set/check-ring-light${
        _vm.modelValue === _vm.periodOption.value ? '' : '-2'
      }.svg`)}})])
}
var staticRenderFns = []

export { render, staticRenderFns }