
import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import {FileInfoProp, popupSetInterface} from '../../../../interfaces/notice.interface';
import noticeModal from '@/components/common/notice-popup.vue';
import SubMenu from '@/components/user/SubMenu.vue';
import moment from 'moment';

@Component({ components: { noticeModal, SubMenu } })
export default class QnaWrite extends Vue {
  private type = '';
  private title = '';
  private date = '';
  private content = '';
  private requestStatus = '';
  private requestFiles: FileInfoProp[] = [];

  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface["type"] = 'BACK';
  private strGoOut: string = '';

  mounted() {
    this.getContent();
  }

  async getContent() {
    try {
      const { data } = await this.axios.get(
        `custom-data-service/${this.$route.params.seq}`,
        {
          params: { token: this.$store.getters.isToken },
        },
      );
      console.log(data);
      if (data) {
        this.type = data.type;
        this.date = moment(data.createdAt).format('YYYY-MM-DD ');
        this.title = data.title;
        this.content = data.requestContent;
        this.requestFiles = data.requestFiles;
        this.requestStatus = data.state;
      } else {
        this.strGoOut = '/my-history';
        await this.showAlert(data.msg, 'WITHDRAW');
      }
    } catch (e) {
      console.log(e);
    }
  }

  async showAlert(
    msg: string,
    type: 'BACK' | 'GO' | 'NO' | 'GoOut' | 'Alert' | 'WITHDRAW',
  ) {
    this.modalSetting({
      title: '',
      content: msg,
      type: type,
    });
    await this.$bvModal.show('noticePopup-read');
  }

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }

  async modifyClick() {
    await this.$router.push(
      `/custom-data-service/request/${this.$route.params.seq}`,
    );
  }

  async deleteClick() {
    this.modalSetting({
      title: '정말 삭제하시겠습니까?',
      content: '이후 해당 자료 열람이 불가합니다.',
      type: 'GO',
    });
    await this.$bvModal.show('noticePopup-read');
  }

  async isOk() {
    try {
      const data = await this.axios.patch(
        `custom-data-service/${this.$route.params.seq}`,
      );
      const result = data.data.result;
      if (result === undefined) {
        this.showAlert('삭제 완료되었습니다.', 'WITHDRAW');
        this.strGoOut = '/my-history';
        this.$bvModal.show('noticePopup-read');
      } else {
        this.showAlert(data.data.msg, 'Alert');
        this.$bvModal.hide('noticePopup-read');
      }
    } catch (err) {
      console.error(err);
    }
  }

  goOut() {
    this.$router.push(this.strGoOut);
  }

  historyBack() {
    this.$router.go(-1);
  }
}
