
import { Component, Vue, Watch } from 'vue-property-decorator';
import ChartSelection from '@/components/data-workshop/data-visualize/chart-selection.vue';
import History from '@/components/data-workshop/data-visualize/history.vue';
import { chartType } from '@/common/visualize';
import { DataWorkshop } from '@/interface/data-workshop';

@Component({
  components: { History, ChartSelection },
})
export default class Visualizing extends Vue {
  chartList: DataWorkshop.IChartType[] = chartType({
    url: this.$route.params._id,
    version: 2,
  });
  showChartList = false;
  joinProject: DataWorkshop.IJoinProject | { header: []; histories: [] } = {
    header: [],
    histories: [],
  };
  pending = false;

  handleToggleBtn() {
    this.showChartList = !this.showChartList;
  }

  async created() {
    this.pending = true;
    await this.loadJoinProject();
    this.pending = false;
  }

  async loadJoinProject() {
    try {
      const {
        data,
      }: {
        data: {
          result: boolean;
          project: DataWorkshop.IJoinProject;
        };
      } = await this.axios({
        method: 'GET',
        url: `data-process/project/${this.$route.params._id}`,
      });
      if (data.result) {
        this.joinProject = data.project;

        // join project 기본 제목 설정
        const { utilizationData, title } = this.joinProject;
        if (!title && utilizationData && utilizationData.length > 0) {
          this.joinProject.title = utilizationData.reduce(
            (acc, cur) => acc + `${acc ? ' + ' : ''}` + cur,
            '',
          );
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  // 시각화 차트 작업 변경시마다 작업 내역 끄기
  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.showChartList = false;
  }
}
