
import { Component, Vue } from 'vue-property-decorator';
import noticeModal from '@/components/common/notice-popup.vue';
@Component({
  components: { noticeModal },
})
export default class dataList extends Vue {
  private modalContent: string = '';
  private clickType: string = '';
  private data: object | null = null;

  showModal({
    message,
    type,
    data,
  }: {
    message: string;
    type: string;
    data: object;
  }) {
    this.modalContent = message;
    this.clickType = type;
    if (data) this.data = data;
    this.$bvModal.show('data-list');
  }

  specificPage(data: { page: string }) {
    const { page } = data;
    if (page) this.$router.push(`/${page}`);
  }
}
