
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class customInput extends Vue {
  @Prop({ default: 'text' }) inputType!: string; // 타입
  @Prop({ default: false }) required!: boolean; // 필수 아이콘
  @Prop({ default: false }) passwordIcon!: boolean; // 비밀번호 확인 아이콘
  @Prop({ default: false }) resetIcon!: boolean; // 삭제 아이콘
  @Prop({ default: false }) inputReadonly!: boolean;
  @Prop() value!: string; // v-model
  @Prop() inputTitle?: string; // 제목
  @Prop() inputPlaceholder?: string; // placeholder 노출 내용
  @Prop() valid?: boolean | null; // input 하단에 경고/성공 메세지 노출 여부 (검증)
  @Prop() validMessage?: string; // 경고/성공 메세지
  @Prop() desc?: string;
  private pwdShow: boolean = false; // password -> text 타입 변경

  get type() {
    if (!this.passwordIcon) return this.inputType;
    if (this.pwdShow) return 'text';
    return 'password';
  }

  get modelValue() {
    return this.value;
  }
  set modelValue(value) {
    this.$emit('input', value);
  }

  get validCheck() {
    return this.valid !== null || false;
  }

  get inputBorderColor() {
    if (this.valid === null || this.valid === undefined) {
      return '';
    } else {
      return this.valid ? 'var(--material-themesourceseed)' : '#ea1f2d';
    }
  }

  @Emit('inputBlur')
  inputBlur() {}

  @Emit('inputFocus')
  inputFocus() {}

  @Emit('inputKeyup')
  inputKeyup() {}

  @Emit('inputChange')
  inputChange() {}

  @Emit('inputReset')
  inputReset() {
    this.modelValue = '';
  }
}
