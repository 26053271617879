
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IProject } from '@/interface/project';
import { PROJECT_TYPE } from '@/enum/common';
@Component({})
export default class ListBlock extends Vue {
  @Prop() private data!: IProject;
  @Prop() private subIdx?: number;
  @Prop() private profileOptions!: { value: string; text: string }[];
  @Prop({ default: false }) private pending!: boolean;

  get imgUrl() {
    if (this.data.type === PROJECT_TYPE.CRAWLING) return 'crawling';
    if (this.data.type === PROJECT_TYPE.TRANSACTION) return 'transaction';
    if (this.data.type === PROJECT_TYPE.PUBLIC_SEOUL) return 'public_seoul';
    if (this.data.type === PROJECT_TYPE.MOBILE) return 'mobile';
    if (this.data.type === PROJECT_TYPE.SURVEY) return 'survey';
    return null;
  }

  get subIndex() {
    return this.subIdx && this.subIdx === 0;
  }

  get profileDivision() {
    const newArray = [];
    for (let index = 0; index < this.profileOptions.length; index += 5) {
      newArray.push(this.profileOptions.slice(index, index + 5));
    }
    return newArray;
  }

  save() {
    if (this.pending) return;
    this.$emit('bookmarkSave', this.data);
  }

  blockClick() {
    this.$emit('blockClick', this.data);
  }
}
