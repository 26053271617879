
import { Component, Vue } from 'vue-property-decorator';
import { profileOptions } from '@/common';
import KeySelect from '@/components/data-set/key-select.vue';
import CheckProfileData from '@/components/data-set/check-profile-data.vue';
import StepButton from '@/components/data-workshop/visualization/stepButton.vue';

@Component({
  components: { StepButton, KeySelect, CheckProfileData },
})
export default class SecondStep extends Vue {
  private pending: boolean = false;
  private ids: string[] = [];
  private connectType: string[] = ['1', '2', '3', '4', '5'];
  private dupProfile: string[] = [];
  private joinDataTitle: string = '';
  private projects: {
    _id: string;
    title: string;
    type: string;
    content: string;
    profile: string[];
  }[] = [];

  private step: string = 'second';

  private selectedProfile: string[] = [];
  private nextFlg: boolean = false;

  created() {
    const { ids } = this.$route.params;
    if (ids) {
      this.ids = ids.split(',');
      this.load();
    }
  }

  get profileOptions() {
    return profileOptions;
  }

  get dupOption() {
    return this.profileOptions.filter(m => this.dupProfile.includes(m.value));
  }

  async load() {
    this.pending = true;
    try {
      const { data } = await this.axios({
        method: 'GET',
        url: `/visualization/data-list/step2/${this.ids}`,
      });
      const { result, dupProfile, projects } = data;
      if (result) {
        this.dupProfile = dupProfile;
        this.projects = projects;
        this.joinDataTitle = this.projects.map(e => e.title).join(' + ');
      }
    } catch (e) {
      console.error(e);
    }
    this.pending = false;
  }

  viewKeySelect() {
    this.nextFlg = !this.nextFlg;
  }

  profileSelect(selectedObj: { selected: string[]; limitFlg: boolean }) {
    this.selectedProfile = selectedObj.selected;

    if (selectedObj.limitFlg) {
      this.$emit('showModal', {
        message: '결합 가능한 변수 키는</br>최대 3개까지 선택이 가능합니다.',
        type: 'Alert',
      });
    }
  }

  next() {
    if (this.dupProfile.length === 0) {
      return this.$bvModal.show('not-profile');
    }
    if (this.selectedProfile.length === 0) {
      return this.$emit('showModal', {
        message: '결합 변수 키를 선택해주세요.',
        type: 'Alert',
      });
    }
    return this.$router.push({
      path: `/visualization/data-list/third-step/${this.ids}?profile=${this.selectedProfile}`,
      query: { joinDataTitle: this.joinDataTitle },
    });
  }
}
