var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container-data-search"},[_c('div',{staticClass:"container col-12 d-flex justify-content-center"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"text-sub d-flex justify-content-center"},[_vm._v("OUR PLATFORM")]),_c('div',{staticClass:"text-title d-flex justify-content-center"},[_vm._v(" 서베이 플랫폼 이용하기 ")]),_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('div',{staticClass:"card-group",on:{"click":function($event){return _vm.checkRedirect({
              HOST_NAME: _vm.HOST_LINK.UNI_SURVEY,
            })},"mouseover":function($event){return _vm.hoverEvent(1, true)},"mouseout":function($event){return _vm.hoverEvent(1, false)}}},[_c('div',{staticClass:"card-inner"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFlag1),expression:"!hoverFlag1"}],staticClass:"card-icon",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/spIcon1.png"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverFlag1),expression:"hoverFlag1"}],staticClass:"card-icon",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/spIcon1_hover.png"}}),_c('div',{staticClass:"card-title"},[_vm._v("온라인 정량조사")]),_vm._m(0)]),_c('div',{staticClass:"learn-more"},[_vm._v(" Learn More "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFlag1),expression:"!hoverFlag1"}],staticClass:"ml-1",staticStyle:{"padding-top":"2px"},attrs:{"src":require("@/assets/img/my-data-hub/arrow-right.svg")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverFlag1),expression:"hoverFlag1"}],staticClass:"ml-1",staticStyle:{"padding-top":"2px"},attrs:{"src":require("@/assets/img/my-data-hub/arrow-right-hover.svg")}})])]),_c('div',{staticClass:"card-group",on:{"click":function($event){return _vm.checkRedirect({
              HOST_NAME: _vm.HOST_LINK.QUAL,
            })},"mouseover":function($event){return _vm.hoverEvent(2, true)},"mouseout":function($event){return _vm.hoverEvent(2, false)}}},[_c('div',{staticClass:"card-inner"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFlag2),expression:"!hoverFlag2"}],staticClass:"card-icon",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/spIcon2.png"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverFlag2),expression:"hoverFlag2"}],staticClass:"card-icon",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/spIcon2_hover.png"}}),_c('div',{staticClass:"card-title"},[_vm._v("온라인 정성조사")]),_vm._m(1)]),_c('div',{staticClass:"learn-more"},[_vm._v(" Learn More "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFlag2),expression:"!hoverFlag2"}],staticClass:"ml-1",staticStyle:{"padding-top":"2px"},attrs:{"src":require("@/assets/img/my-data-hub/arrow-right.svg")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverFlag2),expression:"hoverFlag2"}],staticClass:"ml-1",staticStyle:{"padding-top":"2px"},attrs:{"src":require("@/assets/img/my-data-hub/arrow-right-hover.svg")}})])]),_c('div',{staticClass:"card-group",on:{"click":function($event){return _vm.checkRedirect({
              HOST_NAME: _vm.HOST_LINK.HBU,
            })},"mouseover":function($event){return _vm.hoverEvent(3, true)},"mouseout":function($event){return _vm.hoverEvent(3, false)}}},[_c('div',{staticClass:"card-inner"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFlag3),expression:"!hoverFlag3"}],staticClass:"card-icon",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/spIcon3.png"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverFlag3),expression:"hoverFlag3"}],staticClass:"card-icon",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/spIcon3_hover.png"}}),_c('div',{staticClass:"card-title"},[_vm._v("온라인 패널 공급 시스템")]),_vm._m(2)]),_c('div',{staticClass:"learn-more"},[_vm._v(" Learn More "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFlag3),expression:"!hoverFlag3"}],staticClass:"ml-1",staticStyle:{"padding-top":"2px"},attrs:{"src":require("@/assets/img/my-data-hub/arrow-right.svg")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverFlag3),expression:"hoverFlag3"}],staticClass:"ml-1",staticStyle:{"padding-top":"2px"},attrs:{"src":require("@/assets/img/my-data-hub/arrow-right-hover.svg")}})])]),_c('div',{staticClass:"card-group",on:{"click":function($event){return _vm.checkRedirect({
              HOST_NAME: _vm.HOST_LINK.RECOMMEND,
            })},"mouseover":function($event){return _vm.hoverEvent(4, true)},"mouseout":function($event){return _vm.hoverEvent(4, false)}}},[_c('div',{staticClass:"card-inner"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFlag4),expression:"!hoverFlag4"}],staticClass:"card-icon",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/spIcon4.png"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverFlag4),expression:"hoverFlag4"}],staticClass:"card-icon",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/spIcon4_hover.png"}}),_c('div',{staticClass:"card-title"},[_vm._v("설문 문항 추천 시스템")]),_vm._m(3)]),_c('div',{staticClass:"learn-more"},[_vm._v(" Learn More "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFlag4),expression:"!hoverFlag4"}],staticClass:"ml-1",staticStyle:{"padding-top":"2px"},attrs:{"src":require("@/assets/img/my-data-hub/arrow-right.svg")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverFlag4),expression:"hoverFlag4"}],staticClass:"ml-1",staticStyle:{"padding-top":"2px"},attrs:{"src":require("@/assets/img/my-data-hub/arrow-right-hover.svg")}})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-sub-text"},[_vm._v(" 비대면 온라인 조사"),_c('br'),_vm._v("서베이 솔루션 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-sub-text"},[_vm._v(" STT 기술을 활용한 화상회의"),_c('br'),_vm._v("기반 비대면 솔루션 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-sub-text"},[_vm._v(" SaaS기반 셀프"),_c('br'),_vm._v("패널 서비스 솔루션 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-sub-text"},[_vm._v(" 추천 알고리즘을 통해 손쉽게"),_c('br'),_vm._v("설문을 구성 할 수 있는 솔루션 ")])
}]

export { render, staticRenderFns }