
import {Component, Vue} from 'vue-property-decorator';
import {FileInfoProp, popupSetInterface, PostInfoInterface} from '../../../interfaces/notice.interface';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import noticePermission from '@/common';
import noticeModal from "@/components/common/notice-popup.vue";
import {S3FileCheck} from "@/utils/s3-file-check";

@Component({
  components: {noticeModal}
})
export default class PostView extends Vue {
  private options: { text: string, value: string }[]= [
      { text: '공지', value: '1' },
      { text: '이벤트', value: '2' },
      { text: '메뉴얼', value: '3' },
      { text: '기타', value: '4' },
  ];
  private files: FileInfoProp[] = [];
  private postInfo: PostInfoInterface = {
      type: '1',
      title: '',
      content: '',
      top_fixing: 'N',
      files: [

      ]
  };
  private loading: boolean = true;
  private prevPost: {seq:number, title: string} = {seq:0, title:''};
  private nextPost: {seq:number, title: string} = {seq:0, title:''};
  private writeDate: string = '';
  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface["type"] = 'DELETE';


  created(){
      const searchWord = this.$route.query.search as string;
      const category = this.$route.query.category as string;
      const postType = this.$route.query.type as string;
      const dateType = this.$route.query.dateType as string;
      const fix = this.$route.query.fix as string;
      this.postView({ type: postType, searchType: category, search: searchWord, dateType: dateType, fix });
  }
  mounted() {
      this.$emit('isPage', this.$route.path);
  }

  get isPermission() {
    return noticePermission.includes(this.$store.getters.isGrade);
  }

  private createUrl(){
    const searchWord = this.$route.query.search as string;
    const category = this.$route.query.category as string;
    const postType = this.$route.query.type as string;
    const dateType = this.$route.query.dateType as string;
    const fix = this.$route.query.fix;
    let fixStr = '';
    if(fix) fixStr = '&fix=Y';
    return `?category=${category?category:'0'}&search=${searchWord? searchWord: ''}&type=${postType? postType: '0'}&dateType=${dateType?dateType: '0'}${fixStr}`;
  }
  gotoList() {
    const pageNum = this.$route.query.page;
    const addParam = this.createUrl();
    return this.$router.push(`/notice/${addParam}&page=${pageNum || 1}`);
    //history.back();
  }
  gotoWrite() {
    const pageNum = this.$route.query.page;
    const addParam = this.createUrl();
    return this.$router.push(`/notice/edit/${this.$route.params.seq}${addParam}&page=${pageNum}`);
  }
  async postView(sendData: { type: string; searchType: string; search: string; dateType: string; fix: string; }) {
    try {
        const { data } = await this.axios.post(`/notice/view/${this.$route.params.seq}`, sendData);
        const { result, posts, writeDate } = data;
        this.postInfo = result;
        this.postInfo.type = this.options[Number(this.postInfo.type)-1].text;
        this.files = result.files;
        this.writeDate = writeDate;
        if(Object.keys(posts).includes('prevPost'))
            this.prevPost = posts.prevPost;

        if(Object.keys(posts).includes('nextPost'))
            this.nextPost = posts.nextPost;
        this.loading = false;
    } catch (e) {
        console.log(e);
    }
  }
  async goPost(isCheck: string) {
    const addParam = this.createUrl()
    if('next'=== isCheck) {
        const {seq} = this.nextPost;
        await this.$router.push(`/notice/postView/${seq}${addParam}&page=1`);
        location.reload();
    }
    if('prev' === isCheck){
        const {seq} = this.prevPost;
        await this.$router.push(`/notice/postView/${seq}${addParam}&page=1`);
        location.reload();
    }
  }
  async fileDownload(url: string, fileName: string) {
    const isFile = new S3FileCheck('notice', url);
    const result = await isFile.checkFile();
    if(result) {
      const anchorElement = document.createElement('a');
      document.body.appendChild(anchorElement);
      anchorElement.download = `${fileName}`;
      anchorElement.href = url;
      anchorElement.click();
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(url);
    } else {
      //파일이 없는 경우
      this.modalSetting({title: "", content: "파일이 존재하지 않습니다.", type: "Alert"})
      this.$bvModal.show('isFileCheck');
    }
  }
  async postDeleteBtn() {
    this.modalSetting({title: "", content: "작성한 내용이 모두 삭제됩니다.<br>정말로 삭제하시겠습니까?", type: "WRITE"})
    this.$bvModal.show('postDeleteAlert');
  }
  async postDelete() {
    await this.axios.get(`/notice/delete/${this.$route.params.seq}`);
    await this.gotoList();
    //postDeletePopup
    /*const { message } = data;
    this.modalSetting({title: "", content: message, type: "DeleteAlert"})
    this.$bvModal.show('postDeleteAlert');*/
  }
  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }
}
