
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class RowData extends Vue {
  @Prop() rowData?: {_id: string, title: string, type?: string, profile: string[]};
  @Prop() selectIds?: string[];

@Emit('projectClick')
projectClick (_id: string) {}
};
