var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-overlay',{attrs:{"show":_vm.pending,"rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-icon',{attrs:{"icon":"arrow-clockwise","animation":"spin","font-scale":"4"}}),_c('p',{staticClass:"mt-2 pretendard-light-gravel-16px",staticStyle:{"color":"black"}},[_vm._v(" 잠시만 기다려 주세요... ")])],1)]},proxy:true}])},[_c('b-table-simple',{style:({
      width: isNaN(+_vm.width) ? String(_vm.width) : `${_vm.width}px`,
    }),attrs:{"sticky-header":isNaN(+_vm.height) ? String(_vm.height) : `${_vm.height}px`,"no-border-collapse":"","responsive":"","striped":""}},[_c('b-thead',[_c('b-tr',{staticClass:"text-center"},[_c('b-th',{staticClass:"pretendard-medium-san-marino-12px align-middle text-nowrap font-weight-bold",staticStyle:{"color":"#445cb2","background-color":"#edeff8"},style:({ borderTop: _vm.boundaryBorderTB && '1px solid #000' })},[_vm._v(" IDX ")]),_vm._l((_vm.header),function(h,idx){return _c('b-th',{key:`${h.key}_${idx}`,staticClass:"border-left pretendard-medium-san-marino-12px align-middle text-nowrap font-weight-bold",staticStyle:{"color":"#445cb2","background-color":"#edeff8"},style:({
            borderTop: _vm.boundaryBorderTB && '1px solid #000',
            textDecoration:
              h.processOption && h.processOption.hide ? 'line-through':'',
            color:
              h.processOption && h.processOption.hide ? 'red' : '#445cb2',
          })},[_vm._v(" "+_vm._s(h.name)+" ")])})],2)],1),(_vm.data.length)?_c('b-tbody',_vm._l((_vm.data),function(d,idx){return _c('b-tr',{class:`${idx % 2 === 0 ? 'dataTr' : ''} text-center`,style:(`${
          _vm.changeDataIndexes &&
          _vm.changeDataIndexes['ROW-DELETE'] &&
          _vm.changeDataIndexes['ROW-DELETE'].indexOf(idx) > -1
            ? 'text-decoration: line-through; color: red;'
            : ''
        }`)},[_c('b-td',{staticClass:"align-middle pretendard-light-gravel-12px",style:({
            borderBottom:
              _vm.boundaryBorderTB &&
              _vm.data.length - 1 === idx &&
              '1px solid black',
            fontWeight: _vm.dataTableTextBolder,
          })},[_vm._v(" "+_vm._s(idx + 1)+" ")]),_vm._l((_vm.header),function(h,idx2){return _c('b-td',{key:`${h.key}_${idx}_${idx2}`,staticClass:"border-left align-middle pretendard-light-gravel-12px",style:({
            borderBottom:
              _vm.boundaryBorderTB &&
              _vm.data.length - 1 === idx &&
              '1px solid black',
            fontWeight: _vm.dataTableTextBolder,
            textDecoration:
              h.processOption && h.processOption.hide ? 'line-through':'',
            color: h.processOption && h.processOption.hide ? 'red':'',
          })},[(
              (typeof d[h.key] === 'number' && +d[h.key] === 0) || d[h.key]
            )?_c('div',[(
                _vm.changeDataIndexes &&
                _vm.changeDataIndexes[h.key] &&
                _vm.changeDataIndexes[h.key].indexOf(idx) > -1
              )?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" "+_vm._s(d[h.key])+" ")]):_c('span',[_vm._v(_vm._s(d[h.key]))])]):_vm._e()])})],2)}),1):_c('b-tbody',[_c('b-tr',{staticStyle:{"height":"260px"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }