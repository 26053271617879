
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ChartOptions,
  PluginChartOptions,
} from 'chart.js';
import {
  BoxPlotController,
  BoxAndWiskers,
} from '@sgratzl/chartjs-chart-boxplot';
import { WordCloudController, WordElement } from 'chartjs-chart-wordcloud';
import { chartTypeMatchingTable } from '@/common/visualize';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
  BoxPlotController,
  BoxAndWiskers,
  WordElement,
  WordCloudController,
  zoomPlugin,
);

@Component({})
export default class Chart extends Vue {
  @Prop() chartType!: string;
  @Prop() chartData!: any;
  @Prop() chartOptions!: ChartOptions;

  chart: any = null;
  initialWidth: number = 0;

  $refs!: {
    chart: any;
  };

  // test
  private wheel = false;
  private drag = false;
  private pan = false;

  // custom plugin
  private customPlugin: any = {
    histogram: {
      afterDraw: (chart: any) => {
        const ctx = chart.ctx;
        const xAxis = chart.scales.x;
        const yAxis = chart.scales.y;

        ctx.textAlign = 'center';
        ctx.textBaseline = 'top';
        ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';

        if (chart.data.labels.length > 1) {
          chart.data.labels.forEach((label: string, index: number) => {
            const xPos = xAxis.getPixelForValue(index);
            const yPos = yAxis.bottom + 12;

            const barWidth = chart.getDatasetMeta(0).data[index].width;
            const adjustedXPos = xPos - barWidth / 2;

            ctx.fillText(label, adjustedXPos, yPos);
          });
        }
      },
    },
    'box-plot': {},
    'xy-graph': {},
    'word-cloud': {},
    scaling: {},
    'scaling-histogram': {
      afterDraw: (chart: any) => {
        const ctx = chart.ctx;
        const xAxis = chart.scales.x;
        const yAxis = chart.scales.y;
        if (this.chartOptions.indexAxis) {
          ctx.textAlign = 'right';
          ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
          if (chart.data.labels.length === 1) {
            chart.data.labels.forEach((label: string, index: number) => {
              const xPos = xAxis.left - 11;
              const yPos = yAxis.getPixelForValue(index);
              const barHeight = chart.getDatasetMeta(0).data[index].height;
              const adjustedYPos = yPos + barHeight / 1.6;
              ctx.fillText(label, xPos, adjustedYPos);
            });
          }
          return;
        } else {
          ctx.textAlign = 'center';
          ctx.textBaseline = 'top';
          ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';

          if (chart.data.labels.length > 1) {
            chart.data.labels.forEach((label: string, index: number) => {
              const xPos = xAxis.getPixelForValue(index);
              const yPos = yAxis.bottom + 12;

              const barWidth = chart.getDatasetMeta(0).data[index].width;
              const adjustedXPos = xPos - barWidth / 2;

              ctx.fillText(label, adjustedXPos, yPos);
            });
          }
        }
      },
    },
  };

  mounted() {
    if (this.chartType) {
      this.chart = new ChartJS(this.$refs.chart, {
        type: chartTypeMatchingTable[this.chartType],
        data: this.chartData,
        options: {
          ...this.chartOptions,
          maintainAspectRatio: false,
          plugins: {
            ...this.chartOptions.plugins,
            zoom: {
              pan: {
                enabled: true,
              },
            },
          },
        },
        plugins: [this.customPlugin[this.chartType]],
      });

      // pan 은 맨 처음 true 로 초기화 후 바로 false로 변경
      this.chart.options.plugins.zoom.pan.enabled = false;
      this.chart.update('none');

      this.initialWidth = this.chart.width;

      window.addEventListener('resize', this.handleResize);
    }
  }

  beforeDestory() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.chart.resize(this.initialWidth, this.chart.height);
  }

  @Watch('chartData')
  redrawChartByData() {
    this.chart.data = this.chartData;
    this.chart.resetZoom();
    this.chart.update();
  }

  @Watch('chartOptions')
  redrawChartByOptions() {
    this.chart.options = this.chartOptions;
    this.chart.update();
  }

  download(title: string) {
    const dataUrl = this.$refs.chart.toDataURL();
    const a = document.createElement('a');
    a.href = dataUrl;
    a.download = `${title}`;
    a.style.display = 'none';
    a.click();
  }

  newPage() {
    const dataUrl = this.$refs.chart.toDataURL();
    const chartWindow = window.open();
    chartWindow?.document.write(`
    <!DOCTYPE html>
    <html lang='kr'>
    <head>
      <title>차트 이미지</title>
      <style>
        body { margin: 0; display: flex; justify-content: center; align-items: center; height: 100vh; }
        img { max-width: 100%; max-height: 100vh; }
      </style>
    </head>
    <body>
      <img src='${dataUrl}' alt='차트 이미지'/>
    </body>
    </html>
  `);
    chartWindow?.document.close();
  }

  expansion() {
    this.chart.zoom(1.1);
  }

  reduction() {
    this.chart.zoom(0.9);
  }

  reset() {
    this.chart.resetZoom('none');
  }

  changeColor(idx: number | null, color: string, chartType: string) {
    if (idx === null) {
      return;
    }

    if (chartType === 'histogram') {
      this.chart.data.datasets[0].backgroundColor = [color];
    }

    if (chartType === 'box-plot') {
      this.chart.data.datasets[0].backgroundColor[idx] = color;
      this.chart.data.datasets[0].borderColor[idx] = color;
    }

    if (chartType === 'xy-graph' || chartType === 'scaling') {
      this.chart.data.datasets[idx].backgroundColor = [color];
    }

    if (chartType === 'word-cloud') {
      const targetColor = this.chart.data.datasets[0].color[idx];
      this.chart.data.datasets[0].color = this.chart.data.datasets[0].color.map(
        (origin: string) => {
          if (origin === targetColor) return color;
          else return origin;
        },
      );
    }

    this.chart.update('none');
  }

  test(type: any) {
    if (type === 'drag') this.drag = !this.drag;
    if (type === 'wheel') this.wheel = !this.wheel;
    if (type === 'pan') this.pan = !this.pan;
    if (type === 'pan100px') this.chart.pan({ y: 10 });
    if (type === 'pan-100px') this.chart.pan({ y: -10 });

    this.chart.options.plugins.zoom.zoom.drag.enabled = this.drag;
    this.chart.options.plugins.zoom.zoom.wheel.enabled = this.wheel;
    this.chart.options.plugins.zoom.pan.enabled = this.pan;

    this.chart.update();
  }
}
