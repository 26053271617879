
import { Component, Vue } from 'vue-property-decorator';
import { chartType } from '@/common/visualize';
import noticeModal from '@/components/common/notice-popup.vue';

@Component({
  components: { noticeModal },
})
export default class DataVisualize extends Vue {
  private chartList = chartType({ url: this.$route.params._id });
  private hoveredChart = '';

  handleHover(type: string, act: 'enter' | 'leave') {
    if (act === 'enter') this.hoveredChart = type;
    else this.hoveredChart = '';
  }

  go(path: string) {
    /*const type = path.split('/').pop();
    if (type === 'scaling') this.$bvModal.show('alert');
    else */
    this.$router.push(path);
  }
}
