
import { Vue, Component } from 'vue-property-decorator';
import ProjectCard from '@/components/my-data-hub/project-card.vue';
import noticeModal from '@/components/common/notice-popup.vue';
import { DataWorkshop } from '@/interface/data-workshop';

@Component({
  components: { ProjectCard, noticeModal },
})
export default class MyHome extends Vue {
  private titleText: string = '';
  private deleteMsg = '';
  private deleteTarget: string = '';
  private modifyTarget: string = '';
  private modalType:
    | 'BACK'
    | 'GO'
    | 'NO'
    | 'GoOut'
    | 'Alert'
    | 'WITHDRAW'
    | 'WRITE'
    | 'customDataOk' = 'Alert';
  private dataList: any = [];
  private searchType = [
    { text: '전체', value: '' },
    { text: '제목', value: 'title' },
    { text: '내용', value: 'description' },
  ];
  private searchTypeSelected: string = '';

  private sortTypeOptions = [
    { value: '-1', text: '기본(최신순)' },
    { value: '1', text: '오래된순' },
  ];
  private selectedSortType = -1;

  private searchText = '';
  private pending = false;
  private titleModal = '';
  private moreFlag: boolean = false;

  async created() {
    await this.load();
  }

  async load() {
    try {
      this.pending = true;
      const { data } = await this.axios({
        method: 'GET',
        url: `/data-process/projects/${this.$store.getters.getUserObjectId}`,
        params: {
          searchType: this.searchTypeSelected,
          sortType: this.selectedSortType,
          searchText: this.searchText,
        },
      });
      const { result, data: projects } = data;
      if (result) {
        this.dataList = projects.map((m: any) => {
          return { ...m, optionFlag: false };
        });
        //this.dataList = [];

        this.pending = false;
      }
    } catch (e) {
      console.error(e);
    }
  }
  async showDeleteModal(args: { data: DataWorkshop.IJoinProject }) {
    const { data } = args;
    const { title, _id } = data;
    this.deleteMsg = `“${
      title.length > 50 ? title.slice(0, 50) + '...' : title
    }”을(를) 정말 삭제하시겠습니까?`;
    this.deleteTarget = _id;
    this.titleModal = '마이 데이터 삭제하기';
    this.modalType = 'WRITE';
    this.$bvModal.show('delete-modal');
  }

  async showModifyModal(args: { data: DataWorkshop.IJoinProject }) {
    const { data } = args;
    const { title, _id } = data;
    this.modifyTarget = _id;
    this.titleText = title;
    this.titleModal = '데이터 타이틀 수정하기';
    this.$bvModal.show('modify-modal');
  }

  async deleteData() {
    this.$bvModal.hide('delete-modal');
    const res = await this.axios.delete(
      `/data-process/project/${this.deleteTarget}`,
    );
    if (res.data.result) {
      this.titleModal = '';
      this.modalType = 'Alert';
      this.deleteMsg = res.data.msg;
      this.$bvModal.show('delete-modal');
      await this.load();
    }
  }
  async modifyProject() {
    if (!this.titleText.length) {
      return false;
    }
    await this.axios.put(`/data-process/project/${this.modifyTarget}`, {
      title: this.titleText,
    });
    this.$bvModal.hide('modify-modal');
    await this.load();
  }

  moreClick({
    changeValue,
    targetId,
  }: {
    changeValue: boolean;
    targetId: string;
  }) {
    if (changeValue) {
      for (const i of this.dataList) {
        if (i._id !== targetId) {
          i.optionFlag = false;
        }
      }
    }
  }
}
