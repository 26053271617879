var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('table',{style:({
    width: '100%',
    borderTop: _vm.boundaryBorderTB && '1px solid black',
    borderBottom: _vm.boundaryBorderTB && '1px solid black',
  })},[_c('thead',{staticClass:"variable-table-thead"},[_c('tr',[_vm._l((_vm.checkBoxInfo),function(checked){return _c('th',{staticClass:"text-center pretendard-semi-bold-san-marino-14px align-middle text-nowrap check-th"},[_vm._v(" "+_vm._s(checked.name)+" ")])}),_c('th',{staticClass:"text-center pretendard-semi-bold-san-marino-14px align-middle text-nowrap"},[_vm._v(" 변수명 ")]),_c('th',{staticClass:"text-center pretendard-semi-bold-san-marino-14px align-middle text-nowrap"},[_vm._v(" 타입 ")])],2)]),_c('tbody',{staticClass:"variable-table-tbody"},_vm._l((_vm.variablesTableData.header),function(variable,idx){return (variable.use)?_c('tr',{class:`${
        idx % 2 === 0 ? 'btr' : ''
      } text-center variable-table-tbody-tr`,style:({ backgroundColor: idx % 2 === 0 ? '#fff' : '#F8F8F8' })},[_vm._l((_vm.checkBoxInfo),function(checked){return _c('td',{staticClass:"align-middle pretendard-light-gravel-14px px-1 check-td"},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('b-form-checkbox',{staticStyle:{"text-align":"center"},attrs:{"disabled":!checked.validTypeList.includes(variable.type),"value":{ [checked.type]: variable.key }},on:{"change":function($event){return _vm.checkValidationOfVariable(_vm.modelValue, 'change')}},model:{value:(_vm.modelValue),callback:function ($$v) {_vm.modelValue=$$v},expression:"modelValue"}})],1)])}),_c('td',{staticClass:"align-middle pretendard-light-gravel-14px px-1"},[_vm._v(" "+_vm._s(variable.name)+" ")]),_c('td',{staticClass:"align-middle pretendard-light-gravel-14px px-1"},[_vm._v(" "+_vm._s(variable.type)+" ")])],2):_vm._e()}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }