
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { COLOR_PALETTE } from '@/common/visualize';

@Component({})
export default class Tools extends Vue {
  @Prop() colorList!: string[];
  @Prop() chartType?: string;
  @Prop({ default: false }) autoSelectedFirstColor?: boolean;

  isShowColorPalette = false;
  selectedColorIdx: null | number = null;
  copiedColorList = this.colorList;
  colorPalette = COLOR_PALETTE;

  created() {
    if (this.autoSelectedFirstColor) this.selectedColorIdx = 0;
  }

  handlePalette() {
    this.isShowColorPalette = !this.isShowColorPalette;
  }

  handlePaletteReset() {
    this.isShowColorPalette = false;
  }

  changeColor(idx: number, color: string) {
    this.copiedColorList = this.copiedColorList.map((v, i) => {
      if (idx === i) return color;
      else return v;
    });
    this.$emit('changeColor', idx, color);
  }

  @Watch('colorList')
  watchColorList() {
    this.copiedColorList = this.colorList;
  }
}
