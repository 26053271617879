
import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import noticeModal from '@/components/common/notice-popup.vue';
import { Route } from 'vue-router';
import { popupSetInterface } from '../../../../interfaces/notice.interface';

interface IFaq {
  _id: string;
  type: string;
  content: string;
  answer: string;
  views: number;
  show?: boolean;
  seq: number;
}

@Component({
  components: {
    noticeModal,
  },
})
export default class Faq extends Vue {
  private options = [
    { text: '전체', value: '전체' },
    { text: '가입/회원정보', value: '가입/회원정보' },
    { text: '데이터 탐색', value: '데이터 탐색' },
    { text: '데이터 마켓', value: '데이터 마켓' },
    { text: '데이터 작업소', value: '데이터 작업소' },
    { text: '기타', value: '기타' },
  ];
  private pending: boolean = false;
  private zeroPostMsg: string = '';
  private total: number = 0;
  private page: number = 1;
  private perPage: number = 10;
  private search: string = '';
  private type: string = '전체';
  private list: IFaq[] = [];
  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface['type'] = 'BACK';

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }

  async showAlert(
    msg: string,
    type: 'BACK' | 'GO' | 'NO' | 'GoOut' | 'Alert' | 'WITHDRAW',
  ) {
    this.modalSetting({
      title: '',
      content: msg,
      type: type,
    });
    await this.$bvModal.show('faqPopup');
  }

  private strGoOut: string = '';
  private routeLeaveSkipFlag: boolean = false;

  private get isLogin() {
    return this.$store.getters.isLogin;
  }

  async created() {
    const { page, type, search, seq } = this.$route.query;
    if (page) this.page = Number(page);
    if (type) this.type = String(type);
    if (search) this.search = String(search);
    await this.load();

    const i = this.list.find(item => item.seq === Number(seq));
    if (i) {
      i.show = true;
    }
  }

  async load() {
    this.pending = true;
    try {
      const params = {
        page: this.page,
        perPage: this.perPage,
        search: this.search,
        type: this.type,
      };

      const { data } = await this.axios({
        url: 'faq',
        params,
      });

      const { result, total, list } = data;
      if (result) {
        this.total = Math.ceil(total / this.perPage);
        this.list = list.map((m: IFaq) => {
          return { ...m, show: false };
        });
      }
    } catch (e) {
      console.error(e);
    }
    this.pending = false;
  }

  linkGen(pageNum: number) {
    const link: string[] = [
      pageNum === 1 ? '' : `page=${pageNum}`,
      `type=${this.type}`,
    ];

    if (this.search) link.push(`search=${this.search}`);
    return `?${link.join('&')}`;
  }

  typeChangeAndSearch(target: string) {
    this.page = 1;
    const path = 'faq';
    /*    if (target === 'type') this.search = '';*/
    const query: { type: string; search?: string } = { type: this.type };
    if (this.search) query.search = this.search;
    this.load();
    this.$router
      .push({
        path,
        query,
      })
      .catch(() => {});
    if (this.type) {
      this.zeroPostMsg = !this.search.length
        ? '입력된 데이터가 없습니다.'
        : '검색어를 찾을 수 없습니다.';
    }
  }

  async detail(i: IFaq) {
    i.show = !i.show;
    if (i.show) {
      try {
        const { _id } = i;
        const { data } = await this.axios({
          url: `faq/views/${_id}`,
          method: 'put',
        });
        const { result, increase } = data;
        if (result) {
          i.views = increase;
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  async goQnaWrite() {
    this.modalSetting({
      title: '1:1 문의등록',
      content: '1:1 문의등록 페이지로 이동 하시겠습니까?',
      type: 'GO',
    });
    await this.$bvModal.show('faqPopup');
  }

  isOk() {
    const url = '/qna/write';
    if (!this.isLogin) {
      this.strGoOut = `/login?redirectPage=${url}`;
      this.routeLeaveSkipFlag = true;
      return this.showAlert('로그인 후 이용이 가능합니다.', 'WITHDRAW');
    }
    this.$router.push(url);
  }

  isCancel() {
    this.$bvModal.hide('faqPopup');
  }

  goOut() {
    this.$router.push(this.strGoOut);
  }
}
