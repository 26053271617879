
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface ICustomInputBoxItem {
  title: string;
  value: string;
  key: any;
  readonly?: boolean;
  placeholder?: string;
}

@Component({})
export default class CustomInputBox extends Vue {
  @Prop() list!: ICustomInputBoxItem[];
  @Prop({ type: Boolean, default: true }) selectInputShow!: boolean;
}
