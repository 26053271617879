
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class keySelect extends Vue {
  @Prop() option?: { text: string; value: string; type: any }[];
  @Prop() selected?: string[];

  private selectedProfile: string[] = [];
  private limitFlg: boolean = false;
  private maxSelect: number = 3;

  @Emit('profileSelect')
  profileSelect() {
    this.limitFlg = this.selectedProfile.length > this.maxSelect;

    if (this.limitFlg) {
      this.selectedProfile.pop();
    }
    return { selected: this.selectedProfile, limitFlg: this.limitFlg };
  }
}
