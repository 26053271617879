
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BookmarkBlock from '@/components/data-set/bookmark-block.vue';

@Component({ components: { BookmarkBlock } })
export default class FloatingCard extends Vue {
  private bookmarkList: [] = [];
  private userId: string = '';

  created() {
    const { getUserId } = this.$store.getters;
    this.userId = getUserId;
    this.getBookmarks();
  }

  async getBookmarks() {
    try {
      const { data } = await this.axios({
        method: 'GET',
        url: `/data-set/bookmarks/${this.userId}`,
      });

      const { result, bookmarkList } = data;
      if (result) {
        this.bookmarkList = bookmarkList;
      }
    } catch (e) {
      console.error(e);
    }
  }

  linkGo(link: string) {
    this.$router.push(link);
  }
}
