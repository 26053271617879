
import { Vue, Component } from 'vue-property-decorator';
import PhoneNumber from '@/components/user/phoneNumber.vue';
import SignUpModal from '@/components/user/SignUpModal.vue';
import PhoneAuthModal from '@/components/user/PhoneAuthModal.vue';
import NoticePopup from '@/components/common/notice-popup.vue';
import { popupSetInterface } from '../../../../interfaces/notice.interface';
@Component({
  components: { NoticePopup, PhoneNumber, SignUpModal, PhoneAuthModal },
})
export default class FindId extends Vue {
  private pageChange: boolean = false;
  $refs!: {
    userId: HTMLFormElement;
    password: HTMLFormElement;
    passwordCheck: HTMLFormElement;
    userName: HTMLFormElement;
    number: HTMLFormElement;
    email: HTMLFormElement;
    phone: HTMLFormElement;
    modal: HTMLFormElement;
  };
  private userInfo: {
    userId: string;
    password: string;
    userName: string;
    phone: number | null;
    phoneNumber: string;
    email: string;
    company: string;
    selectedCat1: number | null;
    selectedCat2: number | null;
    sectors: string;
    hubTerms: boolean;
    privacyAgreement: boolean;
  } = {
    userId: '',
    password: '',
    userName: '',
    phone: null,
    phoneNumber: '',
    email: '',
    company: '',
    selectedCat1: null,
    selectedCat2: null,
    sectors: '',
    hubTerms: false,
    privacyAgreement: false,
  };
  private numberValid: boolean = false;
  private phoneDialog: boolean = false;
  private modalMsg: string = '';
  private setData: { userId: string; userName: string } = {
    userId: '',
    userName: '',
  };
  private isNumber: boolean = false;
  private phoneNumber: string = '';
  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface['type'] = 'BACK';
  constructor() {
    super();
  }
  mounted() {
    this.$emit('isPage', this.$route.path);
  }
  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }
  private phoneOnlyNumber(e: any) {
    const regex = /[^0-9]/g;
    let value = e.target.value;
    if (regex.test(value)) {
      value = value.replace(regex, '');
      this.userInfo.phoneNumber = value;
    }
  }
  async PhoneNumberAuth() {
    if (this.numberValid) {
      this.userInfo.phoneNumber = '';
      this.numberValid = false;
      return;
    }

    const userPhoneNumber = this.userInfo.phoneNumber;
    if (userPhoneNumber.length !== 11) {
      this.modalSetting({
        title: '',
        content: '유효하지 않는 핸드폰 번호 입니다.',
        type: 'Alert',
      });
      this.$bvModal.show('findIDPopup');
      /*this.$toast.open({
          message: '유효하지 않는 핸드폰 번호 입니다.',
          type: 'error',
          duration: 5000,
      });*/
      this.$refs.number.focus();
      return false;
    }

    if (!confirm(`${userPhoneNumber}로 인증번호를 발송하시겠습니까?`)) return;

    try {
      //
      const { data } = await this.axios({
        url: '/user/find-id/phone-number',
        method: 'get',
        params: { userPhoneNumber },
      });
      if (!data.result) {
        this.numberValid = false;
        return false;
      }
      const { info } = data;

      this.setData = info;
      this.setUserId();
      this.phoneDialog = true;
      this.modalMsg = userPhoneNumber;
      this.$bvModal.show('modal-phone');
      await this.sendSMS(userPhoneNumber);
    } catch (err) {
      console.error(err);
    }
  }
  async sendSMS(phone: string) {
    //인증 문자 발송
    const randomNumber: string = Math.floor(Math.random() * 1000000)
      .toString()
      .padStart(6, '0');

    try {
      const res = await this.axios.post('/sms', {
        title: '발송 제목',
        message: '본인확인 인증번호(' + randomNumber + ')를 입력해 주세요.',
        randomNumber: randomNumber,
        receiver: [
          {
            mobile: phone,
          },
        ],
        isAuth: true,
      });
      if (!res.data.result) {
        this.hidePhoneDialog();
        this.modalSetting({
          title: '',
          content: `발송 실패 ERROR CODE : ${res.data.status}`,
          type: 'Alert',
        });
        this.$bvModal.show('findIDPopup');
        /*this.$toast.open({
            message: '발송 실패 ERROR CODE : ' + res.data.status,
            type: 'error',
            duration: 5000,
        });*/
      }
    } catch (e) {
      console.error(e);
    }
  }
  hidePhoneDialog() {
    this.phoneDialog = false;
  }
  isPhoneNumber(param: {
    numberValid: boolean;
    phoneDialog: boolean;
    isNumber: boolean;
  }) {
    const { phoneDialog, numberValid, isNumber } = param;
    this.isNumber = isNumber;
    this.phoneDialog = phoneDialog;
    this.numberValid = numberValid;
  }
  private nextPage() {
    //isNumber - false 변경
    this.isNumber = false;
    //pageChange true 일 경우 페이지 모양 변경
    this.pageChange = true;
  }
  setUserId() {
    const { userId } = this.setData;
    const frontStr = userId.substring(0, 3);
    const backStr = userId.slice(-2);
    const remainStr = userId.replace(frontStr, '').replace(backStr, '');
    let remainStar = '';
    const remainLength = remainStr.length;
    for (let i = 0; i < remainLength; i++) {
      remainStar += '*';
    }
    this.setData.userId = `${frontStr}${remainStar}${backStr}`;
  }
  gotoLogin() {
    this.$router.push('/login');
  }
  gotoFindPw() {
    this.$router.push('/help/find_pw');
  }
}
