
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PROJECT_TYPE } from '@/enum/common';
@Component({})
export default class CheckProfileData extends Vue {
  @Prop() data?: {
    _id: string;
    title: string;
    type: string;
    content: string;
    profile: string[];
  }[];
  @Prop() option?: { text: string; value: string; type: any }[];

  private imgUrl(type: string) {
    console.log();
    if (type === PROJECT_TYPE.CRAWLING) return 'crawling';
    if (type === PROJECT_TYPE.TRANSACTION) return 'transaction';
    if (type === PROJECT_TYPE.PUBLIC_SEOUL) return 'public_seoul';
    if (type === PROJECT_TYPE.MOBILE) return 'mobile';
    if (type === PROJECT_TYPE.SURVEY) return 'survey';
    if (type === PROJECT_TYPE.USER_UPLOAD) return 'user_upload';
    return null;
  }

  private profileInclude(profile: string[], item: string) {
    return profile.includes(item);
  }
}
