<template>
  <div class="container">
    <div
      class="text-title"
      style="
        color: black;
        font-size: 28px;
        margin-top: 50px;
        margin-bottom: 90px;
      "
    >
      통합데이터허브 회원가입 이용약관
    </div>
    <div class="text-title">제 1장 총칙</div>
    <div class="text-sub-title">제 1조 목적</div>
    <div class="text-content">
      이 약관은 ㈜피앰아이(이하 '회사')가 운영하는 통합 데이터 허브(이하
      “플랫폼” 이라 한다) 에서 제공하는 서비스를 이용하려는 통합 데이터 허브
      플랫폼 회원간의 권리와 의무, 책임 사항, 그 밖에 필요한 기본적인 사항을
      규정함을 목적으로 합니다.
    </div>
    <div class="text-sub-title">제 2조 용어의 정의</div>
    <div class="text-content">
      <span>①</span> 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br />
      1. 플랫폼 : 통합 데이터 허브 가 데이터, UI, API 등(이하 “데이터 등”이라
      함)을 이용자에게 제공하기 위하여, 데이터를 수집, 처리, 저장, 분석 및
      시각화 등을 통하여 회원 들에게 제반을 혜택을 제공하는 시스템을 말하며,
      아울러 플랫폼을 운영하는 사업자의 의미로도 사용합니다.<br />
      2. 이용자 : “플랫폼”에 접속하여 이 약관에 따라 “플랫폼”이 제공하는
      서비스를 받는 회원 및 비회원을 말합니다.<br />
      3. 회원 : “플랫폼”에 회원 등록을 한 자로서, 계속적으로 “플랫폼”이 제공하는
      서비스를 이용할 수 있는 자를 말하며 개인회원, 기업회원 등으로
      분류합니다.<br />
      4. 비회원 : 통합 데이터 허브에 가입하지 않고 “플랫폼”이 제공하는 서비스를
      이용하는 자를 말합니다.<br />
      5. 아이디(ID) : “회원”의 식별과 서비스 이용을 위하여 “회원”이 정하고
      “회사”가 승인하는 문자, 숫자, 특수문자 등의 조합을 말합니다.<br />
      6. 비밀번호(PASSWORD) : “회원”이 부여 받은 “아이디”와 일치되는 “회원”임을
      확인하고 비밀보호를 위해 “회원” 자신이 설정한 문자, 숫자, 특수문자 등의
      조합을 말합니다.<br />
      7. 데이터 상품 : “플랫폼”에서 제공되는 일체의 데이터 등을 말합니다.
    </div>
    <div class="text-sub-title">
      제 3조 약관의 적용 및 효력, 변경(약관 등의 명시와 설명 및 개정)
    </div>
    <div class="text-content">
      <span>①</span> ‘플랫폼’은 이 약관의 내용과 상호 및 대표자 성명, 영업소
      소재지 주소 (소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호,
      모사전송번호, 전자우편주소, 사업자등록번호 등을 이용자가 쉽게 알 수 있도록
      ‘플랫폼’의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은
      이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.<br />
      <span>②</span> '플랫폼'은 “약관의 규제에 관한 법률”, “정보 통신망 이용
      촉진 및 정보 보호 등에 관한 법률(이하 “정보통신망법”)” 등 관련 법령을
      위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br />
      <span>③</span> ‘플랫폼’은 「약관의 규제에 관한 법률」, 「전자문서 및
      전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망
      이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」,
      「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수
      있습니다.<br />
      <span>④</span> ‘플랫폼’이 약관을 개정할 경우에는 적용일자 및 개정사유를
      명시하여 현행약관과 함께 플랫폼 초기화면에 그 적용일자 7일 이전부터
      적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을
      변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이
      경우 ‘플랫폼’은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가
      알기 쉽도록 표시합니다.<br />
      <span>⑤</span> ‘플랫폼’이 약관을 개정할 경우에는 그 개정약관은 그 적용일자
      이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는
      개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가
      개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지
      기간 내에 ‘플랫폼’에 송신하여 ‘플랫폼’의 동의를 받은 경우에는 개정 약관
      조항이 적용됩니다.<br />
      <span>⑥</span> 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
      소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가
      정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에
      따릅니다.<br />
    </div>
    <div class="text-sub-title">제 4조 서비스 제공 및 변경</div>
    <div class="text-content">
      <span>①</span> “플랫폼”은 다음과 같은 업무를 수행합니다.<br />
      1. 데이터 또는 분석환경에 대한 정보 제공 및 무료 구매 계약의 체결<br />
      2. 데이터 거래 및 활용을 위한 플랫폼 운영<br />
      3. 분석 환경 제공 서비스 : 회원에게는 플랫폼 내의 데이터를 분석하기 위한
      분석 환경을 제공<br />
      4. 기타 “플랫폼”이 정하는 업무<br />
      <span>②</span> “플랫폼”의 기술적 사양의 변경 등의 경우에는 장차 체결되는
      계약에 의해 제공할 데이터 상품 정보와 관련된 내용을 변경할 수 있습니다. 이
      경우에는 변경된 내용 및 제공일자를 명시하여 현재의 데이터 상품 내용을
      게시한 곳에 수정 게시합니다.<br />
    </div>
    <div class="text-sub-title">제 5조 서비스 중단</div>
    <div class="text-content">
      <span>①</span> “플랫폼”은 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장,
      통신의 두절, 디도스 공격, 천재지변 또는 이에 준하는 불가항력 등의 사유가
      발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있으며, 이 경우
      서비스 제공에 관한 책임이 면제됩니다.<br />
      <span>②</span> “플랫폼”은 제1항의 이외의 사유로 서비스의 제공이 일시적으로
      중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단,
      “플랫폼”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
    </div>
    <div class="text-title">제 2장 서비스 이용계약</div>
    <div class="text-sub-title">제 6조 서비스 이용계약 및 가입신청</div>
    <div class="text-content">
      <span>①</span> “플랫폼” 서비스를 이용하고자 하는 자는 “플랫폼”이 정한 가입
      양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서
      회원가입을 신청합니다. 가입신청 시 필요한 정보는 사실대로 기재하여야 하며
      정해진 인증절차를 준수해야 합니다.<br />
      <span>②</span> 회원가입신청서에는 다음 사항을 기재해야 합니다. 2호, 4호,
      5호의 사항은 필수사항이며, 그 외의 사항은 선택사항입니다.<br />
      1. “회원”의 성명<br />
      2. “아이디”와 “비밀번호”<br />
      3. 전자우편주소<br />
      4. 회원 유형(기관 구분)<br />
      5. 기타 “플랫폼”이 필요하다고 인정하는 사항<br />
    </div>
    <div class="text-sub-title">제 7조 서비스 가입신청의 승낙</div>
    <div class="text-content">
      <span>①</span> “플랫폼”은 “이용자”가 약관의 내용에 대해서 동의를 하고,
      서비스 가입신청에 대하여 승낙함을 원칙으로 하며, 휴대폰 번호 인증 완료 시
      서비스 가입신청에 대한 승낙이 완료 됩니다.<br />
      <span>②</span> “플랫폼”은 다음 각 호에 해당 하는 신청에 대하여는 승낙을
      하지 않을 수 있습니다.<br />
      1. 실명이 아니거나 타인의 명의를 이용한 경우<br />
      2. 허위의 정보를 기재하거나, “플랫폼”에서 제시하는 내용을 기재하지 않은
      경우<br />
      3. 가입 신청자의 귀책사유로 인하여 승인이 불가능한 경우<br />
      4. “플랫폼” 으로부터 자격정지 조치 등을 받은 회원이 그 조치 기간 중에
      이용계약을 임의 해지하고 재이용신청을 하는 경우<br />
      5. 기타 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및
      “플랫폼”의 합리적 판단에 의하여 필요하다고 인정되는 경우<br />
      <span>③</span> 제 1항에 따른 신청에 있어서 “플랫폼”은 전문기관을 통한
      실명확인 및 본인인증, 계좌검증 및 사업자등록 확인 등을 요청할 수
      있습니다.<br />
      <span>④</span> 서비스 이용계약의 성립시기는 제 1항에 따른 “플랫폼”의
      승낙이 완료되고 해당 내용을 회원에게 발송한 시점으로 합니다.<br />
    </div>
    <div class="text-sub-title">제 8조 서비스 이용계약의 해지</div>
    <div class="text-content">
      <span>①</span> 회원은 “플랫폼”에 언제든지 탈퇴를 요청할 수 있으며
      “플랫폼”은 즉시 회원탈퇴를 처리합니다.<br />
      <span>②</span> 회원이 다음 각 호의 사유에 해당하는 경우, “플랫폼”은
      회원자격을 제한 및 정지시킬 수 있습니다.<br />
      1. 가입 신청 시에 허위 내용을 등록한 경우<br />
      2. 다른 사람의 “플랫폼” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래
      질서를 위협하는 경우<br />
      3. “플랫폼”을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는
      행위를 하는 경우<br />
      <span>③</span> “플랫폼”이 회원 자격을 제한․정지 시킨 후, 동일한 행위가 2회
      이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “플랫폼”은
      회원 자격을 상실시킬 수 있습니다.<br />
      <span>④</span> “플랫폼”이 회원자격을 상실시키는 경우에는 회원등록을
      말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일
      이상의 기간을 정하여 소명할 기회를 부여합니다.<br />
      <span>⑤</span> 휴면 계정<br />
      1. 회원 이용기간 만료 후 1년 이상 “플랫폼”에 로그인 하지 않을 경우에는
      회원의 계정은 휴면 계정으로 전환 되며, 휴면 계정의 개인정보는 다른 계정과
      별도로 관리됩니다.<br />
    </div>
    <div class="text-title">제 3장 회원정보의 보호</div>
    <div class="text-sub-title">제 9조 회원정보의 수집과 제공</div>
    <div class="text-content">
      <span>①</span> ‘플랫폼’은 이용계약을 위하여 회원이 제공한 정보 외에도
      수집목적 또는 이용목적 등을 밝혀 회원으로부터 필요한 정보를 수집하거나
      이용할 수 있습니다. 이 경우, 플랫폼은 회원으로부터 정보수집 이용에 대한
      동의를 받아야 합니다.<br />
      <span>②</span> ‘플랫폼’은 이용계약을 위하여 회원이 제공한 정보를 회원이
      동의한 서비스 운영을 위한 목적 이외의 용도로 사용할 수 없으며, 새로운
      이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서
      당해 회원에게 그 목적을 고지하고 동의를 받아야 합니다. 다만, 관련 법령에
      달리 정함이 있는 경우에는 예외로 합니다.<br />
    </div>
    <div class="text-sub-title">제 10조 회원정보의 변경</div>
    <div class="text-content">
      <span>①</span> 회원은 이용신청 시 기재한 사항이 잘못되었거나 변경되었을
      경우, 즉시 해당 사항을 수정해야 합니다. 회원정보의 변경은 로그인을 한 후
      내 정보 수정에서 변경할 수 있으며 단, 아이디(ID)는 수정할 수 없음을
      원칙으로 합니다.<br />
      <span>②</span> ‘플랫폼’은 회원에 대한 통지는 회원이 제공한 휴대폰 번호에
      도달함으로써 통지된 것으로 보며, 수정하지 않은 정보로 인하여 발생하는
      손해는 당해 회원이 전적으로 부담하고, ‘플랫폼’은 이에 대하여 어떠한 책임도
      지지 않습니다.<br />
    </div>
    <div class="text-sub-title">제 11조 아이디 및 비밀번호 관리</div>
    <div class="text-content">
      <span>①</span> 아이디(ID), 비밀번호에 대한 관리책임은 회원에게 있으며,
      회원은 어떠한 경우에도 본인의 아이디(ID) 또는 비밀번호를 타인에게
      양도하거나 대여할 수 없습니다.<br />
      <span>②</span> ‘플랫폼’의 귀책사유에 의하지 아니한 아이디(ID) 또는
      비밀번호의 유출, 양도, 대여로 인한 손실이나 손해에 대하여 ‘플랫폼’은
      어떠한 책임도 지지 않습니다.<br />
      <span>③</span> 회원이 아이디(ID) 또는 비밀번호를 도난 당하거나 제3자가
      무단으로 이를 사용하고 있음을 인지한 경우, 이를 즉시 ‘플랫폼’에 통보하여야
      하고, ‘플랫폼’의 안내가 있는 경우에는 그에 따라야 합니다.<br />
    </div>
    <div class="text-sub-title">제 12조 개인정보 제공 동의 등</div>
    <div class="text-content">
      <span>①</span> ‘플랫폼’이 제3자에게 구매자 개인정보를 제공할 필요가 있는
      경우<br />
      1. 개인정보를 제공받는 자<br />
      2. 개인정보를 제공받는 자의 개인정보 이용목적<br />
      3. 제공하는 개인정보의 항목<br />
      4. 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고
      동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)<br />
      <span>②</span> ‘플랫폼’이 제3자에게 구매자의 개인정보를 취급할 수 있도록
      업무를 위탁하는 경우에는<br />
      1. 개인정보 취급위탁을 받는 자<br />
      2. 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야
      합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)<br />
      <br />
      다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과
      관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서
      정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와
      동의절차를 거치지 않아도 됩니다.<br />
    </div>
    <div class="text-title">제 4장 서비스 이용 및 책임의 제한 등</div>
    <div class="text-sub-title">제 13조 플랫폼의 의무</div>
    <div class="text-content">
      <span>①</span> 플랫폼은 특별한 사정이 없는 한 회원이 신청한 서비스를
      서비스제공 개시일에 이용할 수 있도록 합니다.<br />
      <span>②</span> 플랫폼은 시스템 점검 및 서비스 개발, 통신장애, 기타
      불가항력적인 사고 등 특별한 사정이 없는 한 이 약관이 정한 바에 따라
      지속적으로 안정적인 서비스를 제공할 의무가 있습니다.<br />
      <span>③</span> 플랫폼은 서비스 제공과 관련하여 알고 있는 회원의 신상정보를
      본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 단, 관계법령에 의한
      수사상의 목적으로 관계 기관으로부터 요구 받은 경우나 방송통신심의위원회의
      요청이 있는 경우 등 법률의 규정에 따른 적법한 절차에 의한 경우에는
      그러하지 않습니다.<br />
      <span>④</span> 플랫폼은 서비스의 관련한 회원의 불만사항이 접수되는 경우
      이를 신속하게 처리하여야 하며, 신속한 처리가 곤란한 경우 그 사유와 처리
      일정을 서비스 화면에 게재하거나 이메일 등을 통하여 동 회원에게
      통지합니다.<br />
      <span>⑤</span> 플랫폼이 제공하는 서비스로 인하여 회원에게 손해가 발생한
      경우 그러한 손해가 플랫폼의 고의나 중과실에 기해 발생한 경우에 한하여
      플랫폼에서 책임을 부담하여, 그 책임의 범위는 통상손해에 한합니다.<br />
      <span>⑥</span> 플랫폼은 정보통신망 이용촉진 및 정보보호에 관한 법률,
      통신비밀보호법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.<br />
      <span>⑦</span> 플랫폼은 회원이 안전하게 서비스를 이용할 수 있도록 개인정보
      보호를 위해 보안시스템을 갖추어야 하며, 개인정보취급방침을 공시하고
      준수합니다.<br />
    </div>
    <div class="text-sub-title">제 14조 회원의 의무</div>
    <div class="text-content">
      <span>①</span> 회원은 서비스를 이용할 때 다음 각호의 행위를 하지 않아야
      합니다.<br />
      1. 다른 회원의 아이디(ID) 및 비밀번호(PASSWORD)를 부정하게 사용하는
      행위<br />
      2. 서비스를 이용하여 얻은 정보를 복사, 가공, 2차적 저작 등을 통하여 복제,
      방송, 전시, 배포, 출판 등에 사용하거나 제3자에게 제공하는 행위<br />
      3. 타인의 명예를 손상시키거나 불이익을 주는 행위<br />
      4. 플랫폼 및 제3자의 저작권, 지식재산권 등 기타 권리를 침해하는 내용인
      경우<br />
      5. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을
      타인에게 유포하는 행위<br />
      6. 범죄와 결부된다고 객관적으로 인정되는 행위<br />
      7. 서비스와 관련된 설비의 오 동작이나 정보 등의 파괴 및 혼란을 유발시키는
      컴퓨터 바이러스 감염자료를 등록 또는 유포하는 행위<br />
      8. 서비스의 안정적 운영을 방해할 수 있는 정보를 전송하거나 수신자의 의사에
      반하여 광고성 정보를 전송하는 행위<br />
      9. 기타 관계법령에 위배되는 행위<br />
      <span>②</span> 아이디(ID)와 비밀번호(PASSWORD) 관리에 관한 일체의 책임은
      회원 본인에게 있습니다. 회원에게 부여된 아이디(ID)와 비밀번호(PASSWORD)를
      제3자에게 대여 또는 양도하거나 이와 유사한 행위를 하여서는 안되며,
      아이디(ID)와 비밀번호(PASSWORD)의 관리소홀, 부정사용에 의하여 발생하는
      모든 결과에 대한 책임은 회원 본인에게 있습니다.<br />
      <span>③</span> 회원은 자신의 아이디(ID)가 부정하게 사용된 것을 인지한
      경우, 즉시 자신의 비밀번호(PASSWORD)를 변경하고 플랫폼에 신고하여야
      합니다. 신고를 하지 않음으로 인해 발생하는 모든 책임은 회원 본인에게
      있습니다.<br />
      <span>④</span> 회원은 본인의 신상 관련 사항이 변경되었을 때는 “플랫폼
      홈페이지”을 통하여 수정하여야 합니다. 변경된 신상 정보를 변경하지 않음으로
      인해 발생하는 모든 책임은 회원 본인에게 있습니다.<br />
      <span>⑤</span> 회원은 플랫폼의 사전승낙 없이 서비스를 이용하여 영업활동을
      할 수 없으며, 그 영업활동으로 인한 결과에 대하여 플랫폼은 책임을 지지
      않습니다.<br />
      <span>⑥</span> 회원은 이 약관 및 관계법령에서 규정한 사항과 서비스
      이용안내 또는 주의사항을 성실히 준수하여야 합니다.<br />
      <span>⑦</span> 회원은 내용별로 플랫폼이 서비스 공지사항에 게시하거나
      별도로 공지한 이용 제한사항을 준수하여야 합니다.<br />
      <span>⑧</span> 회원은 플랫폼의 사전동의 없이 서비스의 이용권한, 기타
      이용계약상 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수
      없습니다.<br />
    </div>
    <div class="text-sub-title">제 15조 게시물 등의 관리</div>
    <div class="text-content">
      <span>①</span> 플랫폼은 서비스를 제공하는 “플랫폼 홈페이지”의 원활한
      관리∙감독을 위하여 필요한 경우, 게시되는 내용물에 대해 추가∙수정∙삭제할 수
      있으며, 회원에게 별도 통지 없이 서비스의 개편 및 내용의 추가∙수정∙삭제를
      할 수 있습니다.<br />
      <span>②</span> 플랫폼은 회원이 게시하거나 전달하는 서비스 내의 모든 내용물
      (회원간 전달내용 포함)이 다음의 경우에 해당한다고 판단되는 경우 별도의
      사전 통지 없이 삭제할 수 있으며, 이에 대해 플랫폼은 어떠한 책임도 지지
      않습니다.<br />
      1. 플랫폼, 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를
      손상시키는 내용인 경우<br />
      2. 공공질서 및 미풍양속에 위반되는 내용인 경우<br />
      3. 범죄적 행위에 결부된다고 인정되는 내용인 경우<br />
      4. 플랫폼의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우<br />
      5. 본조 제2항 소정의 세부 이용지침을 통하여 플랫폼에서 규정한 게시기간을
      초과한 경우<br />
      6. 플랫폼에서 제공하는 서비스와 관련 없는 내용인 경우<br />
      7. 불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우<br />
      8. 기타 관계법령 및 플랫폼의 세부지침 등에 위반된다고 판단되는 경우<br />
      <span>③</span> 플랫폼은 게시물에 관련된 세부 이용지침을 별도로 정하여
      시행할 수 있으며, 회원은 그 지침에 따라 각종 게시물(회원간 전달내용
      포함)을 등록, 삭제 등을 하여야 합니다.<br />
      <span>④</span> 회원은 개인적인 이익을 위하여 플랫폼에 게시물의 추가적 게재
      등의 요구를 할 수 없습니다.<br />
    </div>
    <div class="text-sub-title">제 16조 링크사이트에 대한 책임</div>
    <div class="text-content">
      <span>①</span> 플랫폼은 회원에게 다른 문정원의 웹사이트 또는 자료에 대한
      링크를 제공할 수 있습니다. 그러나 이 경우 플랫폼은 링크된 외부사이트 및
      자료에 대한 아무런 통제권이 없으므로 그로부터 제공받은 서비스나 자료의
      유용성에 대해 플랫폼이 아무런 보증도 하지 않고, 그에 대해 책임이
      없습니다.<br />
      <span>②</span> 회원은 링크된 외부사이트의 서비스나 자료를 신뢰함으로써
      야기되거나 야기되었다고 주장되는 어떠한 손해나 손실에 대해서는 플랫폼에
      책임이 없음을 인정하고 이에 동의합니다.<br />
    </div>
    <div class="text-sub-title">제 17조 면책</div>
    <div class="text-content">
      <span>①</span> 플랫폼은 다음 각호의 사유로 인하여 회원에게 발생한 손해에
      대해서는 책임을 지지 않습니다. 다만, 플랫폼이 책임 있다고 판단되는 상당한
      인과관계가 있는 경우에는 그러하지 아니합니다.<br />
      1. 통신기기, 회선 및 컴퓨터의 장애나 거래 폭주 등 부득이한 사정으로
      서비스가 제공되지 못하거나 지연된 경우<br />
      2. 회원이 아이디(ID), 비밀번호(PASSWORD) 등을 본인의 관리소홀로 인해
      제3자에게 누출한 경우<br />
      3. 회원의 귀책사유(전산조작이나 업무처리 오류 등)로 인한 경우<br />
      4. 기타 천재지변 등의 불가피한 사유로 인한 경우<br />
      <span>②</span> 플랫폼은 회원이 서비스를 이용하여 기대하는 수익을 상실한
      것에 대하여 책임을 지지 않으며 그 밖에 서비스를 통여 얻은 자료로 인한 손해
      등에 대하여도 책임을 지지 않습니다. 플랫폼은 회원이 “플랫폼 홈페이지”에
      게재한 정보, 자료의 신뢰도, 정확성 등 내용에 대하여는 책임을 지지
      않습니다.<br />
      <span>③</span> 플랫폼은 회원 상호간 또는 회원과 제3자 상호간에 서비스를
      매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할
      책임도 없습니다.<br />
    </div>
    <div class="text-sub-title">제 18조 저작권 귀족 및 이용제한</div>
    <div class="text-content">
      <span>①</span> 플랫폼이 제공하는 데이터, 서비스, 그에 필요한 화면(문구 및
      디자인) 등과 관련된 지적재산권 및 기타 관리는 플랫폼에 귀속됩니다.<br />
      <span>②</span> 회원은 플랫폼이 제공하는 서비스를 이용함으로써 얻은 정보 중
      플랫폼에 지적재산권이 귀속된 정보를 사전 승낙 없이 출판, 복제, 방송 및
      기타 방법에 의하여 유포하거나 제3자에게 이용하게 해서는 아니 됩니다.<br />
      <span>③</span> 회원이 서비스 내에 게시한 게시물(회원간 전달내용 포함)의
      저작권은 회원이 소유하며 플랫폼은 서비스 내에 이를 게시할 수 있는 권리를
      갖습니다.<br />
      <span>④</span> 플랫폼은 회원의 동의 없이 게시물을 다른 목적으로 사용할 수
      없습니다.<br />
      <span>⑤</span> 플랫폼은 회원이 서비스 내에 게시한 게시물이 타인의 저작권,
      프로그램 저작권 등을 침해하더라도 이에 대해 민, 형사 상의 책임을 부담하지
      않습니다. 만일 회원이 타인의 저작권, 프로그램 저작권 등을 침해하였음을
      이유로 플랫폼이 타인으로부터 손해배상 청구 등 이의 제기를 받은 경우 회원은
      플랫폼의 면책을 위하여 노력해야 하며, 플랫폼이 면책되지 못한 경우 회원은
      그로 인해 플랫폼에 발생한 모든 손해를 부담하여야 합니다.<br />
      <span>⑥</span> 플랫폼은 회원이 해지하거나 적법한 사유로 해지된 경우 해당
      회원이 게시하였던 게시물을 삭제할 수 있습니다.<br />
    </div>
    <div class="text-sub-title">제 19조 약관위반 시 책임</div>
    <div class="text-content">
      <span>①</span> 플랫폼과 회원은 이 약관을 위반함으로써 발생하는 모든 책임을
      각자 부담하며, 이로 인하여 상대방에게 손해를 입힌 경우에는 지체 없이
      배상하여야 합니다.
    </div>
  </div>
</template>

<script>
export default {
  name: 'service',
};
</script>

<style scoped>
span {
  font-size: 12px;
}
.container {
  max-width: 1140px;
  padding-bottom: 20px;
}
.text-title {
  font-family: 'Pretendard-Bold';
  font-size: 19px;
  margin-bottom: 20px;
  color: #445cb2;
}
.text-sub-title {
  font-family: 'Pretendard-SemiBold';
  font-size: 17px;
  margin-bottom: 10px;
}
.text-content {
  font-family: 'Pretendard-Regular';
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 20px;
}
</style>
