
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class PopularCard extends Vue {
  private popularSearchData = {
    data: [
      { index: 1, label: '인기 조회 데이터1', state: 'up' },
      { index: 2, label: '인기 조회 데이터2', state: 'down' },
      { index: 3, label: '인기 조회 데이터3', state: 'new' },
    ],
  };
  private popularDownData = {
    data: [
      { index: 1, label: '인기 다운로드 데이터1', state: 'up' },
      { index: 2, label: '인기 다운로드 데이터2', state: 'new' },
      { index: 3, label: '인기 다운로드 데이터3', state: 'down' },
    ],
  };
  linkTo() {
    this.$emit('showAlert', ['서비스 준비중입니다.', 'Alert']);
  }
}
