
import { Vue, Component, Prop } from 'vue-property-decorator';
import customInput from '@/components/common/custom-input.vue';
import NoticePopup from '@/components/common/notice-popup.vue';
@Component({
  components: { NoticePopup, customInput },
})
export default class PwInquiry extends Vue {
  @Prop({
    default: {
      _id: '',
      userId: '',
      userName: '',
    },
  })
  infos: { _id: string; userId: string; userName: string } | undefined; // 타입
  $refs!: {
    userId: HTMLFormElement;
    password: HTMLFormElement;
    passwordCheck: HTMLFormElement;
    userName: HTMLFormElement;
    number: HTMLFormElement;
    email: HTMLFormElement;
    phone: HTMLFormElement;
    modal: HTMLFormElement;
  };
  private password: string = '';
  private passwordCheck: string = '';
  private passwordValidFlag: boolean | null = null;
  private passwordCheckValidFlag: boolean | null = null;
  constructor() {
    super();
  }
  created() {
    if (!this.infos?._id && !this.infos?.userId) this.$router.push('/login');
  }
  mounted() {
    this.$emit('isPage', this.$route.path);
  }
  passwordValid() {
    const regex =
      /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/;
    this.passwordValidFlag = regex.test(this.password);
    if (this.passwordCheckValidFlag !== null) this.passwordCheckValid();
  }
  passwordCheckValid() {
    if (this.password === '' && this.passwordCheck === '') return;
    this.passwordCheckValidFlag = this.password === this.passwordCheck;
  }
  async pwInquiry() {
    const sendData = { ...this.infos, newPw: this.password };
    await this.axios.post('/user/pwInquiry', sendData);
    /*await this.$bvModal.msgBoxOk('비밀번호가 변경되었습니다.', {
          okVariant: 'success',
          okTitle: '확인',
          title: '',
          headerClass: 'p-2 top-header',
          bodyClass: 'pt-4 text-center top-alert',
          footerClass: 'p-2 footer-node',
          centered: true,
      });*/
    await this.$bvModal.show('pwInquiryPopup');
    //await this.$router.push('/login');
  }
  goOut(url: string) {
    this.$router.push(url);
  }
}
