var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container-data-search"},[_c('div',{staticClass:"container col-9 d-flex justify-content-center"},[_c('div',[_c('div',{staticClass:"text-sub d-flex justify-content-center"},[_vm._v("DATA SOLUTION")]),_c('div',{staticClass:"text-title d-flex justify-content-center"},[_vm._v(" 데이터 탐색하기 ")]),_c('div',{staticClass:"d-flex justify-content-between mb-5"},[_c('div',{staticClass:"card-group",on:{"click":function($event){return _vm.checkRedirect({
              HOST_NAME: _vm.HOST_LINK.CRAWLING,
            })},"mouseover":function($event){return _vm.hoverEvent(1, true)},"mouseout":function($event){return _vm.hoverEvent(1, false)}}},[_c('div',{staticClass:"card-inner"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFlag1),expression:"!hoverFlag1"}],staticClass:"card-icon",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/크롤링01.svg"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverFlag1),expression:"hoverFlag1"}],staticClass:"card-icon",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/크롤링_hover.svg"}}),_c('div',{staticClass:"card-title"},[_vm._v("뉴스/SNS 트렌드 데이터")]),_vm._m(0)]),_c('div',{staticClass:"learn-more"},[_vm._v(" Learn More "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFlag1),expression:"!hoverFlag1"}],staticClass:"ml-2",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/arrow-right-4.svg"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverFlag1),expression:"hoverFlag1"}],staticClass:"ml-2",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/arrow-right_hover.svg"}})])]),_c('div',{staticClass:"card-group",on:{"click":function($event){return _vm.checkRedirect({
              HOST_NAME: _vm.HOST_LINK.MPD,
            })},"mouseover":function($event){return _vm.hoverEvent(2, true)},"mouseout":function($event){return _vm.hoverEvent(2, false)}}},[_c('div',{staticClass:"card-inner"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFlag2),expression:"!hoverFlag2"}],staticClass:"card-icon",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/mpd.svg"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverFlag2),expression:"hoverFlag2"}],staticClass:"card-icon",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/모바일_hover.svg"}}),_c('div',{staticClass:"card-title"},[_vm._v("모바일 행동 데이터")]),_vm._m(1)]),_c('div',{staticClass:"learn-more"},[_vm._v(" Learn More "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFlag2),expression:"!hoverFlag2"}],staticClass:"ml-2",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/arrow-right-4.svg"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverFlag2),expression:"hoverFlag2"}],staticClass:"ml-2",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/arrow-right_hover.svg"}})])]),_c('div',{staticClass:"card-group",on:{"click":function($event){return _vm.checkRedirect({
              HOST_NAME: _vm.HOST_LINK.PUBLIC,
            })},"mouseover":function($event){return _vm.hoverEvent(3, true)},"mouseout":function($event){return _vm.hoverEvent(3, false)}}},[_c('div',{staticClass:"card-inner"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFlag3),expression:"!hoverFlag3"}],staticClass:"card-icon",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/gg.svg"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverFlag3),expression:"hoverFlag3"}],staticClass:"card-icon",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/공공_hover.svg"}}),_c('div',{staticClass:"card-title"},[_vm._v("공공 데이터")]),_c('div',{staticClass:"card-sub-text"},[_vm._v("서울시 공공 데이터 분석")])]),_c('div',{staticClass:"learn-more"},[_vm._v(" Learn More "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFlag3),expression:"!hoverFlag3"}],staticClass:"ml-2",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/arrow-right-4.svg"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverFlag3),expression:"hoverFlag3"}],staticClass:"ml-2",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/arrow-right_hover.svg"}})])]),_c('div',{staticClass:"card-group",on:{"click":function($event){return _vm.checkRedirect({
              HOST_NAME: _vm.HOST_LINK.TRANSACTION,
            })},"mouseover":function($event){return _vm.hoverEvent(4, true)},"mouseout":function($event){return _vm.hoverEvent(4, false)}}},[_c('div',{staticClass:"card-inner"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFlag4),expression:"!hoverFlag4"}],staticClass:"card-icon",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/결제01.svg"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverFlag4),expression:"hoverFlag4"}],staticClass:"card-icon",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/결제_hover.svg"}}),_c('div',{staticClass:"card-title"},[_vm._v("결제 데이터")]),_vm._m(2)]),_c('div',{staticClass:"learn-more"},[_vm._v(" Learn More "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hoverFlag4),expression:"!hoverFlag4"}],staticClass:"ml-2",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/arrow-right-4.svg"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverFlag4),expression:"hoverFlag4"}],staticClass:"ml-2",attrs:{"src":"https://get-data-upload.s3.ap-northeast-2.amazonaws.com/1073/intergration/img/arrow-right_hover.svg"}})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-sub-text"},[_vm._v(" 웹 크롤링 수집을 통한"),_c('br'),_vm._v("Buzz분석 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-sub-text"},[_vm._v(" 모바일 행동 데이터"),_c('br'),_vm._v("수집 및 분석 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-sub-text"},[_vm._v(" 상권 현황 및 평가 결제 데이터"),_c('br'),_vm._v("수집 및 분석 ")])
}]

export { render, staticRenderFns }