
import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import { FileInfoProp } from '../../../../interfaces/notice.interface';
import { qnaPermission } from '@/common';
@Component({})
export default class QnaAnswer extends Vue {
  private writer = '';
  private type = '';
  private title = '';
  private date = '';
  private content = '';
  private answer = '';
  private files: FileInfoProp[] = [];

  get isPermission() {
    return qnaPermission.includes(this.$store.getters.isGrade);
  }

  mounted() {
    this.getContent();
  }

  async getContent() {
    try {
      if (this.isPermission) {
        const { data } = await this.axios.get(`qna/${this.$route.params.seq}`, {
          params: { token: this.$store.getters.isToken },
        });

        if (data.result) {
          this.writer = data.user;
          this.type = data.type;
          this.date = data.date;
          this.title = data.title;
          this.content = data.content;
          this.answer = data.answer;
          this.files = data.files;
        } else {
          await this.showAlert(data.msg);
          this.$router.go(-1);
        }
      } else {
        await this.showAlert('권한이 없습니다.');
        this.$router.go(-1);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async register() {
    if (
      await this.$bvModal.msgBoxConfirm('답변을 등록하시겠습니까?', {
        title: '답변 등록',
        cancelTitle: '취소',
        okTitle: '확인',
        okVariant: 'dark',
        cancelVariant: 'outline-success',
        centered: true,
        footerClass: 'justify-content-center',
        bodyClass: 'pt-5 pb-5 text-center',
      })
    ) {
      try {
        const payload = {
          answerContent: this.answer,
          answerStatus: true,
          answerFiles: [],
        };
        const res = await this.axios.post(
          `/qna/answer/${this.$route.params.seq}`,
          payload,
        );
        if (res.data.result) {
          await this.showAlert(res.data.message);
          this.$router.go(-1);
        } else {
          await this.showAlert(res.data.message);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  async showAlert(msg: string) {
    return await this.$bvModal.msgBoxOk(msg, {
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'success',
      okTitle: '확인',
      headerClass: 'p-2 border-bottom-0',
      bodyClass: 'pt-4',
      footerClass: 'p-2 border-top-0',
      centered: true,
    });
  }
}
