
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DataWorkshop } from '@/interface/data-workshop';

@Component({})
export default class ProjectCard extends Vue {
  @Prop() dataList!: DataWorkshop.IJoinProject & { optionFlag: boolean };
  //private optionFlag = false;
  private deleteMsg = '';

  goto(projectId: string) {
    this.$router.push(`/dashboard/${projectId}`);
  }

  modifyData() {
    this.dataList.optionFlag = false;
    this.$emit('modifyData', { data: this.dataList });
  }

  async deleteData() {
    this.dataList.optionFlag = false;
    this.$emit('deleteData', { data: this.dataList });
  }

  moreClick() {
    const changeValue = !this.dataList.optionFlag;
    this.dataList.optionFlag = changeValue;
    this.$emit('moreClick', { changeValue, targetId: this.dataList._id });
  }
}
