
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataWorkshop } from '@/interface/data-workshop';
import noticeModal from '@/components/common/notice-popup.vue';

@Component({
  components: { noticeModal },
})
export default class ChartSelection extends Vue {
  @Prop() chartList!: DataWorkshop.IChartType[];
  @Prop() toggle!: boolean;

  go(path: string | undefined) {
    if (path && this.$route.path !== path) {
      /*const type = path.split('/').pop();
      if (type === 'scaling') this.$bvModal.show('alert');else*/
      this.$router.push(path);
    }
  }
}
