
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VariableTable from '@/components/data-workshop/data-visualize/variable-table.vue';
import Chart from '@/components/data-workshop/data-visualize/chart.vue';
import { ChartData } from 'chart.js';
import { DataWorkshop } from '@/interface/data-workshop';
import CustomInput from '@/components/common/custom-input.vue';
import CustomInputBox, {
  ICustomInputBoxItem,
} from '@/components/data-set/detail/custom-input-box.vue';
import SaveModalTemplate from '@/components/data-workshop/data-visualize/save-modal-template.vue';
import CustomModal from '@/components/common/custom-modal.vue';
import NoticeModal from '@/components/common/notice-popup.vue';
import Tools from '@/components/data-workshop/data-visualize/tools.vue';
import IHeader = DataWorkshop.IHeader;
import CommonSelectBox from '@/components/common/custom-select-box.vue';
import * as dfd from 'danfojs';
import { CHART_COLORS_A } from '@/common/visualize';
import { BModal } from 'bootstrap-vue';

const enum SCALING_TYPE {
  MIN_MAX_SCALER = 'MinMaxScaler',
  STANDARD_SCALER = 'StandardScaler',
  LOG_1P = 'log1p',
  EXPM1 = 'expm1',
}

@Component({
  components: {
    CommonSelectBox,
    Tools,
    CustomModal,
    NoticeModal,
    SaveModalTemplate,
    CustomInputBox,
    CustomInput,
    VariableTable,
    Chart,
  },
})
export default class ScalingHistogram extends Vue {
  @Prop() joinProject!: DataWorkshop.IJoinProject;
  @Prop() variablesTablePending!: boolean;

  $refs!: {
    chartRef: any;
    'save-scaling-modal': BModal;
  };

  // 차트 마다 다를 수 있는 데이터 상태값
  private checkBoxInfo = [
    { name: '', type: 'n', validTypeList: ['integer', 'numeric'] },
  ];
  // 차트 마다 공통된 데이터 상태값
  private selectedVariables: { [key: string]: string }[] = [];
  private chartOptions: any = null;
  private chartData: (ChartData & { chartDivided?: number[][] }) | null = null;
  private isSaveSuccess = false;
  private chartPending = false; // 차트 부분만
  private chartContainerShow = false; // 차트 밖 (타이틀 및 인풋, 저장하기 버튼)
  private savePending = false;
  private isDrawn = false;
  private chartInput: ICustomInputBoxItem[] = [
    {
      key: 'chartTitle',
      title: '차트 제목',
      value: this.joinProject.chartTitle || '',
      placeholder: '차트 제목을 입력해 주세요',
    },
    {
      key: 'keyword',
      title: '주요 키워드',
      value: this.joinProject.keyword || '',
      placeholder: '주요 키워드를 입력해 주세요',
    },
    {
      key: 'description',
      title: '차트 설명',
      value: this.joinProject.description || '',
      placeholder: '차트에 대한 설명을 입력해 주세요',
    },
  ];
  private clickType = '';
  private modalContent = '';
  private colorList: string[] = [];
  private histogramDivisionNumber = 10;
  private scalingSelected: string = '';
  private selectOption: {}[] = [
    {
      value: SCALING_TYPE.MIN_MAX_SCALER,
      text: '최소-최대 변환',
      active: false,
    },
    {
      value: SCALING_TYPE.STANDARD_SCALER,
      text: 'Z 변환',
      active: false,
    },
    {
      value: SCALING_TYPE.LOG_1P,
      text: '로그 변환',
      active: false,
    },
    {
      value: SCALING_TYPE.EXPM1,
      text: '지수 변환',
      active: false,
    },
  ];
  private chartHeight: number = 420;
  private xyChange: boolean = false;
  private chartDivided: number[][] = [];
  private scalingData: { [key: string]: string | number | null }[] = [];
  private delValues: number[] = [-Infinity, Infinity, NaN];
  private scalingVariableName: string = '';

  get chartType() {
    return this.$route.name || '';
  }

  get projectId() {
    return this.$route.params._id;
  }

  @Watch('joinProject')
  watchJoinProject() {
    this.chartInput = [
      {
        key: 'chartTitle',
        title: '차트 제목',
        value: this.joinProject.chartTitle || '',
        placeholder: '차트 제목을 입력해 주세요',
      },
      {
        key: 'keyword',
        title: '주요 키워드',
        value: this.joinProject.keyword || '',
        placeholder: '주요 키워드를 입력해 주세요',
      },
      {
        key: 'description',
        title: '차트 설명',
        value: this.joinProject.description || '',
        placeholder: '차트에 대한 설명을 입력해 주세요',
      },
    ];
  }

  chartOptionInit() {
    this.chartHeight = 420;
    this.chartOptions = {
      categoryPercentage: 1,
      barPercentage: 1,
      borderWidth: 1,
      borderColor: '#fff',
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: '',
        },
      },
    };
  }

  handleChangeColor(idx: number, color: string) {
    this.$nextTick(() => {
      this.$refs.chartRef.changeColor(0, color, this.$route.name);
    });
  }

  // 체크값 하나만 허용 (차트마다 다름)
  checkValidationOfVariable(
    selectedVariables: { [key: string]: string }[],
    type = 'change',
  ) {
    if (selectedVariables.length > 1) {
      selectedVariables.shift();
    } else if (selectedVariables.length === 0 && type === 'submit') {
      this.showModal({ message: '변수를 선택해 주세요.', type: 'Alert' });
      return false;
    }
    return true;
  }

  // 차트마다 결과 처리가 다를 수 있음
  async loadChartData(selectedVariables: { [key: string]: string }[]) {
    if (!this.checkValidationOfVariable(selectedVariables, 'submit')) {
      return;
    }

    if (this.scalingSelected === '') {
      this.showModal({ message: '스케일링을 선택해 주세요.', type: 'Alert' });
      return false;
    }

    this.chartContainerShow = true;
    this.chartPending = true;
    this.chartData = null;

    if (Number(this.histogramDivisionNumber) < 2) {
      alert('히스토그램 계급 구간 수는 최소 2 입니다.');
      this.histogramDivisionNumber = 2;
    } else if (Number(this.histogramDivisionNumber) > 20) {
      alert('히스토그램 계급 구간 수는 최대 20 입니다.');
      this.histogramDivisionNumber = 20;
    }

    try {
      const {
        data,
      }: {
        data: {
          result: boolean;
          formatted: any;
          extra?: IHeader[];
        };
      } = await this.axios({
        method: 'GET',
        url: `data-visualize/${this.projectId}/${this.chartType}`,
        params: {
          variables: selectedVariables,
          options: { histogramDivisionNumber: this.histogramDivisionNumber },
        },
      });
      let resultData: number[] = [],
        targetFixed = 2;
      const { result, formatted: resFormatted } = data;
      if (result) {
        const { selectedVariable } = resFormatted;
        const label = selectedVariable[0].name || '';

        this.scalingData = resFormatted.data;
        const name = selectedVariables[0].n;
        let setData: any = this.scalingData.map(m => m[name]);

        if (this.scalingSelected === SCALING_TYPE.MIN_MAX_SCALER) {
          setData = this.scalingData.map(m => [m[name]]);
          resultData = this.setMinMaxScaler(setData);
        } else if (this.scalingSelected === SCALING_TYPE.STANDARD_SCALER) {
          resultData = this.setStandardScaler(setData);
        } else if (this.scalingSelected === SCALING_TYPE.LOG_1P) {
          resultData = this.setLog1pScaler(setData);
        } else if (this.scalingSelected === SCALING_TYPE.EXPM1) {
          resultData = this.setExpm1Scaler(setData);
        }

        this.scalingData = this.scalingData.map((m, index) => {
          const value = resultData[index];
          return {
            _id: m._id,
            [`${name}`]: this.delValues.includes(value) ? null : value,
          };
        });
        resultData = resultData.filter(f => !this.delValues.includes(f));
        if (resultData.length) {
          const formatted = this.setChartData({
            data: resultData,
            targetFixed,
            label,
          });
          this.chartData = formatted;
          this.isDrawn = true;
          this.chartOptionInit();

          if (
            formatted &&
            formatted.datasets &&
            formatted.datasets.length > 0
          ) {
            if (formatted.datasets[0].backgroundColor) {
              this.colorList = [formatted.datasets[0].backgroundColor];
            }

            if (formatted.datasets[0].label) {
              this.chartOptions = {
                ...this.chartOptions,
                plugins: {
                  ...this.chartOptions.plugins,
                  title: {
                    display: true,
                    text: formatted.datasets[0].label || '',
                  },
                },
              };
            }

            if (formatted.datasets[0].data.length === 1) {
              this.chartOptions = {
                ...this.chartOptions,
                barPercentage: 0.1,
                scales: {
                  ...this.chartOptions.scales,
                  x: {
                    ticks: {
                      color: 'rgba(0, 0, 0, 0.8)',
                    },
                  },
                },
              };
            } else {
              this.chartOptions = {
                ...this.chartOptions,
                barPercentage: 1,
                scales: {
                  ...this.chartOptions.scales,
                  x: {
                    ticks: {
                      color: 'transparent',
                    },
                    grid: {
                      color: 'transparent',
                    },
                  },
                },
              };
            }

            if (this.xyChange) {
              delete this.chartOptions.scales.x.ticks;
              this.chartOptions.indexAxis = 'y';
              this.chartHeight = this.histogramDivisionNumber > 13 ? 560 : 420;

              if (formatted.datasets[0].data.length > 1) {
                this.chartOptions.scales.y = {
                  ticks: {
                    color: 'transparent',
                  },
                  reverse: true,
                };
              }
            }

            this.chartOptions.plugins.tooltip = {
              callbacks: {
                title: (context: any) => {
                  if (context.length < 1) return;
                  if (context[0].dataset.data.length > 1) {
                    const index = context[0].dataIndex;
                    const text = this.chartDivided[index];
                    return `${text[0]} ~ ${text[1]}`;
                  }
                  return context[0].label;
                },
              },
            };
          }
        } else {
          this.showModal({
            message: '선택한 변수의 데이터가 없습니다.',
            type: 'Alert',
          });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.chartPending = false;
    }
  }

  showModal({ message, type }: { message: string; type: string }) {
    this.modalContent = message;
    this.clickType = type;
    this.$bvModal.show('missing-rate');
  }

  showSaveModal() {
    this.$bvModal.show('save-modal');
    this.isSaveSuccess = false;
  }

  findKey(
    arr: ({ key: string; value: string } | ICustomInputBoxItem)[],
    key: string,
  ) {
    const cur = arr.find(v => v.key === key);
    if (cur) return cur;
    else return { value: '' };
  }

  async saveChart() {
    try {
      if (!this.joinProject.title.trim()) {
        return this.showModal({
          message: '데이터 타이틀을 입력해주세요.',
          type: 'Alert',
        });
      }

      this.savePending = true;

      if (this.chartData) {
        this.chartData['chartDivided'] = this.chartDivided;
      }
      const { data } = await this.axios({
        method: 'PATCH',
        url: `data-visualize/${this.projectId}/${this.chartType}`,
        data: {
          title: this.joinProject.title,
          chartTitle: this.findKey(this.chartInput, 'chartTitle').value,
          keyword: this.findKey(this.chartInput, 'keyword').value,
          description: this.findKey(this.chartInput, 'description').value,
          chartOptions: this.chartOptions,
          chartData: this.chartData,
        },
      });
      const { result } = data;
      if (result) {
        this.isSaveSuccess = true;
        this.savePending = false;
        this.$emit('loadJoinProject');
        this.$bvModal.hide('save-modal');
        this.showModal({
          message: '마이 데이터 랩에 저장되었습니다.',
          type: 'Alert',
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  //최소-최대 변환
  setMinMaxScaler(data: any) {
    const scaler = new dfd.MinMaxScaler();
    scaler.fit(data);
    const PPT_MM = scaler.transform(data);
    return PPT_MM.map((row: any) => +row[0]);
  }
  //Z 변환
  setStandardScaler(setData: any) {
    const scaler = new dfd.StandardScaler();
    let sf = new dfd.Series(setData);
    scaler.fit(sf);
    const sf_enc = scaler.transform(sf);
    return sf_enc.getColumnData;
  }
  //로그 변환
  setLog1pScaler(setData: any) {
    return setData.map((m: number) => Math.log1p(m));
  }
  //지수 반환
  setExpm1Scaler(setData: any) {
    return setData.map((m: number) =>
      Math.round(
        Number(
          Math.expm1(m)
            .toString()
            .replace(/(\d\.\d+?)e[+\-]\d+/, '$1'),
        ),
      ),
    );
  }

  setChartData({
    data,
    targetFixed,
    label,
  }: {
    data: number[];
    targetFixed?: number;
    label: string;
  }) {
    const numberOfDivided = +this.histogramDivisionNumber || 10;
    const min = Math.min(...data);
    const max = Math.max(...data);
    const widthOfDivided = (max - min) / numberOfDivided;
    const divided: any = [];
    const fixed = targetFixed || 1;
    let prev = +min.toFixed(fixed);

    // min = max 즉, 유일값이 하나라면 그냥 막대 하나만 보여주기
    if (min === max) {
      return {
        labels: [Number(min.toFixed(fixed))],
        datasets: [
          {
            backgroundColor: CHART_COLORS_A[0],
            label,
            data: [data.length],
          },
        ],
      };
    }

    for (let i = 1; i <= numberOfDivided; i++) {
      const next = Number((min + widthOfDivided * i).toFixed(fixed));
      divided.push([prev, next]);
      prev = next;
    }
    this.chartDivided = divided;
    const dataOfDatasets = Array(numberOfDivided).fill(0);
    const labels = [
      ...divided.map((v: any) => Number(v[0].toFixed(fixed))),
      divided[divided.length - 1][1],
    ];
    data.forEach(v => {
      for (let i = 0; i < numberOfDivided; i++) {
        if (divided[i][0] <= v && v < divided[i][1]) {
          dataOfDatasets[i]++;
          break;
        }
        if (i === numberOfDivided - 1 && v === divided[i][1]) {
          dataOfDatasets[i]++;
        }
      }
    });

    const formatted = {
      labels,
      datasets: [
        {
          axis: 'y',
          backgroundColor: CHART_COLORS_A[0],
          label,
          data: [...dataOfDatasets, 0],
        },
      ],
    };
    return formatted;
  }

  async saveScalingData() {
    if (!this.scalingVariableName.trim()) {
      return this.showModal({
        message: '변수 이름을 입력해주세요.',
        type: 'Alert',
      });
    }

    this.savePending = true;
    try {
      const { data } = await this.axios({
        method: 'PATCH',
        url: `data-visualize/${this.chartType}/add-data/${this.projectId}`,
        data: {
          variableName: this.scalingVariableName,
          scalingData: this.scalingData,
          originKey: this.selectedVariables[0].n,
        },
      });
      const { result } = data;
      if (result) {
        this.$emit('loadJoinProject');
        this.$bvModal.hide('save-scaling-modal');
        this.showModal({
          message: `${this.scalingVariableName} 변수로 스케일링한 데이터를 저장하였습니다.`,
          type: 'Alert',
        });
      }
    } catch (e) {
      console.error(e);
    }
    this.savePending = false;
  }

  scalingModalTrigger() {
    this.$bvModal.show('save-scaling-modal');
    this.scalingVariableName = '';
    this.isSaveSuccess = false;
  }
}
