
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class PlatformConfiguration extends Vue {
  @Prop() configTypes?: {
    title?: string;
    sub?: {
      subtitle?: string;
      subTxt?: string;
    }[];
  }[];
}
