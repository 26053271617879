<template>
  <div class="container">
    <div
      class="text-title"
      style="
        color: black;
        font-size: 28px;
        margin-top: 50px;
        margin-bottom: 90px;
      "
    >
      개인정보 수집 및 이용동의
    </div>
    <div class="text-content">
      1. 수집하는 개인정보 항목<br />
      통합 데이터 허브 플랫폼의 이용자 참여와 이용통계 분석 등의 서비스를 위해
      회원 가입 시 아래의 개인정보를 수집하고 있습니다.<br />
      <br />
      가. 필수정보: 아이디(ID), 비밀번호(P/W), 휴대폰, 기관명(기업회원)<br />
      나. 서비스 이용 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수
      있습니다.<br />
      (접속로그, 접속IP정보, 쿠키, 방문 일시, 서비스 이용기록, 불량 이용
      기록)<br />
      <br />
      2. 통합 데이터 허브 플랫폼에서 이용자 회원가입 시 직접 개인정보를 입력 및
      수정하여 개인정보를 수집합니다.<br />
      <br />
      3. 수집 개인정보의 이용목적<br />
      가. 회원가입, 회원활동 내역 관리, 회원탈퇴 의사 확인 등 회원관리<br />
      나. 통합 데이터 허브 플랫폼에서 제공되는 서비스 이용 기록 분석을 통한
      관심사에 기반한 맞춤형 서비스 등 제공<br />
      다. 신규 서비스 개발 및 활성화, 홍보 및 이벤트 등 정보 전달<br />
      라. 향후 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리,
      고지사항 전달 등<br />
      <br />
      4. 개인정보의 보유 및 이용기간<br />
      이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체
      없이 파기합니다.<br />
      따라서 통합 데이터 허브 플랫폼은 최종 로그인 후 1년이 경과하였거나
      정보주체의 회원 탈퇴 신청 시 회원의 개인정보를 지체 없이 파기합니다.<br />
      <br />
      5. 동의 거부 권리 사실 및 불이익 내용<br />
      이용자는 동의를 거부할 권리가 있습니다. 동의를 거부할 경우에는 서비스
      가입에 제한됨을 알려드립니다.<br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'service',
};
</script>

<style scoped>
span {
  font-size: 12px;
}
.container {
  max-width: 1140px;
  padding-bottom: 20px;
}
.text-title {
  font-family: 'Pretendard-Bold';
  font-size: 19px;
  margin-bottom: 20px;
  color: #445cb2;
}
.text-sub-title {
  font-family: 'Pretendard-SemiBold';
  font-size: 17px;
  margin-bottom: 10px;
}
.text-content {
  font-family: 'Pretendard-Regular';
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 20px;
}
</style>
