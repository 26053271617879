
import { Component, Vue } from 'vue-property-decorator';
import FloatingCard from '@/components/my-data-hub/floating-card.vue';

@Component({ components: { FloatingCard } })
export default class MyDataHub extends Vue {
  private navList = [
    { path: '/my-data-hub/my-home', text: '마이 홈' },
    { path: '/my-data-hub/bookmark', text: '북마크 데이터' },
  ];
}
