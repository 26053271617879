
import { Component, Mixins } from 'vue-property-decorator';
import { HOST_LINK } from '@/enum/common';
import mixin from '@/utils/common';

@Component({})
export default class Login extends Mixins(mixin) {
  userId: string;
  userPwd: string;
  userIdValid: boolean = true;
  userPwdValid: boolean = true;
  userIdValidMessage: string = '';
  userPwdValidMessage: string = '';
  idSave: boolean = false;
  private loading: boolean = true;
  url: string | undefined = undefined;

  constructor() {
    super();
    this.userId = '';
    this.userPwd = '';
  }

  async created() {
    const { isToken } = this.$store.getters;
    const { url } = this.$route.query;
    this.url = url as string;

    if (isToken !== null) {
      try {
        const { result } = await this.$store.dispatch('verify');
        if (result) {
          if (this.url) {
            const url = await this.$store.dispatch('replaceUrl', this.url);
            await this.$router.push(url);
          } else {
            await this.$router.push('/main');
          }
        } else {
          await this.$store.dispatch('logout');
          await new Promise(resolve => setTimeout(resolve, 3e2));
        }
      } catch (e) {
        console.error(e);
      }
    }

    const cookieEmail = this.$cookies.get('emailCookie');
    const cookieIdSave = this.$cookies.get('saveCookie');
    if (cookieEmail) this.userId = cookieEmail;
    if (cookieIdSave) this.idSave = true;
    this.loading = false;
  }
  /*mounted() {
    window.Kakao.init('01cbd258b0023cad6265b1792b2c55f8');
  }*/

  idCheck() {
    if (this.userId === '') {
      this.userIdValid = false;
      this.userIdValidMessage = '아이디를 입력하세요.';
    }
  }

  pwdCheck() {
    if (this.userPwd === '') {
      this.userPwdValid = false;
      this.userPwdValidMessage = '비밀번호를 입력하세요.';
    }
  }
  async login() {
    if (this.userId === '') {
      this.userIdValid = false;
      this.userIdValidMessage = '아이디를 입력하세요.';
    }
    if (this.userPwd === '') {
      this.userPwdValid = false;
      this.userPwdValidMessage = '비밀번호를 입력하세요.';
    }
    if (!this.userIdValid || !this.userPwdValid) return;

    try {
      const payload: {
        userId: string;
        userPwd: string;
        redirectUrl?: string;
        redirectPage?: string;
        hostExists?: boolean;
        idSave: boolean;
      } = {
        userId: this.userId,
        userPwd: this.userPwd,
        idSave: this.idSave,
      };
      if (this.url) payload.redirectUrl = this.url;

      const { redirectPage, hostName } = this.$route.query;
      if (redirectPage) payload.redirectPage = redirectPage as string;
      if (hostName) payload.hostExists = true;

      const { result } = await this.$store.dispatch('login', payload);
      // HOST_LINK 포함된 링크로 이동 시
      if (result) {
        await this.redirectUrl({ HOST_NAME: hostName as HOST_LINK });
      }
    } catch (e) {
      console.error(e);
    }
  }
  idFind() {
    this.$router.push('/help/find_id');
  }
  pwFind() {
    this.$router.push('/help/find_pw');
  }
  ready() {
    alert('준비중입니다.');
  }
  //TODO: 카카오 로그인
  /*kakaoLogin() {
    console.log('kakao');
    const { Kakao } = window;

    Kakao.Auth.login({
      success: function () {
        Kakao.API.request({
          url: '/v2/user/me',
          success: (response: any) => {
            console.log(response);
          },
          fail: (error: any) => {
            console.log(error);
          },
        });
      },
      fail: (error: any) => {
        console.log(error);
      },
    });
  }

  kakaoLogout() {
    if (window.Kakao.Auth.getAccessToken()) {
      window.Kakao.API.request({
        url: '/v1/user/unlink',
        success: function (response: any) {
          console.log(response);
        },
        fail: function (error: any) {
          console.log(error);
        },
      });
      window.Kakao.Auth.setAccessToken(undefined);
    }
  }*/
}
