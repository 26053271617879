
import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment/moment';

@Component({})
export default class NoticeCard extends Vue {
  @Prop() title!: 'Notice' | 'FAQ';
  @Prop() sub!: string;
  private contentItems: Array<{
    label: string;
    date: string;
    seq: number;
    type: 'Notice' | 'FAQ';
  }> = [];

  mounted() {
    if (this.title === 'Notice') {
      this.getNoticeData();
    } else if (this.title === 'FAQ') {
      this.getFAQData();
    }
  }

  linkTo(type: 'Notice' | 'FAQ', seq?: number) {
    if (type === 'Notice') {
      if (seq) {
        this.$router.push(
          `/notice/postView/${seq}?page=1&search=&category=0&type=0&dateType=0&fix=Y`,
        );
      } else {
        this.$router.push(
          `/notice/?page=1&search=&category=0&type=0&dateType=0`,
        );
      }
    } else {
      if (seq) {
        this.$router.push(`/faq?seq=${seq}`);
      } else {
        this.$router.push(`/faq`);
      }
    }
  }

  async getNoticeData() {
    try {
      const sendData = {};
      const result = await this.axios.post(`/notice/list/1`, sendData);
      if (result) {
        const { list, topList } = result.data;
        const totalList: [] = topList.concat(list);
        const sliceData = totalList.slice(0, 4);
        const returnData: Array<{
          label: string;
          date: string;
          seq: number;
          type: 'Notice' | 'FAQ';
        }> = sliceData.map(
          (item: { title: string; writeDate: string; seq: number }) => {
            return {
              label: item.title,
              date: item.writeDate,
              seq: item.seq,
              type: 'Notice',
            };
          },
        );
        this.contentItems = returnData;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getFAQData() {
    try {
      const sendData = { page: 1, perPage: 4, search: '', type: '전체' };
      const result = await this.axios.get(`/faq`, { params: sendData });
      if (result) {
        const { list } = result.data;
        const returnData: Array<{
          label: string;
          date: string;
          seq: number;
          type: 'Notice' | 'FAQ';
        }> = list.map(
          (item: { content: string; regDate: string; seq: number }) => {
            return {
              label: item.content,
              date: moment(item.regDate).format('YYYY.MM.DD').toString(),
              seq: item.seq,
              type: 'FAQ',
            };
          },
        );
        this.contentItems = returnData;
      }
    } catch (e) {
      console.log(e);
    }
  }
}
