
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class CustomizedDataServices extends Vue {
  @Prop() dataTypes?: {
    icon?: string;
    cardTitle?: string;
    cardSubtitle1?: string;
    cardSubtitle2?: string;
  }[];
}
