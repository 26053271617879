
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class CommonModal extends Vue {
  @Prop() private id!: string;
  @Prop() private content!: string; // 본문
  @Prop() private desc!: string | null; // 추가 설명문
  @Prop({ default: 'md' }) private size!: string; // ex) md lg
  @Prop() private icon!: { type: string; name: string; imgUrl?: string }; // 아이콘이 이미지가 아니고 div 로 생성할 경우
  @Prop() private btn!: { text: string; color: string; click: () => void }[];
  private iconCreateNames: string[] = ['file-dock-add'];

  close() {
    this.$bvModal.hide(this.id);
  }
}
