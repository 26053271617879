
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import BasicMenu from './components/BasicMenu.vue';

export interface IBasicMenu {
  title: string;
  url?: string;
  options?: {
    anchor?: boolean;
    tab?: boolean;
  };
}

export interface IMainMenu {
  title: string;
  url?: string;
  detailMenu?: Array<IBasicMenu>;
  options?: {
    anchor?: boolean;
    tab?: boolean;
  };
}

@Component({
  components: { BasicMenu },
})
export default class Nav extends Vue {
  @Prop() mainTitle?: IBasicMenu;
  @Prop() mainMenu?: IMainMenu[];
  @Prop() subMenu?: IBasicMenu[];
  @Prop() imgSrc?: NodeRequire;
  @Prop() isLogin?: boolean;

  @Emit('clickMenu')
  private clickMenu(title: string) {
    return title;
  }
  @Emit('logout')
  private logout() {
    return;
  }

  private hoverCheck = false;
  private detailBgHeight = 0;
  private hoveredMenu = '';
  private scrollTop = 0;

  public hover(check: boolean) {
    if (!check) this.hoveredMenu = '';
    this.hoverCheck = check;
  }

  private setHoveredMenu(title: string) {
    this.hoveredMenu = title;
  }

  private getListWidth() {
    if (this.mainMenu) return { width: `${620 / this.mainMenu.length}px` };
  }

  private getMenu(menu: IBasicMenu[] | IMainMenu[]) {
    if (this.isLogin) {
      const currentMenu = menu.filter(item => item.title !== '회원가입');
      const loginMenu = currentMenu.find(item => item.title === '로그인');
      if (loginMenu) loginMenu.title = '로그아웃';
      return currentMenu;
    } else if (!this.isLogin) {
      const currentMenu = menu.filter(item => item.title !== '마이페이지');
      const loginMenu = currentMenu.find(item => item.title === '로그아웃');
      if (loginMenu) loginMenu.title = '로그인';
      return currentMenu;
    }
    return menu;
  }

  mounted() {
    const ul = this.$refs.listUl as HTMLUListElement[];
    if (ul && Array.isArray(ul)) {
      ul.forEach(li => {
        if (this.detailBgHeight < li.clientHeight) this.detailBgHeight = 160;
      });
    }
  }
}
