
import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import 'vue-select/dist/vue-select.css';
import customInput from '@/components/common/custom-input.vue';
import SubMenu from '@/components/user/SubMenu.vue';
import noticeModal from '@/components/common/notice-popup.vue';
import {popupSetInterface} from "../../../interfaces/notice.interface";

@Component({
  components: { SubMenu, customInput, noticeModal },
})
export default class changePwd extends Vue {
  $refs!: {
    userId: HTMLFormElement;
    currentPassword: HTMLFormElement;
    changePassword: HTMLFormElement;
    changePasswordCheck: HTMLFormElement;
  };

  private userId: string = this.$store.state.userId;
  private currentPassword: string = '';
  private changePassword: string = '';
  private changePasswordCheck: string = '';
  private passwordValidFlag: boolean | null = null;
  private changePasswordValidFlag: boolean | null = null;
  private changePasswordCheckValidFlag: boolean | null = null;
  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface["type"] = 'BACK';
  private strGoOut: string = '';

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }
  showAlert(
    msg: string,
    type: 'BACK' | 'GO' | 'NO' | 'GoOut' | 'Alert' | 'WITHDRAW',
  ) {
    this.modalSetting({
      title: '',
      content: msg,
      type: type,
    });
    this.$bvModal.show('changePwdPopup');
  }

  private get isLogin() {
    return this.$store.getters.isLogin;
  }

  async mounted() {
    if (!this.isLogin) {
      this.strGoOut = '/login';
      this.showAlert('로그인 후 이용이 가능합니다.', 'WITHDRAW');
      return false;
    }
  }

  goOut() {
    this.$router.push(this.strGoOut);
    this.$store.dispatch('logout');
  }

  pwdValid() {
    if (this.currentPassword === '') {
      this.passwordValidFlag = null;
      return;
    }
    this.passwordValidFlag = this.currentPassword.length > 0;
  }

  changePwdReset() {
    if (this.changePassword === '') {
      this.changePasswordValidFlag = null;
      if (this.changePasswordCheck !== '') {
        this.changePasswordCheckValidFlag = false;
      }
    }
  }
  changePwdChkReset() {
    if (this.changePasswordCheck === '') {
      this.changePasswordCheckValidFlag = null;
    }
  }

  changePasswordValid() {
    if (this.changePassword === '') {
      this.changePasswordValidFlag = null;
      return;
    }
    const regex =
      /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/;
    this.changePasswordValidFlag = regex.test(this.changePassword);
    if (this.changePasswordCheckValidFlag !== null)
      this.changePasswordCheckValid();
  }

  changePasswordCheckValid() {
    if (this.changePasswordCheck === '') {
      this.changePasswordCheckValidFlag = null;
      return;
    }

    this.changePasswordCheckValidFlag =
      this.changePassword === this.changePasswordCheck;
  }

  get formSubmit() {
    return this.changePassword === this.changePasswordCheck;
  }

  async formValidator() {
    if (!this.formSubmit) {
      this.showAlert('비밀번호가 일치하지않습니다.', 'Alert');
    }

    try {
      const data = await this.axios({
        url: '/user/changePwd',
        method: 'patch',
        data: {
          userId: this.userId,
          currentPassword: this.currentPassword,
          changePassword: this.changePassword,
          changePasswordCheck: this.changePasswordCheck,
        },
      });
      const result = data;
      if (result.data.result === false) {
        if (result.data.case === 2 || result.data.case === 1) {
          this.passwordValidFlag = false;
          this.showAlert(result.data.message, 'Alert');
        } else if (result.data.case === 3 || result.data.case === 4) {
          this.passwordValidFlag = true;
          this.showAlert(result.data.message, 'Alert');
        }
      } else {
        this.passwordValidFlag = true;
        this.strGoOut = '/login';
        this.showAlert('비밀번호 변경이 완료되었습니다.', 'WITHDRAW');
      }
    } catch (err) {
      console.error(err);
    }
  }
}
