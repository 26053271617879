import { render, staticRenderFns } from "./postView.vue?vue&type=template&id=08b0550b&scoped=true&"
import script from "./postView.vue?vue&type=script&lang=ts&"
export * from "./postView.vue?vue&type=script&lang=ts&"
import style0 from "./postView.vue?vue&type=style&index=0&id=08b0550b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b0550b",
  null
  
)

export default component.exports