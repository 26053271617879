
import { Vue, Component } from 'vue-property-decorator';
import noticeModal from '@/components/common/notice-popup.vue';
import {popupSetInterface} from "../../../interfaces/notice.interface";
@Component({ components: { noticeModal } })
export default class signSuccess extends Vue {
  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface["type"] = 'BACK';
  private strGoOut: string = '';
  async showAlert(
    msg: string,
    type: 'BACK' | 'GO' | 'NO' | 'GoOut' | 'Alert' | 'WITHDRAW' | 'ACCESS',
  ) {
    this.modalSetting({
      title: '',
      content: msg,
      type: type,
    });
    await this.$bvModal.show('myPagePopup');
  }
  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }

  private get isLogin() {
    return this.$store.getters.isLogin;
  }

  goOut() {
    this.$router.push(this.strGoOut);
  }

  async mounted() {
    if (this.isLogin) {
      this.strGoOut = '/';
      this.showAlert('비 정상적인 접근입니다.', 'ACCESS');
      return false;
    } else {
    }
  }
}
