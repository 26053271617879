
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Apps extends Vue {
  apps = [];
  created() {
    this.load();
  }

  async load() {
    try {
      const { data } = await this.axios({
        url: '/apps',
      });
      const { result, apps } = data;
      if (result) {
        this.apps = apps;
      }
    } catch (e) {
      console.error(e);
    }
  }
}
