
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { HOST_LINK } from '@/enum/common';
import mixin from '@/utils/common';

@Component({})
export default class SurveyPlatform extends Mixins(mixin) {
  @Prop() toggleSurveyPlatform?: boolean;
  private HOST_LINK = HOST_LINK;
  private hoverFlag1 = false;
  private hoverFlag2 = false;
  private hoverFlag3 = false;
  private hoverFlag4 = false;

  async checkRedirect({
    HOST_NAME,
    isNotLogin,
  }: {
    HOST_NAME: HOST_LINK;
    isNotLogin?: boolean;
  }) {
    const isLogin = this.$store.getters.isLogin;
    if (!isLogin) {
      this.$emit('showAlert', [
        '로그인 후 이용이 가능합니다.',
        'WITHDRAW',
        '/login',
      ]);
      return false;
    }
    await this.redirectUrl({ HOST_NAME: HOST_NAME, isNotLogin });
  }

  hoverEvent(num: number, state: boolean) {
    if (num === 1) {
      this.hoverFlag1 = state;
    }
    if (num === 2) {
      this.hoverFlag2 = state;
    }
    if (num === 3) {
      this.hoverFlag3 = state;
    }
    if (num === 4) {
      this.hoverFlag4 = state;
    }
  }
}
