import { ins as api } from '@/utils/axios';
import store from '@/store';

export const getProject = async (projectId: string) => {
  try {
    const { data } = await api({
      method: 'GET',
      url: `data-process/project/${projectId}`,
    });
    const { result, project } = data;
    if (result) {
      return project;
    } else {
      return null;
    }
  } catch (e) {
    console.error(e);
  }
};

export const getProjectOwnerId = async (projectId: string) => {
  const project = await getProject(projectId);
  return project.userId;
};

export const getUserId = () => {
  return store.getters.getUserObjectId;
};

export const isSameUserAndProject = (userId: string, projectId: string) => {
  return userId === projectId;
};