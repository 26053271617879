var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"user-upload-content"},[_c('div',{staticClass:"rectangle-80"},[_c('div',{staticClass:"uploadContent"},[_c('h1',{staticClass:"text-1"},[_vm._v("데이터 파일 업로드")]),_c('p',{staticClass:"text-2"},[_vm._v("사용자 데이터를 활용한 시각화 서비스 이용하기")]),_c('div',{staticClass:"file-section"},[_c('div',[_c('div',[_vm._m(0),_c('div',{key:_vm.fileInputKey,staticClass:"upload"},[_c('div',{ref:"upload",staticClass:"overlap-group1",class:{ dragged: _vm.isDragged },on:{"click":_vm.setUploadBtn,"drop":_vm.onDrop,"dragover":_vm.onDragover,"dragenter":function($event){_vm.isDragged = true},"dragleave":_vm.onDragLeave}},[_vm._m(1),(_vm.project === null || _vm.project.uploadSave)?_c('p',{staticClass:"drag-drop"},[_vm._m(2),_c('span',{staticClass:"pretendard-regular-normal-gravel-16px-2"},[_vm._v("파일은 최대 3개까지 업로딩됩니다.")])]):_c('p',{staticClass:"drag-drop"},[_c('span',{staticClass:"pretendard-regular-normal-gravel-16px-2"},[_vm._v(" "+_vm._s(_vm.project && _vm.project.uploadFile ? _vm.project.uploadFile.originalname : '파일 이름 가져오기 실패')+" ")])]),_c('input',{ref:"inputFile",staticClass:"custom-file-input",staticStyle:{"display":"none"},attrs:{"id":"validatedCustomFile4","type":"file","accept":".xls, .xlsx"},on:{"change":_vm.setExcelFiles}})]),_c('div',{staticClass:"overlap-group2",on:{"click":_vm.setUploadBtn}},[_c('div',{staticClass:"text-4 valign-text-middle pretendard-medium-white-20px"},[_vm._v("   파일 업로드 ")])])])]),_c('div',[_c('div',{staticClass:"text-5 pretendard-medium-black-20px"},[_vm._v("파일명")]),_c('div',[(_vm.projectList.length)?_c('b-list-group',{staticClass:"overlap-group-container"},_vm._l((_vm.projectList),function(p,idx){return _c('b-list-group-item',{key:p._id,staticClass:"pl-2",class:`overlap-group-4 ${
                      p._id === _vm.project?._id ? 'select_data' : ''
                    } py-0`},[_c('img',{staticClass:"dell",staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/img/visualization/user-upload/dell.svg"),"alt":"Dell"},on:{"click":function($event){return _vm.uploadDelClick(p._id, p.title)}}}),_c('div',{staticClass:"text-15 valign-text-middle pretendard-regular-normal-gravel-16px-2"},[_c('b-form-input',{ref:`inputTitle${idx}`,refInFor:true,staticClass:"pl-1",class:`${
                          p.inputState !== undefined && !p.inputState
                            ? 'input-border-danger'
                            : ''
                        }`,staticStyle:{"font-size":"15px"},attrs:{"state":p.title.trim().length !== 0 ? null : false,"maxlength":"30","placeholder":"파일명을 입력해 주시기 바랍니다."},on:{"keyup":function($event){return _vm.setInputTitleChange(p)},"focusin":function($event){return _vm.getDetail(p._id)}},model:{value:(p.title),callback:function ($$v) {_vm.$set(p, "title", $$v)},expression:"p.title"}})],1),_c('div',{staticClass:"x5-mb-1 valign-text-middle pretendard-regular-normal-gravel-12px",on:{"click":function($event){return _vm.getDetail(p._id)}}},[_vm._v(" "+_vm._s(_vm.fnGetFileSize(p.uploadFile.fileSize))+" ")]),_c('img',{staticClass:"line-22-2",attrs:{"src":require("@/assets/img/visualization/user-upload/line-22-1.svg"),"alt":"Line 22"}}),(p.uploadSave)?_c('img',{staticClass:"subtract-1",attrs:{"src":require("@/assets/img/visualization/user-upload/subtract-2.svg"),"alt":"Subtract"}}):_vm._e()])}),1):_c('b-list-group',{staticClass:"overlap-group-container"},[_c('b-list-group-item',{staticClass:"overlap-group-4"},[_c('div',{staticClass:"valign-text-middle pretendard-regular-normal-gravel-16px-2"},[_vm._v(" 업로드 된 파일이 없습니다. ")])])],1)],1)])]),_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"text-7 pretendard-medium-black-20px"},[_vm._v(" 데이터 타입 ")]),_c('div',[_c('b-button',{staticClass:"pretendard-medium-white-12px background-gary",attrs:{"variant":"white","size":"sm"},on:{"click":_vm.typeAllReset}},[_vm._v(" 초기화 ")])],1)]),_c('b-overlay',{staticClass:"table_l",attrs:{"show":_vm.pending,"opacity":"0.6"}},[_c('b-table-simple',{staticStyle:{"max-height":"500px"},attrs:{"sticky-header":"","responsive":""}},[_c('colgroup',[_c('col',{staticClass:"col-md-2"}),_c('col',{staticClass:"col-md-4"}),_c('col',{staticClass:"col-md-6"})]),_c('b-thead',[_c('b-tr',_vm._l((_vm.theadNames),function(text,idx){return _c('b-td',{key:`name_${idx}`,class:[
                        'text-center',
                        'thead-text',
                        'align-middle',
                        'text-nowrap',
                        idx !== 0 ? 'table-border-left' : '',
                      ],staticStyle:{"color":"rgba(68, 92, 178, 1)","background-color":"var(--san-marino3)"}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{class:`${text === '타입' ? 'type-pt' : ''}`},[_vm._v(" "+_vm._s(text)+" ")]),_c('div',[_c('b-tooltip',{ref:"tooltip",refInFor:true,attrs:{"target":"tooltip-button-show-event","placement":"bottom"}},[_c('img',{staticClass:"mt-2",staticStyle:{"width":"600px","height":"300px","opacity":"1"},attrs:{"src":require("@/assets/img/visualization/user-upload/upload-type-guide.png")}})]),(text === '타입')?_c('img',{staticClass:"question_duotone_line",staticStyle:{"cursor":"pointer"},attrs:{"src":require("../../../assets/img/visualization/user-upload/question-duotone-line.svg"),"alt":"Question_duotone_line","id":"tooltip-button-show-event"},on:{"click":_vm.showTooltip}}):_vm._e()],1)])])}),1)],1),_c('b-tbody',{staticClass:"table-css"},_vm._l((_vm.header),function(i,idx){return _c('b-tr',{key:`header_${idx}`,class:`${idx % 2 !== 0 ? 'btr' : ''} text-center`},[_c('b-td',{class:`align-middle pretendard-light-gravel-14px px-1 ${
                        idx + 1 === _vm.header.length
                          ? 'tr-end-border-bottom'
                          : ''
                      }`,staticStyle:{"width":"120px"}},[_vm._v(" "+_vm._s(idx + 1)+" ")]),_c('b-td',{class:`align-middle pretendard-light-gravel-14px px-1 table-border-left ${
                        idx + 1 === _vm.header.length
                          ? 'tr-end-border-bottom'
                          : ''
                      }`},[_vm._v(" "+_vm._s(i.name)+" ")]),_c('b-td',{class:`align-middle pretendard-light-gravel-14px px-1 py-1 table-border-left ${
                        idx + 1 === _vm.header.length
                          ? 'tr-end-border-bottom'
                          : ''
                      }`},[_c('b-form-select',{ref:`select${idx}`,refInFor:true,class:`${i.type === null ? 'red-select' : ''}`,attrs:{"state":i.type !== null,"disabled":i.disabled,"size":"sm"},on:{"change":function($event){_vm.dataUpdating = true}},model:{value:(i.type),callback:function ($$v) {_vm.$set(i, "type", $$v)},expression:"i.type"}},_vm._l((_vm.options),function(o){return _c('b-form-select-option',{key:o.value,staticStyle:{"color":"black"},attrs:{"value":o.value}},[_vm._v(_vm._s(o.text))])}),1)],1)],1)}),1)],1)],1)],1)]),_c('div',{staticClass:"skipBtn"},[_c('div',{staticClass:"overlap-group3",staticStyle:{"cursor":"pointer"},on:{"click":_vm.save}},[_c('div',{staticClass:"text-10 valign-text-middle"},[_vm._v(" "+_vm._s(_vm.headerOpen ? '저장하기' : '건너뛰기')+" ")])])]),_c('div',{staticClass:"btn1"},[_c('div',{staticClass:"overlap-group-2",on:{"click":_vm.prevBtn}},[_c('div',{staticClass:"text valign-text-middle pretendard-medium-white-20px"},[_vm._v(" 이전 ")])]),_c('div',{class:`${_vm.savePending ? 'overlap-group-2' : 'overlap-group1-1'}`,on:{"click":_vm.next}},[_c('div',{staticClass:"text valign-text-middle pretendard-medium-white-20px"},[_vm._v(" 다음 ")])])])])]),_c('noticeModal',{attrs:{"modalContent":_vm.modalContent,"clickType":_vm.clickType,"modal-id":'upload-write',"backdrop-option":true},on:{"confirm":_vm.getConfirmCheck,"write":_vm.getConfirmCheck}}),_c('noticeModal',{attrs:{"modalContent":_vm.modalContent,"clickType":"WRITE2","modal-id":'upload-file-check',"backdrop-option":true},on:{"confirm":_vm.getConfirmCheck,"write":_vm.getConfirmCheck,"cancelModal":_vm.setFileUpload}}),_c('common-modal',{attrs:{"id":"uploadModal","icon":{ type: 'create', name: 'file-dock-add' },"content":_vm.commonModalContent,"desc":_vm.commonModalDesc,"btn":_vm.commonModalBtns}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uploadTxt"},[_c('div',{staticClass:"text-6 pretendard-medium-black-20px"},[_vm._v(" 파일 업로드 ")]),_c('div',{staticClass:"group-488"},[_c('a',{attrs:{"href":"/files/DEMO_파일 업로드 포맷.xlsx","download":""}},[_c('img',{staticClass:"file_dock",attrs:{"src":require("../../../assets/img/visualization/user-upload/file-dock-1.svg"),"alt":"File_dock"}}),_c('div',{staticClass:"overlap-group"},[_c('div',{staticClass:"text-3 valign-text-middle pretendard-regular-normal-gravel-16px-2"},[_vm._v(" 데이터 폼 다운로드 ")]),_c('img',{staticClass:"import",attrs:{"src":require("../../../assets/img/visualization/user-upload/import.svg"),"alt":"Import"}})])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"icon_upload"},[_c('div',{staticClass:"upload-1"},[_c('div',{staticClass:"overlap-group-1"},[_c('img',{staticClass:"subtract",attrs:{"src":require("../../../assets/img/visualization/user-upload/subtract-1.svg"),"alt":"Subtract"}}),_c('img',{staticClass:"vector-10",attrs:{"src":require("../../../assets/img/visualization/user-upload/vector-10-1.svg"),"alt":"Vector 10"}})])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"span0"},[_vm._v("Drag&Drop 또는 파일 업로드 버튼을 클릭하여"),_c('br'),_vm._v("등록해 주시기 바랍니다."),_c('br')])
}]

export { render, staticRenderFns }