
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class JoinCreate extends Vue {
  public selectIds: string[] = [];
  public dashboardTitle: string = '';
  public profile: string[] = [];
  public joinType: string = '';
  public createSetTimeout: NodeJS.Timeout | null = null;
  public eventSource: EventSource | null = null;
  public joinProjectId: string | null = null;

  created() {
    const { joinProjectId } = this.$route.query;
    if (joinProjectId) {
      this.joinProjectId = joinProjectId as string;
      this.creatingDataCheck(this.joinProjectId);
    }
  }

  async eventSourceSetting(_id: string) {
    try {
      this.eventSource = new EventSource(
        `/sse/create/checking/${_id}`,
      );
      this.eventSource.onmessage = ({ data }) => {
        const { complete } = JSON.parse(data);
        console.log(complete);
        if (complete) {
          if (this.eventSource) {
            this.nextPage(_id);
            this.eventSource.close();
          }
        }
      };
    } catch (e) {
      console.error(e);
    }
  }

  public async createJoinData() {
    try {
      console.log(this.dashboardTitle);
      const { data } = await this.axios({
        url: '/visualization/join/create',
        method: 'POST',
        data: {
          selectIds: this.selectIds,
          profile: this.profile,
          joinType: this.joinType,
          title: this.dashboardTitle, // 타이틀 추가
        },
      });

      const { result, _id } = data;
      if (result) {
        let url = `${this.$route.fullPath}&joinProjectId=${_id}`;
        if (this.$route.name === 'first-step') {
          const split = this.$route.fullPath.split('?');
          url = split[0] + `?joinProjectId=${_id}`;
        }
        await this.$router.replace(url);
        await this.creatingDataCheck(_id);
      }
    } catch (e) {
      console.error(e);
    }
  }

  public async creatingDataCheck(_id: string) {
    this.$store.commit('setLoading', {
      state: true,
      message: '선택된 데이터 생성중입니다',
    });
    try {
      const { data } = await this.axios({
        url: `/visualization/join/create/${_id}`,
      });
      console.log(data);
      const { result } = data;
      if (result) {
        return this.nextPage(_id);
      }
      await this.eventSourceSetting(_id);
    } catch (e) {
      console.error(e);
    }
  }

  nextPage(_id: string) {
    this.$router.push(`/data-visualize/entry/${_id}`);
    this.$store.commit('setLoading', { state: false, message: '' });
  }

  beforeDestroy() {
    if (this.eventSource) {
      this.eventSource.close();
      this.$store.commit('setLoading', { state: false, message: '' });
    }
  }
}
