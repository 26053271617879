
import { Component, Vue } from 'vue-property-decorator';
import { DATA_TYPE, PROJECT_TYPE } from '@/enum/common';
import ProfileBlock from '@/components/data-set/profile-block.vue';
import { profileOptions } from '@/common';
import CustomInputBox, {
  ICustomInputBoxItem,
} from '@/components/data-set/detail/custom-input-box.vue';
import CustomNav, {
  ICustomNavItem,
} from '@/components/data-set/detail/custom-nav.vue';
import DataTable from '@/components/common/data-table.vue';
import { DataWorkshop } from '@/interface/data-workshop';
import CommonTable from '@/components/common/common-table.vue';
import noticeModal from '@/components/common/notice-popup.vue';
import BookmarkBlock from '@/components/data-set/bookmark-block.vue';
import { IProject } from '@/interface/project';
import IHeaderProcess = DataWorkshop.IHeaderProcess;

@Component({
  components: {
    BookmarkBlock,
    noticeModal,
    CommonTable,
    DataTable,
    CustomNav,
    CustomInputBox,
    ProfileBlock,
  },
})
export default class DataSetDetail extends Vue {
  private title: string | null = null;
  private content: string | null = null;
  private type: string | null = null;
  private profile: string[] = [];
  private data: [] = [];
  private header: IHeaderProcess[] = [];
  private collectionName?: string;
  private survey_id?: string;
  private subTab: string = 'detail';
  private dataInfoTab = 'guide';
  private prevDataInfoTab = 'guide';
  private search: {
    keyword: string | null;
    profile: string[];
    start_date: string | null;
    end_date: string | null;
  } = {
    keyword: null,
    profile: [],
    start_date: null,
    end_date: null,
  };
  private projectId: string | null = null;
  private users: string[] = [];
  private guide: {
    variableKey: string;
    name: string;
    key: string;
    value: string;
    type: string;
  }[] = [];
  private pendingData: boolean = false;
  private login: boolean = false;
  private navList: ICustomNavItem[] = [
    {
      key: 'detail',
      title: '상세 정보',
      active: this.subTab === 'detail',
      cb: () => this.subTabChange('detail'),
    },
    {
      key: 'data',
      title: '데이터 정보',
      active: this.subTab === 'data',
      cb: () => this.subTabChange('data'),
    },
    {
      key: 'summary',
      title: '수치적 자료 요약',
      active: this.subTab === 'summary',
      cb: () => this.subTabChange('summary'),
    },
  ];
  private inputBoxList: ICustomInputBoxItem[] = [];
  private guideData = [];
  private guideHeader = [
    { key: 'variableKey', text: '변수명' },
    { key: 'type', text: '데이터 타입' },
    { key: 'name', text: '변수 설명' },
    { key: 'key', text: '보기 번호' },
    { key: 'value', text: '보기 이름' },
  ];
  private guideBtn: boolean = false;
  private dataBtn: boolean = false;
  private bookmarkList: IProject[] = [];
  private userId: string = '';
  private bookmarkPending: boolean = false;
  private bookmarkCheck: boolean = false;
  private modalContent: string = '';
  private clickType: string = '';
  private messageFrontImg: string | null = null;
  private numericalData = [];
  private numericalHeader: any[] = [
    { key: 'key', type: DATA_TYPE.CHARACTER, name: '변수명' },
    { key: 'type', type: DATA_TYPE.CHARACTER, name: '타입' },
    { key: 'quantity', type: DATA_TYPE.INTEGER, name: 'N' },
    { key: 'missing', type: DATA_TYPE.INTEGER, name: '결측치수' },
    { key: 'range', type: DATA_TYPE.INTEGER, name: '범위' },
    { key: 'min', type: DATA_TYPE.INTEGER, name: '최소값' },
    { key: 'firstQuartile', type: DATA_TYPE.INTEGER, name: '1사분위수' },
    { key: 'median', type: DATA_TYPE.INTEGER, name: '중앙값' },
    { key: 'thirdQuartile', type: DATA_TYPE.INTEGER, name: '3사분위수' },
    { key: 'max', type: DATA_TYPE.INTEGER, name: '최대값' },
    { key: 'average', type: DATA_TYPE.INTEGER, name: '평균' },
    { key: 'variance', type: DATA_TYPE.INTEGER, name: '분산' },
    { key: 'standardDeviation', type: DATA_TYPE.INTEGER, name: '표준편차' },
  ];
  private isNotLogin: boolean = false;

  setGuideBtn(result: boolean) {
    console.log(result);
    this.guideBtn = result;
  }

  /*get bookmarkCheck() {
    const { getUserId } = this.$store.getters;
    return this.users.indexOf(getUserId) > -1;
  }*/

  get profileOptions() {
    return profileOptions;
  }

  get imgUrl() {
    if (this.type === PROJECT_TYPE.CRAWLING) return 'crawling_long';
    if (this.type === PROJECT_TYPE.TRANSACTION) return 'transaction_long';
    if (this.type === PROJECT_TYPE.PUBLIC_SEOUL) return 'public_seoul_long';
    if (this.type === PROJECT_TYPE.MOBILE) return 'mobile_long';
    if (this.type === PROJECT_TYPE.SURVEY) return 'survey_long';
    return null;
  }

  created() {
    const { _id } = this.$route.params;
    const { isLogin, getUserId } = this.$store.getters;

    this.login = isLogin;
    if (this.login) {
      this.userId = getUserId;
    }
    if (_id === 'detail') {
      this.getDefaultDetail();
    } else {
      this.projectId = _id;
      this.load();
    }
  }

  getDefaultDetail() {
    const { getDataSetDetail } = this.$store.getters;
    if (getDataSetDetail) {
      const {
        title,
        content,
        type,
        search,
        header,
        data,
        profile,
        collectionName,
        survey_id,
      } = getDataSetDetail;
      this.type = type;
      this.title = title;
      this.content = content;
      this.profile = profile;
      this.search = search;
      this.header = header;
      this.data = data;
      if (collectionName) this.collectionName = collectionName;
      if (survey_id) this.survey_id = survey_id;
      this.getColumnGuide();
      this.getDetailInfo({ type, content, profile });
      if (this.login) this.getBookmarks();
    }
  }

  subTabChange(type: string) {
    this.navList.forEach((item, idx) => {
      this.navList[idx].active = item.key === type;
    });

    if (type === 'summary') {
      if (!this.login) {
        this.isNotLogin = true;
        return this.showModal({
          message: '로그인 후 이용이 가능합니다.',
          type: 'WITHDRAW',
        });
      } else if (!this.numericalData.length) {
        this.loadNumericalData();
      }
    }
    this.subTab = type;
  }

  dataInfoTabChange(type: string | boolean) {
    if (!type) this.dataInfoTab = this.prevDataInfoTab;
    else {
      this.prevDataInfoTab = this.dataInfoTab;
    }
  }

  async load() {
    try {
      const { data } = await this.axios({
        method: 'GET',
        url: `/data-set/detail/${this.projectId}`,
      });
      const { result, info } = data;
      if (result && info) {
        const {
          type,
          title,
          header,
          data,
          keyword,
          profile,
          start_date,
          end_date,
          collectionName,
          survey_id,
          content,
        } = info;
        const payload: { [key: string]: any } = {
          type,
          title,
          content,
          profile,
          header,
          data,
          search: {
            keyword,
            profile,
            start_date,
            end_date,
          },
        };
        if (collectionName) payload.collectionName = collectionName;
        if (survey_id) payload.survey_id = survey_id;
        this.$store.commit('setDataSetDetail', payload);
        this.bookmarkCheck = true;
        this.getDefaultDetail();
      }
    } catch (e) {
      console.error(e);
    }
  }

  getDetailInfo({
    type,
    profile,
    content,
  }: {
    type: string;
    profile: string[];
    content: string;
  }) {
    let description = `${type} 데이터에 대한 설명을 간략하게 서술합니다.`;
    if (content) description = content;
    const existsProfile = profileOptions
      .filter(f => profile.includes(f.value))
      .map(m => m.text);

    this.inputBoxList = [
      {
        key: 'description',
        title: '데이터 설명',
        value: description,
        readonly: true,
      },
      {
        key: 'source',
        title: '데이터 출처',
        value: `데이터 출처에 대한 내용이 기재됩니다.`,
        readonly: true,
      },
      {
        key: 'profile',
        title: '프로파일 변수',
        value: existsProfile.join(', '),
        readonly: true,
      },
    ];
  }

  async getBookmarks() {
    try {
      const { data } = await this.axios({
        method: 'GET',
        url: `/data-set/bookmarks/${this.userId}`,
      });

      const { result, bookmarkList } = data;
      if (result) {
        this.bookmarkList = bookmarkList;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async getColumnGuide() {
    try {
      const { data } = await this.axios({
        method: 'POST',
        url: `/data-set/guide`,
        data: {
          header: this.header,
        },
      });
      if (data) {
        this.guideData = data;
      }
    } catch (e) {
      console.error(e);
    }
  }

  showModal({
    message,
    type,
    messageFrontImg,
  }: {
    message: string;
    type: string;
    messageFrontImg?: string;
  }) {
    this.modalContent = message;
    this.clickType = type;
    if (messageFrontImg) {
      this.messageFrontImg = messageFrontImg;
    } else {
      this.messageFrontImg = null;
    }
    this.$bvModal.show('dataset-detail');
  }

  async downloadGuide() {
    if (!this.login) {
      this.isNotLogin = true;
      return this.showModal({
        message: '로그인 후 이용이 가능합니다.',
        type: 'WITHDRAW',
      });
    }
    location.href = `/api/data-set/guide/download/${this.type}/${this.projectId}?collectionName=${this.collectionName}`;
  }

  async bookmarksSave() {
    if (this.bookmarkPending) return;
    if (!this.login) {
      this.isNotLogin = true;
      return this.showModal({
        message: '로그인 후 이용이 가능합니다.',
        type: 'WITHDRAW',
      });
    }

    if (this.projectId) {
      return await this.bookmarksDelete();
    }

    this.bookmarkPending = true;
    try {
      const { data } = await this.axios({
        method: 'post',
        url: '/data-set/bookmarks',
        data: {
          type: this.type,
          title: this.title,
          header: this.header,
          content: this.content,
          profiles: this.profile,
          data: this.data,
          collectionName: this.collectionName,
          survey_id: this.survey_id,
          ...this.search,
        },
      });
      const { result, _id, users } = data;
      if (result) {
        this.projectId = _id;
        this.users = users;
        await this.$router.replace(`/data-set/${_id}`);
        this.bookmarkCheck = true;
        this.bookmarkSuccess(this.bookmarkCheck);
        await this.getBookmarks();
      }
    } catch (e) {
      console.error(e);
    }
    this.bookmarkPending = false;
  }

  bookmarkSuccess(result: boolean) {
    const content = `북마크가 ${result ? '저장' : '해제'} 되었습니다.`;
    const contentFrontImgUrl = result
      ? 'assets/img/data-set/bookmark-2.svg'
      : 'assets/img/data-set/bookmark.svg';
    return this.showModal({
      message: content,
      type: 'WITHDRAW',
      messageFrontImg: contentFrontImgUrl,
    });
  }

  // closeModal() {
  //   this.$bvModal.hide('bookmarkModal');
  // }

  async bookmarksDelete() {
    this.bookmarkPending = true;
    try {
      const { data } = await this.axios({
        method: 'DELETE',
        url: `/data-set/bookmarks/${this.projectId}`,
      });

      const { result } = data;
      if (result) {
        await this.$router.replace(`/data-set/detail`);
        this.projectId = null;
        this.bookmarkCheck = false;
        this.bookmarkSuccess(this.bookmarkCheck);
        await this.getBookmarks();
      }
    } catch (e) {
      console.error(e);
    }
    this.bookmarkPending = false;
  }

  async loadNumericalData() {
    this.pendingData = true;
    try {
      const { data } = await this.axios({
        method: 'GET',
        url: `/data-set/numerical/${this.projectId}`,
        params: {
          type: this.type,
          keyword: this.search.keyword,
          start_date: this.search.start_date,
          end_date: this.search.end_date,
          collectionName: this.collectionName,
          survey_id: this.survey_id,
        },
      });

      const { result, dataList } = data;
      if (result) {
        this.numericalData = dataList;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.pendingData = false;
    }
  }

  async downloadData() {
    if (!this.login) {
      this.isNotLogin = true;
      return this.showModal({
        message: '로그인 후 이용이 가능합니다.',
        type: 'WITHDRAW',
      });
    }

    this.pendingData = true;
    try {
      const { data } = await this.axios({
        method: 'GET',
        url: '/data-set/download/data-create',
        params: {
          type: this.type,
          keyword: this.search.keyword,
          start_date: this.search.start_date,
          end_date: this.search.end_date,
          collectionName: this.collectionName,
          survey_id: this.survey_id,
          title: this.title,
        },
      });
      const { result, Location } = data;
      if (result) {
        window.location.href = Location;
      }
    } catch (e) {
      console.error(e);
    }
    this.pendingData = false;
  }

  linkGo(link: string) {
    this.$router.push(link);
  }

  isNotLoginLinkGo(link: string) {
    if (this.isNotLogin) {
      this.$router.push(`${link}?redirectPage=${this.$route.fullPath}`);
    }
  }
}
