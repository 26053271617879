
import { Component, Vue } from 'vue-property-decorator';
import { HOST_LINK } from '@/enum/common';
import router from '@/router';

@Component({})
export default class Header extends Vue {
  private HOST_LINK = HOST_LINK;
  $common: any;

  get token() {
    return this.$store.getters.getAccessToken;
  }

  get isLogin() {
    return this.$store.getters.isLogin;
  }

  private routeMyDataHub() {
    this.$store.commit('clickMyDataHubReset', true);
  }

  logout() {
    this.$store.dispatch('logout');
    this.$router.push('/login');
  }

  redirectUrl({ HOST_NAME, token }: { HOST_NAME: HOST_LINK; token?: string }) {
    if (!this.isLogin) {
      return this.$toast.open({
        message: '로그인 후 이용해 주세요.',
        type: 'error',
        duration: 3000,
      });
    }
    this.$common.redirectUrl({ HOST_NAME, token });
  }

  linkTo() {
    alert('서비스 준비중입니다.');
    return false;
  }
}
