var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',[_c('div',{staticClass:"nav-container"},[_c('img',{staticStyle:{"margin-top":"10px"},attrs:{"src":_vm.imgSrc,"id":"logo","alt":"logoImage"}}),_c('div',{staticClass:"title-container",on:{"mouseenter":function($event){return _vm.hover(true)},"mouseleave":function($event){return _vm.hover(false)}}},[(_vm.mainTitle)?_c('div',[_c('BasicMenu',{attrs:{"title":_vm.mainTitle.title,"url":_vm.mainTitle.url,"options":_vm.mainTitle.options},on:{"clickMenu":_vm.clickMenu,"logout":_vm.logout}})],1):_vm._e()]),_c('div',{staticClass:"main-menu-container",on:{"mouseenter":function($event){return _vm.hover(true)},"mouseleave":function($event){return _vm.hover(false)}}},[(_vm.mainMenu)?_c('div',{staticStyle:{"justify-content":"space-between"}},_vm._l((_vm.getMenu(_vm.mainMenu)),function({ title, url, options, detailMenu }){return _c('div',{key:`key-${title}`,staticClass:"main-menu-title-container",style:(_vm.hoveredMenu === title ? { borderBottom: 'solid 3px #00a880' } : {}),on:{"mouseenter":function($event){return _vm.setHoveredMenu(title)}}},[(options && options.tab && options.anchor)?_c('div',[_c('div',{staticClass:"main-title-layout"},[_c('a',{attrs:{"href":url,"target":"_blank"}},[(title === '로그아웃')?_c('span',{on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(title)+" ")]):_c('span',{on:{"click":function($event){return _vm.clickMenu(title)}}},[_vm._v(" "+_vm._s(title)+" ")])])]),(detailMenu)?_c('ul',{ref:"listUl",refInFor:true,staticClass:"detail-list-ul",class:{ 'detail-list-ul-show': _vm.hoverCheck },style:(_vm.getListWidth())},_vm._l((detailMenu),function({
                  title: detailTitle,
                  url: detailUrl,
                  options: detailOptions,
                }){return _c('li',{key:detailTitle,staticClass:"detail-list-li",class:{ 'detail-list-li-show': _vm.hoverCheck }},[_c('BasicMenu',{attrs:{"title":detailTitle,"url":detailUrl,"options":detailOptions,"detailMenu":true},on:{"clickMenu":_vm.clickMenu,"logout":_vm.logout}})],1)}),0):_vm._e()]):(options && !options.tab && options.anchor)?_c('div',[_c('div',{staticClass:"main-title-layout"},[_c('a',{attrs:{"href":url}},[(title === '로그아웃')?_c('span',{on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(title)+" ")]):_c('span',{on:{"click":function($event){return _vm.clickMenu(title)}}},[_vm._v(" "+_vm._s(title)+" ")])])]),(detailMenu)?_c('ul',{ref:"listUl",refInFor:true,staticClass:"detail-list-ul",class:{ 'detail-list-ul-show': _vm.hoverCheck },style:(_vm.getListWidth())},_vm._l((detailMenu),function({
                  title: detailTitle,
                  url: detailUrl,
                  options: detailOptions,
                }){return _c('li',{key:detailTitle,staticClass:"detail-list-li",class:{ 'detail-list-li-show': _vm.hoverCheck }},[_c('BasicMenu',{attrs:{"title":detailTitle,"url":detailUrl,"options":detailOptions,"detailMenu":true},on:{"clickMenu":_vm.clickMenu,"logout":_vm.logout}})],1)}),0):_vm._e()]):(options && options.tab)?_c('div',[_c('div',{staticClass:"main-title-layout"},[_c('router-link',{attrs:{"to":url,"target":"_blank"}},[(title === '로그아웃')?_c('span',{on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(title)+" ")]):_c('span',{on:{"click":function($event){return _vm.clickMenu(title)}}},[_vm._v(" "+_vm._s(title)+" ")])])],1),(detailMenu)?_c('ul',{ref:"listUl",refInFor:true,staticClass:"detail-list-ul",class:{ 'detail-list-ul-show': _vm.hoverCheck },style:(_vm.getListWidth())},_vm._l((detailMenu),function({
                  title: detailTitle,
                  url: detailUrl,
                  options: detailOptions,
                }){return _c('li',{key:detailTitle,staticClass:"detail-list-li",class:{ 'detail-list-li-show': _vm.hoverCheck }},[_c('BasicMenu',{attrs:{"title":detailTitle,"url":detailUrl,"options":detailOptions,"detailMenu":true},on:{"clickMenu":_vm.clickMenu,"logout":_vm.logout}})],1)}),0):_vm._e()]):_c('div',[_c('div',{staticClass:"main-title-layout"},[(url)?_c('router-link',{attrs:{"to":url}},[(title === '로그아웃')?_c('span',{on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(title)+" ")]):_c('span',{on:{"click":function($event){return _vm.clickMenu(title)}}},[_vm._v(" "+_vm._s(title))])]):_c('div',{staticStyle:{"cursor":"pointer"}},[(title === '로그아웃')?_c('span',{on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(title)+" ")]):_c('span',{on:{"click":function($event){return _vm.clickMenu(title)}}},[_vm._v(" "+_vm._s(title)+" ")])])],1),(detailMenu)?_c('ul',{ref:"listUl",refInFor:true,staticClass:"detail-list-ul",class:{ 'detail-list-ul-show': _vm.hoverCheck },style:(_vm.getListWidth())},_vm._l((detailMenu),function({
                  title: detailTitle,
                  url: detailUrl,
                  options: detailOptions,
                }){return _c('li',{key:detailTitle,staticClass:"detail-list-li",class:{ 'detail-list-li-show': _vm.hoverCheck }},[_c('BasicMenu',{attrs:{"title":detailTitle,"url":detailUrl,"options":detailOptions,"detailMenu":true},on:{"clickMenu":_vm.clickMenu,"logout":_vm.logout}})],1)}),0):_vm._e()])])}),0):_vm._e()]),_c('div',{staticClass:"sub-menu-container"},[(_vm.subMenu)?_c('div',_vm._l((_vm.getMenu(_vm.subMenu)),function({ title, url, options }){return _c('div',{key:`key-${title}`},[_c('BasicMenu',{attrs:{"title":title,"url":url,"options":options},on:{"clickMenu":_vm.clickMenu,"logout":_vm.logout}})],1)}),0):_vm._e()])]),_c('div',{staticClass:"detail-list-bg",style:(_vm.hoverCheck ? { height: `${_vm.detailBgHeight}px` } : {}),on:{"mouseenter":function($event){return _vm.hover(true)},"mouseleave":function($event){return _vm.hover(false)}}},[_c('div',{staticClass:"detail-list-inner-bg",style:(_vm.hoverCheck ? { height: `${_vm.detailBgHeight}px` } : {})},[_c('div',{staticClass:"detail-list-inner-text",class:{ 'detail-list-ul-show': _vm.hoverCheck }},[_c('div',{staticClass:"detail-list-inner-title-text"},[_vm._v("통합 데이터 허브")]),_vm._m(0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"detail-list-inner-sub-text"},[_vm._v(" 이종 데이터 통합 분석 서비스"),_c('br'),_vm._v("(저장/분석/시각화)를 이용할 수 있는"),_c('br'),_vm._v("플랫폼 입니다. ")])
}]

export { render, staticRenderFns }