import axios, { AxiosInstance } from 'axios';
import Vue from 'vue';
import Vuex from '../store';
import router from '@/router/index';
const baseURL = process.env.NODE_ENV === 'development' ? '/api' : '/api';
axios.defaults.withCredentials = true;

const instance: AxiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 500000,
});

instance.interceptors.request.use(config => {
  /*console.log(Vue.$cookies);*/
  //const token = Vue.$cookies.get('Authentication');
  if (Vue.$cookies) {
    const token = Vuex.getters.getAccessToken;
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { response } = error;
    const { status, data } = response;

    if (status === 401) {
      Vue.$toast.open({
        message: '로그인 후 이용이 가능합니다.',
        type: 'error',
        duration: 5000,
      });
      Vuex.dispatch('logout').catch(() => {});
      return router.push('/login');
    } else {
      Vue.$toast.open({
        message: data.message,
        type: 'error',
        duration: 5000,
      });
    }
    return response;
  },
);

export const ins = instance;
