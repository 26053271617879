
import { Component, Mixins, Vue } from 'vue-property-decorator';
import noticeModal from '@/components/common/notice-popup.vue';
import * as XLSX from 'xlsx'
import { s3Upload } from '@/utils/s3-upload';
import { profileOptions } from '@/common';
import { IProject, s3FileUpload } from '@/interface/project';

interface defaultHeader {type: null, key: string, name:string, value?: {[key: string]: string}, disabled: boolean};

@Component({
  components: { noticeModal },
})
export default class UserUpload extends Vue {
  private project: IProject[] = [];
  private file: any = null;
  private header: defaultHeader[] = [];
  private title: string = '';
  private options = [
    { value: null, text: '타입을 선택해 주세요.' },
    { value: 'integer', text: 'integer' },
    { value: 'numeric', text: 'numeric' },
    { value: 'character', text: 'character' },
    { value: 'factor', text: 'factor' },
  ];
  private saveClick: boolean = false;
  private nextShow: boolean = false;
  private projectId: string | null = null;
  private uploadFile: s3FileUpload = {
    Etag: '',
    Key: '',
    Location: '',
    Bucket: ''
  };

  created() {
    this.load();
  }

  async load() {
    try {
      const {data} = await this.axios({
        url: '/visualization/user-upload'
      });
      const {result, project} = data;
      if (result) {
        this.project = project;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async fileUpload () {
    if(this.project.length > 2) {
      return this.$toast.open({
        message: '업로드 데이터는 최대 3개까지 등록이 가능합니다.',
        type: 'error',
        duration: 3000
      });
    }

    if(this.file === null) {
      return this.$toast.open({
        message: '파일을 먼저 등록해 주시기 바랍니다.',
        type: 'error',
        duration: 3000
      });
    }

    this.saveClick = false;
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target) return;
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });

      const dataName = wb.SheetNames[0];
      const guideName = wb.SheetNames[1];
      const ws = wb.Sheets[dataName];
      const gs = wb.Sheets[guideName];
      /*const data: string[][] = XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.header = data[0].map((m: string) => {
        return {
          key: m,
          type: null
        }
      })*/

      const guide: {'변수명': string, '변수설명': string, key: string, value: string}[] = XLSX.utils.sheet_to_json(gs);
      const headerGuide: defaultHeader[] = [];
      for (const i of guide) {
        const dataKey = i['변수명'];
        const name = i['변수설명'];
        const {key, value} = i;
        let dataValue: {[key: string]: string} | null = null;

        if(key) {
          dataValue = {};
          dataValue[`${key}`] = value;
        }
        const findIndex = headerGuide.findIndex(f => f.key === dataKey);
        if (findIndex === -1) {
          const obj: defaultHeader = {type: null, key: dataKey, name, disabled: false};
          if (dataValue) {
            obj.value = dataValue;
          }
          const findProfile = this.profiles.find(f => f.value === dataKey);
          if (findProfile) {
            obj.type = findProfile.type;
            obj.disabled = true;
          }
          headerGuide.push(obj);
        } else {
          if (dataValue) {
            headerGuide[findIndex].value = {...headerGuide[findIndex].value, ...dataValue};
          }
        }
      }
      this.header = headerGuide;
    }
    reader.readAsBinaryString(this.file);
  }

  get profiles() {
    return profileOptions
  }

  async save () {
    //this.$bvModal.show('continue');
    if (this.title === "") {
      return this.$toast.open({
        message: '파일명을 입력해 주세요.',
        type: 'error',
        duration: 3000
      });
    }
    this.saveClick = true;
    const typeNoSelect = this.header.filter(f => f.type === null);
    if (typeNoSelect.length > 0) {
      return this.$toast.open({
        message: '타입을 모두 선택하여 주세요.',
        type: 'error',
        duration: 3000
      });
    }

    if (this.projectId) {
      return await this.update();
    }
    await this.dataSave();
  }

  async dataSave() {
    try {
      const awsS3 = new s3Upload([this.file], [], 'user-upload');
      const uploaded = await awsS3.upload();
      if (uploaded) {
        this.uploadFile = awsS3.getOriginalFile;
        const {data} = await this.axios({
          method: 'POST',
          url: '/visualization/user-upload',
          data: {
            title: this.title,
            uploadFile: this.uploadFile,
            header: this.header,
            fileSize: this.file.size
          }
        });
        const {result, _id} = data;
        if (result) {
          this.projectId = _id;
          this.nextShow = true;
          return this.$toast.open({
            message: '저장하였습니다.',
            type: 'success',
            duration: 3000
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  async update() {
    try {
      const {data} = await this.axios({
        method: 'PUT',
        url: `/visualization/user-upload/${this.projectId}`,
        data: {
          title: this.title,
          header: this.header,
        }
      });
      const {result} = data;
      if (result) {
        return this.$toast.open({
          message: '저장하였습니다.',
          type: 'success',
          duration: 3000
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  async cancel() {
    if (this.projectId) {
      try {
        const {data} = await this.axios({
          url: `/visualization/user-upload/${this.projectId}`,
        });
        const {result, header, title} = data;
        if (result) {
          this.header = header;
          this.title = title;
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      this.header = this.header.map(m => {
        return {
          ...m,
          type: m.disabled ? m.type : null
        }
      });
      this.title = '';
      this.saveClick = false;
    }

    return this.$toast.open({
      message: '취소하였습니다.',
      type: 'warning',
      duration: 3000
    });
  }

  prev () {
    if (this.header.length === 0) return this.$router.go(-1);
    this.init();
  }
  next() {
    if (this.project.length < 3) {
      return this.$bvModal.show('continue');
    }
    this.nextPage();
  }

  async projectDelete(_id: string) {
    try {
      const {data} = await this.axios({
        method: 'DELETE',
        url: `/visualization/user-upload/${_id}`
      });
      console.log(data);
      const {result} = data;
      if (result) {
        await this.load();
      }
    } catch (e) {
      console.error(e);
    }
  }

  fileAdd() {
    this.init();
    this.$bvModal.hide("continue");
  }

  init() {
    this.file = null;
    this.header = [];
    this.title = '';
    this.nextShow = false;
    this.projectId = null;
    this.load();
  }
  nextPage() {
    this.$router.push('/visualization/data-list/first-step')
  }
};
