
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CustomModal extends Vue {
  @Prop({ default: '#00000066' }) backdrop?: string;
  @Prop({ default: true }) noCloseOnBackdrop?: boolean;
  @Prop({ default: false }) noCloseOnEsc?: boolean;

  private isShow = false;

  mounted() {
    document.addEventListener('keyup', this.pushEscBtn);
  }

  beforeDestroy() {
    document.removeEventListener('keyup', this.pushEscBtn);
  }

  open() {
    this.isShow = true;
    this.move();
  }

  close() {
    this.isShow = false;
  }

  pushEscBtn(e: KeyboardEvent) {
    if (e.key === 'Escape') this.isShow = !!this.noCloseOnEsc;
  }

  move() {
    const body = document.querySelector('body');
    if (body) {
      body.appendChild(this.$el);
    }
  }
}
