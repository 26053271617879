
import { Component, Vue } from 'vue-property-decorator';
import noticeModal from '@/components/common/notice-popup.vue';
import { VueEditor } from 'vue2-editor';
import {
  FileInfoProp, popupSetInterface,
  PostInfoInterface,
  sendDataInterface,
} from '../../../interfaces/notice.interface';
import { s3Upload } from '@/utils/s3-upload';
import { Route } from 'vue-router';
import defaultToolbar from '@/utils/vue2-editor-toolbar';

@Component({
  components: { noticeModal, VueEditor },
})
export default class NoticeWrite extends Vue {
  private options: { text: string; value: string }[] = [
    { text: '공지', value: '1' },
    { text: '이벤트', value: '2' },
    { text: '메뉴얼', value: '3' },
    { text: '기타', value: '4' },
  ];
  private topFixings: { text: string; value: string }[] = [
    { text: 'YES', value: 'Y' },
    { text: 'NO', value: 'N' },
  ];
  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface["type"] =
    'GoOut';
  private strGoOut: string = '';
  private files: any[]; //파일첨부
  private fileViewSave: FileInfoProp[]; //view, save 될때 보여줄거
  private beforeUpload: FileInfoProp[];
  private fileDeletes: FileInfoProp[];
  private addUploadFiles: FileInfoProp[];
  private customToolbar = defaultToolbar;
  private postInfo: PostInfoInterface = {
    type: '1',
    title: '',
    content: '',
    top_fixing: 'N',
    files: [
      {
        BFile_Nm: '',
        BFile_Type: '',
        BFile_Size: 0,
        BFile_URL: '',
      },
    ],
  };
  private registerFlag = false;
  private isUpload: boolean = false;
  private titleRegex: boolean = true;
  private topFixCount: number = 0;
  private topFixValue: 'N' | 'Y' = 'N';
  constructor() {
    super();
    this.files = [];
    this.fileViewSave = [];
    this.beforeUpload = [];
    this.fileDeletes = [];
    this.addUploadFiles = [];
  }
  async beforeRouteLeave(to: Route, from: Route, next: () => void) {
    if (!this.registerFlag) {
      this.modalSetting({
        title: '',
        content: '작성한 내용이 모두 삭제됩니다.<br>정말로 나가시겠습니까?',
        type: 'GoOut',
      });
      this.strGoOut = to.path;
      await this.$bvModal.show('noticePopup');
    } else {
      next();
    }
  }

  async goOut(path: string) {
    if (!this.$route.params.seq) {
      await this.s3Delete(this.fileViewSave);
    }
    if (this.addUploadFiles.length > 0) {
      await this.s3Delete(this.addUploadFiles);
    }
    if(path) window.location.href = `${path}`;
    else {
      this.registerFlag = true
      history.back()
    }
  }

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }
  isCheck() {
    //제목 or 내용 각각 2글자 이하면 경고 팝업창 제시
    if (
      this.postInfo.title.length < 2 ||
      this.postInfo.content.replace(/<[^>]*>?/g, '').length < 2
    ) {
      let contentTitle = '내용';
      if (this.postInfo.title.length < 2) contentTitle = '제목';
      this.modalSetting({
        title: '',
        content: `${contentTitle}을 2글자 이상 입력해 주세요.`,
        type: 'Alert',
      });
      this.$bvModal.show('noticePopup');
      this.registerFlag = false;
      return false;
    }
    if (!this.titleRegex) {
      this.modalSetting({
        title: '제목 안내',
        content: '한글, 영문 포함된 제목으로 작성 부탁드립니다.',
        type: 'Alert',
      });
      this.$bvModal.show('noticePopup');
      this.registerFlag = false;
      return false;
    }
    const seq = this.$route.params.seq;
    this.modalSetting({
      title: seq ? '' : '공지사항 등록',
      content: `${seq ? '수정사항' : '공지사항'}을 ${seq ? '저장' : '등록'}하시겠습니까?`,
      type: 'GO',
    });
  }
  isOk() {
    this.registerFlag = true;
    if (this.fileDeletes.length > 0) this.s3Delete(this.fileDeletes);
    this.postSave();
  }
  cancelModal() {
    const seq = this.$route.params.seq;
    this.modalSetting({
      title: seq ? '' : '공지사항 취소',
      content: `작성한 내용이 모두 삭제됩니다.<br>${seq ? '정말로 나가시겠습니까?': '취소하시겠습니까?'}`,
      type: 'GoOut',
    });
  }
  isCancel() {
    if (!this.$route.params.seq) {
      //글작성 취소-확인 누를때 업로드 된 파일들 전부 삭제
      this.s3Delete(this.fileViewSave);
    }
    if (this.addUploadFiles.length > 0) {
      //글 수정 - 이후 기존 파일을 삭제후 다른 파일로 업로드후에 취소할 경우 추가된 파일만 삭제함
      this.s3Delete(this.addUploadFiles);
    }
    this.registerFlag = true;
    history.back();
  }
  mounted() {
    this.$emit('isPage', this.$route.path);
    if (this.$route.params.seq) {
      this.getPostInfo();
    }
    this.getTopFix();
  }
  created() {}
  async getPostInfo() {
    try {
      const sendData = {};
      const { data } = await this.axios.post(
        `/notice/view/${this.$route.params.seq}`,
        sendData,
      );
      const { result } = data;
      this.postInfo = result;
      this.topFixValue = result.top_fixing;
      this.fileViewSave = result.files;
      if (this.fileViewSave.length > 0) this.isUpload = true;
    } catch (e) {
      console.log(e);
    }
  }
  private createUrl() {
    const searchWord = this.$route.query.search as string;
    const category = this.$route.query.category as string;
    const postType = this.$route.query.type as string;
    const dateType = this.$route.query.dateType as string;
    const fix = this.$route.query.fix;
    let fixStr = '';
    if (fix) fixStr = '&fix=Y';
    return `?category=${category ? category : '0'}&search=${
      searchWord ? searchWord : ''
    }&type=${postType ? postType : '0'}&dateType=${
      dateType ? dateType : '0'
    }${fixStr}`;
  }
  async postSave() {
    const fileLength = this.fileViewSave.length + this.files.length;
    if (fileLength > 3) this.isUpload = true;
    else this.isUpload = false;
    if (this.isUpload) {
      this.modalSetting({
        title: '업로드 안내',
        content: '업로드 최대 개수는 3개이며,<br>다시 업로드 해주세요.',
        type: 'Alert',
      });
      await this.$bvModal.show('noticePopup');
      this.registerFlag = false;
      //await this.showAlert('업로드 최대 개수는 3개이며, 다시 업로드 해주세요.');
      return false;
    }
    try {
      //업로드 위치
      await this.uploadClick();
      this.postInfo.top_fixing = this.topFixValue;
      const sendData: sendDataInterface = {
        type: this.postInfo.type,
        title: this.postInfo.title,
        content: this.postInfo.content,
        top_fixing: this.postInfo.top_fixing,
        files: this.fileViewSave,
      };
      const addParam = this.createUrl();
      const pageNum = this.$route.query.page as string;
      if (this.$route.params.seq) {
        sendData.seq = Number(this.$route.params.seq);
        await this.save('edit', sendData);
        return await this.$router.push(
          `/notice/postView/${this.$route.params.seq}${addParam}&page=${
            pageNum ? pageNum : 1
          }`,
        );
      } else {
        await this.save('save', sendData);
        return await this.$router.push(
          `/notice${addParam}&page=${pageNum ? pageNum : 1}`,
        );
      }
    } catch (e) {
      console.log(e);
    }
  }
  private async save(path: string, sendData: sendDataInterface) {
    await this.axios.post(`/notice/${path}`, sendData);
  }

  async uploadClick() {
    this.isUpload = true;
    const result = new s3Upload(this.files, this.fileViewSave, 'notice');
    const { bool, message } = result.isUpload(3, '20MB');
    if (!bool) {
      await result.upload();
      this.fileViewSave = result.getFiles;
      this.addUploadFiles = result.getAddFiles;
    } else {
      //this.$toast.warning(message);
      this.modalSetting({
        title: '업로드 안내',
        content: `${message}`,
        type: 'Alert',
      });
      await this.$bvModal.show('noticePopup');
      //await this.showAlert(message)
    }
    this.files = [];
  }
  fileDelete(datatOneFile: any) {
    //삭제 파일한 대상 추출
    const deleteTarget = this.fileViewSave.filter(item => {
      return item === datatOneFile;
    });
    this.fileViewSave = this.fileViewSave.filter(item => {
      return item !== datatOneFile;
    });
    this.fileDeletes.push(...deleteTarget);
    if (this.fileViewSave.length === 0) {
      this.isUpload = false;
    }
  }
  async s3Delete(params: any[] = []) {
    const result = new s3Upload([], params, 'notice');
    await result.delete();
  }
  isInput() {
    const title = this.postInfo.title;
    if (title) {
      const regex = /[ㄱ-ㅎ]/i;
      const isTest = regex.test(title);
      if (isTest) this.titleRegex = false;
      else this.titleRegex = true;
    } else {
      this.titleRegex = true;
    }
  }
  private async getTopFix() {
    const { data } = await this.axios.get('notice/getTopFix');
    const { result: topCount } = data;
    this.topFixCount = topCount;
  }
  private async topFixClick(value: string) {
    if (
      this.topFixCount >= 3 &&
      value === 'Y' &&
      this.postInfo.top_fixing !== 'Y'
    ) {
      //상단 고정 안내
      this.modalSetting({
        title: '',
        content: '상단 고정은 최대 3개까지만 가능합니다.',
        type: 'WITHDRAW',
      });
      await this.$bvModal.show('noticePopup');
      this.topFixValue = 'N';
    }
  }
  async filesSelect() {
    await this.uploadClick();
  }
}
