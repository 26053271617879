import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { HOST_LINK } from '@/enum/common';
import Vuex from '../store';

@Component({})
export default class mixin extends Vue {
  async redirectUrl({
    HOST_NAME,
    isNotLogin,
  }: {
    HOST_NAME: HOST_LINK;
    isNotLogin?: boolean;
  }) {
    try {
      const { data } = await this.axios({
        url: `/user/redirect/${HOST_NAME}`,
      });

      const { result, token, url } = data;
      if (result) {
        await Vuex.dispatch('tokenUpdate', { token });
        if (isNotLogin) {
          window.open(url);
          return;
        }

        let urlToken = `${url}${token}`;
        if (HOST_NAME === HOST_LINK.CRAWLING) {
          urlToken = `${url}?token=${token}`;
        }
        window.location.href = urlToken;
      }
    } catch (e) {
      console.error(e);
    }
  }
}
