export const chartTypeMatchingTable: { [key: string]: any } = {
  histogram: 'bar',
  'box-plot': 'boxplot',
  'xy-graph': 'bar',
  'word-cloud': 'wordCloud',
  scaling: 'bar',
};

export const visualizeTypeMatchingTableByKr: { [key: string]: any } = {
  missing: '결측치 처리',
  outlier: '이상치 처리',
  scaling: '스케일링',
  histogram: '히스토그램',
  'box-plot': 'Box plot',
  'xy-graph': 'X-Y 그래프',
  'word-cloud': '워드 클라우드',
};

export const chartType = ({
  url,
  version,
}: {
  url: string;
  version?: string | number;
}) => [
  {
    name: '결측치 처리',
    type: 'missing',
    path: path('missing', url),
    imgSrc: imgSrc('missing', String(version || '')),
    description:
      '데이터 중에서 누락된 값을 대체하거나 삭제하여 데이터의 정확성을 높일 수 있습니다.',
  },
  {
    name: '이상치 처리',
    type: 'outlier',
    path: path('outlier', url),
    imgSrc: imgSrc('outlier', String(version || '')),
    description:
      '데이터 중에서 극단적으로 크거나 작은 값을 대체하거나 삭제하여 데이터의 정확성을 높일 수 있습니다.',
  },
  {
    name: '스케일링',
    type: 'scaling',
    path: path('scaling', url),
    imgSrc: imgSrc('scaling', String(version || '')),
    description:
      '다양한 범위를 가진 데이터의 크기를 조정하여 상대 비교와 분석에 도움이 됩니다.',
  },
  {
    name: '히스토그램',
    type: 'histogram',
    path: path('histogram', url),
    imgSrc: imgSrc('histogram', String(version || '')),
    description:
      '수치 데이터를 막대 그래프 형태로 나타내는 그래픽 표현 방법으로 데이터의 주요 특성과 패턴을 이해하는 데 도움이 됩니다.',
  },
  {
    name: 'Box plot',
    type: 'box-plot',
    path: path('box-plot', url),
    imgSrc: imgSrc('box-plot', String(version || '')),
    description:
      '데이터의 요약 통계량과 이상치를 시각적으로 표현하는 그래프로 데이터의 분포를 이해하고 비교하는 데 도움이 됩니다.',
  },
  {
    name: 'X-Y 그래프',
    type: 'xy-graph',
    path: path('xy-graph', url),
    imgSrc: imgSrc('xy-graph', String(version || '')),
    description:
      '두 개의 축을 사용하여 데이터를 표현하는 그래프로 수치 데이터를 비교하거나 분석하는 데 도움이 됩니다.',
  },
  {
    name: '워드 클라우드',
    type: 'word-cloud',
    path: path('word-cloud', url),
    imgSrc: imgSrc('word-cloud', String(version || '')),
    description:
      '텍스트 데이터에서 가장 빈번하게 등장하는 단어들을 시각적으로 나타내는 차트로 어떤 주제나 텍스트 데이터의 중요한 내용을 한눈에 파악할 수 있습니다.',
  },
];

export const COLOR_PALETTE = [
  [
    '#1A1A1A',
    '#3E3E3E',
    '#545454',
    '#6A6A6A',
    '#777777',
    '#A6A5A5',
    '#D6D6D5',
    '#F1F1F1',
  ],
  [
    '#494C1B',
    '#656B1D',
    '#899128',
    '#ADB933',
    '#C3D039',
    '#DBE765',
    '#EDF3B2',
    '#F8FADF',
  ],
  [
    '#30471D',
    '#406422',
    '#57882E',
    '#6EAC3A',
    '#7DC240',
    '#9EDB6B',
    '#CFEDB5',
    '#EBF8E1',
  ],
  [
    '#1A4538',
    '#1B5D48',
    '#267E63',
    '#309F7D',
    '#37B38B',
    '#62CEAD',
    '#B1E7D6',
    '#DFF5EE',
  ],
  [
    '#18264D',
    '#162A65',
    '#1E3788',
    '#2647AC',
    '#2C51BE',
    '#5879DA',
    '#ABBCED',
    '#DDE3F7',
  ],
  [
    '#1B1247',
    '#201163',
    '#2C1685',
    '#371DA8',
    '#482BBD',
    '#694FD6',
    '#B4A7EB',
    '#E1DBF6',
  ],
  [
    '#510E30',
    '#6B083F',
    '#861D5C',
    '#A40D77',
    '#B80F87',
    '#CE40AA',
    '#E79FD5',
    '#F5D8EE',
  ],
  [
    '#6D0A0A',
    '#910000',
    '#AC0000',
    '#C90000',
    '#DA0000',
    '#EA4444',
    '#F59999',
    '#FBD4D4',
  ],
  [
    '#87330A',
    '#C34600',
    '#DF5D00',
    '#ED6C00',
    '#F87900',
    '#FF9333',
    '#FFC694',
    '#FFE5D0',
  ],
  [
    '#875C0A',
    '#C38400',
    '#DFB100',
    '#F1D500',
    '#F8E700',
    '#FFF333',
    '#FFF694',
    '#FFF9D0',
  ],
];

function path(type: string, url: string) {
  return `/data-visualize/visualizing/${url}/${type}`;
}

function imgSrc(type: string, version = '') {
  return require(`@/assets/img/visualization/${type}${version}.svg`);
}

export const CHART_COLORS_A = [
  '#1978b5',
  '#419dd9',
  '#afc8e9',
  '#ff8006',
  '#ff942e',
  '#ffbc79',
  '#28a128',
  '#53ce53',
  '#99e08b',
  '#d72223',
  '#e25556',
  '#ff9997',
  '#9567be',
  '#b98be1',
  '#c6b1d6',
  '#8d554a',
  '#be7161',
  '#c59d95',
  '#e478c3',
  '#fc96dd',
  '#f7b7d3',
  '#7f7f7f',
  '#a5a4a4',
  '#c7c7c7',
  '#bdbe1c',
  '#dedf40',
  '#dcdc8e',
  '#0fbfd0',
  '#4ce3f1',
  '#9fdbe6',
];

export const CHART_COLORS_B = ['#00A880', '#445CB2', '#272A3E'];
