
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class PeriodBlock extends Vue {
  @Prop() value!: number | null;
  @Prop() periodOption!: { value: number; text: string };
  @Prop() idx!: number;
  @Prop({ default: false }) disabled!: boolean;

  get modelValue() {
    return this.value;
  }
  @Emit()
  periodClick(value: number) {}
}
