
import { Component, Vue } from 'vue-property-decorator';
import noticeModal from '@/components/common/notice-popup.vue';
import { popupSetInterface } from '../../../../interfaces/notice.interface';

@Component({ components: { noticeModal } })
export default class CustomDataService extends Vue {
  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface['type'] = 'BACK';
  private strGoOut: string = '';

  mounted() {}

  async goto() {
    if (this.isLogin) {
      await this.$router.push('/custom-data-service/request');
    } else {
      this.strGoOut = '/login?redirectPage=/custom-data-service/request';
      await this.showAlert('로그인 후 이용이 가능합니다.', 'WITHDRAW');
    }
  }

  private get isLogin() {
    return this.$store.getters.isLogin;
  }

  //모달관련 메소드
  async showAlert(
    msg: string,
    type:
      | 'BACK'
      | 'GO'
      | 'NO'
      | 'GoOut'
      | 'Alert'
      | 'WITHDRAW'
      | 'customDataOk',
  ) {
    this.modalSetting({
      title: '',
      content: msg,
      type: type,
    });
    await this.$bvModal.show('noticePopup-write');
  }

  async isOk() {
    await this.$router.push('/login');
  }

  isCancel() {
    history.back();
  }

  goOut() {
    this.$router.push(this.strGoOut);
  }

  async cancelModal() {
    this.modalSetting({
      title: '맞춤형 데이터 서비스 신청 취소',
      content: '작성한 내용이 모두 삭제됩니다.<br>취소하시겠습니까?',
      type: 'GoOut',
    });
    await this.$bvModal.show('noticePopup-write');
  }

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }
}
