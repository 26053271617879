import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';
import createPersistedState from 'vuex-persistedstate';
import router from '../router/index';
import VueRouter from 'vue-router';
import { Data, DetailData, MyData } from '../../interfaces/my-data-hub';
import { GRADE, HOST_LINK } from '@/enum/common';

Vue.use(Vuex);
Vue.use(VueCookies);

Vue.$cookies.config('1d');

export interface State {
  isLogin: boolean;
  accessToken: string | null;
  _id: string | null;
  userId: string | null;
  userPwd: string | null;
  grade: string | null;
  company: string | null;
  myDataList: MyData[];
  selectedData: Data[];
  addDataModal: boolean;
  toggleScroll: Function;
  firstVisitToDataSet: boolean;
  dataSetDetail: {
    type: string | null;
    title: string | null;
    content: string | null;
    profile: string[];
    header: [];
    data: { [key: string]: string | number }[];
    search: {
      keyword: string | null;
      profile: string[];
      start_date: string | null;
      end_date: string | null;
    };
    collectionName?: string;
    survey_id?: string;
  };
  loading: {
    state: boolean;
    message: string;
  };
}

export default new Vuex.Store<State>({
  plugins: [createPersistedState()],
  state: {
    isLogin: false,
    accessToken: null,
    _id: null,
    userId: null,
    userPwd: null,
    grade: null,
    company: null,
    myDataList: [
      {
        id: '',
        title: '',
        detailData: [],
      },
    ],
    selectedData: [],
    addDataModal: false,
    toggleScroll(toggle: boolean) {
      if (toggle) {
        document.body.style.height = '100vh';
        document.body.style.width = '100vw';
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.height = '100%';
        document.body.style.width = '100%';
        document.body.style.overflow = 'auto';
      }
    },
    firstVisitToDataSet: true,
    dataSetDetail: {
      type: null,
      title: null,
      content: null,
      profile: [],
      header: [],
      data: [],
      search: {
        keyword: null,
        profile: [],
        start_date: null,
        end_date: null,
      },
    },
    loading: {
      state: false,
      message: '',
    },
  },
  mutations: {
    pushMyData(state, data: MyData) {
      state.myDataList.push(data);
    },
    unshiftMyData(state, data: MyData) {
      state.myDataList.unshift(data);
    },
    removeMyData(state, id: string) {
      state.myDataList = state.myDataList.filter(data => data.id !== id);
    },
    updateMyDataTitle(state, payload: { id: string; title: string }) {
      const { id, title } = payload;
      state.myDataList.forEach(data => {
        if (data.id === id) data.title = title;
      });
    },
    updateMyDataDate(state, payload: { id: string; start: Date; end: Date }) {
      const { id, start, end } = payload;
      state.myDataList.forEach(data => {
        if (data.id === id) {
          data.start = start;
          data.end = end;
        }
      });
    },
    resetMyDataList(state) {
      state.myDataList = [];
    },
    unshiftDetailData(state, payload: { id: string; detailData: DetailData }) {
      const { id, detailData } = payload;
      state.myDataList.forEach(data => {
        if (data.id === id) data.detailData.unshift(detailData);
      });
    },
    updateDetailDataTitle(state, payload: { id: string; title: string }) {
      const { id, title } = payload;
      state.myDataList.forEach(myData => {
        myData.detailData.forEach(detailData => {
          if (detailData.id === id) detailData.title = title;
        });
      });
    },
    deleteDuplicateDetailData(state, detailDataId: string) {
      state.myDataList.forEach(data => {
        data.detailData = data.detailData.filter(
          detailData => detailData.id !== detailDataId,
        );
      });
    },
    setSelectedData(state, data: Data[]) {
      state.selectedData = data;
    },
    pushSelectedData(state, data: Data) {
      state.selectedData.push(data);
    },
    deleteDuplicateData(state, dataId: number) {
      state.selectedData = state.selectedData.filter(
        selectedData => selectedData.id !== dataId,
      );
    },
    resetSelectedData(state) {
      state.selectedData = [];
    },
    setAddDataModal(state, check: boolean) {
      state.addDataModal = check;
    },
    login(state, { token, _id, userId, grade, company, visitDataSet }) {
      state._id = _id;
      state.isLogin = true;
      state.accessToken = token;
      state.userId = userId;
      state.grade = grade;
      state.company = company;
      state.firstVisitToDataSet = visitDataSet;
      if (visitDataSet === undefined) {
        state.firstVisitToDataSet = false;
      }
    },
    logout(state) {
      state._id = null;
      state.isLogin = false;
      state.grade = null;
      state.accessToken = null;
      state.userId = null;
      state.userPwd = null;
      state.firstVisitToDataSet = true;
    },
    setNewToken(state, { token }) {
      state.accessToken = token;
    },

    setDataSetDetail(state, payload) {
      state.dataSetDetail = payload;
    },
    setDataSetDetailReset(state) {
      state.dataSetDetail = {
        type: null,
        title: null,
        content: null,
        profile: [],
        header: [],
        data: [],
        search: {
          keyword: null,
          profile: [],
          start_date: null,
          end_date: null,
        },
      };
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setFirstVisitToDataSet(state, result) {
      state.firstVisitToDataSet = result;
    },
  },
  getters: {
    toggleScroll: status => {
      return status.toggleScroll;
    },
    myDataList: status => {
      return status.myDataList;
    },
    addDataModal: status => {
      return status.addDataModal;
    },
    selectedData: status => {
      return status.selectedData;
    },
    isToken: status => {
      return status.accessToken;
    },
    isLogin: status => {
      return status.isLogin;
    },
    getAccessToken: state => {
      return state.accessToken;
    },
    isGrade: status => {
      return status.grade;
    },
    isCompany: status => {
      return status.company;
    },
    getDataSetDetail: state => {
      return state.dataSetDetail;
    },
    getUserId: state => {
      return state.userId;
    },
    getUserObjectId: state => {
      return state._id;
    },
    getLoading: state => {
      return state.loading;
    },
    getFirstVisitToDataSet: state => {
      return state.firstVisitToDataSet;
    },
  },
  actions: {
    replaceUrl({ getters }, url: string) {
      if (getters.getAccessToken)
        return url.replace(/\[TOKEN]/gi, getters.getAccessToken);
      return url;
    },
    tokenUpdate({ commit }, { token }) {
      commit('setNewToken', { token });
    },
    async login({ commit, getters, dispatch }, loginData) {
      try {
        const { redirectUrl, idSave, redirectPage, hostExists } = loginData;
        const { data } = await Vue.axios({
          url: '/user/login',
          method: 'POST',
          data: loginData,
        });
        const { result, token, _id, userId, grade, company, visitDataSet } =
          data;
        if (result && data.token) {
          commit('login', {
            token,
            _id,
            userId,
            grade,
            company,
            visitDataSet,
          });
          if (idSave) {
            Vue.$cookies.set('emailCookie', userId);
            Vue.$cookies.set('saveCookie', idSave);
          } else {
            Vue.$cookies.remove('emailCookie');
            Vue.$cookies.remove('saveCookie');
          }
          if (hostExists) return { result: true };

          if (redirectUrl) {
            const url = await dispatch('replaceUrl', redirectUrl);
            window.location.href = `${url}`;
            return;
          } else if (redirectPage) {
            return await router.push(`${redirectPage}`);
          } else {
            await router.push('/');
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async logout({ commit }) {
      commit('logout');
      Vue.$cookies.remove('Authentication');
    },

    async verify({ commit }) {
      try {
        const { data } = await Vue.axios({
          url: `/user/verify`,
          method: 'GET',
        });
        const { result, token, _id, userId, grade, company, visitDataSet } =
          data;
        if (result && token) {
          commit('login', {
            token,
            _id,
            userId,
            grade,
            company,
            visitDataSet,
          });
        } else if (!result) commit('logout');
        return { result };
      } catch (e) {
        console.log(e);
      }
    },

    async setFirstVisitDataSet({ commit }) {
      try {
        const { data } = await Vue.axios({
          method: 'PUT',
          url: '/data-set/visitDataSet',
        });
        const { result, visitDataSet } = data;
        if (result) {
          commit('setFirstVisitToDataSet', visitDataSet);
        }
      } catch (e) {
        console.error(e);
      }
    },

    async getUserInfo(context, payLoad) {
      const res = await Vue.axios.get('/user/info/' + payLoad.userId);
      return res.data;
    },
  },
  modules: {},
});
