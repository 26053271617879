import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Store from '../store/index';
import { noticePermission } from '@/common';
import {
  getProjectOwnerId,
  getUserId,
  isSameUserAndProject,
} from '@/router/utils';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/404',
    name: 'errorPage',
    meta: {
      header: false,
    },
    component: () => import('@/views/PageNotFound.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/user/login.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/user/logout.vue'),
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import('@/views/user/signUp.vue'),
  },
  {
    path: '/sign-up-success',
    name: 'sign-up-success',
    component: () => import('@/views/user/signSuccess.vue'),
  },
  {
    path: '/mypage',
    name: 'mypage',
    component: () => import('@/views/user/mypage.vue'),
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: () => import('@/views/user/withdraw.vue'),
  },
  {
    path: '/changePwd',
    name: 'changePwd',
    component: () => import('@/views/user/changePwd.vue'),
  },
  {
    path: '/my-history',
    name: 'my-history',
    component: () => import('@/views/user/myHistory/list.vue'),
  },
  {
    path: '/my-history/read/:seq',
    name: 'myHistoryRead',
    component: () => import('@/views/user/myHistory/read.vue'),
  },
  {
    path: '/main/:id?',
    name: 'main',
    meta: {
      userAuth: true,
    },
    component: () => import('@/views/main/index.vue'),
  },
  {
    path: '/',
    // name: 'main',
    redirect: 'main',
    /*meta: {
      userAuth: true,
    },
    component: () => import('@/views/main/index.vue'),*/
  },
  {
    path: '/about-plat-form',
    meta: {
      userAuth: false,
    },
    component: () => import('@/views/main/about/aboutPlatForm.vue'),
  },
  {
    path: '/policy-detail',
    meta: {
      userAuth: false,
    },
    component: () => import('@/views/main/rules/policyDetail.vue'),
    children: [
      {
        path: 'service',
        name: 'service',
        component: () =>
          import('@/views/main/rules/service.vue'),
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: () =>
          import('@/views/main/rules/privacy.vue'),
      },
    ],
  },
  {
    path: '/data-search',
    name: 'data-search',
    meta: {
      userAuth: true,
    },
    component: () => import('@/views/data-search/index.vue'),
  },
  {
    path: '/my-data-hub',
    meta: {
      userAuth: true,
    },
    component: () => import('@/views/data-workshop/my-data-hub/index.vue'),
    children: [
      {
        path: 'my-home',
        name: 'my-home',
        component: () =>
          import('@/views/data-workshop/my-data-hub/my-home/index.vue'),
      },
      {
        path: 'bookmark',
        name: 'bookmark',
        component: () =>
          import('@/views/data-workshop/my-data-hub/bookmark/index.vue'),
      },
    ],
  },
  {
    path: '/dashboard/:id',
    meta: {
      userAuth: true,
    },
    component: () => import('@/views/data-workshop/dashboard/index.vue'),
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/alarm/faq/index.vue'),
  },
  {
    path: '/qna',
    name: 'qna',
    component: () => import('@/views/alarm/qna/index.vue'),
  },
  {
    path: '/qna/write',
    name: 'qnaWrite',
    component: () => import('@/views/alarm/qna/write.vue'),
  },
  {
    path: '/qna/read/:seq',
    name: 'qnaRead',
    component: () => import('@/views/alarm/qna/read.vue'),
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('@/views/alarm/notice/index.vue'),
    children: [
      {
        path: 'new-post',
        meta: {
          noticePermissionAuth: true,
        },
        component: () => import('@/components/notice/write.vue'),
      },
      {
        path: 'edit/:seq',
        meta: {
          noticePermissionAuth: true,
        },
        component: () => import('@/components/notice/write.vue'),
      },
      {
        path: 'postView/:seq?',
        component: () => import('@/components/notice/postView.vue'),
      },
      {
        path: '/',
        component: () => import('@/components/notice/list.vue'),
        /*  children: [
            {
              path: '?',
              component: () => import('@/components/notice/list.vue'),
            },
          ],*/
      },
    ],
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/user/find/index.vue'),
    children: [
      {
        path: 'find_id',
        component: () => import('@/components/user/find/findId.vue'),
      },
      {
        path: 'find_pw',
        component: () => import('@/components/user/find/findPw.vue'),
      },
      {
        path: 'pw_inquiry',
        component: () => import('@/components/user/find/pwInquiry.vue'),
      },
    ],
  },
  {
    path: '/data-set',
    name: 'data-set',
    component: () => import('@/views/data-market/data-set/search.vue'),
  },
  {
    path: '/data-set/:_id',
    name: 'data-set-detail',
    component: () => import('@/views/data-market/data-set/detail.vue'),
  },
  {
    path: '/visualization',
    name: 'visualization',
    component: () => import('@/views/data-workshop/visualization/index.vue'),
    children: [
      {
        path: '',
        component: () =>
          import('@/views/data-workshop/visualization/intro.vue'),
      },
      /* {
        path: 'user-upload',
        name: 'user-upload',
        component: () => import('@/views/data-workshop/visualization/user-upload/index.vue'),
      },*/
      {
        path: 'data-list',
        name: 'data-list',
        component: () =>
          import('@/views/data-workshop/visualization/data-list/index.vue'),
        children: [
          {
            path: 'select',
            name: 'select',
            component: () =>
              import(
                '@/views/data-workshop/visualization/data-list/select.vue'
              ),
          },
          {
            path: 'first-step',
            name: 'first-step',
            component: () =>
              import(
                '@/views/data-workshop/visualization/data-list/first-step.vue'
              ),
          },
          {
            path: 'second-step/:ids',
            name: 'second-step',
            component: () =>
              import(
                '@/views/data-workshop/visualization/data-list/second-step.vue'
              ),
          },
          {
            path: 'third-step/:ids',
            name: 'third-step',
            component: () =>
              import(
                '@/views/data-workshop/visualization/data-list/third-step.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/visualization/user-upload',
    name: 'user-upload',
    component: () =>
      import('@/views/data-workshop/visualization/user-upload.vue'),
  },
  /*{
    path: '/data-workshop/visualization/data-list',
    name: 'visualization-data-list',
    component: () => import('@/views/data-workshop/visualization/data-list.vue'),
  },*/
  {
    path: '/custom-data-service',
    name: 'CustomDataService',
    component: () =>
      import('@/views/data-market/custom-data-service/index.vue'),
  },
  {
    path: '/custom-data-service/request',
    name: 'request',
    component: () =>
      import('@/views/data-market/custom-data-service/request.vue'),
  },
  {
    path: '/custom-data-service/request/:seq',
    name: 'modify',
    component: () =>
      import('@/views/data-market/custom-data-service/modify.vue'),
  },
  {
    path: '/data-visualize/entry/:_id',
    name: 'data-visualize-entry',
    meta: { userAuth: true, visualizationAuth: true },
    component: () => import('@/views/data-workshop/data-visualize/index.vue'),
  },
  {
    path: '/data-visualize/visualizing/:_id',
    name: 'data-visualize-visualizing',
    meta: { userAuth: true, visualizationAuth: true },
    props: true,
    component: () =>
      import('@/views/data-workshop/data-visualize/visualizing/index.vue'),
    children: [
      {
        path: 'missing',
        name: 'missing',
        component: () =>
          import(
            '@/views/data-workshop/data-visualize/data-process/missing.vue'
          ),
      },
      {
        path: 'outlier',
        name: 'outlier',
        component: () =>
          import(
            '@/views/data-workshop/data-visualize/data-process/outlier.vue'
          ),
      },
      // scaling 페이지 작업은 미정(23.11.01)
      {
        path: 'scaling',
        name: 'scaling',
        /*beforeEnter: () => {
          return;
        },*/
        component: () =>
          import('@/views/data-workshop/data-visualize/chart/scaling.vue'),
      },
      {
        path: 'histogram',
        name: 'histogram',
        component: () =>
          import('@/views/data-workshop/data-visualize/chart/histogram.vue'),
      },
      {
        path: 'box-plot',
        name: 'box-plot',
        component: () =>
          import('@/views/data-workshop/data-visualize/chart/box-plot.vue'),
      },
      {
        path: 'xy-graph',
        name: 'xy-graph',
        component: () =>
          import('@/views/data-workshop/data-visualize/chart/xy-graph.vue'),
      },
      {
        path: 'word-cloud',
        name: 'word-cloud',
        component: () =>
          import('@/views/data-workshop/data-visualize/chart/word-cloud.vue'),
      },
    ],
  },
  {
    path: '/fusion-data',
    name: 'fusion-data',
    meta: {
      userAuth: true,
    },
    component: () => import('@/views/data-market/fusion-data/index.vue'),
    children: [],
  },
  {
    path: '/fusion-data/:pid',
    name: 'fusion-data-detail',
    meta: {
      userAuth: true,
    },
    component: () => import('@/views/data-market/fusion-data/detail.vue'),
    children: [],
  },
  {
    path: '/rules/service',
    name: 'rules-service',
    component: () => import('@/views/main/rules/service.vue'),
    children: [],
  },
  {
    path: '/rules/privacy',
    name: 'rules-privacy',
    component: () => import('@/views/main/rules/privacy.vue'),
    children: [],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const { matched, name } = to;
  if (matched.some(({ meta }) => meta.userAuth)) {
    if (!Store.getters.isLogin && name === 'main') {
      return next();
    }

    const { result } = await Store.dispatch('verify');
    if (!result) {
      return next(`/login`);
    }
  }

  if (matched.some(({ meta }) => meta.noticePermissionAuth)) {
    if (!noticePermission.includes(Store.getters.isGrade)) {
      return next('/login');
    }
  }

  if (matched.some(({ meta }) => meta.visualizationAuth)) {
    const projectId = to.params._id;
    if (isSameUserAndProject(getUserId(), await getProjectOwnerId(projectId))) {
      return next();
    } else {
      return next({
        path: '/visualization',
        query: { tl: 'ds' },
        replace: true,
      });
    }
  }

  return next();
});

export default router;
