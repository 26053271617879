
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import noticeModal from '@/components/common/notice-popup.vue';

@Component({
  components: { noticeModal },
})
export default class createVisualization extends Vue {
  private modalContent: string = '';
  private clickType: string = '';

  showModal({ message, type }: { message: string; type: string }) {
    this.modalContent = message;
    this.clickType = type;
    this.$bvModal.show('noticePopup-write');
  }

  mounted() {
    if (this.$route.query['tl']) {
      this.showModal({
        message: '유효하지 않는 ID 입니다.',
        type: 'Alert',
      });
      this.$router.replace('/visualization');
    }
  }
}
