
import { Component, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class carousel extends Vue {
  private get isLogin() {
    return this.$store.getters.isLogin;
  }

  linkTo(login: boolean, url?: string) {
    if (url) {
      if (login) {
        if (!this.isLogin) {
          this.$emit('showAlert', [
            '로그인 후 이용이 가능합니다.',
            'WITHDRAW',
            '/login',
          ]);
          return false;
        }
      }
      this.$router.push(url);
    } else {
      this.$emit('showAlert', ['서비스 준비중입니다.', 'Alert']);
    }
  }
  moveScroll(id: string) {
    if (!this.isLogin) {
      this.$emit('showAlert', [
        '로그인 후 이용이 가능합니다.',
        'WITHDRAW',
        '/login',
      ]);
      return false;
    }
    this.$emit('moveScroll', id);
  }
}
