
import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import { qnaPermission } from '@/common';
import noticeModal from '@/components/common/notice-popup.vue';
import { popupSetInterface } from '../../../interfaces/notice.interface';

export interface REQUESTList {
  user_id: string;
  index: number;
  seq: number;
  type: string;
  title: { name: string; file: boolean };
  user: string;
  date: string;
  state: string;
}

@Component({ components: { noticeModal } })
export default class UserList extends Vue {
  private pending: boolean = true;
  private searchType = [
    { text: '서비스 유형 전체', value: '서비스 유형 전체' },
    { text: '맞춤형 데이터 수집', value: '맞춤형 데이터 수집' },
    { text: '데이터 융합 분석', value: '데이터 융합 분석' },
    { text: '분석 Dashboard 상담', value: '분석 Dashboard 상담' },
    { text: '맞춤형 데이터 컨설팅 상담', value: '맞춤형 데이터 컨설팅 상담' },
    { text: '기타', value: '기타' },
  ];
  private fields = [
    {
      key: 'index',
      label: '번호',
      thStyle: 'width: 5%;',
      thClass: ['th-center', 'text-table-th'],
    },
    {
      key: 'type',
      label: '서비스 유형',
      thStyle: 'width: 20 %;',
      thClass: ['th-center', 'text-table-th'],
    },
    {
      key: 'title',
      label: '제목',
      thStyle: 'width: 40%;',
      thClass: ['th-center', 'text-table-th'],
      tdClass: 'myTitle',
    },
    {
      key: 'date',
      label: '등록일',
      thStyle: 'width: 15%;',
      thClass: ['th-center', 'text-table-th'],
    },
    {
      key: 'state',
      label: '처리현황',
      thStyle: 'width: 10%;',
      thClass: ['th-center', 'text-table-th'],
    },
  ];
  private items: Array<REQUESTList> = [];
  private searchTypeSelected: string = '서비스 유형 전체';
  private keyword: string = '';
  private currentPage: number = 1;
  private perPage: number = 10;
  private totalPage: number = 1;
  private historyCount: number = 0;
  private zeroPostMsg: string = '';
  private numbering: number = 0;
  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface['type'] = 'BACK';
  private strGoOut: string = '';

  linkGen(pageNum: number) {
    const link: string[] = [
      `page=${pageNum}`,
      `searchType=${this.searchTypeSelected}`,
      `keyword=${this.keyword}`,
    ];
    return `?${link.join('&')}`;
  }

  async search(type: string) {
    await this.$router.push(
      `/my-history?&searchType=${this.searchTypeSelected}&keyword=${this.keyword}&page=1`,
    );
    if (type === 'type') {
      this.keyword = '';
    }
    this.currentPage = 1;
    await this.getList(1);
  }

  private get isLogin() {
    return this.$store.getters.isLogin;
  }

  created() {
    const { page, searchType, keyword } = this.$route.query;
    if (page) this.currentPage = Number(page);
    if (searchType) this.searchTypeSelected = String(searchType);
    if (keyword) this.keyword = String(keyword);
  }

  mounted() {
    this.getList(this.currentPage);
  }

  async getList(page: number) {
    try {
      this.pending = true;
      const payload = {
        searchType: this.searchTypeSelected,
        keyword: this.keyword,
        perPage: this.perPage,
        pageNumber: page,
      };
      const result = await this.axios.get(
        `/custom-data-service/list/` + this.$store.state.userId,
        {
          params: payload,
        },
      );
      this.totalPage = result.data.totalPage === 0 ? 1 : result.data.totalPage;
      /* this.items = result.data.returnData;*/
      this.items = result.data.returnData.map(
        (item: {
          date: string;
          requestFiles: boolean;
          index: number;
          seq: number;
          type: string;
          user: string;
          user_id: string;
          title: string;
          state: string;
        }) => {
          return {
            user_id: item.user_id,
            seq: item.seq,
            index: item.index,
            type: item.type,
            title: { name: item.title, file: item.requestFiles },
            user: item.user,
            date: item.date,
            state: item.state,
          };
        },
      );
      this.numbering = result.data.numbering;
      this.historyCount = result.data.historyCount;
      this.pending = false;
      this.zeroPostMsg = !this.keyword.length
        ? '신청하신 내역이 없습니다.'
        : '검색 결과가 없습니다.';
      this.pending = false;
      console.log(0, this.items);
    } catch (e) {
      console.log(e);
    }
  }

  async tableClick(record: any) {
    await this.$router.push(`/my-history/read/${record.seq}`);
  }

  async goto(url: string) {
    if (!this.isLogin) {
      this.strGoOut = '/login';
      await this.showAlert('로그인 후 이용이 가능합니다.', 'WITHDRAW');
    } else {
      await this.$router.push(url);
    }
  }

  goOut() {
    this.$router.push(this.strGoOut);
  }

  async showAlert(
    msg: string,
    type: 'BACK' | 'GO' | 'NO' | 'GoOut' | 'Alert' | 'WITHDRAW',
  ) {
    this.modalSetting({
      title: '',
      content: msg,
      type: type,
    });
    await this.$bvModal.show('noticePopup-userList');
  }

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }
}
