
import { Component, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class DataAnalytics extends Vue {
  async goto() {
    if (!this.isLogin) {
      this.$emit('showAlert', [
        '로그인 후 이용이 가능합니다.',
        'WITHDRAW',
        '/login',
      ]);
      return false;
    }
    await this.$router.push('/fusion-data');
  }

  private get isLogin() {
    return this.$store.getters.isLogin;
  }
}
