
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class CommonModal extends Vue {
  @Prop() private id!: string;
  @Prop() private content!: string; // 본문
  @Prop() private contentFrontImgUrl!: string | null;
  @Prop() private desc!: string | null; // 추가 설명문
  @Prop({
    default: () => {
      return [{ text: '확인', color: 'black' }];
    },
  })
  private btn!: {
    text: string;
    color: string; // white or black
    clickMethod?: () => void;
  }[];

  clickEvt(clickMethod?: () => void) {
    if (clickMethod) {
      return clickMethod();
    }
    this.$bvModal.hide(this.id);
  }
}
