
import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import UserList from '@/components/qna/UserList.vue';
import AdminList from '@/components/qna/adminList.vue';
import { qnaPermission } from '@/common';

@Component({ components: { UserList, AdminList } })
export default class QnaList extends Vue {
  get isPermission() {
    return qnaPermission.includes(this.$store.getters.isGrade);
  }
}
