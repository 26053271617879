
import { Component, Prop, Vue } from 'vue-property-decorator';
interface IOption {
  text: string;
  value: string | number;
  active: boolean;
}
@Component({})
export default class CustomSelectBox extends Vue {
  @Prop({ type: Number, default: 110 }) private width!: number;
  @Prop({ type: Array, required: true }) private selectOption!: IOption[];
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop() value!: string; // v-model
  private isActive: boolean = false;
  private targetOptions: IOption[] = [];

  created() {
    this.targetOptions = JSON.parse(JSON.stringify(this.selectOption));
  }

  get selectValue() {
    return this.selectOption.find(f => f.value === this.value)?.text || '';
  }
  set selectValue(value: string | number) {
    this.$emit('input', value);
  }

  toggleOption() {
    this.isActive = !this.isActive;
  }
  selectedOption(value: string | number) {
    this.selectValue = value;
    this.isActive = false;
    const actives = this.targetOptions.filter(
      f => f.active && f.value !== value,
    );

    for (const o of actives) {
      o.active = false;
    }
    this.$emit('change');
  }
  leave(option: IOption) {
    if (option.value !== this.value) {
      option.active = false;
    }
  }
}
