
import { Component, Vue } from 'vue-property-decorator';
import RulesModal from '@/components/common/rules-modal.vue';
@Component({ components: { RulesModal } })
export default class Footer extends Vue {
  /*private modalMode: 'service' | 'privacy' = 'service';
  showModal(mode: 'service' | 'privacy') {
    this.modalMode = mode;
    this.$bvModal.show('rules-modal');
  }*/

  goto(path: string) {
    this.$router.push(`/policy-detail/${path}`);
  }
}
