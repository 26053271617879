
import { Component, Vue } from 'vue-property-decorator';
import Carousel from '@/components/main/carousel.vue';
@Component({
  components: { Carousel },
})
export default class visualizationIntro extends Vue {
  private guidePages: string[] = [
    'slide1',
    'slide2',
    'slide3',
    'slide4',
    'slide5',
    'slide6',
  ];
  private slide: number = 0;
  private sliding: boolean = false;

  showGuide() {
    this.slide = 0;
    this.$bvModal.show('visualization-guide'),
      setTimeout(() => {
        const carousel = this.$refs.carousel as Vue | undefined;
        if (carousel) {
          const prevControl = carousel.$el.querySelector(
            '.carousel-control-prev',
          );
          if (prevControl) {
            prevControl.classList.add('hide-arrow');
          }
        }
      }, 100);
  }

  closeGuide() {
    this.$bvModal.hide('visualization-guide');
  }

  prev() {
    if (this.slide > 0) {
      this.sliding = true;
      this.slide--;
    }
  }

  next() {
    if (this.slide < this.guidePages.length - 1) {
      this.sliding = true;
      this.slide++;
    }
  }

  onSlideStart(slide: number) {
    this.slide = slide;
    this.sliding = true;
  }
  onSlideEnd(slide: number) {
    this.slide = slide;
    this.sliding = false;

    const carousel = this.$refs.carousel as Vue | undefined;
    if (carousel) {
      const prevControl = carousel.$el.querySelector('.carousel-control-prev');
      const nextControl = carousel.$el.querySelector('.carousel-control-next');

      if (prevControl && nextControl) {
        if (slide === 0) {
          prevControl.classList.add('hide-arrow');
        } else {
          prevControl.classList.remove('hide-arrow');
        }
        if (this.slide === this.guidePages.length - 1) {
          nextControl.classList.add('hide-arrow');
        } else {
          nextControl.classList.remove('hide-arrow');
        }
      }
    }
  }
  getStart() {
    console.log();
  }
}
