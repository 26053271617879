
import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import 'vue-select/dist/vue-select.css';
import SubMenu from '@/components/user/SubMenu.vue';
import historyList from '@/components/user/historyList.vue';
import { popupSetInterface } from '../../../../interfaces/notice.interface';

@Component({
  components: { SubMenu, historyList },
})
export default class List extends Vue {
  $refs!: {
    userId: HTMLFormElement;
    currentPassword: HTMLFormElement;
    changePassword: HTMLFormElement;
    changePasswordCheck: HTMLFormElement;
  };

  private userId: string = this.$store.state.userId;
  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface['type'] = 'BACK';
  private strGoOut: string = '';

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }
  ShowAlert(
    msg: string,
    type: 'BACK' | 'GO' | 'NO' | 'GoOut' | 'Alert' | 'WITHDRAW',
  ) {
    this.modalSetting({
      title: '',
      content: msg,
      type: type,
    });
    this.$bvModal.show('changePwdPopup');
  }

  private get isLogin() {
    return this.$store.getters.isLogin;
  }

  async mounted() {
    if (!this.isLogin) {
      this.strGoOut = '/login';
      this.ShowAlert('로그인 후 이용이 가능합니다.', 'WITHDRAW');
      return false;
    }
  }

  goOut() {
    this.$router.push(this.strGoOut);
  }
}
