import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
/*import '@/assets/css/bootstrap.min.css';*/
import vClickOutside from 'v-click-outside';
import VueAxios from 'vue-axios';
import { ins as axios } from './utils/axios';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import vueCookies from 'vue-cookies';
import Component from 'vue-class-component';

import '@/assets/css/globals.css';
import '@/assets/css/styleguide.css';
import '@/assets/css/variable.css'

import vSelect from 'vue-select';

Vue.component('v-select', vSelect);

Vue.use(BootstrapVue);
Vue.use(vClickOutside);
Vue.use(IconsPlugin);
Vue.use(BootstrapVueIcons);
Vue.use(VueAxios, axios);
Vue.use(VueToast, { position: 'top-right' });
Vue.config.productionTip = false;
Vue.use(vueCookies);
Vue.$cookies.config('7d');

declare global {
  interface Window {
    Kakao: any;
  }
}

Component.registerHooks(['beforeRouteLeave']);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
