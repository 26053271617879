
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IProject } from '@/interface/project';
@Component({})
export default class StepButton extends Vue {
  @Prop() step?: string;
  @Prop() data?: IProject[];
  @Prop() selected?: string[];
  @Prop() nextFlg?: boolean;

  @Emit('next')
  next() {}

  @Emit('create')
  create() {}
}
