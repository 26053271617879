
import { Component, Vue } from 'vue-property-decorator';
import Carousel from '@/components/main/carousel.vue';
import DataSearchCard from '@/components/data-search/dataSearchCard.vue';
import PopularCard from '@/components/data-search/PopularCard.vue';
import SurveyPlatform from '@/components/main/SurveyPlatform.vue';
import NoticeCard from '@/components/main/NoticeCard.vue';
import Search from '@/components/main/Search.vue';
import noticeModal from '@/components/common/notice-popup.vue';
import DataAnalytics from '@/components/main/DataAnalytics.vue';
import Footer from '@/components/main/Footer.vue';
import { popupSetInterface } from '../../../interfaces/notice.interface';

@Component({
  components: {
    DataAnalytics,
    Carousel,
    Search,
    DataSearchCard,
    PopularCard,
    SurveyPlatform,
    NoticeCard,
    noticeModal,
    Footer,
  },
})
export default class Main extends Vue {
  $refs!: {
    dataSearch: DataSearchCard;
  };
  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: string = '';
  private strGoOut: string = '';
  private noticeData: Array<{ label: string; date: string; seq: number }> = [];
  private faqData: Array<{ label: string; date: string; seq: number }> = [];
  async created() {
    if (this.$store.getters.isLogin) await this.mainVerify();
  }

  mounted() {
    const { id } = this.$route.params;
    if (id) {
      document
        .getElementById(id)!
        .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  async mainVerify() {
    try {
      const { data } = await this.axios({
        url: 'user/main-verify',
      });
      const { result } = data;
      if (!result) {
        await this.$store.dispatch('logout');
      }
    } catch (e) {
      console.error(e);
    }
  }

  goOut() {
    this.$router.push(this.strGoOut);
  }

  async showAlert(args: string[]) {
    this.modalSetting({
      title: '',
      content: args[0],
      type: args[1],
    });
    if (args[2]) {
      this.strGoOut = args[2];
    }
    await this.$bvModal.show('noticePopup-main');
  }

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }

  moveScroll(id: string) {
    const section = document.getElementById(id) as HTMLElement;
    section.scrollIntoView({ behavior: 'smooth' });
  }
}
