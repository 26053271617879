
import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { VueEditor } from 'vue2-editor';
import { s3Upload } from '@/utils/s3-upload';
import {
  FileInfoProp,
  popupSetInterface,
} from '../../../../interfaces/notice.interface';
import noticeModal from '@/components/common/notice-popup.vue';

@Component({ components: { VueEditor, noticeModal } })
export default class QnaWrite extends Vue {
  private writer: string = '';
  private type = [
    { text: '가입/회원정보', value: '가입/회원정보' },
    { text: '데이터 탐색', value: '데이터 탐색' },
    { text: '데이터 마켓', value: '데이터 마켓' },
    { text: '데이터 작업소', value: '데이터 작업소' },
    { text: '기타', value: '기타' },
  ];
  private selectedType: string = '가입/회원정보';
  private title: string = '';
  private content: string = '';
  private file = [];
  private fileInfo: FileInfoProp[] = [];
  private publicStatus = [
    { text: 'YES', value: 'YES' },
    { text: 'NO', value: 'NO' },
  ];
  private isPublic: string = 'YES';
  private routeLeaveSkipFlag: boolean = false;
  private pageLeaveMsg: string = `작성한 내용이 모두 삭제됩니다.<br>
    정말로 나가시겠습니까?`;

  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface['type'] = 'BACK';
  private strGoOut: string = '';

  private customToolbar = [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline', 'strike'],
    [
      { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
    ],
    [{ header: 1 }, { header: 2 }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ color: [] }, { background: [] }],
    ['link', 'image', 'video'],
  ];

  private get isLogin() {
    return this.$store.getters.isLogin;
  }

  async beforeRouteLeave(to: Route, from: Route, next: () => void) {
    if (!this.routeLeaveSkipFlag) {
      this.strGoOut = to.path;
      await this.showAlert(this.pageLeaveMsg, 'GoOut', '');
    } else {
      this.pageLeaveMsg = `작성한 내용이 모두 삭제됩니다.<br>
    정말로 나가시겠습니까?`;
      this.titleModal = '';
      next();
    }
  }

  mounted() {
    this.validLogin();
    this.getUserId();
    this.isPublic = 'YES';
  }

  validLogin() {
    if (!this.isLogin) {
      this.strGoOut = '/login';
      this.routeLeaveSkipFlag = true;
      this.showAlert('로그인 후 이용가능합니다.', 'WITHDRAW', '');
      this.routeLeaveSkipFlag = false;
    }
  }

  async getUserId() {
    try {
      if (this.isLogin) {
        const res = await this.axios.get(`/user/${this.$store.state._id}`);
        this.writer = res.data.userId;
      }
    } catch (e) {
      console.log(e);
    }
  }

  ValidateForm() {
    if (this.title.length < 2) {
      this.showAlert('제목을 2글자 이상 입력해 주세요.', 'Alert', '');
      return false;
    }
    if (this.content.replace(/<[^>]*>?/g, '').length < 2) {
      this.showAlert('내용을 2글자 이상 입력해 주세요.', 'Alert', '');
      return false;
    }
    this.showAlert('문의사항을 등록하시겠습니까?', 'GO', '');
  }

  async register() {
    try {
      const uploadResult: true | string = await this.uploadClick();

      const payload = {
        user_id: this.$store.state._id,
        user: this.writer,
        type: this.selectedType,
        title: this.title,
        questionContent: this.content,
        isPublic: this.isPublic,
        questionFiles: this.fileInfo,
      };
      if (uploadResult === true) {
        const res = await this.axios.post('/qna', payload);
        if (res.data.result) {
          this.strGoOut = '/qna';
          this.routeLeaveSkipFlag = true;
          await this.showAlert(res.data.message[0], 'WITHDRAW', '');
        } else {
          await this.showAlert(res.data.message[0], 'Alert', '');
        }
      } else {
        await this.showAlert(uploadResult, 'Alert', '');
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async uploadClick() {
    const result = new s3Upload(this.file, this.fileInfo, 'qna');
    const { bool, message } = result.isUpload(1, '10MB');
    if (!bool) {
      await result.upload();
      this.fileInfo = result.getFiles;
      this.file = [];
      return true;
    } else {
      return message;
    }
  }

  fileDelete(index: number) {
    this.file.splice(index, 1);
  }

  async historyBack() {
    this.routeLeaveSkipFlag = true;
    this.strGoOut = '/qna';
    await this.showAlert(
      '작성한 내용이 모두 삭제됩니다.<br>취소하시겠습니까?',
      'GoOut',
      '문의사항 취소',
    );
    this.routeLeaveSkipFlag = false;
  }

  async showAlert(
    msg: string,
    type: 'BACK' | 'GO' | 'NO' | 'GoOut' | 'Alert' | 'WITHDRAW',
    title: string,
  ) {
    this.modalSetting({
      title: title,
      content: msg,
      type: type,
    });
    await this.$bvModal.show('noticePopup-write');
    this.pageLeaveMsg = `작성한 내용이 모두 삭제됩니다.<br>정말로 나가시겠습니까?`;
  }

  async isOk() {
    await this.register();
    return false;
  }

  goOut() {
    window.location.href = this.strGoOut;
  }

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }
}
