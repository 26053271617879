
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Search extends Vue {
  private keyword: string = '';
  async searchDataSet() {
    console.log(`/data-set?keyword=${this.keyword}`);
    await this.$router.push(`/data-set?keyword=${this.keyword}`);
  }
}
