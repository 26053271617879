
import { Vue, Component, Watch } from 'vue-property-decorator';
import { DataWorkshop } from '@/interface/data-workshop';
import Chart from '@/components/data-workshop/data-visualize/chart.vue';
import DataTable from '@/components/common/data-table.vue';
import SurveyPlatform from '@/components/my-data-hub/SurveyPlatform.vue';
import FloatingCard from '@/components/my-data-hub/floating-card.vue';
import IHeaderProcess = DataWorkshop.IHeaderProcess;
import CustomInputBox, {
  ICustomInputBoxItem,
} from '@/components/data-set/detail/custom-input-box.vue';

@Component({
  components: {
    CustomInputBox,
    SurveyPlatform,
    DataTable,
    Chart,
    FloatingCard,
  },
})
export default class Detail extends Vue {
  private mainProject = {} as DataWorkshop.IJoinProject;
  private mainData = [];
  private pending = false;
  private histories: {
    type: string;
    changes: { key: string; name: string; processType: string }[];
  }[] = [];
  private header: IHeaderProcess[] = [];
  private chartInput: ICustomInputBoxItem[] = [
    {
      key: 'title',
      title: '차트 제목',
      value: this.mainProject.chartTitle || '-',
      placeholder: '차트 제목을 입력해 주세요',
    },
    {
      key: 'keyword',
      title: '주요 키워드',
      value: this.mainProject.keyword || '-',
      placeholder: '주요 키워드를 입력해 주세요',
    },
    {
      key: 'description',
      title: '차트 설명',
      value: this.mainProject.description || '-',
      placeholder: '차트에 대한 설명을 입력해 주세요',
    },
  ];
  private chartProcess = ['outlier', 'missing', 'scaling'];

  async created() {
    this.pending = true;
    await this.load();
    this.pending = false;
  }

  async load() {
    const { id: projectId } = this.$route.params;

    try {
      const getProject = this.axios({
        method: 'GET',
        url: `/data-process/project/${projectId}`,
      });

      const getData = this.axios({
        method: 'GET',
        url: `/data-process/project/data/${projectId}`,
      });

      const [getProjectResult, getDataResult] = await Promise.all([
        getProject,
        getData,
      ]);

      const {
        data: { result: projectResult, project },
      } = getProjectResult;
      const {
        data: { result: dataResult, data },
      } = getDataResult;

      if (projectResult && dataResult) {
        this.mainProject = project;
        this.mainData = data;
        const { header, histories } = project;
        if (header) this.header = header;
        if (histories) {
          this.histories = histories.filter((f: any) =>
            this.chartProcess.includes(f.type),
          );
        }

        //히스토그램 tooltip 범위로 변경
        const chartDivided = this.mainProject.chartData?.chartDivided;
        const plugins = this.mainProject.chartOptions?.plugins;
        if (chartDivided && plugins) {
          plugins.tooltip = {
            callbacks: {
              title: (context: any) => {
                if (context.length < 1) return;
                if (context[0].dataset.data.length > 1) {
                  const index = context[0].dataIndex;
                  const text = chartDivided[index];
                  return `${text[0]} ~ ${text[1]}`;
                }
                return context[0].label;
              },
            },
          };
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  async handleDownloadDataTable() {
    const { id: projectId } = this.$route.params;
    const dataTableComponentRef: any = this.$refs.dataTableComponentRef;
    if (dataTableComponentRef) {
      dataTableComponentRef.download(projectId);
    }
  }

  async handleDownloadDataGuide() {
    const { id: projectId } = this.$route.params;
    const dataTableComponentRef: any = this.$refs.dataTableComponentRef;
    if (dataTableComponentRef) {
      dataTableComponentRef.downloadByGuide(projectId);
    }
  }

  handleDownloadChart() {
    const chartComponentRef: any = this.$refs.chartComponentRef;
    if (chartComponentRef) {
      chartComponentRef.download(this.mainProject.title);
    }
  }

  getHistoriesText(item: {
    type: string;
    changes: { key: string; name: string; processType: string }[];
  }) {
    const { type, changes } = item;
    let typeName,
      changeText = null;

    if (type === 'missing') {
      typeName = '결측치 처리';
    } else if (type === 'outlier') {
      typeName = '이상치 처리';
    } else if (type === 'scaling') {
      typeName = '스케일링';
    }

    if (changes) {
      changeText = changes
        .map(m => {
          const { key, name, processType } = m;
          let text = processType;
          if (processType === '지정값') text += ' 대체';
          if (type === 'scaling') return key;
          return `${key} : ${text}`;
        })
        .join(', ');
    }

    return changeText ? `· ${typeName} (${changeText})` : '';
  }

  @Watch('mainProject')
  watchMainProject() {
    this.chartInput = [
      {
        key: 'chartTitle',
        title: '차트 제목',
        value: this.mainProject.chartTitle || '-',
        placeholder: '차트 제목을 입력해 주세요',
        readonly: true,
      },
      {
        key: 'keyword',
        title: '주요 키워드',
        value: this.mainProject.keyword || '-',
        placeholder: '주요 키워드를 입력해 주세요',
        readonly: true,
      },
      {
        key: 'description',
        title: '차트 설명',
        value: this.mainProject.description || '-',
        placeholder: '차트에 대한 설명을 입력해 주세요',
        readonly: true,
      },
    ];
  }
}
