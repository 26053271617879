var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.selectInputShow)?_c('div',{staticClass:"d-flex flex-row-reverse mb-1 mr-2"},[_c('div',{staticClass:"text-21"},[_vm._v("* 선택 입력")])]):_vm._e(),_c('ul',{staticClass:"p-0"},_vm._l((_vm.list),function(item,idx){return (_vm.list.length)?_c('li',{staticClass:"d-flex flex-wrap w-100"},[_c('div',{staticClass:"cib-head pretendard-semi-bold-san-marino-18px",class:{
          borderTopPeak: idx === 0,
          borderTopLine: idx !== 0,
          borderBottomPeak: _vm.list.length - 1 === idx,
        }},[_vm._v(" "+_vm._s(item.title)+" "),(_vm.selectInputShow)?_c('span',[_vm._v("*")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.value),expression:"item.value"}],staticClass:"cib-body material-themetitleb3",class:{
          borderTopPeak: idx === 0,
          borderTopLine: idx !== 0,
          borderBottomPeak: _vm.list.length - 1 === idx,
          textColor: item.title === '데이터 출처',
        },attrs:{"placeholder":item.placeholder,"readonly":item.readonly},domProps:{"value":(item.value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(item, "value", $event.target.value)}}})]):_vm._e()}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }