
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BasicMenu extends Vue {
  @Prop() title?: string;
  @Prop() url?: string;
  @Prop() options?: { anchor?: boolean; tab?: boolean };
  @Prop() detailMenu?: boolean;

  @Emit('logout')
  private logout() {
    return;
  }
  @Emit('clickMenu')
  private clickMenu(title: string) {
    return title;
  }
}
