
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class KeyFunctions extends Vue {
  /*전체 페이지의 정보*/
  @Prop() private functionPages?: {
    carouselTitle?: string;
    carouselSub?: string;
    page?: number;
    step?: boolean;
    selectImg?: number;
    pageContent?: {
      pageImg?: string;
      pageTitle?: string;
    }[];
  }[];

  /* 캐러셀 내에 노출될 페이지의 index*/
  @Prop() private page?: number;
  private nowPage: number = 0;

  /* step 1 선택 시 이미지 적용시키는 함수  */
  private isSelect1(step?: boolean) {
    if (step) {
      return 'step1-select';
    } else {
      return 'step1';
    }
  }

  /* step 2~4 선택 시 이미지 적용시키는 함수  */
  private isSelectN(step: boolean) {
    if (step) {
      return 'stepN-select';
    } else {
      return 'stepN';
    }
  }

  /* step 5 선택 시 이미지 적용시키는 함수  */
  private isSelectLast(step: boolean) {
    if (step) {
      return 'stepLast-select';
    } else {
      return 'stepLast';
    }
  }

  /* step 바 클릭 시 실행되는 함수 */
  @Emit('selectStep')
  selectStep(selected: number) {
    this.nowPage = selected;
  }

  /* 캐러셀 화살표 이동 시 실행되는 함수 */
  @Emit('onSlideEnd')
  onSlideEnd(page: number) {
    this.nowPage = page;
  }

  /* 이미지 선택 시 실행되는 함수 */
  @Emit('selectImg')
  selectImg(page: number, selectedImg: number) {}
}
