
import { Component, Vue, Watch } from 'vue-property-decorator';
import { VueEditor } from 'vue2-editor';
import {
  FileInfoProp,
  popupSetInterface,
} from '../../../../interfaces/notice.interface';
import noticeModal from '@/components/common/notice-popup.vue';
import { s3Upload } from '@/utils/s3-upload';
import { Route } from 'vue-router';

@Component({ components: { VueEditor, noticeModal } })
export default class Modify extends Vue {
  $refs!: {
    title: HTMLFormElement;
  };
  private routeLeaveSkipFlag: boolean = false;
  private pageLeaveMsg: string = `작성한 내용이 모두 삭제됩니다.
    정말로 나가시겠습니까?`;

  private type = [
    { text: '맞춤형 데이터 수집', value: '맞춤형 데이터 수집' },
    { text: '데이터 융합 분석', value: '데이터 융합 분석' },
    { text: '분석 Dashboard 상담', value: '분석 Dashboard 상담' },
    { text: '맞춤형 데이터 컨설팅 상담', value: '맞춤형 데이터 컨설팅 상담' },
    { text: '기타', value: '기타' },
  ];
  private typeWarning: boolean = false;
  private selectedType: string | null = null;
  private title: string = '';
  private titleWarning: boolean = false;
  private content: string = '';
  private contentWarning: boolean = false;
  private company: string = '';
  private email: string = '';
  private emailWarning: boolean = false;
  private contactName: string = '';
  private phoneNumber: string = '';
  private phoneWarning: boolean = false;
  //파일 추가 배열
  private file = [];
  //업로드할 파일 보여주기 용도
  private tempUploadFile: Array<{ name: string; from: string; index: number }> =
    [];
  //삭제할 파일 저장 배열
  private deleteFile: FileInfoProp[] = [];
  //기존에 업로드 되었던 파일 정보
  private fileInfo: FileInfoProp[] = [];

  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface['type'] = 'BACK';

  private strGoOut: string = '';

  private customToolbar = [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline', 'strike'],
    [
      { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
    ],
    [{ header: 1 }, { header: 2 }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ color: [] }, { background: [] }],
    ['link', 'image', 'video'],
  ];
  get selectColor() {
    return this.selectedType ? '' : 'grey_color';
  }

  mounted() {
    this.getData();
  }

  @Watch('file')
  setFile() {
    if (this.file.length > 1) {
      const temp = this.file;
      this.file = [];
      this.file.push(temp[0]);
    }
    this.tempUploadFile = [];
    this.fileInfo = [];
    this.tempUploadFile = this.file;
  }

  async beforeRouteLeave(to: Route, from: Route, next: () => void) {
    if (!this.routeLeaveSkipFlag) {
      this.strGoOut = to.path;
      await this.showAlert(
        '작성한 내용이 모두 삭제됩니다.<br>정말로 나가시겠습니까?',
        'GoOut',
        '',
      );
    } else {
      this.pageLeaveMsg = `작성한 내용이 모두 삭제됩니다.<br>정말로 나가시겠습니까?`;
      this.titleModal = '';
      next();
    }
  }

  async getData() {
    try {
      const { data } = await this.axios.get(
        `custom-data-service/${this.$route.params.seq}`,
        {
          params: { token: this.$store.getters.isToken },
        },
      );
      if (data.error) {
        this.strGoOut = '/custom-data-service';
        await this.showAlert(data.msg, 'WITHDRAW', '');
      }

      this.selectedType = data.type;
      this.title = data.title;
      this.content = data.requestContent;
      this.company = data.company;
      this.email = data.email;
      this.contactName = data.contactName;
      this.phoneNumber = data.phoneNumber;
      this.fileInfo = data.requestFiles;

      this.setUploadTempFile();
    } catch (e) {
      console.log(e);
    }
  }

  ValidateForm() {
    let flag = false;
    let message = '';
    const email_regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const phoneNumber_regex = /^[0-9]+$/;

    if (this.phoneNumber.length) {
      if (!phoneNumber_regex.test(this.phoneNumber)) {
        message = '연락처는 숫자만 입력해 주세요.';
        this.phoneWarning = true;
        flag = true;
      }
      if (this.phoneNumber.length < 9 || this.phoneNumber.length > 11) {
        message = '연락처를 다시 확인해 주시기 바랍니다.';
        this.phoneWarning = true;
        flag = true;
      }
    }
    if (!email_regex.test(this.email)) {
      message = '이메일 형식이 올바르지 않습니다.<br>다시 확인해 주세요.';
      this.emailWarning = true;
      flag = true;
    }
    if (this.content.replace(/<[^>]*>?/g, '').length < 1) {
      message = '내용을 1글자 이상 입력해 주세요.';
      this.contentWarning = true;
      flag = true;
    } else if (this.content.replace(/<[^>]*>?/g, '').length > 50) {
      message = '내용을 500글자 이하로 입력해 주세요.';
      this.contentWarning = true;
      flag = true;
    }
    if (this.title.length < 1) {
      message = '제목을 1글자 이상 입력해 주세요.';
      this.titleWarning = true;
      flag = true;
    }
    if (this.selectedType === null) {
      message = '서비스 유형을 선택해 주세요.';
      this.typeWarning = true;
      flag = true;
    }
    if (flag) {
      this.showAlert(message, 'Alert', '');
    } else {
      this.showAlert('등록하시겠습니까?', 'GO', '');
    }
  }

  async register() {
    try {
      const payload = {
        user_id: this.$store.state._id,
        user: this.$store.state.userId,
        type: this.selectedType,
        title: this.title,
        requestContent: this.content,
        company: this.company,
        email: this.email,
        contactName: this.contactName,
        phoneNumber: this.phoneNumber,
        requestFiles: this.fileInfo,
      };
      const uploadResult: true | string = await this.fileUpload();

      if (uploadResult === true) {
        const res = await this.axios.put(
          `custom-data-service/${this.$route.params.seq}`,
          payload,
        );
        if (res.data.result) {
          this.strGoOut = '/my-history';
          this.routeLeaveSkipFlag = true;
          await this.showAlert(res.data.message[0], 'MoveTo', '');
        } else {
          await this.showAlert(res.data.message[0], 'Alert', '');
        }
      } else {
        await this.showAlert(uploadResult, 'Alert', '');
      }
    } catch (e) {
      console.log(e);
    }
  }

  fileDelete(file: any) {
    if (file.from === 'fileInfo') {
      this.deleteFile.push(this.fileInfo[0]);
    }
    this.file = [];
    this.fileInfo = [];
  }

  async fileUpload() {
    if (this.file.length) {
      const result = new s3Upload(
        this.file,
        this.fileInfo,
        'custom-data-service',
      );
      const { bool, message } = result.isUpload(1, '10MB');
      try {
        if (!bool) {
          await result.upload();
          this.fileInfo = result.getFiles;
          this.file = [];
          return true;
        } else {
          return message;
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (this.deleteFile.length) this.s3Delete(this.deleteFile);
    return true;
  }
  s3Delete(params: any[] = []) {
    const result = new s3Upload([], params, 'custom-data-service');
    result.delete();
  }

  setUploadTempFile() {
    this.tempUploadFile = this.fileInfo.map((item: FileInfoProp, index) => {
      return {
        name: item.BFile_Nm,
        from: 'fileInfo',
        index,
      };
    });
  }

  //모달 관련 메소드
  async showAlert(
    msg: string,
    type:
      | 'BACK'
      | 'GO'
      | 'NO'
      | 'GoOut'
      | 'Alert'
      | 'WITHDRAW'
      | 'customDataOk'
      | 'MoveTo',
    title: string,
  ) {
    this.modalSetting({
      title: title,
      content: msg,
      type: type,
    });
    await this.$bvModal.show('noticePopup-write');
  }
  isOk() {
    this.register();
    return false;
  }

  moveTo() {
    this.$router.push(this.strGoOut);
  }

  isCancel() {
    history.back();
  }
  goOut(args?: string) {
    if (args) {
      this.strGoOut = args;
    }
    window.location.href = this.strGoOut;
  }
  moveList() {
    this.$router.push(this.strGoOut);
  }

  async cancelModal() {
    this.routeLeaveSkipFlag = true;
    this.modalSetting({
      title: '맞춤형 데이터 서비스 신청 취소',
      content: '작성한 내용이 모두 삭제됩니다.<br>취소하시겠습니까?',
      type: 'BACK',
    });
    await this.$bvModal.show('noticePopup-write');
  }
  async historyBack() {
    this.routeLeaveSkipFlag = true;
    this.strGoOut = '/my-history';
    await this.showAlert(
      '작성한 내용이 모두 삭제됩니다.<br>취소하시겠습니까?',
      'GoOut',
      '맞춤형 데이터 서비스 신청 취소',
    );
    this.routeLeaveSkipFlag = false;
  }
  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }
}
