<template>
  <div class="overlap-group1">
    <div class="page-wrap d-flex flex-row align-items-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center">
            <span class="display-1 d-block">404</span>
            <div class="mb-4 lead">
              The page you are looking for was not found.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center">
      <a href="/">Back to Home</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>

<style scoped>
.overlap-group1 {
  align-items: center;
  background-color: var(--desert-storm2);
  display: flex;
  flex-direction: column;
  padding-top: 200px;
  min-height: 850px;
  width: 100%;
}
</style>
