
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataWorkshop } from '@/interface/data-workshop';

@Component({})
export default class DataTable extends Vue {
  @Prop() header!: DataWorkshop.IHeaderProcess[];
  @Prop() data!: { [key: string]: { value: string | number } }[];
  @Prop() width!: number | string;
  @Prop() height!: number | string;
  @Prop() changeDataIndexes?: { [key: string]: any };
  @Prop() tableType?: string;
  @Prop({ default: false }) boundaryBorderTB?: boolean;
  @Prop({ default: '' }) dataTableTextBolder?: string;
  @Prop() title?: string;
  @Prop() pending!: boolean;

  async download(projectId: string) {
    try {
      const { data } = await this.axios({
        method: 'GET',
        url: `/data-process/download/${projectId}`,
      });
      const { result, Location } = data;
      if (result) {
        window.location.href = Location;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async downloadByGuide(projectId: string) {
    try {
      location.href = `/api/data-process/download/guide/${projectId}`;
    } catch (e) {
      console.error(e);
    }
  }
}
