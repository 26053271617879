
import { Component, Mixins, Vue } from 'vue-property-decorator';
import JoinCreate from '@/components/data-workshop/visualization/joinCreate.vue';
import { IProject } from '@/interface/project';
import { profileOptions } from '@/common';
import { PROJECT_TYPE } from '@/enum/common';
import { popupSetInterface } from '../../../../../interfaces/notice.interface';
import noticeModal from '@/components/common/notice-popup.vue';
import StepButton from '@/components/data-workshop/visualization/stepButton.vue';

@Component({
  components: {
    StepButton,
    noticeModal,
  },
})
export default class FirstStep extends Mixins(JoinCreate) {
  private projectData: IProject[] = [];
  private selelctTitle: string = '';

  private mainModal = false;
  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: string = '';

  private strGoOut: string = '';
  private isSaveSuccess = false;

  private savePending = false;

  private step: string = 'first';

  private userObjectId: string | null = null;
  private typeIcon: string | null = null;
  private checkFlg: boolean = false;
  private dupProfile?: string[] | undefined = [];
  private header: {}[] = [
    {
      key: 'check',
      label: '',
      tdClass: 'tbTxt1',
      thClass: 'thTxt',
      thStyle: { width: '5%' },
    },
    {
      key: 'type',
      label: '데이터 종류',
      tdClass: 'tbTxt1',
      thClass: 'thTxt',
      thStyle: { width: '15%' },
    },
    {
      key: 'title',
      label: '파일 명',
      tdClass: 'tbTxt1',
      thClass: 'thTxt',
      thStyle: { width: '35%' },
    },
    {
      key: 'profile',
      label: '결합 가능한 프로파일 변수',
      tdClass: 'tbTxt1',
      thClass: 'thTxt',
      thStyle: { width: '36%' },
    },
  ];

  goOut() {
    this.$bvModal.hide('popup-noDupData');
    this.$router.push(this.strGoOut);
  }

  closeModal() {
    this.$bvModal.hide('popup-noDupData');
  }

  ShowAlert(
    title: string,
    msg: string,
    type:
      | 'BACK'
      | 'GO'
      | 'NO'
      | 'GoOut'
      | 'Alert'
      | 'WITHDRAW'
      | 'WARN'
      | 'WRITE'
      | 'WRITE2'
      | 'SAVE_INPUT'
      | 'CREATE_DASHBOARD',
  ) {
    this.modalSetting({
      title: title,
      content: msg,
      type: type,
    });
    this.$bvModal.show('popup-noDupData');
  }

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }

  created() {
    const { getUserObjectId } = this.$store.getters;
    if (getUserObjectId) {
      this.userObjectId = getUserObjectId;
      this.load();
    }
  }

  get profileOptions() {
    return profileOptions;
  }

  private imgUrl(type: string) {
    if (type === PROJECT_TYPE.CRAWLING) return 'crawling';
    if (type === PROJECT_TYPE.TRANSACTION) return 'transaction';
    if (type === PROJECT_TYPE.PUBLIC_SEOUL) return 'public_seoul';
    if (type === PROJECT_TYPE.MOBILE) return 'mobile';
    if (type === PROJECT_TYPE.SURVEY) return 'survey';
    if (type === PROJECT_TYPE.USER_UPLOAD) return 'user_upload';
    return null;
  }

  private typeTxt(type: string) {
    if (type === PROJECT_TYPE.CRAWLING) return '뉴스/SNS';
    if (type === PROJECT_TYPE.TRANSACTION) return '결제';
    if (type === PROJECT_TYPE.PUBLIC_SEOUL) return '공공';
    if (type === PROJECT_TYPE.MOBILE) return '모바일';
    if (type === PROJECT_TYPE.SURVEY) return '설문';
    if (type === PROJECT_TYPE.USER_UPLOAD) return '업로딩';
  }

  private isCheck(item: string) {
    return this.selectIds.includes(item);
  }

  private profileInclude(profile: string[], item: string) {
    return profile.includes(item);
  }

  private getEven(index: number) {
    return index % 2 === 0;
  }

  async load() {
    try {
      const { data } = await this.axios({
        method: 'GET',
        url: `/visualization/data-list`,
      });
      const { result, projectAll } = data;
      if (result) {
        this.projectData = projectAll;
      }
    } catch (e) {
      console.error(e);
    }
  }

  duplicateChk(profiles: string[]) {
    const data = this.projectData.filter(
      f => f._id && profiles.includes(f._id),
    );
    const someProfile = data.map(m => m.profile);
    this.dupProfile = someProfile[0].filter(f => someProfile[1].includes(f));
  }

  selectSomeRow(items: string, title: string) {
    this.selelctTitle = title;
    if (this.selectIds.includes(items)) {
      this.selectIds.splice(this.selectIds.indexOf(items), 1);
    } else {
      if (this.selectIds.length == 2) {
        this.selectIds.shift();
      }
      this.selectIds.push(items);
    }
    if (this.selectIds.length === 2) this.duplicateChk(this.selectIds);
  }

  /*  selectSomeRow(items: string, title: string) {
    this.setItem(this.selectIds, items);
    this.setItem(this.selectTitles, title);
    const titleLng = this.selectTitles.length;
    console.log(titleLng);
    this.dashboardTitle = titleLng === 1 ? title : this.selectTitles.join(', ');
  }

  setItem(this.selectIds: string[], items: string) {
    if (this.selectIds.includes(items)) {
      this.selectIds.splice(this.selectIds.indexOf(items), 1);
    } else {
      if (this.selectIds.length == 2) {
        this.selectIds.shift();
      }
      this.selectIds.push(items);
    }
    if (this.selectIds.length === 2) this.duplicateChk(this.selectIds);
  }*/

  async next() {
    if (this.projectData.length === 0) {
      await this.$router.push('/data-set');
      return;
    }
    const selectCount = this.selectIds.length;
    if (selectCount === 0) {
      this.ShowAlert('', '데이터를 선택해주시기 바랍니다.', 'Alert');
    } else if (selectCount === 1) {
      this.dashboardTitle = this.selelctTitle;
      this.ShowAlert(
        '대시보드 만들기',
        `신규 대시보드로 템플릿이 추가됩니다.`,
        'CREATE_DASHBOARD',
      );
    } else {
      if (this.dupProfile?.length === 0) {
        this.ShowAlert(
          '결합 가능한 프로파일 변수가 없습니다',
          `선택하신 데이터들의 결합 가능한 변수가 없습니다.<br />데이터 결합을 진행할 수 없으니 <br />데이터를 다시 선택해 주시기 바랍니다.`,
          'WARN',
        );
      } else {
        await this.$router.push(
          `/visualization/data-list/second-step/${this.selectIds}`,
        );
      }
    }
  }

  async save() {
    try {
      if (!this.dashboardTitle.trim()) {
        this.dashboardTitle = this.selelctTitle;
        return this.ShowAlert('', '데이터 타이틀을 입력해주세요.', 'Alert');
      }
      this.$bvModal.hide('popup-noDupData');
      await this.createJoinData();
    } catch (e) {
      console.error(e);
    }
  }
}
