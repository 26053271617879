
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VariableTable from '@/components/data-workshop/data-visualize/variable-table.vue';
import Chart from '@/components/data-workshop/data-visualize/chart.vue';
import { ChartData } from 'chart.js';
import CustomInput from '@/components/common/custom-input.vue';
import { DataWorkshop } from '@/interface/data-workshop';
import CustomInputBox, {
  ICustomInputBoxItem,
} from '@/components/data-set/detail/custom-input-box.vue';
import SaveModalTemplate from '@/components/data-workshop/data-visualize/save-modal-template.vue';
import CustomModal from '@/components/common/custom-modal.vue';
import NoticeModal from '@/components/common/notice-popup.vue';
import Tools from '@/components/data-workshop/data-visualize/tools.vue';

@Component({
  components: {
    Tools,
    NoticeModal,
    CustomModal,
    SaveModalTemplate,
    CustomInputBox,
    CustomInput,
    VariableTable,
    Chart,
  },
})
export default class BoxPlot extends Vue {
  @Prop() joinProject!: DataWorkshop.IJoinProject;
  @Prop() variablesTablePending!: boolean;

  $refs!: {
    chartRef: any;
  };

  // 차트 마다 다를 수 있는 데이터 상태값
  private checkBoxInfo = [
    { name: 'X', type: 'x', validTypeList: ['factor', 'character'] },
    { name: 'Y', type: 'y', validTypeList: ['integer', 'numeric'] },
  ];
  private chartOptions = {
    categoryPercentage: 0.65,
    barPercentage: 0.65,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  // 차트 마다 공통된 데이터 상태값
  private selectedVariables: { [key: string]: string }[] = [];
  private chartData: ChartData | null = null;
  private isSaveSuccess = false;
  private chartPending = false; // 차트 부분만
  private chartContainerShow = false; // 차트 밖 (타이틀 및 인풋, 저장하기 버튼)
  private savePending = false;
  private isDrawn = false;
  private chartInput: ICustomInputBoxItem[] = [
    {
      key: 'chartTitle',
      title: '차트 제목',
      value: this.joinProject.chartTitle || '',
      placeholder: '차트 제목을 입력해 주세요',
    },
    {
      key: 'keyword',
      title: '주요 키워드',
      value: this.joinProject.keyword || '',
      placeholder: '주요 키워드를 입력해 주세요',
    },
    {
      key: 'description',
      title: '차트 설명',
      value: this.joinProject.description || '',
      placeholder: '차트에 대한 설명을 입력해 주세요',
    },
  ];
  private clickType = '';
  private modalContent = '';
  private colorList: string[] = [];

  get chartType() {
    return this.$route.name || '';
  }

  get projectId() {
    return this.$route.params._id;
  }

  @Watch('joinProject')
  watchJoinProject() {
    this.chartInput = [
      {
        key: 'chartTitle',
        title: '차트 제목',
        value: this.joinProject.chartTitle || '',
        placeholder: '차트 제목을 입력해 주세요',
      },
      {
        key: 'keyword',
        title: '주요 키워드',
        value: this.joinProject.keyword || '',
        placeholder: '주요 키워드를 입력해 주세요',
      },
      {
        key: 'description',
        title: '차트 설명',
        value: this.joinProject.description || '',
        placeholder: '차트에 대한 설명을 입력해 주세요',
      },
    ];
  }

  handleChangeColor(idx: number, color: string) {
    this.$nextTick(() => {
      this.$refs.chartRef.changeColor(idx, color, this.$route.name);
    });
  }

  // 체크값 x, y 하나만 가능 (차트마다 다름)
  checkValidationOfVariable(
    selectedVariables: { [key: string]: string }[],
    type = 'change',
  ) {
    let nx = 0;
    let ny = 0;
    if (selectedVariables.length > 0) {
      selectedVariables.forEach((v: any) => {
        if (v.x) nx++;
        if (v.y) ny++;
      });
      if (nx > 1) {
        const idx = selectedVariables.findIndex(t => t.hasOwnProperty('x'));
        selectedVariables.splice(idx, 1);
        return false;
      } else if (ny > 1) {
        const idx = selectedVariables.findIndex(t => t.hasOwnProperty('y'));
        selectedVariables.splice(idx, 1);
        return false;
      } else if (ny === 0 && type === 'submit') {
        this.showModal({ message: 'y 변수를 선택해 주세요.', type: 'Alert' });
        return false;
      }
    } else if (type === 'submit') {
      this.showModal({ message: '변수를 선택해 주세요.', type: 'Alert' });
      return false;
    }
    return true;
  }

  // 차트마다 결과 처리가 다를 수 있음
  async loadChartData(selectedVariables: { [key: string]: string }[]) {
    if (!this.checkValidationOfVariable(selectedVariables, 'submit')) {
      return;
    }

    this.chartContainerShow = true;
    this.chartPending = true;

    try {
      const {
        data,
      }: {
        data: {
          result: boolean;
          formatted: any;
        };
      } = await this.axios({
        method: 'GET',
        url: `data-visualize/${this.projectId}/${this.chartType}`,
        params: { variables: selectedVariables },
      });
      if (data.result) {
        this.chartData = data.formatted;
        this.isDrawn = true;

        if (data.formatted && data.formatted.datasets[0]) {
          const tmp: any = [];
          data.formatted.datasets[0].data.forEach((d: number, i: number) => {
            if (d) tmp.push(data.formatted?.datasets[0].backgroundColor[i]);
          });
          this.colorList = tmp;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.chartPending = false;
    }
  }

  showModal({ message, type }: { message: string; type: string }) {
    this.modalContent = message;
    this.clickType = type;
    this.$bvModal.show('missing-rate');
  }

  /*  showSaveModal(){
    this.$bvModal.show('save-modal')
    this.isSaveSuccess = false;
  }*/

  findKey(
    arr: ({ key: string; value: string } | ICustomInputBoxItem)[],
    key: string,
  ) {
    const cur = arr.find(v => v.key === key);
    if (cur) return cur;
    else return { value: '' };
  }

  async showSaveModal() {
    this.isSaveSuccess = false;
    try {
      /* if (!this.joinProject.title.trim()) {
        return this.showModal({
          message: '데이터 타이틀을 입력해주세요.',
          type: 'Alert',
        });
      }*/

      this.savePending = true;

      const { data } = await this.axios({
        method: 'PATCH',
        url: `data-visualize/${this.projectId}/${this.chartType}`,
        data: {
          title: this.joinProject.title,
          chartTitle: this.findKey(this.chartInput, 'chartTitle').value,
          keyword: this.findKey(this.chartInput, 'keyword').value,
          description: this.findKey(this.chartInput, 'description').value,
          chartOptions: this.chartOptions,
          chartData: this.chartData,
        },
      });
      const { result } = data;
      if (result) {
        this.isSaveSuccess = true;
        this.savePending = false;
        this.$emit('loadJoinProject');
        this.$bvModal.hide('save-modal');
        this.showModal({
          message: '작업한 내용이 저장되었습니다.',
          type: 'Alert',
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
  moveMyDataLabPage() {
    if (!this.isSaveSuccess) {
      return this.showModal({
        message:
          '변경된 작업 내용이 존재합니다.</br>저장하지 않고 다음으로 넘어가시겠습니까?',
        type: 'WRITE',
      });
    }
    this.goOut();
  }

  goOut() {
    this.$router.push(`/dashboard/${this.projectId}`);
  }
}
