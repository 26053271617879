
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface ICustomNavItem {
  title: string;
  key: string;
  active: boolean;
  cb: any;
}

@Component({})
export default class CustomNav extends Vue {
  @Prop() list!: ICustomNavItem[];
}

