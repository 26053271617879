
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class FusionData extends Vue {
  private searchType = [
    { text: '전체', value: '전체' },
    { text: '제목', value: '제목' },
    { text: '내용', value: '내용' },
  ];
  private searchTypeSelected: string = '전체';

  private cardContents = [
    {
      img: require('@/assets/img/fusion-data/img1.png'),
      title: `전통시장 상권 변화와 대형 유통 채널</br>점포 매출액 데이터`,
      sub: ['전통시장 데이터', '대형 유통 채널 점포 매출액 데이터'],
      projectId: '6531d290cc9c01ae28d0cc7f',
    },
    {
      img: require('@/assets/img/fusion-data/img2.svg'),
      title: `크롤링 데이터에 따른 모바일 행동<br />데이터 변화 추적 데이터`,
      sub: ['전통시장 데이터', '대형 유통 채널 점포 매출액 데이터'],
      projectId: '654443fbcc9c01ae28d728e2',
    },
    {
      img: require('@/assets/img/fusion-data/img3.svg'),
      title: `서울시 주중주말 출퇴근 시간대 별<br />연령별평균 유동인구 현황`,
      sub: ['전통시장 데이터', '대형 유통 채널 점포 매출액 데이터'],
      projectId: '65444444cc9c01ae28d7b559',
    },
  ];
  mounted() {
    window.scrollTo(0, 0);
  }
  //로컬로 돌릴땐 projectId 653f4483325f4782df357791

  goto(projectId: number) {
    this.$router.push(`/fusion-data/${projectId}`);
  }
}
