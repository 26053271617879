
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ProfileBlock extends Vue {
  @Prop() private value!: string; // v-model
  @Prop() private profileOption!: { value: string; text: string };
  @Prop() private disabled!: boolean;

  get modelValue() {
    return this.value;
  }

  @Emit()
  profileClick(value: string) {}
}
