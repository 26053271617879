
import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import SignUpModal from '@/components/user/SignUpModal.vue';
import PhoneAuthModal from '@/components/user/PhoneAuthModal.vue';
import 'vue-select/dist/vue-select.css';
import openIndicator from '@/assets/icon/openIndicator.vue';
import customInput from '@/components/common/custom-input.vue';
import noticeModal from '@/components/common/notice-popup.vue';
import { popupSetInterface } from '../../../interfaces/notice.interface';
import RulesModal from '@/components/common/rules-modal.vue';

@Component({
  components: {
    customInput,
    PhoneAuthModal,
    SignUpModal,
    noticeModal,
    RulesModal,
  },
})
export default class signUp extends Vue {
  $refs!: {
    userId: HTMLFormElement;
    password: HTMLFormElement;
    passwordCheck: HTMLFormElement;
    userName: HTMLFormElement;
    number: HTMLFormElement;
    email: HTMLFormElement;
    phone: HTMLFormElement;
    modal: HTMLFormElement;
  };

  private pending = false;

  private userInfo: {
    userId: string;
    password: string;
    userName: string;
    phone: number | null;
    phoneNumber: string;
    email: string;
    company: string;
    selectedCat1: number | null;
    selectedCat2: number | null;
    sectors: string | null;
    hubTerms: boolean;
    privacyAgreement: boolean;
  } = {
    userId: '',
    password: '',
    userName: '',
    phone: null,
    phoneNumber: '',
    email: '',
    company: '',
    selectedCat1: null,
    selectedCat2: null,
    sectors: '',
    hubTerms: false,
    privacyAgreement: false,
  };

  private agreements: { value: string; text: string }[] = [
    { value: 'hubTerms', text: '통합 데이터 허브 이용약관' },
    { value: 'privacyAgreement', text: '개인정보 수집 및 처리방침' },
  ];

  private sectors: Array<{ value: string; text: string }> = [];
  private userIdValidFlag: boolean | null = null;
  private userIdValidMessage: string = '';
  private emailValidFlag: boolean | null = null;
  private companyFlag: boolean | null = null;
  private passwordValidFlag: boolean | null = null;
  private passwordCheckValidFlag: boolean | null = null;
  private nameValidFlag: boolean | null = null;
  private cat1ValidFlag: boolean | null = null;
  private cat2ValidFlag: boolean | null = null;
  private cat1RqrFlag: boolean = true;
  private cat2RqrFlag: boolean = true;
  private cat1FcsFlag: boolean = true;
  private cat2FcsFlag: boolean = true;
  private allAgreementFlag: boolean = true;
  private passwordCheck: string = '';
  private numberValid = false;
  private phoneDialog = false;
  private modalTitle = '';
  private modalMsg = '';
  private OpenIndicator = openIndicator;
  private allAgreement = false;
  private msg1 = '테스트1';
  private msg2 = '테스트2';
  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface['type'] = 'BACK';
  private strGoOut: string = '';
  private rulesModalMode: 'service' | 'privacy' = 'service';

  async showAlert(
    msg: string,
    type: 'BACK' | 'GO' | 'NO' | 'GoOut' | 'Alert' | 'WITHDRAW',
  ) {
    this.modalSetting({
      title: '',
      content: msg,
      type: type,
    });
    await this.$bvModal.show('agreementPopup');
  }
  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }

  private Cat1: Array<{
    value: number | null;
    text: string;
    disabled?: boolean;
    hidden?: boolean;
  }> = [
    {
      value: null,
      text: '기업 분류를 선택해 주세요.',
      disabled: true,
      hidden: true,
    },
  ];

  private Cat2: Array<{
    value: number | null;
    text: string;
    disabled?: boolean;
    hidden?: boolean;
  }> = [
    {
      value: null,
      text: '기업 구분을 선택해 주세요.',
      disabled: true,
      hidden: true,
    },
  ];

  created() {
    this.getSelectOptions();
  }

  /*get companyFlag() {
    if (this.userInfo.company !== null) {
      return (
        this.userInfo.company.length > 2 && this.userInfo.company.length < 51
      );
    }
    return null;
  }*/

  private phoneOnlyNumber(e: any) {
    const regex = /[^0-9]/g;
    let value = e.target.value;
    if (regex.test(value)) {
      value = value.replace(regex, '');
      this.userInfo.phoneNumber = value;
    }
  }

  private dropdownShouldOpen(VueSelect: any) {
    if (VueSelect.search.length === 0) return false;
    if (this.userInfo.sectors === '' || this.userInfo.sectors === null) {
      return VueSelect.open;
    }
    if (this.userInfo.sectors !== null && this.userInfo.sectors !== '') {
      return VueSelect.open;
    }
  }

  private selectAgree() {
    const { hubTerms, privacyAgreement } = this.userInfo;
    console.log(1, hubTerms);
    console.log(2, privacyAgreement);
    this.allAgreement = hubTerms && privacyAgreement;
  }

  private selectAllAgree() {
    this.userInfo.hubTerms = this.allAgreement;
    this.userInfo.privacyAgreement = this.allAgreement;
  }

  async getSelectOptions() {
    try {
      const { data } = await this.axios({
        url: '/user/select-option',
        method: 'get',
      });
      const { result, corporation, company, industry } = data;
      if (result) {
        this.Cat1 = this.Cat1.concat(corporation);
        this.Cat2 = this.Cat2.concat(company);
        this.sectors = industry;
      }
    } catch (e) {
      console.error(e);
    }
  }

  private cat1Vaild() {
    if (this.userInfo.selectedCat1 === null) {
      this.cat1RqrFlag = false;
    } else {
      this.cat1RqrFlag = true;
    }
  }

  private cat2Vaild() {
    if (this.userInfo.selectedCat2 === null) {
      this.cat2RqrFlag = false;
    } else {
      this.cat2RqrFlag = true;
    }
  }

  private cat1Init() {
    if (!this.userInfo.selectedCat1) {
      this.userInfo.selectedCat1 = null;
    }
    if (this.userInfo.selectedCat1 === null) {
      this.cat1RqrFlag = false;
      this.cat1FcsFlag = true;
    } else {
      this.cat1RqrFlag = true;
      this.cat1FcsFlag = false;
    }
  }

  private cat2Init() {
    if (!this.userInfo.selectedCat2) {
      this.userInfo.selectedCat2 = null;
    }
    if (this.userInfo.selectedCat2 === null) {
      this.cat2RqrFlag = false;
      this.cat2FcsFlag = true;
    } else {
      this.cat2RqrFlag = true;
      this.cat2FcsFlag = false;
    }
  }

  async userIdValid() {
    try {
      let flag = true;
      const reg = /^[a-z]+[a-z0-9]{5,19}$/g;
      if (this.userInfo.userId === '' || !reg.test(this.userInfo.userId)) {
        this.userIdValidMessage =
          '6~20자의 영문 소문자(숫자 조합 가능) 형식으로 입력해 주세요.';
        flag = false;
      }

      if (flag) {
        const { data } = await this.axios({
          url: `/user/exists/${this.userInfo.userId}`,
        });
        const { result, message } = data;
        this.userIdValidMessage = message;
        flag = !result;
      }

      this.userIdValidFlag = flag;
    } catch (e) {
      console.error(e);
    }
  }

  passwordValid() {
    const regex =
      /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/;
    this.passwordValidFlag = regex.test(this.userInfo.password);
    if (this.userInfo.password === '' && this.passwordCheck !== '') {
      return (this.passwordCheckValidFlag = false);
    }
    if (this.passwordCheckValidFlag !== null) this.passwordCheckValid();
  }

  passwordCheckValid() {
    if (this.userInfo.password === '' && this.passwordCheck === '') return;
    if (this.passwordCheck === '') {
      return (this.passwordCheckValidFlag = null);
    }
    this.passwordCheckValidFlag = this.userInfo.password === this.passwordCheck;
  }

  emailCheckValid() {
    if (this.userInfo.email === '') {
      this.emailValidFlag = null;
      return;
    }
    const regex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6})*$/;
    this.emailValidFlag = regex.test(this.userInfo.email);
  }

  nameCheckValid() {
    if (this.userInfo.userName === '') {
      this.nameValidFlag = null;
      return;
    }
    const regex = /^[가-힣a-zA-Z]+$/;
    this.nameValidFlag = regex.test(this.userInfo.userName);
  }

  companyCheckValid() {
    if (this.userInfo.company === '') {
      this.companyFlag = null;
      return;
    }

    const regex = /[가-힣|a-z|A-Z]/;
    this.companyFlag = regex.test(this.userInfo.company);
  }

  cancel() {
    this.$bvModal.hide('modal-phone');
    this.numberValid = false;
    return;
  }

  async ok() {
    try {
      const result = await this.axios.get(
        '/sms?userphonenumber=' +
          this.userInfo.phoneNumber +
          '&code=' +
          this.$refs.phone.code,
      );

      if (result.data) {
        this.showAlert('휴대폰번호 인증이 완료되었습니다.', 'Alert');
        this.$refs.phone.code = '';
        this.authModalClose();
      } else {
        this.showAlert('인증코드를 다시 확인해 주세요.', 'Alert');
      }
    } catch (e) {
      return false;
    }
  }

  async openModal(aggrement: string) {
    if (aggrement == 'hubTerms') {
      this.rulesModalMode = 'service';
      this.$bvModal.show('rules-modal');
      return false;
    } else {
      this.rulesModalMode = 'privacy';
      this.$bvModal.show('rules-modal');
      return false;
    }
    await this.$bvModal.show('agreementPopup');
  }

  async reSendCode() {
    const randomNumber: string = Math.floor(Math.random() * 1000000)
      .toString()
      .padStart(6, '0');

    try {
      const res = await this.axios.post('/sms', {
        title: '발송 제목',
        message: '본인확인 인증번호(' + randomNumber + ')를 입력해 주세요.',
        randomNumber: randomNumber,
        receiver: [
          {
            mobile: this.userInfo.phoneNumber,
          },
        ],
        isAuth: true,
      });

      if (!res.data.result) {
        this.$toast.open({
          message: '발송 실패 ERROR CODE : ' + res.data.status,
          type: 'error',
          duration: 5000,
        });
        return false;
      }
      this.showAlert('재전송 되었습니다.', 'Alert');
    } catch (e) {
      console.log(e);
    }
  }

  async PhoneNumberAuth() {
    if (this.numberValid) {
      this.userInfo.phoneNumber = '';
      this.numberValid = false;
      return;
    }

    const userPhoneNumber = this.userInfo.phoneNumber;
    if (userPhoneNumber.length !== 11) {
      this.showAlert(
        '잘못된 휴대폰 번호입니다.' + '</br>' + '확인 후 다시시도해주세요.',
        'Alert',
      );
      this.$refs.number.focus();
      return false;
    }

    if (!confirm(`${userPhoneNumber}로 인증번호를 발송하시겠습니까?`)) return;

    try {
      const { data } = await this.axios({
        url: '/user/check-phone-number',
        method: 'get',
        params: { userPhoneNumber },
      });

      if (!data.result) {
        await this.showAlert(data.msg, 'Alert');
        this.numberValid = false;
        return false;
      }
      this.phoneDialog = true;
      this.modalMsg = userPhoneNumber;
      this.$bvModal.show('modal-phone');
      await this.sendSMS(userPhoneNumber);
    } catch (err) {
      console.error(err);
    }
  }

  async sendSMS(phone: string) {
    //인증 문자 발송
    const randomNumber: string = Math.floor(Math.random() * 1000000)
      .toString()
      .padStart(6, '0');

    try {
      const res = await this.axios.post('/sms', {
        title: '발송 제목',
        message: '본인확인 인증번호(' + randomNumber + ')를 입력해 주세요.',
        randomNumber: randomNumber,
        receiver: [
          {
            mobile: phone,
          },
        ],
        isAuth: true,
      });

      if (!res.data.result) {
        this.hidePhoneDialog();
        await this.showAlert(res.data.msg, 'Alert');
        this.$toast.open({
          message: '발송 실패 ERROR CODE : ' + res.data.status,
          type: 'error',
          duration: 5000,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  hidePhoneDialog() {
    this.phoneDialog = false;
  }

  authModalClose() {
    this.phoneDialog = false;
    this.numberValid = true;
  }

  get formSubmit() {
    return (
      this.userIdValidFlag &&
      this.passwordValidFlag &&
      this.passwordCheckValidFlag &&
      this.numberValid &&
      this.companyFlag &&
      this.userInfo.selectedCat1 !== null &&
      this.userInfo.selectedCat2 !== null &&
      this.allAgreement &&
      (this.userInfo.email ? this.emailValidFlag : true) && // 이메일 입력이 있는 경우 정규식 로직 확인
      !this.pending
    );
  }

  async formValidator() {
    if (!this.formSubmit) {
      if (!this.userInfo.selectedCat1) this.cat1ValidFlag = false;
      if (!this.userInfo.selectedCat2) this.cat2ValidFlag = false;
      if (!this.allAgreement) this.allAgreementFlag = false;
      return this.showAlert('입력하신 정보를 다시 확인해주세요.', 'Alert');
    }
    this.pending = true;
    try {
      const { data } = await this.axios({
        url: '/user/signUp',
        method: 'post',
        data: this.userInfo,
      });

      const { result } = data;
      if (result) {
        await this.$router.push('/sign-up-success');
      } else {
        await this.showAlert(data.msg, 'Alert');
      }
    } catch (err) {
      console.error(err);
    }
    this.pending = false;
  }
}
