
import { Component, Mixins, Vue } from 'vue-property-decorator';
import JoinCreate from '@/components/data-workshop/visualization/joinCreate.vue';
import StepButton from '@/components/data-workshop/visualization/stepButton.vue';
import KeySelect from '@/components/data-set/key-select.vue';
import RowData from '@/components/data-workshop/visualization/row-data.vue';
import CheckProfileData from '@/components/data-set/check-profile-data.vue';
import { popupSetInterface } from '../../../../../interfaces/notice.interface';
import noticeModal from '@/components/common/notice-popup.vue';
@Component({ components: { StepButton, noticeModal } })
export default class ThirdStep extends Mixins(JoinCreate) {
  private selectTitle: string = '';

  created() {
    const { ids } = this.$route.params;
    const { profile, joinProjectId, joinDataTitle } = this.$route.query;
    if (profile) this.profile = profile.toString().split(',');
    if (ids) this.selectIds = ids.split(',');
    if (joinDataTitle && typeof joinDataTitle === 'string')
      this.selectTitle = joinDataTitle;
    if (joinProjectId) this.creatingDataCheck(joinProjectId as string);
  }

  private step: string = 'third';
  private isInner: boolean = false;
  private isLeft: boolean = false;
  private isOuter: boolean = false;

  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: string = '';

  private strGoOut: string = '';

  ShowAlert(
    title: string,
    msg: string,
    type:
      | 'BACK'
      | 'GO'
      | 'NO'
      | 'GoOut'
      | 'Alert'
      | 'WITHDRAW'
      | 'WARN'
      | 'WRITE'
      | 'WRITE2'
      | 'SAVE_INPUT'
      | 'CREATE_DASHBOARD',
  ) {
    this.modalSetting({
      title: title,
      content: msg,
      type: type,
    });
    this.$bvModal.show('popup-selectJoinData');
  }

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }

  private selectJoinType(type: string) {
    if (this.joinType === type) {
      this.joinType = '';
      return;
    }
    this.joinType = type;
  }
  async create() {
    if (this.joinType === '') {
      return this.$emit('showModal', {
        message: '결합 타입을 선택해 주세요. ',
        type: 'Alert',
      });
    } else {
      this.dashboardTitle = this.selectTitle;
      this.ShowAlert(
        '대시보드 만들기',
        `신규 대시보드로 템플릿이 추가됩니다.`,
        'CREATE_DASHBOARD',
      );
    }
  }
  async save() {
    try {
      if (!this.dashboardTitle.trim()) {
        this.dashboardTitle = this.selectTitle;
        return this.ShowAlert('', '데이터 타이틀을 입력해주세요.', 'Alert');
      }
      this.$bvModal.hide('popup-selectJoinData');
      await this.createJoinData();
    } catch (e) {
      console.error(e);
    }
  }
}
