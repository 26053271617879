
import { Prop, Vue, Component, Watch } from 'vue-property-decorator';

@Component({})
export default class PhoneAuthModal extends Vue {
  @Prop() btnContent!: string;
  @Prop() active!: boolean;
  private setTime = 5 * 60;
  private code = '';
  private time = 0;
  private timer: any = 0;
  private flag = false;

  mounted() {
    this.flag = this.active;
  }

  @Watch('flag')
  startTimer() {
    if (this.flag) {
      this.time = this.setTime;
      this.startTime();
    } else {
      this.time = 0;
      clearInterval(this.timer);
    }
  }

  @Watch('active')
  setTest() {
    this.flag = this.active;
  }

  get getTime() {
    return Math.floor(this.time / 60) + '분' + (this.time % 60) + '초';
  }

  startTime() {
    this.timer = setInterval(() => this.countDown(), 1000);
  }

  countDown() {
    if (this.time > 0) {
      this.time--;
    } else if (this.time === 0) {
      this.$toast.open({
        message: '인증기간이 지났습니다.',
        type: 'error',
        duration: 5000,
      });
      this.closeSelf();
    }
  }

  closeSelf() {
    this.code = '';
    this.$emit('hide');
    clearInterval(this.timer);
  }

  resendCode() {
    this.time = this.setTime;
    this.$emit('resendCode');
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }

  /* async ok() {
    try {
      const result = await this.$store.dispatch('codeValidation', {
        phoneNumber: this.btnContent,
        code: this.code,
        type: 'phone',
      });
      if (result) {
        this.$toast.open({
          message: '인증코드를 다시 확인해 주세요.',
          type: 'success',
          duration: 5000,
        });
        this.code = '';
        this.$emit('modalclose', 'phone');
      } else {
        this.$toast.open({
          message: '인증코드를 다시 확인해 주세요.',
          type: 'error',
          duration: 5000,
        });
      }
    } catch (e) {
      return false;
    }
  }*/
}
