
import { Component, Mixins } from 'vue-property-decorator';
import { HOST_LINK } from '@/enum/common';
import Nav, { IBasicMenu, IMainMenu } from '@/components/nav/index.vue';
import mixin from '@/utils/common';
import noticeModal from '@/components/common/notice-popup.vue';
import { popupSetInterface } from '../interfaces/notice.interface';

@Component({
  components: { Nav, noticeModal },
})
export default class App extends Mixins(mixin) {
  /*  기본 options 사용법
   *  options 없이 전달시 기본 라우터
   *  anchor: true -> anchor 태그 사용
   *  tab: true -> 새창에 열림
   */
  //모달 창 사용변수
  private mainModal = false;
  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: string = '';
  private strGoOut: string = '';
  private redirectPage: { param: string; value: string }[] = [];

  // Logo 이미지
  private imgSrc = require('@/assets/img/logo.svg'); // require 로 넘겨주세요.
  //nav ref 변수
  $refs!: {
    nav: Nav;
  };

  // 메인 타이틀
  private mainTitle: IBasicMenu = {
    title: '통합 데이터 허브',
    url: '/main',
    // options:{
    //   anchor: true,
    //   tab: true,
    // }
  };

  /* 메인 메뉴
   *  hover 메뉴는 detailMenu 에 담아서 넘기면 됩니다.
   *  detailMenu 도 options 사용 가능.
   *  detailMenu 는 mainMenu 에서만 가능.
   *  로그인 메뉴 로그인/로그아웃 변경 및 회원가입 메뉴 숨김은 메뉴 이름이 로그인, 회원가입일때만 적용.
   */
  private mainMenu: IMainMenu[] = [
    {
      title: '데이터 탐색',
      detailMenu: [
        {
          title: '뉴스/SNS 트렌드 데이터',
          url: '',
          // options: {
          // anchor: true,
          // tab: true,
          // },
        },
        {
          title: '모바일 행동 데이터',
          url: '',
        },
        {
          title: '공공 데이터',
          url: '',
        },
        {
          title: '결제 데이터',
          url: '',
        },
      ],
      // options: {
      //   anchor: true,
      //   tab: true,
      // }
    },
    {
      title: '데이터 마켓',
      url: '#',
      detailMenu: [
        {
          title: '데이터 셋',
          url: '/data-set',
        },
        {
          title: '융합 데이터',
          url: '',
        },
        {
          title: '맞춤형 데이터 서비스',
          url: '/custom-data-service',
        },
      ],
    },
    {
      title: '데이터 작업소',
      url: '#',
      detailMenu: [
        {
          title: '시각화 만들기',
          url: '',
        },
        {
          title: '마이 데이터 랩',
          url: '',
        },
      ],
    },
    {
      title: '알림',
      url: '#',
      detailMenu: [
        {
          title: '플랫폼 소개',
          url: '/about-plat-form',
        },
        {
          title: '공지사항',
          url: '/notice',
        },
        {
          title: '자주하는 질문',
          url: '/faq',
        },
        {
          title: '문의사항',
          url: '/qna',
        },
      ],
    },
  ];

  /* 서브 메뉴
   * 로그인 메뉴 로그인/로그아웃 변경 및 회원가입 메뉴 숨김은 메뉴 이름이 로그인, 회원가입일 때만 적용.
   */
  private subMenu: IBasicMenu[] = [
    {
      title: '회원가입',
      url: '/sign-up',
      // options: {
      //   anchor: true,
      //   tab: true,
      // }
    },
    {
      title: '로그인',
      url: '/login',
    },
    {
      title: '마이페이지',
      url: '/mypage',
      // options: {
      //   anchor: true,
      //   tab: true,
      // }
    },
    {
      title: '이용안내',
      url: '#',
      // options: {
      //   anchor: true,
      //   tab: true,
      // }
    },
    {
      title: '사이트맵',
      url: '#',
      options: {
        anchor: true,
        tab: false,
      },
    },
  ];

  created() {
    const { state } = this.$store.getters.getLoading;
    if (state) {
      this.$store.commit('setLoading', { state: false, message: '' });
    }
  }

  // 로그인 여부 boolean
  private get isLogin() {
    return this.$store.getters.isLogin;
  }

  // 로그아웃 이벤트
  private logout() {
    this.$store.dispatch('logout');
  }

  // 메뉴 클릭 시 누른 메뉴의 title 값을 파라미터로 받음 (클릭 이벤트로 사용)
  private async clickMenu(title: string) {
    this.redirectPage = [];
    switch (title) {
      case '마이 데이터 랩': {
        const url = '/my-data-hub/my-home';
        if (!this.isLogin) {
          this.strGoOut = '/login';
          this.redirectPage.push({ param: 'redirectPage', value: url });
          return this.ShowAlert('로그인 후 이용이 가능합니다.', 'WITHDRAW');
        } else {
          await this.$router.replace(url);
        }
        break;
      }
      case '시각화 만들기': {
        const url = '/visualization';
        if (!this.isLogin) {
          this.strGoOut = '/login';
          this.redirectPage.push({ param: 'redirectPage', value: url });
          return this.ShowAlert('로그인 후 이용이 가능합니다.', 'WITHDRAW');
        }
        await this.$router.push(url);
        break;
      }
      case '융합 데이터': {
        const url = '/fusion-data';
        if (!this.isLogin) {
          this.strGoOut = '/login';
          this.redirectPage.push({ param: 'redirectPage', value: url });
          await this.ShowAlert('로그인 후 이용이 가능합니다.', 'WITHDRAW');
          return false;
        }
        await this.$router.push(url);
        break;
      }
      case '뉴스/SNS 트렌드 데이터': {
        if (!this.isLogin) {
          this.strGoOut = '/login';
          this.redirectPage.push({
            param: 'hostName',
            value: HOST_LINK.CRAWLING,
          });
          await this.ShowAlert('로그인 후 이용이 가능합니다.', 'WITHDRAW');
          return false;
        }
        await this.redirectUrl({ HOST_NAME: HOST_LINK.CRAWLING });
        break;
      }
      case '모바일 행동 데이터': {
        if (!this.isLogin) {
          this.strGoOut = '/login';
          this.redirectPage.push({ param: 'hostName', value: HOST_LINK.MPD });
          await this.ShowAlert('로그인 후 이용이 가능합니다.', 'WITHDRAW');
          return false;
        }
        await this.redirectUrl({ HOST_NAME: HOST_LINK.MPD });
        break;
      }
      case '공공 데이터': {
        if (!this.isLogin) {
          this.strGoOut = '/login';
          this.redirectPage.push({
            param: 'hostName',
            value: HOST_LINK.PUBLIC,
          });
          await this.ShowAlert('로그인 후 이용이 가능합니다.', 'WITHDRAW');
          return false;
        }
        await this.redirectUrl({ HOST_NAME: HOST_LINK.PUBLIC });
        break;
      }
      case '결제 데이터': {
        if (!this.isLogin) {
          this.strGoOut = '/login';
          this.redirectPage.push({
            param: 'hostName',
            value: HOST_LINK.TRANSACTION,
          });
          await this.ShowAlert('로그인 후 이용이 가능합니다.', 'WITHDRAW');
          return false;
        }
        await this.redirectUrl({ HOST_NAME: HOST_LINK.TRANSACTION });
        break;
      }
      case '사이트맵': {
        this.$refs.nav.hover(true);
      }
    }
  }

  goOut() {
    this.$bvModal.hide('noticePopup-topBar');
    let url = this.strGoOut;
    if (this.redirectPage.length > 0) {
      const params = this.redirectPage
        .map(m => `${m.param}=${m.value}`)
        .join('&');
      url += `?${params}`;
    }
    this.$router.push(url);
  }

  ShowAlert(
    msg: string,
    type: 'BACK' | 'GO' | 'NO' | 'GoOut' | 'Alert' | 'WITHDRAW',
  ) {
    this.modalSetting({
      title: '',
      content: msg,
      type: type,
    });
    this.$bvModal.show('noticePopup-topBar');
  }

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }

  get getNavClass() {
    let className = '';
    if (this.$route.path === '/' || this.$route.path === '/main') {
      className = 'nav-position-home';
    } else {
      className = 'nav-position';
    }
    return className;
  }
}
