
import { Component, Vue } from 'vue-property-decorator';
import { IProject, s3FileUpload } from '@/interface/project';
import noticeModal from '@/components/common/notice-popup.vue';
import CommonModal from '@/components/common/common-modal.vue';
import { BTooltip } from 'bootstrap-vue';

interface defaultHeader {
  type: null;
  key: string;
  name: string;
  value?: { [key: string]: string };
  disabled: boolean;
}
@Component({
  components: { CommonModal, noticeModal },
})
export default class UserUpload2 extends Vue {
  private pending: boolean = false;
  private project: IProject | null = null;
  private projectList: IProject[] = [];
  private header: defaultHeader[] = [];
  private options = [
    { value: null, text: '타입을 선택해 주세요.' },
    { value: 'integer', text: 'integer' },
    { value: 'numeric', text: 'numeric' },
    { value: 'character', text: 'character' },
    { value: 'factor', text: 'factor' },
  ];
  private saveClick: boolean = false;
  private theadNames: string[] = ['no.', '변수명', '타입'];
  private modalContent: string = '';
  private clickType: string = '';
  private fileInputKey: number = 0;
  private headerOpen: boolean = false;
  private savePending: boolean = false;
  private commonModalContent: string = '';
  private commonModalBtns: {
    text: string;
    color: string;
    click: () => void;
  }[] = [];
  private commonModalDesc: string | null = null;
  private guideTooltip: boolean = false;
  private modalConfirmType: string = '';
  private deleteProjectId: string = '';
  private dataUpdating: boolean = false;
  private saveFlag: boolean = false;
  private file: any = null;
  private isDragged = false;

  $refs!: {
    inputFile: HTMLInputElement;
    tooltip: BTooltip;
    [key: string]: any;
    upload: HTMLDivElement;
  };

  created() {
    this.initCommonModal();
    this.load();
  }
  showTooltip() {
    this.guideTooltip = !this.guideTooltip;
    this.$root.$emit(
      `bv::${this.guideTooltip ? 'show' : 'hide'}::tooltip`,
      'tooltip-button-show-event',
    );
  }

  initCommonModal() {
    this.commonModalDesc = null;
    this.commonModalContent =
      '추가로 업로드 하실 데이터가 있으신가요?<br /><br />아래 [파일 추가하기] 버튼을 클릭하시면<br />추가로\n데이터 파일을 업로드 하실 수 있습니다.';
    this.commonModalBtns = [
      { text: '파일 추가하기', color: 'white', click: () => this.closeModal() },
      { text: '다음', color: 'black', click: () => this.nextPage() },
    ];
  }

  async load() {
    try {
      const { data } = await this.axios({
        url: '/visualization/user-upload',
      });
      const { result, project } = data;
      if (result) {
        this.projectList = project;
        for (let i = 0; i < this.projectList.length; i++) {
          this.$refs[`inputTitle${i}`] as HTMLInputElement;
        }
        /*this.$refs.inputTitle = this.projectList.map(
          (_, idx) => (this.$refs as Record<string, any>)[`inputTitle${idx}`],
        ) as HTMLInputElement;*/
      }
    } catch (e) {
      console.error(e);
    }
  }

  async getDetail(_id: string | null) {
    if (_id === this.project?._id) return;
    this.pending = true;
    await new Promise(r => setTimeout(r, 1000));
    if (this.dataUpdating && this.project) {
      const { _id, title } = this.project;
      const find = this.projectList.find(f => _id === f._id);
      if (find) {
        find.title = title;
        find.inputState = undefined;
      }
    }
    try {
      const { data } = await this.axios({
        url: `/visualization/user-upload/${_id}`,
      });

      const { result, project } = data;
      if (result) {
        this.headerOpen = true;
        this.project = project;
        this.header = project.header;
        this.dataUpdating = false;
      }
    } catch (e) {
      console.error(e);
    }
    this.pending = false;
  }
  uploadValidation() {
    if (this.project) {
      const { uploadSave, title, _id } = this.project;
      if ((!uploadSave && this.headerOpen) || this.dataUpdating) {
        let textTitle = title;
        const targetProject = this.projectList.find(f => f._id === _id);
        if (targetProject) textTitle = targetProject.title;
        return this.showModal({
          message: `[${textTitle}] 파일을 먼저 저장해 주세요.`,
          type: 'WITHDRAW',
        });
      }
    }
    if (this.projectList.length > 2) {
      this.fileInputKey++;
      return this.uploadFullModal();
    }
    return true;
  }
  setUploadBtn() {
    const valid = this.uploadValidation();
    if (valid) this.$refs.inputFile.click();
  }
  setExcelFiles(e: any) {
    const { target } = e;
    const { files } = target;
    this.uploadFiles(files);
  }

  async uploadFiles(files: any) {
    if (files.length !== 1) {
      return this.showModal({
        message: '한 개의 파일만 업로드 가능합니다.',
        type: 'WITHDRAW',
      });
    }

    const checkExt = ['xlsx', 'xls'];
    const fileName = files[0].name.split('.')[0];
    const ext = files[0].name.split('.').pop();

    if (checkExt.indexOf(ext) === -1) {
      return this.showModal({
        message: '파일 확장자를 확인해 주시기 바랍니다.',
        type: 'WITHDRAW',
      });
    }

    this.file = files[0];
    const existsFiles = this.projectList.filter(f => {
      if (f.title === fileName) return true;
      return false;
    });

    if (existsFiles.length > 0) {
      this.modalConfirmType = 'overwrite';
      this.modalContent =
        '현재 동일한 파일을 업로드 하셨습니다.\n 파일을 덮어 씌우시겠습니까?';

      this.$bvModal.show('upload-file-check');
      return;
    }

    await this.setFileUpload();
  }

  async setFileUpload(overwrite?: boolean) {
    try {
      let formData = new FormData();
      formData.append('file', this.file);

      let type = 'insert';
      if (overwrite) type = 'update';
      const { data } = await this.axios({
        url: `/visualization/data-upload/${type}`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const { result, project, message } = data;
      if (result) {
        this.headerOpen = true;
        this.header = project.header;
        this.project = project;
        this.file = null;
        await this.load();
      } else {
        return this.showModal({
          message,
          type: 'WITHDRAW',
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  async save() {
    if (this.savePending) return;
    try {
      if (this.project === null) {
        //return this.nextPage();
        this.modalConfirmType = 'nextPage';
        return this.showModal({
          message: `데이터 업로드 하지 않고 다음 페이지로 이동하시겠습니까?`,
          type: 'WRITE',
        });
      }

      const typeNoSelect = this.header.filter(f => f.type === null);
      if (typeNoSelect.length > 0) {
        this.saveClick = true;
        return this.showModal({
          message: `데이터 타입을 선택해주세요.`,
          type: 'WITHDRAW',
        });
      }

      const { _id } = this.project;
      const find = this.projectList.find(f => f._id === _id);
      let projectTitle = null;
      if (find) {
        const { title } = find;
        if (title.trim() === '') {
          return this.showModal({
            message: `파일명을 입력해 주시기 바랍니다`,
            type: 'WITHDRAW',
          });
        }
        projectTitle = find.title;
      }
      const requestData: { header: defaultHeader[]; title: string | null } = {
        header: this.header,
        title: projectTitle,
      };

      this.savePending = true;
      const projectId = this.project._id;
      const { data } = await this.axios({
        method: 'PUT',
        url: `/visualization/user-upload/${projectId}`,
        data: requestData,
      });
      const { result, message } = data;
      if (!result) {
        this.projectList = this.projectList.map((m, index) => {
          if (m._id === projectId) {
            (this.$refs as Record<string, any>)[
              `inputTitle${index}`
            ][0].focus();
          }
          return {
            ...m,
            inputState: m._id === projectId ? false : undefined,
          };
        });
        return this.showModal({ message, type: 'WITHDRAW' });
      }

      this.showModal({
        message: `데이터 정보가 정상적으로 업로드 되었습니다.`,
        type: 'WITHDRAW',
      });
      this.dataUpdating = false;
      this.project.uploadSave = true;
      this.fileInputKey++;
      await this.load();
    } catch (e) {
      console.error(e);
    } finally {
      this.savePending = false;
    }
  }

  reset() {
    this.project = null;
    this.header = [];
    this.headerOpen = false;
    this.dataUpdating = false;
    this.saveFlag = false;
    this.fileInputKey++;
  }

  uploadDelClick(projectId: string | null, title: string) {
    if (projectId) {
      this.deleteProjectId = projectId;
      this.modalConfirmType = 'uploadDelete';
      this.showModal({
        message: `${title} 파일을 정말로 삭제 하시겠습니까?`,
        type: 'WRITE',
      });
    } else {
      this.showModal({
        message: `프로젝트 ID 정보가 없습니다. 관리자에게 문의하여 주세요.`,
        type: 'WRITE',
      });
    }
  }
  async uploadDel(_id: string) {
    try {
      const { data } = await this.axios({
        method: 'DELETE',
        url: `/visualization/user-upload/${_id}`,
      });
      const { result } = data;
      if (result) {
        await this.load();
        if (_id === this.project?._id) {
          this.reset();
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  fnGetFileSize(filesize: number) {
    const text = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
    const e = Math.floor(Math.log(filesize) / Math.log(1024));
    return (filesize / Math.pow(1024, e)).toFixed(2) + ' ' + text[e];
  }

  prevBtn() {
    if (this.dataUpdating) {
      this.modalConfirmType = 'prev';
      return this.showModal({
        message: `입력하신 내용이 모두 초기화 됩니다.<br />정말로 이전 페이지로 이동 하시겠습니까?`,
        type: 'WRITE',
      });
    }
    this.prev();
  }

  prev() {
    this.$router.push('/visualization/data-list/select');
  }

  next() {
    const typeNoSelect = this.header.filter(f => f.type === null);
    if (typeNoSelect.length > 0) {
      return this.showModal({
        message: `데이터 타입을 선택해주세요.`,
        type: 'WITHDRAW',
      });
    }

    if (this.project && !this.project.uploadSave) {
      return this.showModal({
        message: `데이터를 저장하신 후 다음페이지로 이동하세요.`,
        type: 'WITHDRAW',
      });
    }

    let message =
      '추가로 업로드 하실 데이터가 있으신가요?<br />' +
      '아래 [파일 추가하기] 버튼을 클릭하시면 추가로 데이터 파일을 업로드 하실 수 있습니다.';
    let desc =
      '추가 업로드 하실 파일이 없으시다면 [다음]버튼을 클릭해 주시기 바랍니다.';
    let btn = [
      { text: '파일 추가하기', color: 'white', click: () => this.closeModal() },
      { text: '다음', color: 'black', click: () => this.nextPage() },
    ];

    if (this.dataUpdating) {
      message =
        '이 페이지를 벗어나면 마지막 저장 후 수정된 내용은 저장되지 않습니다.';
      desc = '';
      btn = [
        {
          text: '취소',
          color: 'white',
          click: () => this.closeModal(),
        },
        { text: '다음', color: 'black', click: () => this.nextPage() },
      ];
      this.modalConfirmType = 'nextPage';
      return this.showModal({
        message: `이 페이지를 벗어나면 마지막 저장 후 수정된 내용은<br>저장되지 않습니다.`,
        type: 'WRITE',
      });
    } else if (this.projectList.length === 3 || !this.project) {
      //const saveProjects = this.projectList.filter(f => f.uploadSave);
      return this.nextPage();
    }

    this.commonModalContent = message;
    this.commonModalDesc = desc;
    this.commonModalBtns = btn;
    this.$bvModal.show('uploadModal');
  }

  uploadFullModal() {
    const message =
      '현재까지 업로드 한 파일은 총 3개로, 더 이상 업로드는 불가능합니다. ' +
      "<span class='pretendard-regular-normal-san-marino-16px' style='line-height: 19.1px;'>(최대 3개까지)</span><br /><br />" +
      '다음 단계로 이동해주세요.';
    const desc =
      '다른 데이터를 업로드 하고 싶으신가요?<br />' +
      '이전 페이지로 돌아가서 데이터 삭제 후 파일을 다시 업로드 해주시기 바랍니다.';

    this.commonModalContent = message;
    this.commonModalDesc = desc;
    this.commonModalBtns = [
      { text: '취소', color: 'white', click: () => this.closeModal() },
      { text: '다음으로', color: 'black', click: () => this.nextPage() },
    ];
    this.$bvModal.show('uploadModal');
  }

  nextPage() {
    this.$router.push('/visualization/data-list/first-step');
  }

  showModal({ message, type }: { message: string; type: string }) {
    this.modalContent = message;
    this.clickType = type;
    this.$bvModal.show('upload-write');
  }

  closeModal() {
    this.$bvModal.hide('uploadModal');
  }

  demoDown() {
    const url =
      'https://get-data-upload.s3.ap-northeast-2.amazonaws.com/integration/DEMO_%ED%8C%8C%EC%9D%BC+%EC%97%85%EB%A1%9C%EB%93%9C+%ED%8F%AC%EB%A7%B7.xlsx';
    window.location.href = url;
  }

  getConfirmCheck(result: boolean) {
    switch (this.modalConfirmType) {
      case 'uploadDelete':
        this.uploadDel(this.deleteProjectId);
        break;
      case 'reset':
        this.reset();
        break;
      case 'nextPage':
        this.nextPage();
        break;
      case 'prev':
        this.prev();
        break;
      case 'overwrite':
        this.setFileUpload(true);
        break;
    }
  }

  typeAllReset() {
    this.header = this.header.map(m => {
      const isDisabled = m.disabled;
      const type = isDisabled ? m.type : null;
      return {
        ...m,
        type,
      };
    });
  }

  setInputTitleChange(project: IProject) {
    this.dataUpdating = true;
    project.inputState = undefined;
    //if (this.project) this.project.title = project.title;
  }
  onDragover(event: DragEvent) {
    // 드롭을 허용하도록 prevetDefault() 호출
    event.preventDefault();
  }

  onDragLeave(event: DragEvent) {
    if (!this.$refs.upload.contains(event.relatedTarget as HTMLElement)) {
      this.isDragged = false;
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragged = false;
    const valid = this.uploadValidation();
    if (valid) {
      const dataTransfer = event.dataTransfer as DataTransfer;
      const files = dataTransfer.files;
      this.uploadFiles(files);
    }
  }
}
