
import { Component, Vue } from 'vue-property-decorator';
import datepicker from 'vuejs-datepicker';
import { ko } from 'vuejs-datepicker/dist/locale';
import noticeModal from '@/components/common/notice-popup.vue';
import moment from 'moment';
import { profileOptions } from '@/common';
import { IProject } from '@/interface/project';
import ProfileBlock from '@/components/data-set/profile-block.vue';
import PeriodBlock from '@/components/data-set/period-block.vue';
import TypeListRow from '@/components/data-set/type-list-row.vue';
import ListBlock from '@/components/data-set/list-block.vue';
import BookmarkBlock from '@/components/data-set/bookmark-block.vue';
import Guide from '@/components/data-set/guide.vue';

@Component({
  components: {
    Guide,
    datepicker,
    noticeModal,
    ProfileBlock,
    PeriodBlock,
    TypeListRow,
    ListBlock,
    BookmarkBlock,
  },
})
export default class DataSetSearch extends Vue {
  private pending: boolean = false;
  private bookmarkPending: boolean = false;
  private userId: string | null = null;
  private bookmarkList: IProject[] = [];
  private search: {
    keyword: string | null;
    profile: string[];
    startDate: Date;
    endDate: Date;
  } = {
    keyword: null,
    profile: [],
    startDate: new Date(),
    endDate: new Date(),
  };
  private typeCounts = [];
  private list: IProject[] = [];
  private listRow: IProject[][] = [];
  private period: number | null = null;
  private login: boolean = false;
  private periodOptions = [
    { text: '1개월', value: 1 },
    { text: '3개월', value: 3 },
    { text: '6개월', value: 6 },
    { text: '1년', value: 12 },
  ];

  private lang = { ko };
  private nowDate: Date = new Date();
  private disabledDates = {
    to: new Date(2021, 0, 1),
    from: new Date(
      this.nowDate.getFullYear(),
      this.nowDate.getMonth(),
      this.nowDate.getDate(),
    ),
  };
  private modalContent: string = '';
  private clickType: string = '';
  private messageFrontImg: string | null = null;
  private newsAndSnsSwitch: boolean = false;
  private guideShow: boolean = false;
  private isNotLogin: boolean = false;

  get profileOptions() {
    return profileOptions;
  }

  get typeCountNumber() {
    return this.typeCounts.reduce(
      (p: number, v: { text: string; count: number }) => {
        return p + v.count;
      },
      0,
    );
  }

  created() {
    const {
      getUserId,
      isLogin,
      getUserObjectId,
      getFirstVisitToDataSet,
      getDataSetDetail,
    } = this.$store.getters;

    this.login = isLogin;
    if (this.login) {
      this.userId = getUserId;
    }

    if (!getFirstVisitToDataSet) {
      this.guideShow = true;
      if (this.guideShow) {
        this.$store.dispatch('setFirstVisitDataSet');
      }
    }

    const mainKeyword = this.$route.query.keyword as string;
    /*const profile = this.$route.query.profile as string;
    const newsAndSnsSwitch = this.$route.query.newsAndSnsSwitch as string;
    const startDate = this.$route.query.startDate as string;
    const endDate = this.$route.query.endDate as string;*/

    if (mainKeyword) {
      this.search.keyword = mainKeyword;
      this.search.profile = ['PNU_SI', 'PNU_GU', 'PNU'];
      this.$nextTick(() => {
        this.setSearch();
      });
    }
  }

  profileClick(value: string) {
    if (this.search.profile.includes(value)) {
      const index = this.search.profile.findIndex(f => f === value);
      return this.search.profile.splice(index, 1);
    }
    if (this.search.profile.length > 2) {
      return this.showModal({
        message: '선택 가능한 프로파일 변수는 최대 3개까지 선택 가능합니다.',
        type: 'WITHDRAW',
      });
    } else {
      this.search.profile.push(value);
    }
  }

  async getBookmarks() {
    try {
      const { data } = await this.axios({
        method: 'GET',
        url: `/data-set/bookmarks/${this.userId}`,
      });

      const { result, bookmarkList } = data;
      if (result) {
        this.bookmarkList = bookmarkList;
      }
    } catch (e) {
      console.error(e);
    }
  }

  reset() {
    this.search = {
      keyword: null,
      profile: [],
      startDate: new Date(),
      endDate: new Date(),
    };
    this.period = null;
    this.list = [];
    this.bookmarkList = [];
    this.listRow = [];
    /*this.$router.replace(`/data-set`);*/
  }

  periodClick(value: number) {
    if (value === 12 && this.newsAndSnsSwitch) return;
    this.period = value;
    const start = new Date();
    const sel_month = value * -1;
    start.setMonth(start.getMonth() + sel_month);
    this.search.startDate = start;
    this.search.endDate = new Date();
  }

  setNewsAndSnsSwitch() {
    if (this.newsAndSnsSwitch && this.period === 12) {
      this.period = null;
      this.search.startDate = new Date();
      this.search.endDate = new Date();
    }
  }

  datepickerClick() {
    this.period = null;
  }

  async setSearch() {
    const { keyword, profile, startDate, endDate } = this.search;
    if (keyword === null || keyword.trim() === '' || profile.length === 0) {
      this.showModal({
        message:
          '맞춤형 검색을 위한 프로파일 변수 또는 기간을 선택해 주시기 바랍니다.',
        type: 'WITHDRAW',
      });
      return;
    }
    const searchKeyword = keyword.trim();
    this.search.keyword = searchKeyword;

    const start_date = moment(startDate).format('YYYYMMDD');
    const end_date = moment(endDate).format('YYYYMMDD');
    this.list = [];
    this.pending = true;

    try {
      const { data } = await this.axios({
        method: 'get',
        url: '/data-set',
        params: {
          keyword: searchKeyword,
          profile,
          start_date,
          end_date,
          userId: this.userId,
        },
      });
      const { result, list, typeCounts } = data;

      if (result) {
        this.list = list;
        this.typeCounts = typeCounts;
        if (this.list.length > 0) {
          await this.load();
        } else {
          this.showModal({
            message: '조회 조건에 맞는 데이터가 없습니다',
            type: 'WITHDRAW',
          });
          this.reset();
        }
      }
    } catch (e) {
      console.error(e);
    }
    this.pending = false;
  }

  async load() {
    try {
      await this.listDivision();
      if (this.login) await this.getBookmarks();
    } catch (e) {
      console.error(e);
    }
  }

  listDivision() {
    const newArray = [];
    for (let index = 0; index < this.list.length; index += 3) {
      newArray.push(this.list.slice(index, index + 3));
    }
    this.listRow = newArray;
  }

  async detail(selectData: IProject) {
    const {
      _id,
      type,
      title,
      content,
      header,
      data,
      profile,
      collectionName,
      survey_id,
    } = selectData;
    /*await this.$router.replace(`/data-set?keyword=${title}`);*/

    if (_id) {
      return this.bookmarkDetail(_id);
    }

    const { keyword, profile: searchProfile, startDate, endDate } = this.search;
    const start_date = moment(startDate).format('YYYYMMDD');
    const end_date = moment(endDate).format('YYYYMMDD');
    const payload: { [key: string]: any } = {
      type,
      title,
      content,
      profile,
      header,
      data,
      search: {
        keyword,
        profile: searchProfile,
        start_date,
        end_date,
      },
    };
    if (collectionName) payload.collectionName = collectionName;
    if (survey_id) payload.survey_id = survey_id;
    this.$store.commit('setDataSetDetail', payload);
    await this.$router.push('data-set/detail');
  }

  bookmarkDetail(_id: string) {
    this.$store.commit('setDataSetDetailReset');
    this.$router.push(`data-set/${_id}`);
  }

  async bookmarksSave(projectData: IProject) {
    if (this.bookmarkPending) return;
    if (!this.login) {
      this.showModal({
        message: '로그인 후 이용이 가능합니다.',
        type: 'WITHDRAW',
      });
      this.isNotLogin = true;
      return;
    }

    const {
      title,
      content,
      type,
      header,
      profile,
      data: answerData,
      collectionName,
      survey_id,
      index,
    } = projectData;

    if (index === undefined) {
      return this.showModal({
        message: '선택하신 데이터의 Index가 존재하지 않습니다.',
        type: 'WITHDRAW',
      });
    }

    if (projectData._id) {
      return await this.bookmarksDelete(projectData._id, index);
    }

    this.bookmarkPending = true;
    try {
      const { data } = await this.axios({
        method: 'post',
        url: '/data-set/bookmarks',
        data: {
          type,
          title,
          content,
          header,
          profiles: profile,
          data: answerData,
          collectionName,
          survey_id,
          keyword: this.search.keyword,
          start_date: moment(this.search.startDate).format('YYYYMMDD'),
          end_date: moment(this.search.endDate).format('YYYYMMDD'),
        },
      });
      const { result, _id } = data;
      if (result) {
        this.bookmarkSuccess(true);
        this.list[index].bookmark = true;
        this.list[index]._id = _id;
        await this.load();
      }
    } catch (e) {
      console.error(e);
    }
    this.bookmarkPending = false;
  }

  async bookmarksDelete(_id: string, index: number) {
    this.bookmarkPending = true;
    try {
      const { data } = await this.axios({
        method: 'DELETE',
        url: `/data-set/bookmarks/${_id}`,
      });

      const { result } = data;
      if (result) {
        this.bookmarkSuccess(false);
        this.list[index].bookmark = false;
        this.list[index]._id = null;
        await this.load();
      }
    } catch (e) {
      console.error(e);
    }
    this.bookmarkPending = false;
  }

  bookmarkSuccess(result: boolean) {
    const message = `북마크가 ${result ? '저장' : '해제'} 되었습니다.`;
    const contentFrontImgUrl = result
      ? 'assets/img/data-set/bookmark-2.svg'
      : 'assets/img/data-set/bookmark.svg';
    this.showModal({
      message,
      type: 'WITHDRAW',
      messageFrontImg: contentFrontImgUrl,
    });
  }

  linkGo(link: string) {
    this.$router.push(link);
  }

  isNotLoginLinkGo(link: string) {
    if (this.isNotLogin) {
      this.$router.push(`${link}?redirectPage=${this.$route.fullPath}`);
    }
  }

  showModal({
    message,
    type,
    messageFrontImg,
  }: {
    message: string;
    type: string;
    messageFrontImg?: string;
  }) {
    this.modalContent = message;
    this.clickType = type;
    if (messageFrontImg) {
      this.messageFrontImg = messageFrontImg;
    } else {
      this.messageFrontImg = null;
    }
    this.$bvModal.show('noticePopup-write');
  }
}
