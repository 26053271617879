
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { DataWorkshop } from '@/interface/data-workshop';

@Component({})
export default class VariableTableMod extends Vue {
  // 시각화 차트 종류 (ex histogram, box-plot, etc...)
  @Prop() chartType!: string;
  // 체크 박스 정보 상태값 (체크 박스 개수, 차트 종류와 변수 타입에 따른 체크 유효성 판별하기 위한 상태값)
  @Prop() checkBoxInfo!: {
    name: string;
    type: string;
    validTypeList: string[];
  }[];
  // 변수 테이블 목록 데이터
  @Prop() variablesTableData!: DataWorkshop.IJoinProject | { header: [] };
  // v-model을 사용해 체크 관리 상태값
  @Prop() value!: string;
  // 차트가 그려져 있는지 아닌지 (다시 그리기 / 그리기 구분)
  @Prop() isDrawn!: boolean;
  @Prop({ default: false }) boundaryBorderTB?: boolean;

  get modelValue() {
    return this.value;
  }

  set modelValue(value) {
    this.$emit('input', value);
  }

  @Emit('checkValidationOfVariable')
  checkValidationOfVariable(val: { [key: string]: string }[], state: string) {}
}
