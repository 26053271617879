
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataWorkshop } from '@/interface/data-workshop';
import NoticeModal from '@/components/common/notice-popup.vue';
import { DATA_TYPE, PROCESS_TYPE } from '@/enum/common';
import IProcessOption = DataWorkshop.IProcessOption;
import IHeaderProcess = DataWorkshop.IHeaderProcess;
import DataTable from '@/components/common/data-table.vue';
import CustomModal from '@/components/common/custom-modal.vue';
import SaveModalTemplate from '@/components/data-workshop/data-visualize/save-modal-template.vue';
import CommonSelectBox from '@/components/common/custom-select-box.vue';

@Component({
  components: {
    SaveModalTemplate,
    CustomModal,
    DataTable,
    NoticeModal,
    CommonSelectBox,
  },
})
export default class MissingValue extends Vue {
  @Prop() joinProject!: DataWorkshop.IJoinProject;

  _id: string = '';
  header: DataWorkshop.IHeaderProcess[] = [];
  theadNames: string[] = [
    '변수명',
    '타입',
    'N',
    '결측치수',
    '결측률',
    '처리방법',
    '지정값',
  ];
  selectOption = [
    { value: PROCESS_TYPE.COLUMN, text: PROCESS_TYPE.COLUMN, active: false },
    /*{ value: PROCESS_TYPE.ROW, text: PROCESS_TYPE.ROW },*/
    {
      value: PROCESS_TYPE.SPECIFIC_VALUE,
      text: PROCESS_TYPE.SPECIFIC_VALUE,
      active: false,
    },
    { value: PROCESS_TYPE.NOT, text: PROCESS_TYPE.NOT, active: false },
  ];
  allChecked: boolean = false;
  allSelected: string = '';
  allSpecificValue: string = '';
  dataCount: number = 0;
  data: { [key: string]: { value: string | number } }[] = [];
  private pending: boolean = false;
  private modalContent: string = '';
  private clickType: string = '';
  private PROCESS_TYPE = PROCESS_TYPE;
  private changeDataIndexes = {};
  private apply: boolean = false;
  private myDataHubTitle = this.joinProject.title || '';
  private isSaveSuccess = false;
  private savePending = false;
  private eventSource: EventSource | null = null;

  get missingValueExistsCount() {
    return (
      this.header.filter(f => f.missingValue && f.missingValue > 0).length === 0
    );
  }

  isType(type: DATA_TYPE) {
    if (type === DATA_TYPE.NUMERIC || type === DATA_TYPE.INTEGER)
      return 'number';
    return 'text';
  }

  async created() {
    this.pending = true;
    const { _id } = this.$route.params;
    if (_id) {
      this._id = _id;
      await this.load();
      await this.dataLoad();
    }
    this.pending = false;
  }

  async eventSourceSetting() {
    try {
      this.eventSource = new EventSource(
        `/sse/update/checking/${this._id}`,
      );
      this.eventSource.onmessage = ({ data }) => {
        const { complete } = JSON.parse(data);
        if (complete) {
          this.checkingEnd();
          if (this.eventSource) this.eventSource.close();
        }
      };
    } catch (e) {
      console.error(e);
    }
  }

  async load() {
    try {
      const { data } = await this.axios({
        url: `data-process/project/${this._id}`,
      });

      const { result, dataCount, newHeader, project } = data;
      if (result) {
        this.header = newHeader;
        this.dataCount = dataCount;
        if (project.title) this.myDataHubTitle = project.title;
        if (project.updating === 'start') this.checkingModification();
      }
    } catch (e) {
      console.error(e);
    }
  }

  checkingModification() {
    this.$bvModal.show('save-modal');
    this.savePending = true;
    this.eventSourceSetting();
  }

  async dataLoad() {
    try {
      const { data } = await this.axios({
        url: `data-process/project/data/${this._id}`,
      });
      const { result, data: projectData } = data;
      if (result) {
        this.data = projectData;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async reset() {
    this.pending = true;
    try {
      this.apply = false;
      this.allChecked = false;
      this.allSelected = '';
      this.allSpecificValue = '';
      this.changeDataIndexes = {};
      this.savePending = false;

      await this.load();
      await this.dataLoad();
    } catch (e) {
      console.error(e);
    }
    this.pending = false;
  }

  toggleAll() {
    this.header = this.header.map(m => {
      const { missingValue, processOption } = m;
      const { processType, specificValue } = processOption;
      const type = this.allChecked ? processType : null;
      const specific = this.allChecked ? specificValue : '';

      m.processOption = {
        checked: missingValue !== 0 ? this.allChecked : false,
        processType: type,
        specificValue: specific,
      };
      return m;
    });
  }

  toggleOne(data: IHeaderProcess) {
    const { checked } = data.processOption;
    if (!checked) {
      data.processOption.checked = false;
      data.processOption.processType = null;
      data.processOption.specificValue = '';
    }
  }

  getMissingRate(value: number) {
    let rate: number | string = (value / this.dataCount) * 100;
    if (rate % 1 !== 0) {
      rate = rate.toFixed(3);
    }
    return rate;
  }

  targetSelectChange(i: IHeaderProcess, index: number) {
    if (
      i.processOption.processType !== PROCESS_TYPE.SPECIFIC_VALUE &&
      i.processOption.specificValue !== ''
    ) {
      i.processOption.specificValue = '';
      this.$set(this.header, index, i);
    }
  }

  async setMissRate() {
    const checkedHeader = this.header.filter(f => f.processOption.checked);
    if (checkedHeader.length === 0) {
      return this.showModal({
        message: '적용할 결측치 처리가 없습니다.',
        type: 'Alert',
      });
    }

    const processType = checkedHeader.filter(
      f => f.processOption.processType === null,
    );

    if (processType.length > 0) {
      return this.showModal({
        message: '처리방법을 모두 선택하여 주세요.',
        type: 'Alert',
      });
    }

    const specifics = checkedHeader.filter(
      f =>
        f.processOption.processType === PROCESS_TYPE.SPECIFIC_VALUE &&
        !f.processOption.specificValue,
    );
    if (specifics.length > 0) {
      return this.showModal({
        message: '지정값을 모두 입력하여 주세요.',
        type: 'Alert',
      });
    }

    this.pending = true;
    try {
      const { data } = await this.axios({
        url: `data-process/dataProcessValue/change/${this._id}`,
        method: 'put',
        data: {
          header: this.header,
          dataProcessType: 'missing',
        },
      });

      const { result, data: resultDate, header, changeDataIndexes } = data;
      if (result) {
        this.data = resultDate;
        this.header = header;
        this.changeDataIndexes = changeDataIndexes;
        this.apply = true;
      }
    } catch (e) {
      console.error(e);
    }
    this.pending = false;
  }

  async showSaveModal() {
    this.isSaveSuccess = false;
    try {
      this.savePending = true;
      const { data } = await this.axios({
        url: `data-process/missingRate/update/${this._id}`,
        method: 'put',
        data: {
          header: this.header,
          title: this.myDataHubTitle,
          useChart: 'missing',
        },
      });
      const { result } = data;
      if (result) {
        this.checkingModification();
        /* await this.reset();
        this.isSaveSuccess = true;
        this.$emit('loadJoinProject');
        this.$bvModal.hide('save-modal');
        this.showModal({
          message: '작업한 내용이 저장되었습니다.',
          type: 'Alert',
        });*/
      }
    } catch (e) {
      console.error(e);
    }
  }

  allChange() {
    if (this.header.filter(f => f.processOption.checked).length === 0)
      return this.showModal({
        message: '선택된 변수가 없습니다.',
        type: 'Alert',
      });
    if (this.allSelected === '')
      return this.showModal({
        message: '일괄변경 타입을 선택하여 주세요.',
        type: 'Alert',
      });
    if (
      this.allSelected === PROCESS_TYPE.SPECIFIC_VALUE &&
      this.allSpecificValue.trim() === ''
    )
      return this.showModal({
        message: '일괄변경 지정값을 입력하여 주세요.',
        type: 'Alert',
      });

    this.header = this.header.map(m => {
      const { processOption } = m;
      const { checked } = processOption;
      processOption.specificValue = ''; //type 변경시 지정 값 init;
      if (checked) {
        processOption.processType = this.allSelected;
        if (this.allSelected === PROCESS_TYPE.SPECIFIC_VALUE)
          processOption.specificValue = this.allSpecificValue;
      }
      return m;
    });
  }

  showModal({ message, type }: { message: string; type: string }) {
    this.modalContent = message;
    this.clickType = type;
    this.$bvModal.show('missing-rate');
  }

  /*
  showSaveModal() {
    this.$bvModal.show('save-modal');
    this.isSaveSuccess = false;
  }
*/
  checkingEnd() {
    this.reset();
    this.isSaveSuccess = true;
    this.$emit('loadJoinProject');
    this.$bvModal.hide('save-modal');
    this.showModal({
      message: '작업한 내용이 저장되었습니다.',
      type: 'Alert',
    });
  }

  beforeDestroy() {
    if (this.eventSource) this.eventSource.close();
  }

  moveMyDataLabPage() {
    if (!this.isSaveSuccess && this.apply) {
      return this.showModal({
        message:
          '변경된 작업 내용이 존재합니다.</br>저장하지 않고 다음으로 넘어가시겠습니까?',
        type: 'WRITE',
      });
    }
    this.goOut();
  }

  goOut() {
    this.$router.push(`/dashboard/${this._id}`);
  }
}
