
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PROJECT_TYPE } from '@/enum/common';
@Component({})
export default class TypeListRow extends Vue {
  @Prop() private value!: number;
  @Prop() private text!: string;
  get imgUrl() {
    if(this.text === PROJECT_TYPE.CRAWLING) return 'network';
    if(this.text === PROJECT_TYPE.TRANSACTION) return 'credit-card';
    if(this.text === PROJECT_TYPE.PUBLIC_SEOUL) return 'hotel';
    if(this.text === PROJECT_TYPE.MOBILE) return 'mobile-button';
    if(this.text === PROJECT_TYPE.SURVEY) return 'file-user';
    return null;
  }
};
