
import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import 'vue-select/dist/vue-select.css';

@Component({
  components: {},
})
export default class SubMenu extends Vue {
  private clickFlg1: boolean | null = null;
  private clickFlg2: boolean | null = null;
  private clickFlg3: boolean | null = null;

  async mounted() {
    //회원정보 불러오기
    const path = this.$router.currentRoute.path.split('/')[1];
    if (path === 'mypage' || path === 'withdraw') {
      this.clickFlg1 = true;
      this.clickFlg2 = false;
      this.clickFlg3 = false;
    } else if (path === 'changePwd') {
      this.clickFlg2 = true;
      this.clickFlg1 = false;
      this.clickFlg3 = false;
    } else if (path === 'my-history') {
      this.clickFlg3 = true;
      this.clickFlg1 = false;
      this.clickFlg2 = false;
    }
  }

  refresh(compareStr: string) {
    const path = this.$route.path.split('/')[1];
    if (path === compareStr) {
      this.$router.go(0);
    }
  }
}
