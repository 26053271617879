
import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import PhoneAuthModal from '@/components/user/PhoneAuthModal.vue';
import 'vue-select/dist/vue-select.css';
import customInput from '@/components/common/custom-input.vue';
import WithdrawModal from '@/components/user/WithdrawModal.vue';
import SubMenu from '@/components/user/SubMenu.vue';
import noticeModal from '@/components/common/notice-popup.vue';
import {popupSetInterface} from "../../../interfaces/notice.interface";

@Component({
  components: {
    WithdrawModal,
    customInput,
    PhoneAuthModal,
    SubMenu,
    noticeModal,
  },
})
export default class withdraw extends Vue {
  $refs!: {
    subMenu: HTMLFormElement;
    userId: HTMLFormElement;
    email: HTMLFormElement;
    modal1: HTMLFormElement;
    modal2: HTMLFormElement;
  };

  private reasonInfo: {
    userId: string;
    email: string;
    withdrawReason: string[];
  } = {
    userId: this.$store.state.userId,
    email: '',
    withdrawReason: [],
  };

  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface["type"] = 'BACK';
  private strGoOut: string = '';
  private rsnCheck: string[] = [];
  private RsnInput: string = '';
  private iptActive: boolean = false;
  private etcActive: boolean = false;
  private etcVaild: boolean = false;
  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }
  ShowAlert(
    msg: string,
    type: 'BACK' | 'GO' | 'NO' | 'GoOut' | 'Alert' | 'WITHDRAW',
  ) {
    this.modalSetting({
      title: '',
      content: msg,
      type: type,
    });
    this.$bvModal.show('withdrawPopup');
  }

  private chkSelect = [
    { text: '타 사이트의 유사 서비스 이용' },
    { text: '자주 이용하지 않음' },
    { text: '서비스 이용이 불편함' },
    { text: '사용법을 모르겠음' },
    { text: '찾고자 하는 정보가 없음' },
    { text: '개인정보 변경으로 인한 재가입' },
  ];
  private get isLogin() {
    return this.$store.getters.isLogin;
  }

  async mounted() {
    //const result = this.validLogin();
    if (!this.isLogin) {
      this.strGoOut = '/login';
      this.ShowAlert('로그인 후 이용이 가능합니다.', 'WITHDRAW');
      return false;
    }
    this.rsnCheck = [];
    this.reasonInfo.withdrawReason = [];
    //회원정보 불러오기
    const res = await this.$store.dispatch('getUserInfo', {
      userId: this.reasonInfo.userId,
    });
    this.reasonInfo.email = res.email.toString();
  }

  get formSubmit() {
    if (this.rsnCheck.includes('etc')) {
      return this.RsnInput !== '' && !this.etcVaild;
    }
    return this.rsnCheck.length > 0;
  }

  rsnChk() {
    if (this.rsnCheck.length > 3) {
      this.rsnCheck.pop();
    }
    this.etcChk();
  }

  //etc 활성화 시 input 활성화
  etcChk() {
    if (!this.rsnCheck.includes('etc')) {
      this.etcVaild = false;
      this.iptActive = false;
      this.etcActive = false;
      this.RsnInput = '';
    } else {
      this.iptActive = true;
      this.etcActive = true;
    }
  }

  noSpaceForm() {
    this.RsnInput = this.RsnInput.trim(); // 공백제거
    return false;
  }

  etcInputChk() {
    if (this.RsnInput !== '') {
      this.etcActive = false;
      const pattern = /([^0-9a-zA-Z가-힣\x20])/i;
      const pattern2 = /^[0-9a-zA-Z가-힣ㄱ-ㅎㅏ-ㅣ\x20]*$/gi;
      if (pattern.test(this.RsnInput.substring(0, 1))) {
        if (!pattern2.test(this.RsnInput)) {
          this.etcVaild = true;
        }
        this.etcVaild = true;
      } else {
        this.etcVaild = false;
      }
    }
  }

  openModal() {
    this.modalSetting({
      title: '정말 탈퇴하시겠습니까?',
      content: '일부 서비스를 이용하실 수 없습니다.',
      type: 'GO',
    });
    this.$bvModal.show('withdrawPopup');
  }

  mypage() {
    this.$router.push('/mypage');
  }

  logout() {
    this.$store.dispatch('logout');
    this.$router.push(this.strGoOut);
  }

  async isOk() {
    if (!this.formSubmit) {
      return await this.ShowAlert('탈퇴 이유를 선택해 주세요.', 'WITHDRAW');
    }
    if (this.rsnCheck.includes('etc')) {
      for (let i = 0; i < this.rsnCheck.length; i++) {
        if (this.rsnCheck[i] === 'etc') {
          this.rsnCheck.splice(i, 1);
          i--;
        }
      }
      this.reasonInfo.withdrawReason = this.rsnCheck;
      this.reasonInfo.withdrawReason.push(this.RsnInput);
    } else {
      this.reasonInfo.withdrawReason = this.rsnCheck;
    }
    try {
      const data = await this.axios({
        url: '/user/' + this.reasonInfo.userId,
        method: 'patch',
        data: this.reasonInfo,
      });
      const result = data.data.result;
      if (result !== false) {
        this.modalSetting({
          title: '탈퇴가 완료되었습니다.',
          content: `남겨주신 의견을 바탕으로 <br>더 나은 서비스를 제공하고자 노력하겠습니다.<br>
                  그동안 통합 데이터 허브를 이용해주셔서 <br>감사합니다.`,
          type: 'WITHDRAW',
        });
        this.strGoOut = '/';
        await this.$bvModal.show('withdrawPopup');
        /* this.$refs['modal1'].hide();
        this.$bvModal.show('modal2');
        await this.openModal('탈퇴가 완료되었습니다.', 'msg2');*/
      } else {
        await this.ShowAlert(data.data.msg, 'Alert');
        await this.$bvModal.hide('withdrawPopup');
        this.rsnCheck = [];
        this.RsnInput = '';
      }
    } catch (err) {
      console.error(err);
    }
  }

  isCancel() {
    this.$bvModal.hide('withdrawPopup');
  }
}
