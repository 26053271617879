var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-table-simple',{staticStyle:{"max-height":"600px"},attrs:{"responsive":"","sticky-header":""}},[_c('b-thead',[_c('b-tr',{staticClass:"text-center"},[(_vm.isIndex)?_c('b-td',{staticClass:"pretendard-semi-bold-san-marino-18px align-middle text-nowrap font-weight-bold",staticStyle:{"color":"#445cb2","background-color":"rgba(68, 92, 178, 0.102)"},style:({ borderTop: _vm.boundaryBorderTB && '1px solid #000' })},[_vm._v(" "+_vm._s(_vm.indexTitle)+" ")]):_vm._e(),_vm._l((_vm.header),function(d){return _c('b-td',{staticClass:"border-left pretendard-semi-bold-san-marino-18px align-middle text-nowrap font-weight-bold",staticStyle:{"color":"#445cb2","background-color":"rgba(68, 92, 178, 0.102)"},style:({ borderTop: _vm.boundaryBorderTB && '1px solid #000' })},[_vm._v(" "+_vm._s(d.text)+" ")])})],2)],1),(_vm.data.length)?_c('b-tbody',_vm._l((_vm.data),function(d,idx){return _c('b-tr',{class:`${idx % 2 !== 0 ? 'dataTr ' : ''} text-center`},[(_vm.isIndex)?_c('b-td',{staticClass:"align-middle pretendard-regular-normal-gravel-16px",style:({
          borderBottom:
            _vm.boundaryBorderTB && _vm.data.length - 1 === idx && '1px solid black',
          fontWeight: _vm.dataTableTextBolder,
        })},[_vm._v(" "+_vm._s(idx + 1)+" ")]):_vm._e(),_vm._l((_vm.header),function(h){return _c('b-td',{staticClass:"border-left align-middle pretendard-regular-normal-gravel-16px",style:({
          borderBottom:
            _vm.boundaryBorderTB && _vm.data.length - 1 === idx && '1px solid black',
          fontWeight: _vm.dataTableTextBolder,
        })},[_vm._v(" "+_vm._s(d[h.key])+" ")])})],2)}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }