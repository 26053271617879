
import { Vue, Component } from 'vue-property-decorator';
import noticePermission from '@/common';
@Component({})
export default class NoticeList extends Vue {
    private options: { text: string, value: string }[]= [
        { text: '전체', value: '0' },
        { text: '공지', value: '1' },
        { text: '이벤트', value: '2' },
        { text: '메뉴얼', value: '3' },
        { text: '기타', value: '4' },
    ];
    private searchOptions: { text: string, value: string }[] = [
        { text: '전체', value: '0' },
        { text: '제목', value: '1' },
        { text: '내용', value: '2' },
    ];
    private dateOptions: { text: string, value: string }[] = [
        { text: '등록일 전체', value: '0' },
        { text: '최신순', value: '-1' },
        { text: '오래된순', value: '1' },
    ];
    private searchOption: string = '0';
    private search: string = '';
    private dateOption: string = '0';
    private option: string = '0';
    private writeTotal: number = 0;
    private listData: any[] = [];
    private topListData: any[] = [];
    private currentPage: number;
    private loading: boolean = true;
    constructor() {
    super();
    this.option = '0';
    this.searchOption = '0';
    this.dateOption = '0';
    this.writeTotal = 0;
    this.currentPage = this.$route.query.page ? Number(this.$route.query.page) : 1;
    this.search = Object.keys(this.$route.query).includes('search') ? String(this.$route.query.search) : '';
    }
    mounted() {
        this.$emit('isPage', this.$route.path);
    }
    created() {
        const searchWord = this.$route.query.search as string;
        const searchOpt = this.$route.query.category as string;
        const postType = this.$route.query.type as string;
        const dateDesc = this.$route.query.dateType as string;
        if(dateDesc) this.dateOption = dateDesc;
        if(searchWord) this.search = searchWord;
        if(searchOpt) this.searchOption = searchOpt;
        if(postType) this.option = postType;
        this.getList();
    }

    get isPermission() {
      return noticePermission.includes(this.$store.getters.isGrade);
    }

    gotoWrite() {
        return this.$router.push(`/notice/new-post`);
    }
    private createParam(pageNum: number) {
        return `?search=${this.search}&category=${this.searchOption}&type=${this.option}&dateType=${this.dateOption}&page=${pageNum}`
    }
    routerLink(pageNum: number): string {
        const paramSearch = this.createParam(pageNum);
        return `/notice${paramSearch}`;
    }
    async getList() {
    try {
        if(this.currentPage){
            const sendData = { type: this.option, searchType: this.searchOption, search: this.search, dateType: this.dateOption, currentPage: this.currentPage };
            const { data } = await this.axios.post(`/notice/list/${this.currentPage}`, sendData);
            const { list, total, topList } = data;
            this.listData = list;
            this.writeTotal = total;
            this.topListData = topList;
            const pageNum = this.culTotal(total);
            if(pageNum < this.currentPage){
                this.topListData = [];
                this.writeTotal = 0;
            }
            this.loading = false;
        }
    } catch (e) {
      console.log(e)
    }

    }
    private culTotal(totalCount: number){
        if(totalCount === 0 || totalCount === 1) return 1;
        const divideNum = (totalCount-this.topListData.length)/10;
        return Math.ceil(divideNum);
    }
    private gotoView(seq: number, topFixing: string) {
        return this.$router.push(`/notice/postView/${seq}?page=${this.currentPage}&search=${this.search}&category=${this.searchOption}&type=${this.option}&dateType=${this.dateOption}${topFixing === 'Y'? `&fix=${topFixing}`: '' }`);
    }
    private typeClick() {
        this.loading = true;
        const addParam = this.createParam(1)
        this.$router.push(`/notice${addParam}`);
        this.currentPage = 1;
        this.getList();
        //location.reload();
    }
    private load() {
        const isRex = this.regexCheck();
        if(isRex) return false;
        const searchWord = this.search;
        const addParam =`?search=${searchWord}&category=${this.searchOption}&type=${this.option}&dataType=${this.dateOption}`;
        const path = `/notice${addParam}&page=1`
        this.$router.push({path});
        location.reload();
    }
    dateSort() {
        this.getList();
    }
    private regexCheck() {
        const regExp = /[?}.\],/;[:|\\){*~`!^\-_+<>@#$%&=('"]/g;
        return regExp.test(this.search);
    }
    private regexInput(e: any) {
        const regExp = /[?}.\],/;[:|\\){*~`!^\-_+<>@#$%&=('"]/g;
        let value = e.target.value;
        if (regExp.test(value)) {
            value = value.replace(regExp, '');
            this.search = value;
        }
    }
}
