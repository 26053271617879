
import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import AnswerModal from '@/components/qna/AnswerModal.vue';

export interface IQNAList {
  user_id: string;
  seq: number;
  type: string;
  title: string;
  user: string;
  maskId: string;
  file: string;
  date: string;
  status: string;
  isPublic: string;
}

@Component({ components: { AnswerModal } })
export default class AdminList extends Vue {
  private answerModal: boolean = false;
  private pending: boolean = true;
  private options = [
    { text: '전체', value: '전체' },
    { text: '가입/회원정보', value: '가입/회원정보' },
    { text: '데이터 탐색', value: '데이터 탐색' },
    { text: '데이터 마켓', value: '데이터 마켓' },
    { text: '데이터 작업소', value: '데이터 작업소' },
    { text: '기타', value: '기타' },
  ];
  private statusType = [
    { text: '전체', value: '전체' },
    { text: '답변', value: '답변' },
    { text: '미답변', value: '미답변' },
  ];
  private fields = [
    {
      key: 'answerStatus',
      label: '처리상태',
      thStyle: 'width: 8%;',
      thClass: ['th-center', 'text-table-th'],
    },
    {
      key: 'type',
      label: '문의유형',
      thStyle: 'width: 14%;',
      thClass: ['th-center', 'text-table-th'],
    },
    {
      key: 'title',
      label: '제목',
      thStyle: 'width: 30%;',
      thClass: ['th-center', 'text-table-th'],
    },
    {
      key: 'user',
      label: '작성자',
      thStyle: 'width: 12%;',
      thClass: ['th-center', 'text-table-th'],
    },
    {
      key: 'questionDate',
      label: '등록일',
      thStyle: 'width: 13%;',
      thClass: ['th-center', 'text-table-th'],
    },
    {
      key: 'answerDate',
      label: '답변일',
      thStyle: 'width: 13%;',
      thClass: ['th-center', 'text-table-th'],
    },
    {
      key: 'button',
      label: '문의보기',
      thStyle: 'width: 10%;',
      thClass: ['th-center', 'text-table-th'],
    },
  ];
  private items: Array<IQNAList> = [];
  private optionsSelected: string = '전체';
  private statusSelected: string = '전체';
  private currentPage: number = 1;
  private perPage: number = 10;
  private totalPage: number = 1;
  private answerCount: number = 0;
  private selectedSeq: number = 0;

  linkGen(pageNum: number) {
    const link: string[] = [
      `page=${pageNum}`,
      `option=${this.optionsSelected}`,
      `statusType=${this.statusSelected}`,
    ];
    return `?${link.join('&')}`;
  }

  async search() {
    await this.$router.push(
      `/qna?option=${this.optionsSelected}&statusType=${this.statusSelected}&page=1`,
    );
    this.currentPage = 1;
    await this.getList(1);
  }

  private get isLogin() {
    return this.$store.getters.isLogin;
  }

  created() {
    const { page, option, statusType } = this.$route.query;
    if (page) this.currentPage = Number(page);
    if (option) this.optionsSelected = String(option);
    if (statusType) this.statusSelected = String(statusType);
  }

  mounted() {
    this.getList(this.currentPage);
  }

  async getList(page: number) {
    try {
      this.pending = true;
      const payload = {
        type: this.optionsSelected,
        status: this.statusSelected,
        perPage: this.perPage,
        pageNumber: page,
      };
      const result = await this.axios.get(`/qna/list/admin`, {
        params: payload,
      });
      this.totalPage = result.data.totalPage === 0 ? 1 : result.data.totalPage;
      this.items = result.data.returnData;
      this.answerCount = result.data.answerCount;
      this.pending = false;
    } catch (e) {
      console.log(e);
    }
  }

  async answerBtnClick(seq: number) {
    this.answerModal = true;
    this.selectedSeq = seq;
  }

  async goto(url: string) {
    if (!this.isLogin) {
      const result = await this.showAlert('로그인 후 이용이 가능합니다.');
      if (result) await this.$router.push('/login');
    } else {
      await this.$router.push(url);
    }
  }

  async showAlert(msg: string) {
    return await this.$bvModal.msgBoxOk(msg, {
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'success',
      okTitle: '확인',
      headerClass: 'p-2 border-bottom-0',
      bodyClass: 'pt-4',
      footerClass: 'p-2 border-top-0',
      centered: true,
    });
  }

  closeAnswerModal() {
    this.answerModal = false;
  }

  reloadTable() {
    this.getList(this.currentPage);
  }

  async MoveFirstPage() {
    const payload = {
      type: this.optionsSelected,
      status: this.statusSelected,
      perPage: this.perPage,
      pageNumber: this.currentPage,
    };
    const result = await this.axios.get(`/qna/count/admin`, {
      params: payload,
    });
    const QnaContent = result.data.result;

    /* 삭제한 페이지 내 문의내역이 없을 경우 */
    if (QnaContent.length === 0) {
      this.currentPage = (this.currentPage - 1) | 1;
      this.$router.push(
        `/qna?page=${this.currentPage}&option=${this.optionsSelected}&statusType=${this.statusSelected}`,
      );
    }
    await this.getList(this.currentPage);
  }
}
