
import { Component, Vue } from 'vue-property-decorator';
import { profileOptions } from '@/common';
import DataTable from '@/components/common/data-table.vue';
import Chart from '@/components/data-workshop/data-visualize/chart.vue';
import { DataWorkshop } from '@/interface/data-workshop';

@Component({ components: { DataTable, Chart } })
export default class Detail extends Vue {
  private pending: boolean = true;
  private profileOptions: { text: string; value: string; type: any }[] = [];
  private profiles: string[] = [
    '성별',
    '연령',
    '직업',
    '결혼여부',
    '가구구성',
    '관심사',
    '지역(시)',
    '지역(구)',
    '지역(동)',
  ];
  private title: string = '';
  private description: string = '';
  private utilizationData: string[] = [];
  private profile: string[] = [];
  private dataHeader: DataWorkshop.IHeaderProcess[] | DataWorkshop.IHeader[] =
    [];
  private mainData: { [key: string]: { value: string | number } }[] = [];
  private chartOptions: {
    barPercentage: number;
    categoryPercentage: number;
    plugin: { legend: { display: boolean } };
  } = {
    barPercentage: 0,
    categoryPercentage: 0,
    plugin: { legend: { display: false } },
  };
  private chartData: {
    labels: string[];
    datasets: { data: number[]; backgroundColor: string[] }[];
  } = {
    labels: [],
    datasets: [],
  };
  private useChart: string = '';

  async created() {
    await this.getData();
    this.profileOptions = profileOptions;
    this.pending = false;
  }

  async getData() {
    try {
      const projectId = this.$route.params.pid as string;
      const getProject = await this.axios.get(
        `/data-process/project/${projectId}`,
      );
      this.title = getProject.data.project.title;
      this.description = getProject.data.project.description;
      this.utilizationData = getProject.data.project.utilizationData;
      this.profile = getProject.data.project.profile;
      this.dataHeader = getProject.data.project.header;
      this.chartOptions = getProject.data.project.chartOptions;
      this.chartData = getProject.data.project.chartData;
      this.useChart = getProject.data.project.useChart;

      const getData = await this.axios.get(
        `/data-process/project/data/${projectId}`,
      );
      this.mainData = getData.data.data;
    } catch (e) {
      console.log(e);
    }
  }
  getUtilizationDataCode(item: string) {
    const data = profileOptions.find(profile => profile.text == item)?.value;
    return data;
  }
}
