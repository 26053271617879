import { GRADE } from '@/enum/common';

export const noticePermission: GRADE[] = [GRADE.MASTER, GRADE.STAFF];
export const qnaPermission: GRADE[] = [GRADE.MASTER];

export const profileOptions: { text: string; value: string; type: any }[] = [
  { text: '성별', value: 'GENDER', type: 'integer' },
  { text: '연령', value: 'AGE10', type: 'integer' },
  { text: '직업', value: 'JOB', type: 'integer' },
  { text: '결혼여부', value: 'MARRY', type: 'integer' },
  { text: '가구구성', value: 'FAMILY', type: 'integer' },
  { text: '지역(시)', value: 'PNU_SI', type: 'integer' },
  { text: '지역(구)', value: 'PNU_GU', type: 'integer' },
  { text: '지역(동)', value: 'PNU', type: 'integer' },
  { text: '관심사', value: 'INTEREST', type: 'integer' },
  /*{ text: '라이프 스테이지', value: 'LIFESTAGE1', type: 'integer' },*/
  /*  { text: '날짜(연도)', value: 'YEAR', type: 'integer' },
  { text: '날짜(분기)', value: 'QUARTER', type: 'integer' },
  { text: '날짜(연도_분기)', value: 'YEAR_QUARTER', type: 'integer' },
  { text: '날짜(월)', value: 'MONTH', type: 'integer' },
  { text: '연령(5세 단위)', value: 'AGE5', type: 'integer' },
  { text: 'Life Stage', value: 'LIFESTAGE', type: 'integer' },*/
];

export default noticePermission;
