
import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import {
  FileInfoProp,
  popupSetInterface,
} from '../../../../interfaces/notice.interface';
import noticeModal from '@/components/common/notice-popup.vue';
import { S3FileCheck } from '@/utils/s3-file-check';

@Component({ components: { noticeModal } })
export default class QnaWrite extends Vue {
  private userId = '';
  private writer = '';
  private isWriterFlg = false;
  private type = '';
  private title = '';
  private date = '';
  private content = '';
  private answer = '';
  private answerStatus = false;
  private answerDate = '';
  private questionFiles: FileInfoProp[] = [];
  private answerFiles: FileInfoProp[] = [];

  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface['type'] = 'BACK';
  private strGoOut: string = '';

  mounted() {
    this.getContent();
  }

  async getContent() {
    try {
      const { data } = await this.axios.get(`qna/${this.$route.params.seq}`, {
        params: { token: this.$store.getters.isToken },
      });
      if (data.result) {
        this.userId = data.user;
        this.writer = data.maskId;
        this.type = data.type;
        this.date = data.date;
        this.title = data.title;
        this.content = data.content;
        this.answer = data.answer;
        this.answerStatus = data.answerStatus;
        this.questionFiles = data.questionFiles;
        this.answerFiles = data.answerFiles;
        this.answerDate = data.answerDate;
        this.isWriterFlg = this.$store.state.userId.includes(this.userId);
      } else {
        this.strGoOut = '/qna';
        await this.showAlert(data.msg, 'WITHDRAW');
      }
    } catch (e) {
      console.log(e);
    }
  }

  async showAlert(
    msg: string,
    type: 'BACK' | 'GO' | 'NO' | 'GoOut' | 'Alert' | 'WITHDRAW',
  ) {
    this.modalSetting({
      title: '',
      content: msg,
      type: type,
    });
    await this.$bvModal.show('noticePopup-read');
  }

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }

  goOut() {
    this.$router.push(this.strGoOut);
  }

  historyBack() {
    this.$router.go(-1);
  }
  async deleteBtn() {
    this.modalSetting({
      title: '',
      content: '문의내역을 삭제하시겠습니까?',
      type: 'DELETE',
    });
    this.$bvModal.show('noticePopup-read');
  }
  async qnaDelete() {
    await this.axios.get(`/qna/delete/${this.$route.params.seq}`);
    await this.historyBack();
  }

  async fileDownload(url: string, fileName: string) {
    const isFile = new S3FileCheck('qna', url);
    const result = await isFile.checkFile();
    if (result) {
      const anchorElement = document.createElement('a');
      document.body.appendChild(anchorElement);
      anchorElement.download = `${fileName}`;
      anchorElement.href = url;
      anchorElement.click();
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(url);
    } else {
      //파일이 없는 경우
      this.modalSetting({
        title: '',
        content: '파일이 존재하지 않습니다.',
        type: 'Alert',
      });
      this.$bvModal.show('isFileCheck');
    }
  }
}
