
import { Component, Prop, Vue } from 'vue-property-decorator';
import PhoneAuthModal from '@/components/user/PhoneAuthModal.vue';
import SignUpModal from '@/components/user/SignUpModal.vue';
import NoticePopup from '@/components/common/notice-popup.vue';
import { popupSetInterface } from '../../../interfaces/notice.interface';

@Component({
  components: { NoticePopup, SignUpModal, PhoneAuthModal },
})
export default class PhoneNumber extends Vue {
  @Prop({ default: '' }) modalMsg?: string; //입력한 전화번호
  $refs!: {
    userId: HTMLFormElement;
    password: HTMLFormElement;
    passwordCheck: HTMLFormElement;
    userName: HTMLFormElement;
    number: HTMLFormElement;
    email: HTMLFormElement;
    phone: HTMLFormElement;
    modal: HTMLFormElement;
  };
  private phoneDialog: boolean = false;
  private userInfo: {
    userId: string;
    password: string;
    userName: string;
    phone: number | null;
    phoneNumber: string;
    email: string;
    company: string;
    selectedCat1: number | null;
    selectedCat2: number | null;
    sectors: string;
    hubTerms: boolean;
    privacyAgreement: boolean;
  } = {
    userId: '',
    password: '',
    userName: '',
    phone: null,
    phoneNumber: '',
    email: '',
    company: '',
    selectedCat1: null,
    selectedCat2: null,
    sectors: '',
    hubTerms: false,
    privacyAgreement: false,
  };
  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface['type'] = 'BACK';
  constructor() {
    super();
  }

  cancel() {
    this.$bvModal.hide('modal-phone');
  }
  async reSendCode() {
    const randomNumber: string = Math.floor(Math.random() * 1000000)
      .toString()
      .padStart(6, '0');
    try {
      const res = await this.axios.post('/sms', {
        title: '발송 제목',
        message: '본인확인 인증번호(' + randomNumber + ')를 입력해 주세요.',
        randomNumber: randomNumber,
        receiver: [
          {
            mobile: this.modalMsg,
          },
        ],
        isAuth: true,
      });

      if (!res.data.result) {
        this.modalSetting({
          title: '',
          content: `발송 실패 ERROR CODE : ${res.data.status}`,
          type: 'Alert',
        });
        await this.$bvModal.show('phoneNumberPopup');
        /*this.$toast.open({
            message: '발송 실패 ERROR CODE : ' + res.data.status,
            type: 'error',
            duration: 5000,
        });*/
        return false;
      }
      this.modalSetting({
        title: '',
        content: `재전송 되었습니다.`,
        type: 'Alert',
      });
      await this.$bvModal.show('phoneNumberPopup');
      /*this.$toast.open({
          message: '재전송 되었습니다.',
          type: 'info',
          duration: 5000,
      });*/
    } catch (e) {
      console.log(e);
    }
  }
  private numberValid: boolean = false;
  async ok() {
    try {
      const result = await this.axios.get(
        '/sms?userphonenumber=' +
          this.modalMsg +
          '&code=' +
          this.$refs.phone.code,
      );

      if (result.data) {
        this.modalSetting({
          title: '',
          content: '휴대폰번호 인증이 완료되었습니다',
          type: 'Alert',
        });
        await this.$bvModal.show('phoneNumberPopup');
        /*this.$toast.open({
            message: '휴대폰번호 인증이 완료되었습니다!!.',
            type: 'success',
            duration: 5000,
        });*/
        this.$refs.phone.code = '';
        this.authModalClose();
        //isNumber 최종 인증 완료 변수로서 부모 컨포넌트에서는 버튼을 활성화 시키는데 필요한 변수로 추가함
        this.$emit('isPhoneNumber', {
          numberValid: this.numberValid,
          phoneDialog: this.phoneDialog,
          isNumber: true,
        });
      } else {
        this.modalSetting({
          title: '',
          content: `인증코드를 다시 확인해 주세요.\n\n${this.modalMsg}`,
          type: 'Alert',
        });
        await this.$bvModal.show('phoneNumberPopup');
        /*this.$toast.open({
            message: '인증코드를 다시 확인해 주세요.'+this.modalMsg,
            type: 'error',
            duration: 5000,
        });*/
      }
    } catch (e) {
      return false;
    }
  }
  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }
  hideModal() {
    this.$refs['modal'].hide();
  }
  hidePhoneDialog() {
    this.phoneDialog = false;
  }
  authModalClose() {
    this.phoneDialog = false;
    this.numberValid = true;
  }
}
