export enum HOST_LINK {
  CRAWLING = 'CRAWLING',
  PUBLIC = 'PUBLIC',
  TRANSACTION = 'TRANSACTION',
  MPD = 'MPD',

  UNI_SURVEY = 'UNI_SURVEY',
  HBU = 'HBU',
  RECOMMEND = 'RECOMMEND',
  QUAL = 'QUAL',
}

export enum GRADE {
  MASTER = '마스터',
  STAFF = '스태프',
  GENERAL = '일반',
}

export enum DATA_TYPE {
  INTEGER = 'integer',
  NUMERIC = 'numeric',
  CHARACTER = 'character',
  FACTOR = 'factor',
}

export enum PROJECT_TYPE {
  TRANSACTION = '결제',
  CRAWLING = '뉴스/SNS 트렌드',
  PUBLIC_SEOUL = '공공',
  MOBILE = '모바일 행동',
  SURVEY = '설문조사',
  USER_UPLOAD = '사용자 업로드',
}

export enum PROCESS_TYPE {
  ROW = 'row 삭제',
  COLUMN = 'column 삭제',
  SPECIFIC_VALUE = '지정값',
  NOT = '처리안함',
}
export enum S3_SET {
  KEY = '1073/intergration/',
  BUCKET = 'get-data-upload',
  IDENTITYPOOLID= 'ap-northeast-2:af8aac42-d491-43c5-a086-c7f069b4940b',
  REGION = 'ap-northeast-2',
  APIVERSION = '2006-03-01',
  URL = 'https://get-data-upload.s3-ap-northeast-2.amazonaws.com/',
}