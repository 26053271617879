
import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import { qnaPermission } from '@/common';
import noticeModal from '@/components/common/notice-popup.vue';
import { popupSetInterface } from '../../../interfaces/notice.interface';

export interface IQNAList {
  user_id: string;
  seq: number;
  type: string;
  title: string;
  user: string;
  maskId: string;
  file: string;
  date: string;
  status: string;
  isPublic: string;
}

@Component({ components: { noticeModal } })
export default class UserList extends Vue {
  private pending: boolean = true;
  private options = [
    { text: '전체', value: '전체' },
    { text: '가입/회원정보', value: '가입/회원정보' },
    { text: '데이터 탐색', value: '데이터 탐색' },
    { text: '데이터 마켓', value: '데이터 마켓' },
    { text: '데이터 작업소', value: '데이터 작업소' },
    { text: '기타', value: '기타' },
  ];
  private searchType = [
    { text: '전체', value: '전체' },
    { text: '제목', value: '제목' },
    { text: '작성자', value: '작성자' },
  ];
  private fields = [
    {
      key: 'seq',
      label: '번호',
      thStyle: 'width: 5%;',
      thClass: ['th-center', 'text-table-th'],
    },
    {
      key: 'type',
      label: '문의유형',
      thStyle: 'width: 15%;',
      thClass: ['th-center', 'text-table-th'],
    },
    {
      key: 'title',
      label: '제목',
      thStyle: 'width: 40%;',
      thClass: ['th-center', 'text-table-th'],
    },
    {
      key: 'maskId',
      label: '작성자',
      thStyle: 'width: 15%;',
      thClass: ['th-center', 'text-table-th'],
    },
    {
      key: 'date',
      label: '등록일',
      thStyle: 'width: 13%;',
      thClass: ['th-center', 'text-table-th'],
    },
    {
      key: 'status',
      label: '답변여부',
      thStyle: 'width: 10%;',
      thClass: ['th-center', 'text-table-th'],
    },
  ];
  private items: Array<IQNAList> = [];
  private optionsSelected: string = '전체';
  private searchTypeSelected: string = '전체';
  private keyword: string = '';
  private currentPage: number = 1;
  private perPage: number = 10;
  private totalPage: number = 1;
  private zeroPostMsg: string = '';

  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface['type'] = 'BACK';
  private strGoOut: string = '';

  linkGen(pageNum: number) {
    const link: string[] = [
      `page=${pageNum}`,
      `option=${this.optionsSelected}`,
      `searchType=${this.searchTypeSelected}`,
      `keyword=${this.keyword}`,
    ];
    return `?${link.join('&')}`;
  }

  async search() {
    await this.$router.push(
      `/qna?option=${this.optionsSelected}&searchType=${this.searchTypeSelected}&keyword=${this.keyword}&page=1`,
    );
    this.currentPage = 1;
    await this.getList(1);
  }

  private get isLogin() {
    return this.$store.getters.isLogin;
  }

  get isPermission() {
    return qnaPermission.includes(this.$store.getters.isGrade);
  }

  created() {
    const { page, option, searchType, keyword } = this.$route.query;
    if (page) this.currentPage = Number(page);
    if (option) this.optionsSelected = String(option);
    if (searchType) this.searchTypeSelected = String(searchType);
    if (keyword) this.keyword = String(keyword);
  }

  mounted() {
    this.getList(this.currentPage);
  }

  async getList(page: number) {
    try {
      this.pending = true;
      const payload = {
        type: this.optionsSelected,
        searchType: this.searchTypeSelected,
        keyword: this.keyword,
        perPage: this.perPage,
        pageNumber: page,
      };
      const result = await this.axios.get(`/qna/`, { params: payload });
      this.totalPage = result.data.totalPage === 0 ? 1 : result.data.totalPage;
      this.items = result.data.returnData;
      this.zeroPostMsg = !this.keyword.length
        ? '입력된 데이터가 없습니다.'
        : '검색어를 찾을 수 없습니다.';
      this.pending = false;
    } catch (e) {
      console.log(e);
    }
  }

  async tableClick(record: any) {
    if (this.isPermission) {
      await this.$router.push(`/qna/answer/${record.seq}`);
    } else {
      if (record.isPublic === 'NO') {
        if (this.$store.state._id == record.user_id) {
          await this.$router.push(`/qna/read/${record.seq}`);
        } else {
          await this.showAlert('본인만 확인 가능합니다.', 'Alert');
        }
      } else {
        await this.$router.push(`/qna/read/${record.seq}`);
      }
    }
  }

  async goto(url: string) {
    if (!this.isLogin) {
      this.strGoOut = `/login?redirectPage=${url}`;
      await this.showAlert('로그인 후 이용이 가능합니다.', 'WITHDRAW');
    } else {
      await this.$router.push(url);
    }
  }

  goOut() {
    this.$router.push(this.strGoOut);
  }

  async showAlert(
    msg: string,
    type: 'BACK' | 'GO' | 'NO' | 'GoOut' | 'Alert' | 'WITHDRAW',
  ) {
    this.modalSetting({
      title: '',
      content: msg,
      type: type,
    });
    await this.$bvModal.show('noticePopup-userList');
  }

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }
}
