import { render, staticRenderFns } from "./period-block.vue?vue&type=template&id=8d02a2b4&scoped=true&"
import script from "./period-block.vue?vue&type=script&lang=ts&"
export * from "./period-block.vue?vue&type=script&lang=ts&"
import style0 from "./period-block.vue?vue&type=style&index=0&id=8d02a2b4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d02a2b4",
  null
  
)

export default component.exports