
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class DataTable extends Vue {
  @Prop() title!: string;
  @Prop() header!: { key: string; text: string }[];
  @Prop() data!: { [key: string]: { value: string | number } }[];
  @Prop({ default: false }) boundaryBorderTB?: boolean;
  @Prop({ default: false }) isIndex?: boolean;
  @Prop({ default: '' }) indexTitle?: string;
  @Prop({ default: '' }) dataTableTextBolder?: string;

  /*async download(projectId: string) {
    try {
      const { data } = await this.axios({
        method: 'GET',
        url: `/data-process/download/${projectId}`,
      });
      const { result, fileName } = data;
      if (result) {
        const protocol = window.location.protocol;
        const host = window.location.host;
        window.location.href = `${protocol}//${host}/api/data-set/download/data-excel?originName=${fileName}&fileName=${fileName}`;
      }
    } catch (e) {
      console.error(e);
    }
  }*/
}
