import {CognitoIdentityCredentials, config, S3} from 'aws-sdk';
import {S3_SET} from "@/enum/common";

export class S3FileCheck {
  private readonly key: string = S3_SET.KEY;
  constructor(pathName: string, url: string) {
    this.key+=`${pathName}/${url.replace(S3_SET.URL, '').replace(S3_SET.KEY,'').replace(`${pathName}/`, '')}`;
  }
  private setS3() {
    this.setConfigUpdate();
    return new S3({
      apiVersion: S3_SET.APIVERSION,
      params: { Bucket: S3_SET.BUCKET },
    });
  }
  private setConfigUpdate() {
    config.update({
      region: S3_SET.REGION,
      credentials: new CognitoIdentityCredentials({
        IdentityPoolId: S3_SET.IDENTITYPOOLID,
      }),
    });
  }
  public async checkFile() {
    const s3 = this.setS3();
    const bucketName = S3_SET.BUCKET;
    try {
      return await s3.headObject({ Bucket: bucketName, Key: this.key }).promise();
    } catch(e) {
      return null;
    }
  }
}