
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { PROJECT_TYPE } from '@/enum/common';
import { IProject } from '@/interface/project';
@Component({})
export default class BookmarkBlock extends Vue {
  @Prop() private data!: IProject;
  get imgUrl() {
    if(this.data.type === PROJECT_TYPE.CRAWLING) return 'crawling';
    if(this.data.type === PROJECT_TYPE.TRANSACTION) return 'transaction';
    if(this.data.type === PROJECT_TYPE.PUBLIC_SEOUL) return 'public_seoul';
    if(this.data.type === PROJECT_TYPE.MOBILE) return 'mobile';
    if(this.data.type === PROJECT_TYPE.SURVEY) return 'survey';
    return null;
  }
};
