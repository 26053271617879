
import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import SignUpModal from '@/components/user/SignUpModal.vue';
import PhoneAuthModal from '@/components/user/PhoneAuthModal.vue';
import 'vue-select/dist/vue-select.css';
import openIndicator from '@/assets/icon/openIndicator.vue';
import customInput from '@/components/common/custom-input.vue';
import SubMenu from '@/components/user/SubMenu.vue';
import noticeModal from '@/components/common/notice-popup.vue';
import { popupSetInterface } from '../../../interfaces/notice.interface';

interface IUserInfo {
  userId?: string;
  userName: string;
  phone: number | null;
  phoneNumber: string;
  email: string;
  company: string;
  selectedCat1: string | null;
  selectedCat2: string | null;
  sectors: string;
}

@Component({
  components: {
    SubMenu,
    customInput,
    PhoneAuthModal,
    SignUpModal,
    noticeModal,
  },
})
export default class mypage extends Vue {
  $refs!: {
    userId: HTMLFormElement;
    userName: HTMLFormElement;
    number: HTMLFormElement;
    email: HTMLFormElement;
    phone: HTMLFormElement;
    modal: HTMLFormElement;
  };

  private userInfo: IUserInfo = {
    userId: this.$store.state.userId,
    userName: '',
    phone: null,
    phoneNumber: '',
    email: '',
    company: '',
    selectedCat1: null,
    selectedCat2: null,
    sectors: '',
  };

  private chkInfo: IUserInfo = {
    userName: '',
    phone: null,
    phoneNumber: '',
    email: '',
    company: '',
    selectedCat1: null,
    selectedCat2: null,
    sectors: '',
  };
  private nameValidFlag: boolean | null = null;
  private emailValidFlag: boolean | null = null;
  private sectors: Array<{ value: string; text: string }> = [];
  private cat1ValidFlag: boolean = true;
  private cat2ValidFlag: boolean = true;
  private companyFlag: boolean = true;
  /*private cat1FcsFlag: boolean = true;
  private cat2FcsFlag: boolean | null = null;*/
  private numberValid = true;
  private numberValidFlag: boolean = true;
  private numberSameFlag: boolean = false;
  private sendNumber = false;
  private phoneDialog = false;
  private modalTitle = '';
  private modalMsg = '';
  private OpenIndicator = openIndicator;
  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface['type'] = 'BACK';
  private strGoOut: string = '';
  async showAlert(
    msg: string,
    type: 'BACK' | 'GO' | 'NO' | 'GoOut' | 'Alert' | 'WITHDRAW',
  ) {
    this.modalSetting({
      title: '',
      content: msg,
      type: type,
    });
    await this.$bvModal.show('myPagePopup');
  }
  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }

  private Cat1: Array<{
    value: string | null;
    text: string;
    disabled?: boolean;
    hidden?: boolean;
  }> = [
    {
      value: null,
      text: '기업 분류를 선택해 주세요.',
      disabled: true,
      hidden: true,
    },
  ];

  private Cat2: Array<{
    value: string | null;
    text: string;
    disabled?: boolean;
    hidden?: boolean;
  }> = [
    {
      value: null,
      text: '기업 구분을 선택해 주세요.',
      disabled: true,
      hidden: true,
    },
  ];

  created() {
    this.getSelectOptions();
  }

  async mounted() {
    if (!this.isLogin) {
      this.strGoOut = '/login';
      this.showAlert('로그인 후 이용이 가능합니다.', 'WITHDRAW');
      return false;
    }
    //회원정보 불러오기
    const res = await this.$store.dispatch('getUserInfo', {
      userId: this.userInfo.userId,
    });
    this.userInfo.userName = res.userName;
    this.userInfo.phone = res.phone;
    this.userInfo.phoneNumber = res.phoneNumber;
    this.userInfo.email = res.email;
    this.userInfo.company = res.company;
    this.userInfo.selectedCat1 = res.selectedCat1;
    this.userInfo.selectedCat2 = res.selectedCat2;
    this.userInfo.sectors = res.sectors;
    this.chkInfo = JSON.parse(JSON.stringify(res));
  }

  private get isLogin() {
    return this.$store.getters.isLogin;
  }

  goOut() {
    this.$router.push(this.strGoOut);
  }

  private phoneOnlyNumber(e: any) {
    const regex = /[^0-9]/g;
    let value = e.target.value;
    if (regex.test(value)) {
      value = value.replace(regex, '');
      this.userInfo.phoneNumber = value;
    }
    if (this.userInfo.phoneNumber.length === 11) {
      this.sendNumber = true;
      this.numberValidFlag = false;
    } else {
      this.numberValidFlag = true;
    }

    if (this.userInfo.phoneNumber === this.chkInfo.phoneNumber) {
      this.sendNumber = false;
      this.numberSameFlag = true;
    } else {
      this.numberSameFlag = false;
    }
  }

  private dropdownShouldOpen(VueSelect: any) {
    if (VueSelect.search.length === 0) return false;
    if (this.userInfo.sectors === '' || this.userInfo.sectors === null) {
      return VueSelect.open;
    }
    if (this.userInfo.sectors !== null && this.userInfo.sectors !== '') {
      return VueSelect.open;
    }
  }

  async getSelectOptions() {
    try {
      const { data } = await this.axios({
        url: '/user/select-option',
        method: 'get',
      });
      const { result, corporation, company, industry } = data;
      if (result) {
        this.Cat1 = this.Cat1.concat(corporation);
        this.Cat2 = this.Cat2.concat(company);
        this.sectors = industry;
      }
    } catch (e) {
      console.error(e);
    }
  }

  private cat1Vaild() {
    this.cat1ValidFlag = this.userInfo.selectedCat1 !== '';
  }

  private cat2Vaild() {
    this.cat2ValidFlag = this.userInfo.selectedCat2 !== '';
  }

  nameCheckValid() {
    if (
      this.userInfo.userName === '' ||
      this.userInfo.userName === this.chkInfo.userName
    ) {
      this.nameValidFlag = null;
      return;
    }
    const regex = /^[가-힣a-zA-Z]+$/;
    this.nameValidFlag = regex.test(this.userInfo.userName);
  }

  emailCheckValid() {
    if (
      this.userInfo.email === '' ||
      this.userInfo.email === this.chkInfo.email
    ) {
      this.emailValidFlag = null;
      return;
    }
    const regex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6})*$/;
    this.emailValidFlag = regex.test(this.userInfo.email);
  }

  companyCheckValid() {
    if (this.userInfo.company === '') {
      this.companyFlag = false;
      return;
    }
    const regex = /^[가-힣a-zA-Z]+$/;
    this.companyFlag = regex.test(this.userInfo.company);
    console.log(this.companyFlag);
  }

  async ok() {
    try {
      const result = await this.axios.get(
        '/sms?userphonenumber=' +
          this.userInfo.phoneNumber +
          '&code=' +
          this.$refs.phone.code,
      );
      if (result.data) {
        await this.numberUpdate();
        this.$refs.phone.code = '';
        this.authModalClose();
      } else {
        this.showAlert('인증코드를 다시 확인해 주세요.', 'Alert');
      }
    } catch (e) {
      return false;
    }
  }

  async numberUpdate() {
    try {
      const { data } = await this.axios({
        url: '/user/updateNumber/' + this.userInfo.userId,
        method: 'patch',
        data: this.userInfo,
      });
      if (data) {
        this.showAlert('휴대폰 번호 변경이 완료되었습니다.', 'Alert');
        this.chkInfo.phoneNumber = JSON.parse(
          JSON.stringify(this.userInfo.phoneNumber),
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  cancel() {
    this.$bvModal.hide('modal-phone');
    this.userInfo.phoneNumber = this.chkInfo.phoneNumber;
    this.numberValid = true;
    return;
  }

  async reSendCode() {
    const randomNumber: string = Math.floor(Math.random() * 1000000)
      .toString()
      .padStart(6, '0');

    try {
      const res = await this.axios.post('/sms', {
        title: '발송 제목',
        message: '본인확인 인증번호(' + randomNumber + ')를 입력해 주세요.',
        randomNumber: randomNumber,
        receiver: [
          {
            mobile: this.userInfo.phoneNumber,
          },
        ],
        isAuth: true,
      });

      if (!res.data.result) {
        this.$toast.open({
          message: '발송 실패 ERROR CODE : ' + res.data.status,
          type: 'error',
          duration: 5000,
        });
        return false;
      }
      this.showAlert('재전송 되었습니다.', 'Alert');
    } catch (e) {
      console.log(e);
    }
  }

  async PhoneNumberAuth() {
    if (this.numberValid) {
      this.userInfo.phoneNumber = '';
      this.numberValid = false;
      return;
    }

    const userPhoneNumber = this.userInfo.phoneNumber;
    if (userPhoneNumber.length !== 11) {
      this.showAlert(
        '잘못된 휴대폰 번호입니다.' + '</br>' + '확인 후 다시시도해주세요.',
        'Alert',
      );
      this.$refs.number.focus();
      return false;
    }
    if (!confirm(`${userPhoneNumber}로 인증번호를 발송하시겠습니까?`)) return;

    try {
      const { data } = await this.axios({
        url: '/user/check-phone-number',
        method: 'get',
        params: { userPhoneNumber },
      });

      if (!data.result) {
        await this.showAlert(data.msg, 'Alert');
        this.numberValid = false;
        return false;
      }
      this.phoneDialog = true;
      this.modalMsg = userPhoneNumber;
      this.$bvModal.show('modal-phone');
      await this.sendSMS(userPhoneNumber);
    } catch (err) {
      console.error(err);
    }
  }

  async sendSMS(phone: string) {
    //인증 문자 발송
    const randomNumber: string = Math.floor(Math.random() * 1000000)
      .toString()
      .padStart(6, '0');

    try {
      const res = await this.axios.post('/sms', {
        title: '발송 제목',
        message: '본인확인 인증번호(' + randomNumber + ')를 입력해 주세요.',
        randomNumber: randomNumber,
        receiver: [
          {
            mobile: phone,
          },
        ],
        isAuth: true,
      });

      if (!res.data.result) {
        this.hidePhoneDialog();
        await this.showAlert(res.data.msg, 'Alert');
        this.$toast.open({
          message: '발송 실패 ERROR CODE : ' + res.data.status,
          type: 'error',
          duration: 5000,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  hidePhoneDialog() {
    this.phoneDialog = false;
  }

  authModalClose() {
    this.phoneDialog = false;
    this.numberValid = true;
  }

  get formSubmit() {
    if (
      this.chkInfo.userName !== this.userInfo.userName ||
      this.chkInfo.email !== this.userInfo.email ||
      this.chkInfo.company !== this.userInfo.company ||
      this.chkInfo.selectedCat1 !== this.userInfo.selectedCat1 ||
      this.chkInfo.selectedCat2 !== this.userInfo.selectedCat2 ||
      this.chkInfo.sectors !== this.userInfo.sectors
    ) {
      if (
        this.userInfo.company !== '' &&
        this.userInfo.selectedCat1 !== '' &&
        this.userInfo.selectedCat2 !== ''
      ) {
        if (
          (this.nameValidFlag === null || this.nameValidFlag) &&
          (this.emailValidFlag === null || this.emailValidFlag) &&
          this.companyFlag &&
          this.cat1ValidFlag &&
          this.cat2ValidFlag
        ) {
          return true;
        }
      }
    }
    return false;
  }

  withdraw() {
    this.$router.push('/withdraw');
  }

  async formValidator() {
    if (!this.formSubmit) {
      return this.showAlert('입력하신 정보를 다시 확인해주세요.', 'Alert');
    }

    try {
      const { data } = await Vue.axios({
        url: '/user/update/' + this.userInfo.userId,
        method: 'patch',
        data: this.userInfo,
      });

      if (data) {
        this.showAlert(' 내 정보 수정이 완료 되었습니다.', 'Alert');
      } else {
        await this.showAlert(data.msg, 'Alert');
      }
      this.chkInfo = JSON.parse(JSON.stringify(this.userInfo));
    } catch (err) {
      console.error(err);
    }
  }
}
