
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Logout extends Vue {
  created() {
    this.$store.dispatch('logout');
    if (this.$store.getters.isToken === null) this.$router.push('/login');
  }
}
