
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { VueEditor } from 'vue2-editor';
import { s3Upload } from '@/utils/s3-upload';
import {
  FileInfoProp,
  popupSetInterface,
  qnaAnswerFileInfo,
} from '../../../interfaces/notice.interface';
import noticeModal from '@/components/common/notice-popup.vue';
import { S3FileCheck } from '@/utils/s3-file-check';

export interface IItemInfo {
  user: string;
  type: string;
  title: string;
  seq: number;
  questionFiles: [];
  maskId: string;
  date: string;
  content: string;
  answerStatus: boolean;
  answerFiles: Array<qnaAnswerFileInfo>;
  answer: string;
  result: boolean;
}
@Component({ components: { VueEditor, noticeModal } })
export default class AnswerModal extends Vue {
  @Prop() seq!: number;
  @Prop() active!: boolean;
  private mode = 'register';
  private flag = false;
  private answerContent: string = '';
  private item: IItemInfo = {
    user: '',
    type: '',
    title: '',
    seq: 0,
    questionFiles: [],
    maskId: '',
    date: '',
    content: '',
    answerStatus: false,
    answerFiles: [],
    answer: '',
    result: false,
  };
  //파일 추가 배열
  private file: any[] = [];
  //추가된 파일 저장 배열
  private uploadFiles: any[] = [];
  //삭제할 파일 저장 배열
  private deleteFile: FileInfoProp[] = [];
  //업로드할 파일 보여주기 용도
  private tempUploadFile: Array<{ name: string; from: string; index: number }> =
    [];
  //기존에 업로드 되었던 파일 정보
  private fileInfo: FileInfoProp[] = [];
  private loading = true;

  private customToolbar = [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline', 'strike'],
    [
      { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
    ],
    [{ header: 1 }, { header: 2 }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ color: [] }, { background: [] }],
    ['link', 'image', 'video'],
  ];

  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface['type'] = 'BACK';
  private strGoOut: string = '';

  @Watch('flag')
  async getContent() {
    if (this.active) {
      try {
        this.loading = true;
        const { data } = await this.axios.get(`qna/${this.seq}`, {
          params: { token: this.$store.getters.isToken },
        });
        this.item = data;
        this.fileInfo = data.answerFiles;
        this.setUploadTempFile();
        //this.file = data.answerFiles;
        this.loading = false;
        if (data.answerStatus) {
          this.mode = 'read';
          this.answerContent = data.answer;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
  @Watch('active')
  setFlag() {
    this.flag = this.active;
  }

  mounted() {
    this.flag = this.active;
  }

  registerBefore() {
    this.showAlert('문의사항을 등록하시겠습니까?', 'GO');
  }

  private handleClickBtn(status: boolean, mode: string) {
    if (!status) {
      this.registerBefore();
    } else {
      if (mode === 'read') {
        this.changeModifyMode();
      } else {
        this.registerBefore();
      }
    }
  }

  async deleteBtn() {
    this.modalSetting({
      title: '',
      content: '문의내역을 삭제하시겠습니까?',
      type: 'DELETE',
    });
    this.$bvModal.show('noticePopup-answer');
  }
  async qnaDelete() {
    try {
      const { data } = await this.axios.get(`/qna/delete/${this.seq}`);
      const { result } = data;
      if (result) {
        this.$toast.open({
          message: '문의내역을 삭제하였습니다.',
          type: 'success',
          duration: 3000,
        });
        await this.closeAfterDelete();
      }
    } catch (e) {
      console.error(e);
    }
  }

  async register() {
    try {
      const uploadResult: { bool: boolean; message: boolean | string } =
        await this.uploadClick();
      const payload = {
        answerContent: this.answerContent,
        answerStatus: true,
        answerFiles: this.fileInfo,
        answerAdminId: this.$store.state.userId,
      };
      if (!uploadResult.bool) {
        const res = await this.axios.post(`/qna/answer/${this.seq}`, payload);
        if (res.data.result) {
          await this.showAlert(res.data.message[0], 'Alert');
          this.mode = 'read';
          await this.getContent();
        } else {
          await this.showAlert(res.data.message[0], 'Alert');
          //await this.s3Delete(this.fileInfo);
        }
      } else {
        await this.showAlert(uploadResult.message.toString(), 'Alert');
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async uploadClick() {
    if (this.uploadFiles.length) {
      const result = new s3Upload(this.uploadFiles, this.fileInfo, 'qna');
      const { bool, message } = result.isUpload(3, '20MB');
      if (!bool) {
        await result.upload(false);
        this.fileInfo = result.getFiles;
        this.file = [];
        this.uploadFiles = [];
        return { message, bool };
      } else {
        return { message, bool };
      }
    }
    if (this.deleteFile.length) await this.s3Delete(this.deleteFile);
    return { bool: false, message: '' };
  }

  setUploadTempFile() {
    this.tempUploadFile = this.fileInfo.map((item: FileInfoProp, index) => {
      return {
        name: item.BFile_Nm,
        from: 'fileInfo',
        index,
      };
    });
  }

  addUploadTempFile() {
    if (this.file.length) {
      this.uploadFiles.push(...this.file);
    }
    this.file.forEach(item => {
      this.tempUploadFile.push({
        name: item.name,
        from: 'file',
        index: item.index,
      });
    });
    /*this.tempUploadFile = this.file.map((item: any, index) => {
      return {
        name: item.name,
        from: 'file',
        index,
      };
    });*/

    /*this.file.forEach(item => {
      this.tempUploadFile.push({
        name: item.name,
        from: 'file',
        index: this.file.length,
      });
    });*/
  }

  async fileDownload(url: string, fileName: string) {
    const isFile = new S3FileCheck('qna', url);
    const result = await isFile.checkFile();
    if (result) {
      const anchorElement = document.createElement('a');
      document.body.appendChild(anchorElement);
      anchorElement.download = `${fileName}`;
      anchorElement.href = url;
      anchorElement.click();
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(url);
    } else {
      //파일이 없는 경우
      this.modalSetting({
        title: '',
        content: '파일이 존재하지 않습니다.',
        type: 'Alert',
      });
      this.$bvModal.show('isFileCheck');
    }
  }

  fileDelete(file: any, index: number) {
    if (file.from === 'fileInfo') {
      this.fileInfo = this.fileInfo.filter(item => {
        if (file.name !== item.BFile_Nm) {
          return item;
        } else {
          this.deleteFile.push(item);
        }
      });
    } else {
      this.uploadFiles = this.uploadFiles.filter(item => {
        return file.name != item.name;
      });
    }
    this.tempUploadFile.splice(index, 1);
  }

  async s3Delete(params: any[] = []) {
    const result = new s3Upload([], params, 'qna');
    await result.delete();
  }

  async selfClose() {
    this.$emit('tableLoad');
    this.$emit('close');
  }

  async closeAfterDelete() {
    this.$emit('MoveToPageOne');
    this.$emit('close');
  }

  changeModifyMode() {
    this.mode = 'register';
    this.item.answerFiles.forEach((item: qnaAnswerFileInfo) => {
      item.name = item.BFile_Nm;
    });
    this.fileInfo = this.item.answerFiles;
  }

  async isOk() {
    await this.register();
    return false;
  }

  isCancel() {
    return false;
  }

  goOut() {
    this.$router.push(this.strGoOut);
  }

  async showAlert(
    msg: string,
    type: 'BACK' | 'GO' | 'NO' | 'GoOut' | 'Alert' | 'WITHDRAW',
  ) {
    this.modalSetting({
      title: '',
      content: msg,
      type: type,
    });
    await this.$bvModal.show('noticePopup-answer');
  }

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }
}
