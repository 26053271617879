
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VariableTable from '@/components/data-workshop/data-visualize/variable-table.vue';
import Chart from '@/components/data-workshop/data-visualize/chart.vue';
import { ChartData, ChartOptions } from 'chart.js';
import { DataWorkshop } from '@/interface/data-workshop';
import CustomInput from '@/components/common/custom-input.vue';
import CustomInputBox, {
  ICustomInputBoxItem,
} from '@/components/data-set/detail/custom-input-box.vue';
import SaveModalTemplate from '@/components/data-workshop/data-visualize/save-modal-template.vue';
import CustomModal from '@/components/common/custom-modal.vue';
import NoticeModal from '@/components/common/notice-popup.vue';
import Tools from '@/components/data-workshop/data-visualize/tools.vue';
import IHeader = DataWorkshop.IHeader;
import CommonSelectBox from '@/components/common/custom-select-box.vue';
import * as dfd from 'danfojs';
import { CHART_COLORS_A, CHART_COLORS_B } from '@/common/visualize';
import { BModal } from 'bootstrap-vue';

const enum SCALING_TYPE {
  MIN_MAX_SCALER = 'MinMaxScaler',
  STANDARD_SCALER = 'StandardScaler',
  LOG_1P = 'log1p',
  EXPM1 = 'expm1',
}

@Component({
  components: {
    CommonSelectBox,
    Tools,
    CustomModal,
    NoticeModal,
    SaveModalTemplate,
    CustomInputBox,
    CustomInput,
    VariableTable,
    Chart,
  },
})
export default class Scaling extends Vue {
  @Prop() joinProject!: DataWorkshop.IJoinProject;
  @Prop() variablesTablePending!: boolean;

  $refs!: {
    chartRef: any;
    'save-scaling-modal': BModal;
    tools: Tools;
  };

  // 차트 마다 다를 수 있는 데이터 상태값
  private checkBoxInfo = [
    { name: 'X', type: 'x', validTypeList: ['factor', 'character'] },
    { name: 'Y', type: 'y', validTypeList: ['integer', 'numeric'] },
  ];
  // 차트 마다 공통된 데이터 상태값
  private selectedVariables: { [key: string]: string }[] = [];
  private chartOptions: any = {
    categoryPercentage: 0.55,
    barPercentage: 0.5,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
    },
  };
  private chartData: ChartData | null = null;
  private isSaveSuccess = false;
  private chartPending = false; // 차트 부분만
  private chartContainerShow = false; // 차트 밖 (타이틀 및 인풋, 저장하기 버튼)
  private savePending = false;
  private isDrawn = false;
  private chartInput: ICustomInputBoxItem[] = [
    {
      key: 'chartTitle',
      title: '차트 제목',
      value: this.joinProject.chartTitle || '',
      placeholder: '차트 제목을 입력해 주세요',
    },
    {
      key: 'keyword',
      title: '주요 키워드',
      value: this.joinProject.keyword || '',
      placeholder: '주요 키워드를 입력해 주세요',
    },
    {
      key: 'description',
      title: '차트 설명',
      value: this.joinProject.description || '',
      placeholder: '차트에 대한 설명을 입력해 주세요',
    },
  ];
  private clickType = '';
  private modalContent = '';
  private colorList: string[] = [];
  private scalingSelected: string | null = null;
  private selectOption: {
    value: string | null;
    text: string;
    active: boolean;
  }[] = [
    {
      value: null,
      text: '선택 안함',
      active: true,
    },
    {
      value: SCALING_TYPE.MIN_MAX_SCALER,
      text: '최소-최대 변환',
      active: false,
    },
    {
      value: SCALING_TYPE.STANDARD_SCALER,
      text: 'Z 변환',
      active: false,
    },
    {
      value: SCALING_TYPE.LOG_1P,
      text: '로그 변환',
      active: false,
    },
    {
      value: SCALING_TYPE.EXPM1,
      text: '지수 변환',
      active: false,
    },
  ];
  private chartHeight: number = 420;
  private xyChange: boolean = false;
  private scalingData: { [key: string]: string | number | null }[] = [];
  private delValues: number[] = [-Infinity, Infinity, NaN];
  private scalingVariableName: string = '';
  private selectedVariableY: IHeader[] = [];
  private scalingSaveBtn: boolean = false;

  get chartType() {
    return this.$route.name || '';
  }

  get projectId() {
    return this.$route.params._id;
  }

  @Watch('joinProject')
  watchJoinProject() {
    this.chartInput = [
      {
        key: 'chartTitle',
        title: '차트 제목',
        value: this.joinProject.chartTitle || '',
        placeholder: '차트 제목을 입력해 주세요',
      },
      {
        key: 'keyword',
        title: '주요 키워드',
        value: this.joinProject.keyword || '',
        placeholder: '주요 키워드를 입력해 주세요',
      },
      {
        key: 'description',
        title: '차트 설명',
        value: this.joinProject.description || '',
        placeholder: '차트에 대한 설명을 입력해 주세요',
      },
    ];
  }

  chartOptionInit() {
    this.colorList = [];
    this.chartHeight = 420;
    this.chartOptions = {
      categoryPercentage: 0.55,
      barPercentage: 0.5,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
        },
      },
    };
    this.$refs.tools.handlePaletteReset();
  }

  handleChangeColor(idx: number, color: string) {
    this.$nextTick(() => {
      this.$refs.chartRef.changeColor(idx, color, this.$route.name);
    });
  }

  // 체크값 x는 하나까지 y는 세개까지만 가능 (차트마다 다름)
  checkValidationOfVariable(
    selectedVariables: { [key: string]: string }[],
    type = 'change',
  ) {
    let nx = 0;
    let ny = 0;
    if (selectedVariables.length > 0) {
      selectedVariables.forEach((v: any) => {
        if (v.x) nx++;
        if (v.y) ny++;
      });
      if (nx > 1) {
        const idx = selectedVariables.findIndex(t => t.hasOwnProperty('x'));
        selectedVariables.splice(idx, 1);
        return false;
      } else if (ny > 3) {
        this.showModal({
          message: 'y 변수는 최대 3개까지만 선택할 수 있습니다.',
          type: 'Alert',
        });
        selectedVariables.pop();
        return false;
      } else if (ny === 0 && type === 'submit') {
        this.showModal({
          message: 'y 변수는 무조건 하나이상 선택해야 합니다.',
          type: 'Alert',
        });
        return false;
      }
    } else if (type === 'submit') {
      this.showModal({ message: '변수를 선택해 주세요', type: 'Alert' });
      return false;
    }
    return true;
  }

  // data group by 함수
  getGroupByData(data: any, x: string) {
    const group: { [key: string]: any }[] = Object.values(
      data.reduce((result: any, currentItem: any) => {
        const key = currentItem[x];
        if (!result[key]) {
          result[key] = { [`${x}`]: key, items: [] };
        }
        result[key].items.push(currentItem);

        return result;
      }, {}),
    );
    return group;
  }
  // 차트마다 결과 처리가 다를 수 있음
  async loadChartData(selectedVariables: { [key: string]: string }[]) {
    if (!this.checkValidationOfVariable(selectedVariables, 'submit')) {
      return;
    }
    this.chartContainerShow = true;
    this.chartPending = true;
    this.chartData = null;

    try {
      const {
        data,
      }: {
        data: {
          result: boolean;
          formatted: any;
          extra?: IHeader[];
        };
      } = await this.axios({
        method: 'GET',
        url: `data-visualize/${this.projectId}/${this.chartType}`,
        params: {
          variables: selectedVariables,
          scalingSelected: this.scalingSelected,
        },
      });
      const { result, formatted: resFormatted } = data;
      if (result) {
        this.chartOptionInit();
        const { data: answerData, selectedVariable, labels } = resFormatted;
        this.selectedVariableY = selectedVariable;
        if (data.formatted) {
          if (this.scalingSelected === null && data.formatted.datasets) {
            this.chartData = data.formatted;
            this.isDrawn = true;

            const tmp: any = [];
            data.formatted.datasets.forEach(
              (d: { [key: string]: string }, i: number) => {
                if (d.backgroundColor) tmp.push(d.backgroundColor);
              },
            );
            this.colorList = tmp;
          } else {
            this.scalingSaveBtn = true;
            this.scalingData = answerData;
            const datasets: any = [];

            for (let i = 0; i < selectedVariable.length; i++) {
              const key = selectedVariable[i].key;
              const name = selectedVariable[i].name;
              const prevData = this.scalingData.map((m: any) =>
                m[key] || m[key] === 0 ? m[key] : NaN,
              );

              const rangeFlag = this.dataCheck(prevData);
              if (rangeFlag) {
                let selectName = '';
                const selectOption = this.selectOption.filter(
                  f => f.value === this.scalingSelected,
                );
                if (selectOption.length) {
                  selectName = selectOption[0].text;
                }
                this.showModal({
                  message: `${name} 변수명은 ${selectName} 스케일링이 불가능합니다.`,
                  type: 'Alert',
                });
                break;
              }

              const resultData: number[] = this.getScalingData({ prevData });
              this.scalingData = this.scalingData.map((m: any, idx: number) => {
                const value = resultData[idx];
                return {
                  ...m,
                  [`${key}`]: this.delValues.includes(value) ? null : value,
                };
              });
              const xValue = selectedVariables.find(f => f['x']);
              if (!xValue) return;

              const x = xValue['x'];
              const groupBy = this.getGroupByData(this.scalingData, x);
              if (!groupBy.length) return;

              const avg = groupBy.map(m => {
                const existsData = m.items.filter((f: any) => f[key]);
                const groupSum = existsData.reduce(
                  (sum: number, currentValue: any) => {
                    return (
                      sum +
                      (currentValue[key]
                        ? Number(currentValue[key].toFixed(6))
                        : 0)
                    );
                  },
                  0,
                );
                return groupSum
                  ? Number(
                      (groupSum / existsData.length)
                        .toString()
                        .replace(/(\d\.\d+?)e[+\-]\d+/, '$1'),
                    ).toFixed(2)
                  : groupSum;
              });
              datasets.push({
                data: avg,
                label: name,
                backgroundColor: CHART_COLORS_B[i],
              });
              this.colorList.push(CHART_COLORS_B[i]);
            }

            this.chartData = {
              labels,
              datasets,
            };
          }

          if (this.xyChange && this.chartData) {
            if (this.chartData.labels) {
              const labelLength = this.chartData.labels.length;
              const selectedLength = this.selectedVariables.length - 1;
              this.chartHeight = Math.max(
                Math.min(30 * labelLength * selectedLength, 900),
                this.chartHeight,
              );
            }
            this.chartOptions.indexAxis = 'y';
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.chartPending = false;
    }
  }

  dataCheck(data: number[]) {
    const max = Math.max(...data);
    const min = Math.max(...data);

    switch (this.scalingSelected) {
      case SCALING_TYPE.LOG_1P:
        return min < 1;
      case SCALING_TYPE.EXPM1:
        return min < 0 || 20 < max;
      default:
        return false;
    }
  }

  getScalingData({ prevData }: { prevData: number[] }) {
    switch (this.scalingSelected) {
      case SCALING_TYPE.MIN_MAX_SCALER:
        return this.setMinMaxScaler(prevData);
      case SCALING_TYPE.STANDARD_SCALER:
        return this.setStandardScaler(prevData);
      case SCALING_TYPE.LOG_1P:
        return this.setLog1pScaler(prevData);
      case SCALING_TYPE.EXPM1:
        return this.setExpm1Scaler(prevData);
      default:
        return [];
    }
  }
  //최소-최대 변환
  setMinMaxScaler(data: any) {
    const scaler = new dfd.MinMaxScaler();
    scaler.fit(data);
    const PPT_MM = scaler.transform(data);
    return PPT_MM;
    //return PPT_MM.map((row: any) => +row[0]);
  }
  //Z 변환
  /*setStandardScaler(setData: any) {
    const scaler = new dfd.StandardScaler();
    let sf = new dfd.Series(setData);
    scaler.fit(sf);
    const sf_enc = scaler.transform(sf);
    return sf_enc.getColumnData;
  }*/
  setStandardScaler(setData: any) {
    const df = new dfd.DataFrame({ Column: setData });
    const column = df['Column'];
    const sample_std_dev = column.std(1);
    const z_score = column.sub(column.mean()).div(sample_std_dev);
    return z_score.values;
  }
  //로그 변환
  setLog1pScaler(setData: any) {
    return setData.map((m: number) => Math.log1p(m));
  }
  //지수 반환
  setExpm1Scaler(setData: any) {
    return setData.map((m: number) =>
      Number(
        Math.expm1(m)
          .toString()
          .replace(/(\d\.\d+?)e[+\-]\d+/, '$1'),
      ),
    );
  }

  showModal({ message, type }: { message: string; type: string }) {
    this.modalContent = message;
    this.clickType = type;
    this.$bvModal.show('missing-rate');
  }
  /*
  showSaveModal() {
    this.$bvModal.show('save-modal');
    this.isSaveSuccess = false;
  }*/

  findKey(
    arr: ({ key: string; value: string } | ICustomInputBoxItem)[],
    key: string,
  ) {
    const cur = arr.find(v => v.key === key);
    if (cur) return cur;
    else return { value: '' };
  }

  async showSaveModal() {
    this.isSaveSuccess = false;
    try {
      /* if (!this.joinProject.title.trim()) {
        return this.showModal({
          message: '데이터 타이틀을 입력해주세요.',
          type: 'Alert',
        });
      }*/

      this.savePending = true;
      const { data } = await this.axios({
        method: 'PATCH',
        url: `data-visualize/${this.projectId}/${this.chartType}`,
        data: {
          title: this.joinProject.title,
          chartTitle: this.findKey(this.chartInput, 'chartTitle').value,
          keyword: this.findKey(this.chartInput, 'keyword').value,
          description: this.findKey(this.chartInput, 'description').value,
          chartOptions: this.chartOptions,
          chartData: this.chartData,
        },
      });
      const { result } = data;
      if (result) {
        this.isSaveSuccess = true;
        this.savePending = false;
        this.$emit('loadJoinProject');
        this.$bvModal.hide('save-modal');
        this.showModal({
          message: '작업한 내용이 저장되었습니다.',
          type: 'Alert',
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  async saveScalingData() {
    let flag = true;
    for (const i of this.selectedVariableY) {
      const { name } = i;
      if (i.model === undefined || i.model.trim() === '') {
        flag = false;
        this.showModal({
          message: `[${name}]의 스케일링한 데이터 변수명을 입력해주세요.`,
          type: 'Alert',
        });
        break;
      }
    }
    if (!flag) return;

    const models = this.selectedVariableY.map(m => m.model);
    if (models.length !== new Set(models).size) {
      this.showModal({
        message: `입력한 변수명이 중복됩니다.\n 다시 입력해주세요.`,
        type: 'Alert',
      });
      return;
    }

    this.savePending = true;
    try {
      const { data } = await this.axios({
        method: 'PATCH',
        url: `data-visualize/${this.chartType}/add-data/${this.projectId}/multi`,
        data: {
          variables: this.selectedVariableY,
          scalingData: this.scalingData,
        },
      });
      const { result } = data;
      if (result) {
        this.$emit('loadJoinProject');
        this.$bvModal.hide('save-scaling-modal');
        this.showModal({
          message: `${this.scalingVariableName} 변수로 스케일링한 데이터를 저장하였습니다.`,
          type: 'Alert',
        });
      }
    } catch (e) {
      console.error(e);
    }
    this.savePending = false;
  }

  scalingModalTrigger() {
    this.$bvModal.show('save-scaling-modal');
    this.scalingVariableName = '';
    this.isSaveSuccess = false;
  }

  formatText(text: string) {
    let tmp = text;
    tmp = tmp.replace(/<br\s*\/?>/gi, '\n');
    return tmp;
  }

  /*limitLength() {
    if (this.modelValue && this.modelValue.length >= this.maxlength) {
      this.modelValue.slice(0, this.maxlength);
    }
  }*/
  moveMyDataLabPage() {
    if (!this.isSaveSuccess) {
      return this.showModal({
        message:
          '변경된 작업 내용이 존재합니다.</br>저장하지 않고 다음으로 넘어가시겠습니까?',
        type: 'WRITE',
      });
    }
    this.goOut();
  }

  goOut() {
    this.$router.push(`/dashboard/${this.projectId}`);
  }
}
