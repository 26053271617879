
import { Component, Vue, Watch } from 'vue-property-decorator';
import { VueEditor } from 'vue2-editor';
import {
  FileInfoProp,
  popupSetInterface,
} from '../../../../interfaces/notice.interface';
import noticeModal from '@/components/common/notice-popup.vue';
import { s3Upload } from '@/utils/s3-upload';
import { Route } from 'vue-router';

@Component({ components: { VueEditor, noticeModal } })
export default class Request extends Vue {
  $refs!: {
    title: HTMLFormElement;
  };
  private type = [
    { text: '서비스 유형을 선택해 주세요.', value: '0', disabled: true },
    { text: '맞춤형 데이터 수집', value: '맞춤형 데이터 수집' },
    { text: '데이터 융합 분석', value: '데이터 융합 분석' },
    { text: '분석 Dashboard 상담', value: '분석 Dashboard 상담' },
    { text: '맞춤형 데이터 컨설팅 상담', value: '맞춤형 데이터 컨설팅 상담' },
    { text: '기타', value: '기타' },
  ];
  private typeWarning: boolean = false;
  private selectedType: string = '0';
  private title: string = '';
  private titleWarning: boolean = false;
  private content: string = '';
  private contentWarning: boolean = false;
  private company: string = '';
  private email: string = '';
  private emailWarning: boolean = false;
  private contactName: string = '';
  private phoneNumber: string = '';
  private phoneWarning: boolean = false;
  private file = [];
  private fileInfo: FileInfoProp[] = [];
  private routeLeaveSkipFlag: boolean = false;
  private pageLeaveMsg: string = `작성한 내용이 모두 삭제됩니다.<br>
    정말로 나가시겠습니까?`;

  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: popupSetInterface['type'] = 'BACK';
  private strGoOut: string = '';

  private customToolbar = [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline', 'strike'],
    [
      { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
    ],
    [{ header: 1 }, { header: 2 }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ color: [] }, { background: [] }],
    ['link', 'image', 'video'],
  ];

  get selectColor() {
    return this.selectedType ? '' : 'grey_color';
  }

  mounted() {
    if (!this.isLogin) {
      this.strGoOut = '/login';
      this.showAlert('로그인 후 이용이 가능합니다.', 'WITHDRAW', '');
    }
    this.company = this.Company;
  }

  @Watch('file')
  setFile() {
    if (this.file.length > 1) {
      const temp = this.file;
      this.file = [];
      this.file.push(temp[0]);
    }
  }

  get Company() {
    return this.$store.getters.isCompany;
  }

  get isLogin() {
    return this.$store.getters.isLogin;
  }

  async beforeRouteLeave(to: Route, from: Route, next: () => void) {
    if (!this.routeLeaveSkipFlag) {
      this.strGoOut = to.path;
      await this.showAlert(
        '작성한 내용이 모두 삭제됩니다.<br>정말로 나가시겠습니까?',
        'GoOut',
        '',
      );
    } else {
      this.pageLeaveMsg = `작성한 내용이 모두 삭제됩니다.<br>정말로 나가시겠습니까?`;
      next();
    }
  }

  ValidateForm() {
    let flag = false;
    let message = '';
    const email_regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const phoneNumber_regex = /^[0-9]+$/;

    if (this.phoneNumber.length) {
      if (!phoneNumber_regex.test(this.phoneNumber)) {
        message = '연락처는 숫자만 입력해 주세요.';
        this.phoneWarning = true;
        flag = true;
      }
      if (this.phoneNumber.length < 9 || this.phoneNumber.length > 11) {
        message = '연락처를 다시 확인해 주시기 바랍니다.';
        this.phoneWarning = true;
        flag = true;
      }
    }
    if (!email_regex.test(this.email)) {
      message = '이메일 형식이 올바르지 않습니다.<br>다시 확인해 주세요.';
      this.emailWarning = true;
      flag = true;
    }
    if (this.content.replace(/<[^>]*>?/g, '').length < 1) {
      message = '내용을 1글자 이상 입력해 주세요.';
      this.contentWarning = true;
      flag = true;
    } else if (this.content.replace(/<[^>]*>?/g, '').length > 500) {
      message = '내용을 500글자 이하로 입력해 주세요.';
      this.contentWarning = true;
      flag = true;
    }
    if (this.title.length < 1) {
      message = '제목을 1글자 이상 입력해 주세요.';
      this.titleWarning = true;
      flag = true;
    }
    if (this.selectedType === '0') {
      message = '서비스 유형을 선택해 주세요.';
      this.typeWarning = true;
      flag = true;
    }
    if (flag) {
      this.showAlert(message, 'Alert', '');
    } else {
      this.showAlert('등록하시겠습니까?', 'GO', '');
    }
  }

  async register() {
    try {
      const uploadResult: true | string = await this.fileUpload();
      const payload = {
        user_id: this.$store.state._id,
        user: this.$store.state.userId,
        type: this.selectedType,
        title: this.title,
        requestContent: this.content,
        company: this.company,
        email: this.email,
        contactName: this.contactName,
        phoneNumber: this.phoneNumber,
        requestFiles: this.fileInfo,
      };
      if (uploadResult === true) {
        const res = await this.axios.post('custom-data-service', payload);
        if (res.data.result) {
          this.strGoOut = '/custom-data-service';
          this.routeLeaveSkipFlag = true;
          await this.showAlert(res.data.message[0], 'customDataOk', '');
        } else {
          await this.showAlert(res.data.message[0], 'Alert', '');
        }
      } else {
        await this.showAlert(uploadResult, 'Alert', '');
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  }

  fileDelete(index: number) {
    this.file.splice(index, 1);
  }

  async fileUpload() {
    const result = new s3Upload(
      this.file,
      this.fileInfo,
      'custom-data-service',
    );
    const { bool, message } = result.isUpload(1, '10MB');
    if (!bool) {
      await result.upload();
      this.fileInfo = result.getFiles;
      this.file = [];
      return true;
    } else {
      return message;
    }
  }

  //모달 관련 메소드
  async showAlert(
    msg: string,
    type:
      | 'BACK'
      | 'GO'
      | 'NO'
      | 'GoOut'
      | 'Alert'
      | 'WITHDRAW'
      | 'customDataOk'
      | 'WRITE',
    title: string,
  ) {
    this.modalSetting({
      title: title,
      content: msg,
      type: type,
    });
    await this.$bvModal.show('noticePopup-write');
  }

  async isOk() {
    await this.register();
    return false;
  }

  isCancel() {
    history.back();
  }

  goOut(args: string) {
    if (args) {
      this.strGoOut = args;
    }
    window.location.href = this.strGoOut;
  }

  async cancelModal() {
    this.routeLeaveSkipFlag = true;
    this.modalSetting({
      title: '맞춤형 데이터 서비스 신청 취소',
      content: '작성한 내용이 모두 삭제됩니다.<br>취소하시겠습니까?',
      type: 'GoOut',
    });
    await this.$bvModal.show('noticePopup-write');
    this.routeLeaveSkipFlag = false;
  }

  async historyBack() {
    this.routeLeaveSkipFlag = true;
    this.strGoOut = '/custom-data-service';
    await this.showAlert(
      '작성한 내용이 모두 삭제됩니다.<br>취소하시겠습니까?',
      'GoOut',
      '맞춤형 데이터 서비스 신청 취소',
    );
    this.routeLeaveSkipFlag = false;
  }

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }
}
