
import { Component, Vue } from 'vue-property-decorator';
import noticeModal from '@/components/common/notice-popup.vue';
import Footer from '@/components/main/Footer.vue';
import { popupSetInterface } from '../../../../interfaces/notice.interface';
import IntroducePlatForm from '@/components/main/about/introduceplatForm.vue';
import ServiceStructureChart from '@/components/main/about/serviceStructureChart.vue';
import KeyFunctions from '@/components/main/about/keyFunctions.vue';
import CustomizedDataServices from '@/components/main/about/customizedDataServices.vue';
import PlatformConfiguration from '@/components/main/about/platformConfiguration.vue';

@Component({
  components: {
    PlatformConfiguration,
    CustomizedDataServices,
    KeyFunctions,
    ServiceStructureChart,
    IntroducePlatForm,
    noticeModal,
    Footer,
  },
})
export default class AboutPlatForm extends Vue {
  /* 캐러셀 내에 노출될 페이지의 index(KeyFunctions)*/
  private page: number = 0;

  /*전체 페이지의 정보(KeyFunctions)*/
  private functionPages: {
    carouselTitle: string;
    carouselSub: string;
    page: number;
    selectImg: number;
    pageContent: {
      pageImg: string;
      pageTitle?: string | undefined;
    }[];
  }[] = [
    {
      carouselTitle: '데이터 탐색하기',
      carouselSub:
        '데이터를 한 눈에 파악 가능하고 조회 및 다운로드할 수 있습니다.',
      page: 1,
      selectImg: 0,
      pageContent: [
        {
          pageImg: 'sns',
          pageTitle: '뉴스/SNS 트렌드 데이터',
        },
        {
          pageImg: 'mobileData',
          pageTitle: '모바일 행동 데이터',
        },
        {
          pageImg: 'publicData',
          pageTitle: '공공 데이터',
        },
        {
          pageImg: 'serviceDashboard',
          pageTitle: '결제 데이터',
        },
        {
          pageImg: 'surveyData',
          pageTitle: '조사 데이터',
        },
      ],
    },
    {
      carouselTitle: '데이터 수집하기',
      carouselSub:
        '비조사와 조사 데이터를 통합 검색하고 북마크를 통해 저장할 수 있습니다.',
      page: 2,
      selectImg: 0,
      pageContent: [
        {
          pageImg: 'dataCollection',
          pageTitle: '',
        },
      ],
    },
    {
      carouselTitle: '데이터 분석하기',
      carouselSub:
        '북마크 데이터/사용자 업로드 데이터를 활용하여 데이터 분석 및 시각화 작업을 할 수 있습니다.',
      page: 3,
      selectImg: 0,
      pageContent: [
        {
          pageImg: 'data select',
          pageTitle: '데이터 선택하기',
        },
        {
          pageImg: 'data processing',
          pageTitle: '데이터 가공/전처리',
        },
        {
          pageImg: 'data visualizing',
          pageTitle: '데이터 시각화',
        },
      ],
    },
    {
      carouselTitle: '데이터 확인하기',
      carouselSub:
        '저장된 데이터 분석 결과를 마이 데이터 허브에서 확인할 수 있습니다.',
      page: 4,
      selectImg: 0,
      pageContent: [
        {
          pageImg: 'data result',
          pageTitle: '',
        },
      ],
    },
    {
      carouselTitle: '추가 조사하기',
      carouselSub:
        '분석 후, 아래의 플랫폼을 통해 조사+비조사 데이터로 새로운 인사이트를 도출해보세요!',
      page: 5,
      selectImg: 0,
      pageContent: [
        {
          pageImg: 'quantitative survey',
          pageTitle: '온라인 정량조사',
        },
        {
          pageImg: 'qualitative survey',
          pageTitle: '온라인 정성조사',
        },
        {
          pageImg: 'supply system',
          pageTitle: '온라인 패널공급 시스템',
        },
        {
          pageImg: 'recommendation system',
          pageTitle: '설문 문항 추천 시스템',
        },
      ],
    },
  ];

  /* card내에 뿌릴 데이터(CustomizedDataServices)*/
  private dataTypes: {
    icon: string;
    cardTitle: string;
    cardSubtitle1: string;
    cardSubtitle2: string;
  }[] = [
    {
      icon: 'trendData',
      cardTitle: '뉴스/SNS 트렌드 데이터',
      cardSubtitle1: '웹 크롤링 수집을 통한',
      cardSubtitle2: 'Buzz분석 ',
    },
    {
      icon: 'mobileData',
      cardTitle: '모바일 행동 데이터',
      cardSubtitle1: '모바일 행동 데이터',
      cardSubtitle2: '수집 및 분석',
    },
    {
      icon: 'publicData',
      cardTitle: '공공 데이터',
      cardSubtitle1: '서울시 공공 데이터',
      cardSubtitle2: '분석 ',
    },
    {
      icon: 'paymentData',
      cardTitle: '결제 데이터',
      cardSubtitle1: '상권 현황 및 평가 결제',
      cardSubtitle2: '데이터 수집 및 분석',
    },
    {
      icon: 'surveyData',
      cardTitle: '조사 데이터',
      cardSubtitle1: '상권 현황 및 평가 결제',
      cardSubtitle2: '데이터 수집 및 분석',
    },
  ];

  /*플랫폼 구성 내 리스트 데이터(PlatformConfiguration)*/
  private configTypes?: {
    title: string;
    sub: {
      subtitle: string;
      subTxt: string;
    }[];
  }[] = [
    {
      title: '데이터 탐색',
      sub: [
        {
          subtitle: '뉴스/SNS 트렌드 데이터',
          subTxt: '뉴스/SNS 트렌드 데이터 조회 및 탐색',
        },
        {
          subtitle: '모바일 행동 데이터',
          subTxt: '모바일 행동 데이터 조회 및 탐색',
        },
        {
          subtitle: '공공 데이터',
          subTxt: '공공 데이터 조회 및 탐색',
        },
        {
          subtitle: '결제 데이터',
          subTxt: '결제 데이터 조회 및 탐색',
        },
      ],
    },
    {
      title: '데이터 마켓',
      sub: [
        {
          subtitle: '데이터 셋',
          subTxt: '통합 데이터 조회 및 다운로드',
        },
        {
          subtitle: '융합 데이터',
          subTxt: '이종 데이터 결합 예시 대한 리포트',
        },
        {
          subtitle: '맞춤형 데이터 서비스',
          subTxt: '데이터 컨설팅 및 맞춤형 서비스',
        },
      ],
    },
    {
      title: '데이터 작업소',
      sub: [
        {
          subtitle: '시각화 만들기',
          subTxt: '단일/이종 데이터의 시각화',
        },
        {
          subtitle: '마이 데이터 랩',
          subTxt: '분석 결과를 대시보드에 저장/관리',
        },
      ],
    },
    {
      title: '알림',
      sub: [
        {
          subtitle: '플랫폼 소개',
          subTxt: '플랫폼 시스템에 대한 소개',
        },
        {
          subtitle: '공지사항',
          subTxt: '플랫폼 시스템에 대한 공지',
        },
        {
          subtitle: '자주하는 질문',
          subTxt: '플랫폼 시스템에 대한 공통 문의사항',
        },
        {
          subtitle: '문의사항',
          subTxt: '플랫폼 시스템에 대한 문의사항 등록',
        },
      ],
    },
  ];

  private modalContent: string = '';
  private titleModal: string = '';
  private clickType: string = '';
  private strGoOut: string = '';

  async created() {
    if (this.$store.getters.isLogin) await this.mainVerify();
  }

  async mounted() {
    /* 새로고침 시 보여져야하는 페이지와 이미지 */
    this.functionPages.map(el => {
      el.selectImg = 0;
    });
  }

  /* 페이지 이동 시 이미지 데이터를 뿌리기 위한 함수(keyFunctions)*/
  moveStep(selected: number) {
    //현재 페이지
    this.page = selected;
    //전체페이지의 이미지 위치 초기화
    this.functionPages.map(el => {
      el.selectImg = 0;
    });
    //이동한 페이지 한정 초기화
    /*   this.functionPages[selected].selectImg = 0;*/
  }

  /*캐러셀 내부에 선택한 이미지 데이터를 뿌리기 위한 함수(keyFunctions)*/
  selectImg(page: number, selected: number) {
    //선택한 페이지 지정
    this.functionPages.map(el => {
      if (el.page === page) {
        el.selectImg = selected;
      }
    });
  }

  async mainVerify() {
    try {
      const { data } = await this.axios({
        url: 'user/main-verify',
      });
      const { result } = data;
      if (!result) {
        await this.$store.dispatch('logout');
      }
    } catch (e) {
      console.error(e);
    }
  }

  goOut() {
    this.$router.push(this.strGoOut);
  }

  async showAlert(args: string[]) {
    this.modalSetting({
      title: '',
      content: args[0],
      type: args[1],
    });
    if (args[2]) {
      this.strGoOut = args[2];
    }
    await this.$bvModal.show('noticePopup-main');
  }

  modalSetting(param: popupSetInterface) {
    const { title, content, type } = param;
    this.titleModal = title;
    this.modalContent = content;
    this.clickType = type;
  }
}
