
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class SaveModalTemplate extends Vue {
  @Prop() isShow!: boolean;
  @Prop() value!: string;
  @Prop() isSave!: boolean;
  @Prop() pending!: boolean;

  get modelValue() {
    return this.value;
  }

  set modelValue(value) {
    this.$emit('input', value);
  }
}
